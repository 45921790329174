export const INTERNAL_SERVER_ERROR = 500
export const UNAUTHORIZED_REQUEST = 401
export const DEFAULT_SOURCE_SECTION = 'ba01ca5f-2534-4ec2-93b2-aae83e910013'
export const CHECKED_VIEW = 'f8704615-c2ed-4647-aff1-7513d82e8ed0'
export const MAX_FILE_SIZE_MB = 10
export const PARAGRAPH_LENGTH = 100

export const pathsWithSpecialStyles = [
  '/settings/compentency-test/add-test',
  '/settings/compentency-test/edit-test',
  '/settings/compentency-test/quiz-faq',
  '/settings/skill-checklist/add',
  '/settings/skill-checklist/edit',
  '/settings/compentency-test/preview',
  '/settings/compentency-test/add-test/preview',
  '/settings/compentency-test/edit-test/preview',
  '/settings/skill-checklist/add/preview',
  '/settings/skill-checklist/add/preview/subtopic',
  '/settings/skill-checklist/edit/preview',
  '/settings/skill-checklist/edit/preview/subtopic',
  '/settings/referance-form',
  '/settings/edit-form',
]

export const documentEssentialSub = 'This document will be essential to upload when provider is onboarding.'

export const agencyEmployeeOption = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

export const emplyoeeTypeOption = [
  {
    label: 'Both',
    value: 'both',
  },
  {
    label: 'Employee',
    value: 'employee',
  },
  {
    label: 'Subcontractor',
    value: 'sub_contractor',
  },
]

export const autoAssignOption = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Pre Hire',
    value: 'pre_hire',
  },
  {
    label: 'Post Hire',
    value: 'post_hire',
  },
]

export const validationuponOption = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Warn',
    value: 'warn',
  },
  {
    label: 'Refuse',
    value: 'refuse',
  },
]

export const checkboxOptions = [
  { label: 'Expiration Date', value: 'is_expiration_required' },
  { label: 'Requirements Completed Date', value: 'is_completion_date_required' },
  { label: 'Documents', value: 'is_document_required' },
  { label: 'Verification', value: 'is_verification_required' },
]

export const filterOption = [
  { filterName: 'Certifications' },
  { filterName: 'Specialties' },
  { filterName: 'Credential Category' },
  { filterName: 'Agency Employment' },
  { filterName: 'Auto Assign' },
  { filterName: 'WFP Enforcement' },
]

export const filterSubMenu: Record<
  string,
  {
    name: string[]
  }
> = {
  'Credential Category': {
    name: [
      'No Category Selection',
      'All Credential Categories',
      'CPR Level',
      'Certification',
      'HR',
      'Competency Tests',
      'Skill Checklists',
      'Employment Documents',
      'Surveys',
      'Mobile Skills',
      'Mobile Tests',
      'Mobile Edocs',
    ],
  },
  'Agency Employment': {
    name: ['Either', 'Yes', 'No'],
  },
  'Auto Assign': {
    name: ['Application Start', 'New Hire', 'None'],
  },
  'WFP Enforcement': {
    name: ['All', 'None', 'New Hire', 'Applicant', 'Worker', 'Both'],
  },
}

export const provider = 'provider'

export const facility = 'facility'

export const LINK_EXPIRY = {
  LINK_EXPIRE: 'The link is expired!',
  SORRY_TEXT: 'Sorry, this link is either expired or is used once.',
  LOGIN: 'Go To Login',
}

export const verifyFacility = {
  TYPE: 'Type',
  BEDS: 'Beds',
  EMAIL: 'Email',
  PHONE: 'Phone',
  ADDRESS: 'Address',
  BASIC_DETAIL: 'Basic Details',
  BASIC_NEXT: 'Next: Infrastructure Details',
  CREATE_FACILITY_AS: 'Creating Facility as',
  FACILITY_CLIENT: 'Facility Client',
  MASTER_CLIENT: 'Master Client',
  INFRASTRUCTURE_DETAIL: 'Infrastructure Details',
  NEXT_ADDRESS: 'Next: Address Details',
  ADDRESS_DETAIL: 'Address Details',
  FLOOR_DETAILS: 'Floor Details',
  GENERAL_INSTRUCTION: 'General Instructions',
  NEXT_GENERAL_SETTINGS: 'Next: General Settings',
  GENERAL_SETTINGS: 'General Settings',
  DEFAULT_ORDER_TYPE: 'Default Order Type',
  NEXT_FLOOR: 'Next: Floor Settings',
  FLOOR: 'Floor Settings',
  NEXT_SHIFT: 'Next: Shift Options',
  SHIFT: 'Shift Options',
  NEXT_TIME_ENTRY: 'Next: Time Entry & Approval',
  TIME_ENTRY: 'Time Entry & Approval',
  NEXT_FACILITY: 'Next: Facility Portal Settings',
  FACILITY: 'Facility Portal Settings',
  NEXT_INVOICE: 'Next: Invoice Settings',
  INVOICE: 'Invoice Settings',
  PREVIOUS: 'Previous',
}

export const myFacilityText = {
  FIELD_NAME: 'FIELD NAME',
  DISPLAY_ON_FORM: 'DISPLAY ON FORM?',
  IS_FIELD_MANDATORY: 'IS FIELD MANDATORY?',
  CONTACT_DETAILS: 'Contact Details',
  GENERAL_INSTRUCTIONS: 'General Instructions',
  INFRASTRUCTURE: 'Infrastructure',
  LOGO_TEXT_UPPER: 'Drag and drop or click here to upload logo.',
  LOGO_TEXT_LOWER: 'Upload logo in 187 x 31 px size and in jpg,png or svg format.',
  REUPLOAD: 'Re-upload',
}

export const settingTab = {
  PER_DIEM: 'Per Diem',
  LONG_TERM_CONT: 'Long Term Contract',
  TRAVEL_ASSIGN: 'Travel Assignments',
  PER_DIEM_VALUE: 'per_diem',
  LONG_TERM_CONT_VALUE: 'long_term',
  TRAVEL_ASSIGN_VALUE: 'travel',
  OTHER_SETTINGS: 'Other Settings',
  WORK_COME_CODE: 'Work Comp Code',
  OR: 'OR',
  CLIENT_SPECIAL_FLOOR: 'Client Specific Floor Options',
  ASSOCIATE_WITH_SPEC: 'Associate floors with specialties',
  ASSOCIATE_WITH_SPEC_SUB:
    'Check this box if you want specialties below to associate with the appropriate Floor on the Order form. If you do not check this box, all floors below will show for any specialties that you select on the order form.',
  INVOICE_SHOULD_BE_PAID: 'Invoice Should Be Paid Within',
  DAYS: 'Days',
  ALLOW_CANCEL: 'Allow Cancellations',
  CANCELATION_ADVA: 'Minimum Cancellation Advance',
  HOURS: 'Hours',
  DISPLAY_PROVIDER_REQUEST: 'Display Provider Requests',
  ACCEPT_PROVIDER_REQUEST: 'Accept Provider Requests',
  ALLOWS_SCHEDULING_WARNING: 'Allowed Scheduling Warnings For Approval',
  SET_CLIENT_CONFO: 'Set Client Confirmation when Accepting Requests',
  DISPLAY_BILL_RATE: 'Display Bill Rate',
  SYSTEM_OPTION: 'System Options',
  CLIENT_SELECTED: 'Client Selected Shifts',
  TIME_CARD_SETTING: 'Timecard Settings',
  DEFAULT_LUNCH_MINUTES: 'Default Lunch Minutes',
  MINUTE_ROUNDING: 'Time Card Minute Rounding',
  INCREMENT: 'Increment',
  DIRECTION: 'Direction',
  USE_SYSTEM_SETTING: 'Use System Settings',
  WORKFORCE_PORTAL: 'Workforce Portal Time Entry Settings',
  CLIENT_CLOCKING_DATA: 'Client Clocking Data',
  SOURCE: 'Source',
  TIME_APPROVAL: 'Time Approval Method',
  ALLOWED_ENTRY_TYPE: 'Allowed Entry Types',
  CHECKED_MISSED_MEAL_FOR: 'Check for missed meal break',
  CLOCK_IN: 'Enforce Geofence When Clocking In & Out',
  GEOFENCE_TIME_APPROVAL: 'Enforce Geofence for Time Approval',
  DEFAULT_RADIUS: 'Default Geofence Radius',
  MAP_LOCATION: 'Client Map Location',
  LOCATION_DESCRIPTION: '5050 Kimball Bridge Rd Johns Creek GA 30005',
  ADD_NEW_TIMING: 'Add New Timing',
}

export const UserSystemIncrementData = [
  {
    label: 'none',
    value: 0,
  },
  {
    label: '5 min',
    value: 5,
  },
  {
    label: '6 min',
    value: 6,
  },
  {
    label: '10 min',
    value: 10,
  },
  {
    label: '15 min',
    value: 15,
  },
  {
    label: '20 min',
    value: 20,
  },
  {
    label: '30 min',
    value: 30,
  },
  {
    label: '60 min',
    value: 60,
  },
]

export const UserSystemRoundUpData = [
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Round up',
    value: 'round_up',
  },
  {
    label: 'Round Down',
    value: 'round_down',
  },
]
export const TimeApprovalMethodData = [
  {
    label: 'Signed Timecard',
    value: 'signed_timecard',
  },
  {
    label: 'E Signature',
    value: 'esignature',
  },
  {
    label: 'Facility',
    value: 'facility',
  },
]

export const customClockingSourceData = [
  {
    label: 'Workforce',
    value: 'workforce',
  },
  {
    label: 'Facility',
    value: 'facility',
  },
  {
    label: 'Agency',
    value: 'agency',
  },
]
export const ScheduleAndRequestText = {
  SCHEDULE: 'Schedule',
  SHIFTS: 'Shifts',
  HOW_MANY_TEMPS_CAN_REQUEST_SHIFT: 'how_many_temps_can_request_a_shift',
  HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST: 'before_how_many_hours_the_temp_can_take_back_the_request_for_the_shifts',
}

export const schedulingWarning = [
  { name: 'Compliance', value: 'compliance' },
  { name: 'Orientation', value: 'orientation' },
  { name: 'Double Booking', value: 'double_booking' },
  { name: 'Overtime', value: 'overtime' },
  { name: 'Double Shift', value: 'double_shift' },
  { name: 'Scheduling', value: 'scheduling' },
  { name: 'Weekend Threshold', value: 'weekend_threshold' },
]

export const allowedEntryType = [
  { name: 'Regular', value: 'regular' },
  { name: 'Meal break', value: 'meal_break' },
  { name: 'Other break', value: 'other_break' },
  { name: 'Call back', value: 'callback' },
  { name: 'On call', value: 'on_call' },
]

export const certificateBg = [
  { color: '#1517D1', background: '#ECEDFD' },
  { color: '#220EB1', background: '#F2F0FE' },
  { color: '#FF6C2D', background: '#FFEAE0' },
  { color: '#FB8600', background: '#FFEFDC' },
  { color: '#510E9A', background: '#F7F1FE' },
]

export const durationType = [
  {
    value: 'day',
    label: 'Days',
  },
  {
    value: 'week',
    label: 'Weeks',
  },
  {
    value: 'month',
    label: 'Months',
  },
  {
    value: 'year',
    label: 'Years',
  },
]

export const DELETE_MODAL_COMPLIANCE_MANAGER_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Compliance Manager?',
  DELETE_DESC: 'Are you sure you want to delete this compliance settings',
}

export const DELETE_MODAL_LICENSE_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'License Settings?',
  DELETE_DESC: 'Are you sure you want to delete this license',
}

export const DELETE_FLOOR_DETAILS_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Floor Details?',
  DELETE_DESC: 'Are you sure you want to delete this floor',
}

export const DELETE_MODAL_FACILITY_PROVIDER_SETTING_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD: 'Facility Provider Settings?',
  DELETE_DESC_STATUS: 'Are you sure you want to delete this Status ',
  DELETE_DESC_REASON: 'Are you sure you want to delete this Reason ',
}

export const DELETE_MODAL_SPECIALTIES_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Specialties Settings?',
  DELETE_DESC: 'Are you sure you want to delete this speciality',
}

export const DELETE_MODAL_ROLE_MANAGEMENT_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Role Management Settings?',
  DELETE_DESC: 'Are you sure you want to delete this role',
}

export const DELETE_FACILITY_VERIFICATION_REJECTION_REASONS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Facility Verification Rejection Reasons?',
  DELETE_DESC: 'Are you sure you want to delete this facility verification rejection reason',
}

export const DELETE_PROVIDER_VERIFICATION_REJECTION_REASONS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Provider Verification Rejection Reasons?',
  DELETE_DESC: 'Are you sure you want to delete this provider verification rejection reason',
}

export const DELETE_SHIFT_TYPE = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Shift Type?',
  DELETE_DESC: 'Are you sure you want to delete this shift type',
}

export const DELETE_SHIFT_TIMINGS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Shift Timings?',
  DELETE_DESC: 'Are you sure you want to delete this shift timing',
}

export const DELETE_REJECTED_TIMECARD_REASONS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Reject Timecard Reasons?',
  DELETE_DESC: 'Are you sure you want to delete this reject timecard reason',
}

export const DELETE_COMPETENCY_TEST_SETTINGS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Competency Test Settings?',
  DELETE_DESC: 'Are you sure you want to delete this competency test settings',
}

export const DELETE_SKILLS_CHECKLIST_SETTINGS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Skills Checklist Settings?',
  DELETE_DESC: 'Are you sure you want to delete this skills checklist settings',
}

export const DELETE_SHIFT_CANCEL_REASONS = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Shift Cancel Reasons?',
  DELETE_DESC: 'Are you sure you want to delete this shift cancel reason',
}

export const DELETE_MODAL_REFERENCE_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Reference Form Settings?',
  DELETE_DESC: 'Are you sure you want to delete this reference form settings',
}

export const DELETE_MODAL_TEXT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Delete facility type?',
  DELETE_DESC: 'Are you sure you want to delete this facility type',
}

export const BUTTON = {
  UPDATE: 'Update',
  CREATE: 'Create',
  SAVE: 'Save',
  CREATE_STATUS: 'Create Status',
  ADD_REASON: 'Add Reason',
}

export const FACILITY_TYPE = {
  CREATE: 'Add Facility Type',
  UPDATE: 'Update Facility Type',
}

export const FLAG_COLORS = ['#28714D', '#220EB1', '#FB8600', '#D1293D', '#68696C', '#6111B9', '#0C90B6', '#D11575', '#FF6C2D', '#B710D2']

export const MY_FACILITY_FLAG_COLORS = ['#220EB1', '#6111B9', '#59D1F4', '#359766', '#FFAD4F', '#EF5DA8', '#5D5FEF']

export const COMMON = {
  NAME: 'Name',
  WORK_COMP_CODE: 'Work Comp Code',
  FACILITY_TYPE: 'Facility Type',
  SELECT_REASON: 'Select Reason',
  ADDITIONAL_DETAIL: 'Additional details',
  INVITE: 'Invite',
  EMAIL: 'Email',
  ACTION: 'ACTIONS',
  LICENSE: 'License',
  UPDATE: 'Update',
  SEARCH_LICENCE: 'Search License',
  SEARCH_SPECIALITY: 'Search Speciality',
  SEARCH_FACILITY: 'Search Facility',
  SEARCH_SHIFT: 'Search with shift ID',
  SPECIALITY: 'Speciality',
  BACK: 'Back',
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  TODAY: 'Today',
  TIME_ACTI: 'Time Activity',
  FACILITY_ACC: 'Facility Access',
  DATE: 'Date',
  START_TIME: 'Start Time',
  END_TIME: 'End Time',
  FLOOR: 'Floor',
  SEARCH: 'Search',
  COLUMNS: 'Columns',
  SHIFT_ID_FROM: 'From Shift Id',
  SHIFT_ID_TO: 'To Shift Id',
  ADD_CONTACT: 'Add Contact',
  FLAG: 'Flag',
  REQUEST: 'Request',
  FOR_WORK: 'For Work',
  SEARCH_PROVIDER: 'Search Provider',
  DESCRIPTION: 'Description',
  STATUS: 'Status',
}

export const SHIFTS = {
  FORM_DATE: 'From Date',
  TO_DATE: 'To Date',
  CANCEL: 'Cancel',
  SEARCH_CONTACT: 'Search Contact',
  ADD_CONTACT: 'Add Contact',
  VIEW_TIMECARD: 'View Timecard',
  REPOST: 'Repost',
  VIEW_REQUEST: 'View Request',
  VIEW_DETAILS: 'View Details',
  EDIT_SHIFT: 'Edit Shift',
  MAIN_HEAD_DELETE: 'Are you sure you want to cancel?',
  SUB_HEAD_DELETE: 'Shifts created will be deleted if you click on cancel',
  YES_CANCEL: 'Yes, Cancel',
  GO_BACK: 'Go back',
  UPDATE: 'Update',
  CANCEL_SHIFT: 'Cancel Shift?',
  SHIFTS: 'Shifts',
  SELECT_FACILITY: 'Select Facility',
  SHIFT_TYPE: 'Shift Type',
  DATE: 'Date',
  DETAILS: 'Details',
  INVITE: 'Invite',
  CREATE_SHIFT: 'Create Shift',
  NEW_SHIFT: 'New Shift',
  PER_DIEM: 'Per Diem',
  LONG_TERM: 'Long Term',
  TRAVEL: 'Travel',
  DRAFT: 'Draft',
  NO_FACILITY: 'No Facility',
  NO_DATE: 'No Date',
  PROVIDER: 'Provider',
  INVITE_INSTRUCTION: 'Please select a license and speciality before inviting the provider.',
  CONTACT: 'Contact',
  END_TIME: 'End Time',
  START_TIME: 'Start Time',
  OPENINGS: 'Openings',
  FLOOR: 'Floor',
  SHIFT_DETAILS: 'Shift Details',
  RESET: 'Reset',
  CREATE: 'Create',
  POST: 'Post',
  INVITE_PROVIDER: 'Invite Providers',
  SEARCH_PRO: 'Search Providers',
  SELECTED: 'Selected',
  SELECT_ALL: 'Select All',
  REPEAT_ON: 'Repeat On',
  SELECT_DATES: 'Select Dates',
  CARD_HEAD: 'Your created shift will appear here',
  CARD_SUBHEAD: 'When you create a shift it will appear here. You can edit, clone or delete the created shift from here',
  DELETE_SHIFT_HEAD: 'Are you sure to want to delete shift?',
  ACTIVITY: 'Activity',
  NO_ACTIVITY: 'No Activity',
  NO_ACTIVITY_SUB: 'No activity in this shift currently.',
  SHIFT_DESC: 'Shift Description',
  REQUESTED: 'Requests',
  INVITED: 'Invitees',
  CANCEL_BY: 'Cancelled by',
  CANCEL_ON: 'Cancel On',
  CANCEL_REASON: 'Cancellation Reason',
  SELECTED_DATES: 'Select Dates',
  TABLE: {
    SHIFT_ID: 'SHIFT ID',
    SHIFT_DATE: 'SHIFT DATE',
    SHIFT_TIME: 'SHIFT TIME',
    LICENSE: 'LICENSE',
    SPECIALTIES: 'SPECIALTIES',
    STATUS: 'STATUS',
    NO_OF_REQUEST: 'NO. OF REQUEST',
  },
  REQUEST_TABLE: {
    REQUESTED_BY: 'REQUESTED BY',
    LICENSE: 'LICENSE',
    REQUESTED_ON: 'REQUESTED ON',
    ACTION: 'ACTION',
    STATUS: 'STATUS',
  },
  INVITE_TABLE: {
    INVITEE: 'INVITEE',
    REQUIRE_CREDENTIAL: 'REQUIRE CREDENTIAL',
    LICENSE: 'LICENSE',
    INVITED_ON: 'INVITED ON',
    ACTION: 'ACTION',
    STATUS: 'STATUS',
  },
}

export const BUTTON_STRING = {
  ADD_CONTACT: 'Add Contact',
  SAVE: 'Save',
  SAVE_CHANGES: 'Save Changes',
  EDIT_profile: 'Edit Profile',
  BACK: 'BACK',
  CHANGE_PASS: 'Change Password',
  EDIT: 'Edit',
  DELETE: 'Delete',
  ADD_HOLIDAY: 'Add Holiday',
  ADD: 'Add',
  CANCEL: 'Cancel',
  UPLOAD_DOCUMENT: 'Upload Document',
  UPDATE: 'Update',
  CANCEL_SHIFT: 'Cancel Shift',
  RE_OPEN: 'Re-Open',
  RE_POST: 'Re-Post',
  ASSIGN: 'Assign',
  INVITE: 'Invite',
  ADD_USER: 'Add User',
  SEND_INVITATION: 'Send Invitation',
  VIEW_ACTIVITY: 'View Activity',
  REJECT: 'Reject',
  SAVE_AND_APPROVE: 'Save and Approve',
  SUBMIT_REVIEW: 'Submit Review',
  APPROVE_NOW: 'Approve Now',
  RATE_PROVIDER: 'Rate Provider',
  VIEW_TIMECARD: 'View Timecard',
  ADD_EVALUATION: 'Add Evaluation',
  REQ_TO_WORK: 'Request to Work',
  CREATE_NEW_SHIFT: 'Create New Shift',
  SEND_REQUEST: 'Send Request',
  DOWNLOAD_PDF: 'Download PDF',
  SAVE_EVALUATION: 'Save Evaluation',
  RESET: 'Reset',
  APPLY: 'Apply',
  VIEW_CREDENTIALS: 'View Credentials',
  WORK_HISTORY: 'Work History',
  SHIFT_HISTORY: 'Shift History',
  CHAT: 'Chat',
  PREVIOUS: 'Previous',
  NEXT: 'Next',
}

export const PAGE_TITLE_STRING = {
  FACILITY_PROFILE: 'My Facility',
  FACILITY_CONTACTS: 'Facility Contacts',
  TIME_AND_ATTENDANCE: 'Time & Attendance',
  MY_PROFILE: 'My Profile',
  SHIFTS: 'Shifts',
  PROVIDERS: 'Providers',
  PROVIDER_PROFILE: 'Provider Profile',
  PROVIDER_REJECT_REASON: 'Provider DNR Reason',
}

export const PROVIDER_STRING = {
  OPEN_SHIFT: 'Open Shifts',
  UNCONFIRMED_SHIFT: 'Unconfirmed Shifts',
  REQUESTED_SHIFT: 'Requested Shifts',
  AUTOSCHEDULING_SHIFT: 'Auto Scheduling Shifts',
  NEW_EVALUATION: 'New Evaluation',
  COMPLETE_EVALUATION: 'Completed Evaluation',
  EVALUATION: 'Evaluation',
  SHIFT: 'Shift',
  PROFESSIONAL_LICENCE: 'Professional Licenses',
  REQUIRED_DOCUMENT: 'Required Documents',
  MEDICAL_DOCUMENT: 'Medical Documents',
  HR_DOCUMENT: 'HR Documents',
  LICENSE: 'LICENSE',
  SPECIALTY: 'SPECIALITY',
  BRIEF_INFO: 'BRIEF INFORMATION',
  BIRTH_DATE: 'BIRTH DATE',
  ADDRESS: 'ADDRESS',
  OVERALL_RATING: 'Overall ratings for this provider',
  SORT_BY: 'Sort By',
  ATTENDANCE_SCORE: 'Attendance Score',
  HOUSE_WORKED: 'Hours Worked',
  ON_TIME_RATE: 'On-time Rate',
  SHIFT_COMPLETED: 'Shifts Completed',
  SHOW_RATE: 'Show Rate',
  LOCATION: 'LOCATION',
  MOBILE_EMAIL: 'MOBILE AND EMAIL',
  WORK_EXPERIENCE_WITH: 'How was your experience working with',
  SELECT_SHIFT: 'Select Shift to review',
  PUNCTUALITY: 'Punctuality',
  CLIENT_COM: 'Clinical Competence',
  CO_AND_CO: 'Communication and Collaboration',
  REPORT_ISSUES: 'Report Issue ?',
  ISSUE_DES: 'If you report this as issue, It will be submitted to Nurses Now team. The team will look into the issue.',
  CLINICAL: 'Clinical',
  PROFESSIONAL: 'Professional',
  ALL: 'All',
  DNR: 'DNR',
  CAUTION: 'Caution',
  PREFERRED: 'Preferred',
  FLAG_DNR: 'Flag as DNR',
  FLAG_GREEN: 'Flag as Preferred',
  FLAG_YELLOW: 'Flag as Caution',
  REASON_FOR_DNR: 'What is the main Reason for DNR',
  CLINICAL_VALUE: 'clinical',
  PROFESSIONAL_VALUE: 'professional',
  SELECT_CLINICAL: 'Select Clinical Reasons',
  SELECT_PROFESSIONAL: 'Select Professional Reasons',
  CLINICAL_REASON: 'CLINICAL REASON',
  PROFESSIONAL_REASON: 'PROFESSIONAL REASON',
  REASONS: 'Reasons',
}

export const TABLE_COLUMNS = {
  FIELD_NAME: 'FIELD NAME',
  IS_FIELD_MANDATORY: 'IS FIELD MANDATORY?',
  DISPLAY_ON_FORM: 'DISPLAY ON FORM?',
  ACTION: 'ACTION',
  PROVIDERS: {
    PROVIDER: 'PROVIDERS',
    LICENSE: 'LICENSE',
    SPECIALTIES: 'SPECIALTIES',
    LAST_SCHEDULED: 'LAST SCHEDULED',
    NEXT_SCHEDULED: 'NEXT SCHEDULED',
    SHIFT_WORK: 'SHIFT WORKED',
    FLAG: 'FLAG',
    ACTION: 'ACTION',
  },
  REQ_PROVIDER_WORK: {
    SHIFT_DATE: 'SHIFT DATE',
    SHIFT_TIME: 'SHIFT TIME',
    LICENSE: 'LICENSE',
    SPECIALTIES: 'SPECIALTIES',
    FACILITY: 'FACILITY',
    MATCH: 'MATCH',
  },
  CREDENTIAL: {
    CREDENTIAL: 'CREDENTIAL',
    NAME: 'NAME',
    ISSUE_DATE: 'ISSUE DATE',
    EXPIRY_DATE: 'EXPIRY DATE',
    VERIFIED: 'VERIFIED',
    DAYS: 'DAYS',
    LICENCSE_NO: 'LICENCSE NO.',
    ACTION: 'ACTION',
  },
  SHIFTS_HISTORY: {
    SHIFT_ID: 'SHIFT ID',
    FACILITY: 'FACILITY',
    SHIFT_DATE: 'SHIFT DATE',
    SHIFT_TIME: 'SHIFT TIME',
    STATUS: 'STATUS',
    TYPE: 'TYPE',
    CREATED_BY: 'CREATED BY',
  },
  EVALUATION: {
    EVAL_DATE: 'EVAL. DATE',
    EVALUATION: 'EVALUATION',
    CLIENT: 'CLIENT',
    SERVICE_PERIOD: 'SERVICE PERIOD',
    RESULT: 'RESULT',
    ACTION: 'ACTION',
  },
  COMPLETE_NEW_EVALUATION: {
    PERFORMANCE: 'Performance Evaluation',
    EXCEED: 'Often Exceeds Expectation',
    EXPECTATION: 'Meets Expectation',
    NOT_EXPECTED: 'Does not Meet Expectation',
    NA: 'N/A',
  },
  COMPENTACY: {
    TEST_NAME: 'TEST NAME',
    TEST_FOR: 'TEST FOR',
    REQUIRED_SCORE_TO_PASS: 'REQUIRED SCORE TO PASS',
    TEST_TIME: 'TEST TIME',
    TOTAL_QUESTIONS: 'TOTAL QUESTIONS',
    LAST_UPDATE: 'LAST UPDATE',
  },
}

export const PER_DIEM_STATUS = {
  OPEN: 'open',
  CANCELLED: 'cancelled',
  REQUESTED: 'requested',
  UNCONFIRMED: 'unconfirmed',
  UNSUBMITTED: 'un_submitted',
  SCHEDULE: 'scheduled',
  COMPLETED: 'completed',
}
export const BACK_MODAL_TEXT = {
  DELETE: 'Yes',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Unsaved Changes!',
  DELETE_DESC: 'You have unsaved changes. Are you sure you want to leave this page?',
}

export const DELETE_CATEGORY_FACILITY_SETTING = {
  DELETE: 'Yes',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Delete category of document?',
  DELETE_DESC: 'Are you sure you want to delete this document category',
}

export const DELETE_CATEGORY_MASTAER_ADMIN_SETTING = {
  DELETE: 'Yes',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Delete category of document?',
  DELETE_DESC: 'Are you sure you want to delete this document category',
}

export const DELETE_DOCUMENT_FACILITY_SETTING = {
  DELETE: 'Yes',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Delete document?',
  DELETE_DESC: 'Are you sure you want to delete this document',
}

export const COMPLIENCE_MANAGER = {
  LICENSE: 'LICENSE',
  CERTIFICATE: 'License',
  CREDENTIALS: 'Credentials',
  CERTI_OR_SPECIALITY: 'License/Spaciality',
  AUTO_ASSIGN: 'Auto Assign',
  WORKFORCE_PORTAL: 'WFP Portal',
  CRED_CAT: 'Credential Category',
  AGENCY_EMP: 'Agency Employment',
}

export const WORKFORCE_PORTAL = [
  { label: 'Applicant', value: 'applicant' },
  { label: 'None', value: 'none' },
  { label: 'Worker', value: 'worker' },
  { label: 'Both', value: 'both' },
]

export const autoAssignOptions = [
  {
    id: 'none',
    name: 'None',
  },
  {
    id: 'pre_hire',
    name: 'Pre Hire',
  },
  {
    id: 'post_hire',
    name: 'Post Hire',
  },
]

export const agencyEmployment = [
  { label: 'Either', value: 'either' },
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

export const workForceFilterOptionFilter = [
  { label: 'All', value: 'all' },
  { label: 'None', value: 'none' },
  { label: 'Applicant', value: 'applicant' },
  { label: 'Worker', value: 'worker' },
  { label: 'Both', value: 'both' },
]

export const DELETE_STATUS_OPTION_PROVIDER = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Delete Status Option for Provider?',
  DELETE_DESC: 'Are you sure you want to delete this status option',
}

export const DELETE_STATUS_OPTION_CLIENT = {
  DELETE: 'Yes, Delete',
  CANCEL: 'Cancel',
  DELETE_HEAD_LOB: 'Delete Status Option for Client?',
  DELETE_DESC: 'Are you sure you want to delete this status option',
}

export const badgeData = [
  {
    value: '#F4FBF7',
    label: 'A',
    style: { backgroundColor: '#F4FBF7', color: '#28714D' },
  },
  {
    value: '#F2F0FE',
    label: 'A',
    style: { backgroundColor: '#F2F0FE', color: '#220EB1' },
  },
  {
    value: '#FFF8F0',
    label: 'A',
    style: { backgroundColor: '#FFF8F0', color: '#FB8600' },
  },
  {
    value: '#FDF4F5',
    label: 'A',
    style: { backgroundColor: '#FDF4F5', color: '#D1293D' },
  },
  {
    value: '#F7F7F8',
    label: 'A',
    style: { backgroundColor: '#F7F7F8', color: '#68696C' },
  },
  {
    value: '#F7F1FE',
    label: 'A',
    style: { backgroundColor: '#F7F1FE', color: '#6111B9' },
  },
  {
    value: '#F7FDFE',
    label: 'A',
    style: { backgroundColor: '#F7FDFE', color: '#0C90B6' },
  },
  {
    value: '#FEF1F8',
    label: 'A',
    style: { backgroundColor: '#FEF1F8', color: '#D11575' },
  },
  {
    value: '#FFF5F0',
    label: 'A',
    style: { backgroundColor: '#FFF5F0', color: '#FF6C2D' },
  },
  {
    value: '#FCF1FE',
    label: 'A',
    style: { backgroundColor: '#FCF1FE', color: '#B710D2' },
  },
]
