import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PasswordVisibleIcon, PlusIcon, Settings, ThreeDotIcon } from 'assets/svgs'
import { DELETE_COMPETENCY_TEST_SETTINGS, TABLE_COLUMNS } from 'constants/Constant'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setCurrentTestData, setGlobalLoader, setPreviousTestData } from 'store/slice/CommonSlice'
import { type ICompetencyTest } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import SettingsModal from './modal/SettingsModal'
import { getFindAllTests, getFindOneTest, removeCompetencyTestSetting, updateCompetencyTest } from './api'

import './Compentancy.scss'

const CompetencyTest = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [search, setSearch] = useState<string>('')
  const [listData, setListData] = useState<ICompetencyTest[]>([])
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [settingModalOpen, setSettingModalOpen] = useState<boolean>(false)
  const [editId, setEditId] = useState<string>('')
  const [settingType, setSettingType] = useState<string>('')
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.competencyTest'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleFindAllTests(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit, paginationInfo.current, search, sortTable])

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleFindAllTests = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getFindAllTests(limit, offset, search, order, field)
    if (response?.status === 200) {
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    handlePageChange(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const handlePageChange = (page: number, pageSize: number = paginationInfo?.limit) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
      limit: pageSize,
    }))
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleDeleteTest = async (testId: string) => {
    const response = await removeCompetencyTestSetting(testId)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleFindAllTests(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const handleFindSingleTestData = async (id: string) => {
    const response = await getFindOneTest(id)
    if (response?.status === 200) {
      return response?.data?.data
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
      return null
    }
  }

  const onActiveSwitchChange = async (checked: boolean, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const singleTest = await handleFindSingleTestData(id)
    if (singleTest) {
      void handleEditActiveData(id, newStatus, singleTest, singleTest?.certificate?.id)
    }
  }

  const handleEditActiveData = async (id: string, status: string, singleTest: ICompetencyTest, certificate: number) => {
    if (singleTest?.id === id) {
      const data = {
        ...singleTest,
        status,
        certificate,
      }
      const response = await updateCompetencyTest(id, data)
      if (response?.status === 200) {
        void message.success(response?.data?.message)
        void handleFindAllTests(paginationInfo.limit, paginationInfo.offset, sortTable.order, sortTable.field)
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    }
  }

  const getMenu = (record: ICompetencyTest, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            localStorage.removeItem('compentancyState')
            handleActionMenuClick(index)
            navigate(`/settings/compentency-test/edit-test/${record.id}`)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          onClick: () => {
            localStorage.removeItem('compentancyState')
            handleActionMenuClick(index)
            navigate(`/settings/compentency-test/preview`, { state: record })
          },
          label: (
            <div className="option-wrapper">
              <PasswordVisibleIcon color="#2A2A2B" />
              <span className="activity-text font-normal text-neutral-800">{t('button.preview')}</span>
            </div>
          ),
        },
        {
          key: '3',
          onClick: () => {
            setEditId(record.id)
            handleActionMenuClick(index)
            setSettingType('individual')
            setSettingModalOpen(true)
          },
          label: (
            <div className="option-wrapper">
              <Settings />
              <span className="activity-text font-normal text-neutral-800">{t('button.settings')}</span>
            </div>
          ),
        },
        {
          key: '4',
          label: (
            <div className="option-wrapper">
              <div className=" cursor-pointer w-full" key="2">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>
                  <Switch
                    checked={record.status === 'active'}
                    onChange={(checked) => {
                      if (record.id !== undefined) {
                        void handleFindSingleTestData(record.id)
                        void onActiveSwitchChange(checked, record.id)
                      }
                      handleActionMenuClick(index)
                    }}
                  />
                </p>
              </div>
            </div>
          ),
        },
        {
          key: '5',
          onClick: () => {
            setIsDeleteModalOpen(true)
            setDeleteId(record.id ?? '')
            setDeleteName(record.name)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType<ICompetencyTest> = [
    {
      title: TABLE_COLUMNS.COMPENTACY.TEST_NAME,
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 400,
      render: (_value, record) => {
        return <HeadingText text={record.name} classString={'text-neutral-800 activity-text font-medium line-clamp-table '} />
      },
    },
    {
      title: TABLE_COLUMNS.COMPENTACY.TEST_FOR,
      dataIndex: 'required_score',
      key: 'required_score',
      align: 'left',
      width: 208,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        return <HeadingText text={`${record.required_score}%`} classString={'text-neutral-800 para-p3 font-small'} />
      },
    },
    {
      title: TABLE_COLUMNS.COMPENTACY.REQUIRED_SCORE_TO_PASS,
      dataIndex: 'required_score',
      key: 'required_score',
      align: 'left',
      width: 208,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        return <HeadingText text={`${record.required_score}%`} classString={'text-neutral-800 para-p3 font-small'} />
      },
    },
    {
      title: TABLE_COLUMNS.COMPENTACY.TEST_TIME,
      dataIndex: 'duration',
      key: 'duration',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 208,
      render: (_value, record) => {
        return <HeadingText text={`${record.duration}:00`} classString={'text-neutral-800 para-p3 font-small'} />
      },
    },
    {
      title: TABLE_COLUMNS.COMPENTACY.TOTAL_QUESTIONS,
      dataIndex: 'total_question',
      key: 'total_question',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'left',
      width: 208,
    },
    {
      title: TABLE_COLUMNS.COMPENTACY.LAST_UPDATE,
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 208,
      render: (_value, record) => {
        // const formattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY, hh:mm A')
        const formattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY')
        return <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
      },
    },
    {
      title: TABLE_COLUMNS.ACTION,
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 100,
      // fixed: 'right',
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const tableButton = (
    <div className="flex gap-6">
      <CustomBtn
        text={t('button.quizFaq')}
        onClick={() => {
          navigate('/settings/compentency-test/quiz-faq')
        }}
      />
      <CustomBtn
        isIcon
        svgIcon={<Settings className="svg" />}
        text={t('button.globalSettings')}
        onClick={() => {
          setSettingType('global')
          setSettingModalOpen(true)
        }}
        className="user-Icon"
      />
      <CustomBtn
        isIcon
        svgIcon={<PlusIcon />}
        text={t('button.newTest')}
        onClick={() => {
          dispatch(setCurrentTestData([]))
          dispatch(setPreviousTestData([]))
          localStorage.removeItem('compentancyState')
          navigate('/settings/compentency-test/add-test')
        }}
        type="primary"
      />
    </div>
  )

  return (
    <div>
      <CustomTable
        column={columns}
        data={listData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        search={search}
        isSearch
        onSearch={onSearch}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        name={t('label.competencyTestList')}
        searchPlaceholder={t('placeholder.search')}
        tableButton={tableButton}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
      />
      {settingModalOpen && (
        <CustomModal
          open={settingModalOpen}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal setting-modal"
          onCancel={() => {
            setSettingModalOpen(false)
          }}
          content={<SettingsModal setIsModalOpen={setSettingModalOpen} editId={editId} type={settingType} />}
          width={600}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_COMPETENCY_TEST_SETTINGS.CANCEL}
          okClick={() => {
            void handleDeleteTest(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_COMPETENCY_TEST_SETTINGS.DELETE_DESC}
          mainHead={DELETE_COMPETENCY_TEST_SETTINGS.DELETE_HEAD_LOB}
          okText={DELETE_COMPETENCY_TEST_SETTINGS.DELETE}
        />
      )}
    </div>
  )
}

export default CompetencyTest
