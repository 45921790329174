import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'

import { type ICancelShift, type ICreateShift, type IUpdateShift } from '../types'

export const GET_SHIFTS = async (facilityId: string, page = 1, limit = 10, search: string, order: string, field: string, query?: string) => {
  try {
    const offset = (page - 1) * limit
    const url = `/shift/all/${facilityId}?offset=${offset}&limit=${limit}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}&${query ?? ''}`
    const response = http.get(url)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const ALL_UNPOSTED_SHIFT_DELETE = async (id: string) => {
  try {
    const response = await http.delete(`/shift/un-posted/all/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const CLONE_SHIFT = async (id: string) => {
  try {
    const response = await http.post(`/shift/clone/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const DELETE_UNPOSTED_SHIFT = async (id: string) => {
  try {
    const response = await http.delete(`/shift/un-posted/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const UPDATE_SHIFT = async (id: string, payload: ICreateShift) => {
  try {
    const response = await http.patch(`/shift/update/${id}`, payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const CREATE_SHIFT = async (payload: ICreateShift) => {
  try {
    const response = await http.post('/shift', payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_UNPOSTED_SHIFTS = async (id: string) => {
  try {
    const response = await http.get(`/shift/post/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const POST_SHIFT = async (payload: { shift: string[] }) => {
  try {
    const response = await http.patch('/shift/post', payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_SHIFT_TYPE = async () => {
  try {
    const response = await http.get('/dropdown/shift-type')
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_SINGLE_SHIFT = async (id: string) => {
  try {
    const response = await http.get(`/shift/detail/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_CANCEL_REASON = async () => {
  try {
    const response = await http.get('/dropdown/shift-cancel-reason?type=facility')
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const CANCEL_SHIFT = async (id: string, payload: ICancelShift) => {
  try {
    const response = await http.patch(`/shift/cancel/${id}`, payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const EDIT_POSTED_SHIFT = async (id: string, payload: IUpdateShift) => {
  try {
    const response = await http.patch(`shift/update/${id}`, payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const ASSIGN_USER_REQUEST = async (id: string) => {
  try {
    const response = await http.patch(`/shift-request/assign/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const REJECT_USER_REQUEST = async (id: string) => {
  try {
    const response = await http.delete(`/shift-request/reject/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const INVITE_PROVIDER_AGAIN = async (id: string) => {
  try {
    const response = await http.patch(`/shift-invitation/again/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const WITHDRAWN_INVITATION = async (id: string) => {
  try {
    const response = await http.patch(`/shift-invitation/withdrawn/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_ALL_INVITED_USER = async (id: string, limit: number, offset: number) => {
  try {
    const response = await http.get(`/shift-invitation/all/${id}?limit=${limit}&offset=${offset}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_AUTO_SCHEDULED_USER = async (id: string, limit: number, offset: number) => {
  try {
    const response = await http.get(`/shift-invitation/auto/${id}?limit=${limit}&offset=${offset}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_REQUESTED_USER = async (id: string, limit: number, offset: number) => {
  try {
    const response = await http.get(`/shift-request/all/${id}?limit=${limit}&offset=${offset}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_ALL_PROVIDER = async (filter: string, facility: string, search: string, certificate: string, speciality: string) => {
  try {
    let url = `/dropdown/provider?filter=${filter}&facility_id=${facility}&certificate_id=${certificate}&speciality_id=${speciality}`

    if (search) {
      url += `&search=${search}`
    }

    const response = await http.get(url)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
