import React, { useState } from 'react'
import { CalenderCounterMinus, CalenderCounterPlus, PaginationLeft, PaginationRight } from 'assets/svgs'
import { COMMON } from 'constants/Constant'
import dayjs from 'dayjs'
import type { SelectedDate } from 'types'

import CustomBtn from 'components/common/CustomBtn'

import './Shifts.scss'

interface ICustomCalender {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedDates: SelectedDate[]
  setSelectedDates: React.Dispatch<React.SetStateAction<SelectedDate[]>>
  setError?: React.Dispatch<React.SetStateAction<any>>
}

const CustomCalendar = ({ setIsModalOpen, selectedDates, setSelectedDates, setError }: ICustomCalender) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs().month())
  const [currentYear, setCurrentYear] = useState(dayjs().year())

  const generateCalendarDays = () => {
    const monthStart = dayjs().year(currentYear).month(currentMonth).startOf('month')
    const monthEnd = dayjs().year(currentYear).month(currentMonth).endOf('month')
    const startDate = monthStart.startOf('week')
    const endDate = monthEnd.endOf('week')

    const days = []
    let day = startDate
    while (day.isBefore(endDate, 'day') || day.isSame(endDate, 'day')) {
      days.push(day)
      day = day.add(1, 'day')
    }
    return days
  }

  const isSelected = (date: string) => {
    return selectedDates.find((d) => d.date === date)
  }

  const toggleDateSelection = (date: string) => {
    if (isSelected(date)) {
      setSelectedDates(selectedDates.filter((d) => d.date !== date))
    } else {
      setSelectedDates([...selectedDates, { date, openings: 1 }])
    }
  }

  const incrementOpenings = (date: string) => {
    // eslint-disable-next-line
    setSelectedDates(selectedDates.map((d) => (d.date === date ? { ...d, openings: d.openings + 1 } : d)))
  }

  const decrementOpenings = (date: string) => {
    setSelectedDates(selectedDates.map((d) => (d.date === date ? { ...d, openings: Math.max(1, d.openings - 1) } : d)))
  }

  const onSave = () => {
    if (selectedDates.length !== 0) {
      setError?.((prev: any) => ({
        ...prev,
        calendarData: '',
      }))
    }
    setIsModalOpen(false)
  }

  const onCancel = () => {
    setSelectedDates([])
    setIsModalOpen(false)
  }

  const days = generateCalendarDays()

  const handlePreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11)
      setCurrentYear(currentYear - 1)
    } else {
      setCurrentMonth(currentMonth - 1)
    }
  }

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0)
      setCurrentYear(currentYear + 1)
    } else {
      setCurrentMonth(currentMonth + 1)
    }
  }

  return (
    <>
      <div className="p-4 calendar">
        <div className="calendar-header pb-2">
          <div onClick={handlePreviousMonth} className="cursor-pointer">
            <PaginationLeft />
          </div>
          <p className="text0-gray-900 font-medium para-p3">{dayjs().year(currentYear).month(currentMonth).format('MMMM YYYY')}</p>
          <div onClick={handleNextMonth} className="cursor-pointer">
            <PaginationRight />
          </div>
        </div>
        <div className="calendar-grid">
          {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
            <div key={day} className="pb-4 para-p4 flex justify-center text-neutral-800 font-medium">
              {day}
            </div>
          ))}
          {days.map((day, index) => {
            const formattedDate = day.format('YYYY-MM-DD')
            const selected = isSelected(formattedDate)
            return (
              <div
                key={index}
                className={`rounded-2 py-2 px-4 h-86 para-p3 text-neutral-800 font-medium flex items-center justify-center flex-col calendar-day ${selected ? 'bg-primary-50' : ''}`}
                onClick={() => {
                  toggleDateSelection(formattedDate)
                }}
              >
                {day.date()}
                {selected && (
                  <div className="counter-controls pt-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation()
                        decrementOpenings(formattedDate)
                      }}
                    >
                      <CalenderCounterMinus />
                    </button>
                    <span className="para-p4 font-medium text-neutral-400">{selected.openings}</span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation()
                        incrementOpenings(formattedDate)
                      }}
                    >
                      <CalenderCounterPlus />
                    </button>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex gap-3 justify-end p-4 border-t-stroke">
        <CustomBtn text={COMMON.CANCEL} type="default" htmlType="button" onClick={onCancel} className="h-45 w-full" />
        <CustomBtn text={COMMON.SAVE} type="primary" htmlType="button" onClick={onSave} className="h-45 w-full" />
      </div>
    </>
  )
}

export default CustomCalendar
