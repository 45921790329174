import { useEffect, useState } from 'react'
import { Dropdown } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DownIcon, DownloadPdf, ThreeDotIcon, UploadDoc } from 'assets/svgs'
import { BUTTON_STRING, TABLE_COLUMNS } from 'constants/Constant'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import { GET_PROVIDER_CREDENTIAL } from '../api'
import { type CredentialCategory, type CredentialsDocument, type DocumentResponse } from '../types'

const Credential = ({ providerId, tabNo }: { providerId: string; tabNo: string }) => {
  const dispatch = useAppDispatch()
  const [listData, setNewListData] = useState<CredentialCategory[]>([])
  const [visibleSections, setVisibleSections] = useState<number[]>([])
  const [sortedData, setSortedData] = useState<CredentialCategory[]>([])

  useEffect(() => {
    if (providerId) {
      void getCredentialData(providerId)
    }
    // eslint-disable-next-line
  }, [providerId, tabNo])

  const getCredentialData = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await GET_PROVIDER_CREDENTIAL(id, '', '')
    if (response?.status === 200) {
      setNewListData(response?.data?.data ?? [])
      setSortedData(response?.data?.data ?? [])
    }
    dispatch(setGlobalLoader(false))
  }

  const toggleVisibility = (index: number) => {
    setVisibleSections((prevVisibleSections) => {
      if (prevVisibleSections.includes(index)) {
        return prevVisibleSections.filter((i) => i !== index)
      } else {
        return [...prevVisibleSections, index]
      }
    })
  }

  const handleTableChange = (
    _pagination: any,
    _filters: any,
    sorter: { order: 'ascend' | 'descend'; field: keyof DocumentResponse },
    data: { currentDataSource: CredentialsDocument[]; action: string }
  ) => {
    const currentDataSource = data?.currentDataSource
    const currentDataSourceId = data.currentDataSource[0]?.recordId ?? ''
    if (sorter.order && sorter.field && data?.action === 'sort') {
      const sorted = currentDataSource?.sort((docA, docB) => {
        let fieldA
        let fieldB
        if (sorter?.field === 'name') {
          fieldA = docA.name
          fieldB = docB?.name
        } else {
          fieldA = (docA?.document_response && docA?.document_response[sorter?.field]) ?? ''
          fieldB = (docB?.document_response && docB?.document_response[sorter?.field]) ?? ''
        }
        if (sorter.order === 'ascend') {
          return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0
        } else {
          return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0
        }
      })
      const updatedSortedData = listData?.map((item) => {
        if (item?.id === currentDataSourceId) {
          return { ...item, credentials_document: [...sorted] }
        } else {
          return { ...item }
        }
      })
      setSortedData(updatedSortedData)
    } else {
      setSortedData(listData)
    }
  }

  const columns: ColumnsType = [
    {
      title: TABLE_COLUMNS.CREDENTIAL.CREDENTIAL,
      dataIndex: 'credential',
      key: 'credential',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 140,
      render: (_value, record) => {
        return <HeadingText text={record?.document_response?.credential ?? '-'} classString={'text-neutral-800 font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.NAME,
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 301,
      render: (_value, record) => {
        return <HeadingText text={record?.name ?? '-'} classString={'text-neutral-800 font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.ISSUE_DATE,
      dataIndex: 'issue_date',
      key: 'issue_date',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 120,
      render: (_value, record) => {
        return (
          <HeadingText
            text={record?.document_response?.issue_date ? dayjs(record?.document_response?.issue_date).format('MM/DD/YYYY') : '-'}
            classString={'text-neutral-800 font-normal'}
          />
        )
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.EXPIRY_DATE,
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 120,
      render: (_value, record) => {
        return (
          <HeadingText
            text={record?.document_response?.expiry_date ? dayjs(record?.document_response?.expiry_date).format('MM/DD/YYYY') : '-'}
            classString={'text-neutral-800 font-normal'}
          />
        )
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.VERIFIED,
      dataIndex: 'is_verified',
      key: 'is_verified',
      align: 'start',
      width: 91,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        const isVerified = record?.document_response?.is_verified === 'verified'
        return <HeadingText text={isVerified ? 'Yes' : 'No'} classString={`${isVerified ? 'success' : 'error'} text-neutral-800 font-normal px-2_5 py-1 rounded-2`} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.DAYS,
      dataIndex: 'days',
      key: 'days',
      align: 'start',
      width: 66,
      sorter: false,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        const issueDate: Date = new Date(String(record?.document_response?.issue_date) ?? '')
        const expiryDate: Date = new Date(String(record?.document_response?.expiry_date) ?? '')
        const timeDifference: number = expiryDate.getTime() - issueDate.getTime()
        const daysDifference: number = timeDifference / (1000 * 3600 * 24)
        return <HeadingText text={Number.isNaN(daysDifference) ? '0' : String(daysDifference)} classString={'text-neutral-800 font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.LICENCSE_NO,
      dataIndex: 'license',
      key: 'license',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 138,
      render: (_value, record) => {
        return <HeadingText text={record?.document_response?.license ?? '-'} classString={'text-neutral-800 font-normal'} />
      },
    },
    {
      title: TABLE_COLUMNS.CREDENTIAL.ACTION,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 112,
      render: (_value, record) => {
        return (
          <div className="flex gap-2 justify-center">
            <Dropdown
              rootClassName={`p-4 action-dropdown`}
              menu={{
                items: [
                  {
                    label: (
                      <div className="cursor-pointer w-full p-1" key="1">
                        <p className="flex justify-start items-start gap-2">
                          <UploadDoc /> <span className="mobile-heading-h6 font-normal text-neutral-800">{BUTTON_STRING.UPLOAD_DOCUMENT}</span>
                        </p>
                      </div>
                    ),
                    key: 0,
                  },
                  {
                    label: (
                      <div className="cursor-pointer p-1" key="2">
                        <p className="flex items-center gap-2">
                          <DownloadPdf /> <span className="mobile-heading-h6 font-normal text-neutral-800">{BUTTON_STRING.DOWNLOAD_PDF}</span>
                        </p>
                      </div>
                    ),
                    key: 1,
                  },
                ],
              }}
              trigger={['click']}
            >
              <CustomBtn isIcon svgIcon={<ThreeDotIcon className="cursor-pointer user-icon" />} text="" className="p-1-imp w-fit h-fit three-dot-icon" type={'default'} />
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return (
    <div className="flex flex-col items-center gap-4 w-full px-40 pb-10 credential-wrapper">
      {sortedData?.map((item: { id: string; name: string; credentials_document: CredentialsDocument[] }, index) => {
        const updatedData = item?.credentials_document?.map((data) => ({ ...data, recordId: item?.id }))
        return (
          <div key={item?.id ?? index} className="credential-type-wrapper w-full">
            <div
              className="flex items-center gap-2 cursor-pointer"
              onClick={() => {
                toggleVisibility(index)
              }}
            >
              <div
                style={{
                  transform: !visibleSections.includes(index) ? 'rotate(-90deg)' : 'rotate(0deg)', // Check if this section is in visibleSections
                  transition: 'transform 0.3s',
                }}
              >
                <DownIcon height={25} width={24} />
              </div>
              <p className="text-neutral-800 mobile-heading-h5 font-medium">{item?.name}</p>
            </div>
            {visibleSections.includes(index) && <CustomTable column={columns} data={updatedData} onChange={handleTableChange} />}
          </div>
        )
      })}
    </div>
  )
}

export default Credential
