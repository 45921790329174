import { type RefObject, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, message, Radio, type RadioChangeEvent } from 'antd'
import { CirclePlus, CloseIcon, DeleteIcon, DraggableDots, DropDownIcon, Settings } from 'assets/svgs'
import { commonInputFieldValidation, commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_ERROR, VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setCurrentNameData, setCurrentTestData, setGlobalLoader, setPreviousNameData, setPreviousTestData, setType } from 'store/slice/CommonSlice'
import { type ICompetencyTestForm, type IcompetencyTestOption, type ICompetencyTestQue, type IFieldData } from 'types'
import { addCompentencyDeletedData, handleFieldsChange, validateQuestionsForCompentancy, validateQuestionsForCompentancyPreview } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'

import { createCompetencyTest, getFindOneTest, updateCompetencyTest } from '../api'
import SettingsModal from '../modal/SettingsModal'

import '../Compentancy.scss'

const scoreOption = [...Array(9)].map((_, index) => {
  const score = 60 + index * 5
  return {
    value: score.toString(),
    label: `${score}%`,
  }
})

const durationOption = [...Array(12)].map((_, index) => {
  const minutes = index * 5
  const formattedMinutes = minutes.toString().padStart(2, '0')
  return {
    value: formattedMinutes,
    label: `${formattedMinutes}`,
  }
})

const AddEditTest = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { editId } = useParams()
  const inputRadioRef = useRef<HTMLInputElement>(null)

  const [errors, setError] = useState({
    name: '',
    required_score: '',
    duration: '',
  })
  const [allQues, setAllQues] = useState<ICompetencyTestQue[]>([])
  const [visibleDetails, setVisibleDetails] = useState<any[]>(allQues?.map(() => false))
  const [deleteQueIds, setDeleteQueIds] = useState<string[]>([])
  const [deleteOptionIds, setDeleteOptionIds] = useState<string[]>([])
  const [settingModalOpen, setSettingModalOpen] = useState<boolean>(false)
  const [settingOption, setSettingOption] = useState<any>({})
  const [inputErrors, setInputErrors] = useState<string[]>([])
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const previousAllQuesLength = useRef<number>(allQues.length)

  const findLastObject = (questions: any) => {
    return questions.length > 0 ? questions[questions.length - 1] : undefined
  }

  const lastObject = findLastObject(allQues)

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: editId ? t('pageTitle.editTest') : t('pageTitle.newTest'),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    if (localStorage.getItem('compentancyState')) {
      getLocalStorageData()
      return
    }
    if (editId) void handleFindSingleTestData(editId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, form])

  useEffect(() => {
    if (localStorage.getItem('compentancyState')) {
      getLocalStorageData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatch(setType('test'))
    const nameValue = form.getFieldValue('name')
    const scoreValue = form.getFieldValue('required_score')
    const durationValue = form.getFieldValue('duration')
    if (nameValue || scoreValue || durationValue) dispatch(setCurrentNameData({ name: nameValue, score: scoreValue, duration: durationValue }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('name'), form.getFieldValue('required_score'), form.getFieldValue('duration'), form, dispatch])

  useEffect(() => {
    if (allQues?.length > 0) {
      dispatch(setType('test'))
      dispatch(setCurrentTestData(allQues))
      if (firstLoad) {
        setFirstLoad(false)
        previousAllQuesLength.current = allQues.length
        return
      }
      if (allQues.length > previousAllQuesLength.current) {
        const container = document.querySelector('.question-block')
        const lastQuestion = container?.lastElementChild

        if (lastQuestion) {
          lastQuestion.scrollIntoView({
            block: 'end',
            behavior: 'smooth',
          })
        }
      }
      previousAllQuesLength.current = allQues.length
    }
  }, [allQues, dispatch])

  const getLocalStorageData = () => {
    const getPrevData = localStorage.getItem('compentancyState')
    const getData = getPrevData ? JSON.parse(getPrevData) : {}
    const data = {
      name: getData?.name || '',
      required_score: getData?.required_score || '',
      duration: getData?.duration || '',
    }
    form.setFieldsValue(data)
    setSettingOption(getData?.global_test_setting)
    setDeleteQueIds(getData?.delete_question)
    setDeleteOptionIds(getData?.delete_option)
    const sortedQuestions = getData?.competency_test_question?.sort((a: ICompetencyTestQue, b: ICompetencyTestQue) => a.order - b.order)
    sortedQuestions?.forEach((eachQuestion: ICompetencyTestQue, index: number) => {
      form.setFieldValue(`question-${index}`, eachQuestion.question)
      eachQuestion.competency_test_option.sort((a: IcompetencyTestOption, b: IcompetencyTestOption) => a.order - b.order)
    })
    setAllQues(sortedQuestions)
  }

  const handleFindSingleTestData = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getFindOneTest(id)

    if (response?.status === 200) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { name, required_score, duration, competency_test_question, test_setting } = response.data.data

      dispatch(setPreviousNameData({ name: response?.data?.data?.name, duration: response?.data?.data?.duration, score: response?.data?.data?.required_score }))

      const data = {
        name: name ?? '',
        required_score: String(required_score) ?? '',
        duration: duration ?? '',
      }
      form.setFieldsValue(data)
      setSettingOption(test_setting)
      const sortedQuestions = competency_test_question?.sort((a: ICompetencyTestQue, b: ICompetencyTestQue) => a.order - b.order)
      sortedQuestions?.forEach((eachQuestion: ICompetencyTestQue, index: number) => {
        form.setFieldValue(`question-${index}`, eachQuestion.question)
        eachQuestion.competency_test_option.sort((a: IcompetencyTestOption, b: IcompetencyTestOption) => a.order - b.order)
      })
      setAllQues(sortedQuestions)
      dispatch(setPreviousTestData(sortedQuestions))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
      return null
    }
    dispatch(setGlobalLoader(false))
  }

  const handleAddOption = async (e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>, id: number, inputRadioRef: RefObject<HTMLInputElement>) => {
    // Prevent default form submission when Enter is pressed
    if ('key' in e && e.key === 'Enter') {
      e.preventDefault()
    }

    const target = e.target as HTMLInputElement

    // Check if the input is empty or only contains whitespace
    if (!target.value.trim()) {
      const newErrors = [...inputErrors]
      newErrors[id] = 'Option cannot be empty'
      setInputErrors(newErrors)
      return
    }

    const newOption = { option: target.value.trim(), order: 0, is_answer: false }

    await new Promise<void>((resolve) => {
      setAllQues((prevAllQues) => {
        const updatedQuestions = prevAllQues.map((question, qIndex) => {
          if (qIndex === id) {
            const competencyTestOption = Array.isArray(question.competency_test_option) ? question.competency_test_option : []
            const updatedOptions = [...competencyTestOption, { ...newOption, order: competencyTestOption.length + 1 }]
            return { ...question, competency_test_option: updatedOptions }
          }
          return question
        })
        resolve()
        return updatedQuestions
      })

      if (inputRadioRef.current) {
        inputRadioRef.current.value = ''
      }
    })

    const clearedErrors = [...inputErrors]
    clearedErrors[id] = ''
    setInputErrors(clearedErrors)
  }

  const onOptionTextChange = (e: React.ChangeEvent<HTMLInputElement>, questionIndex: number, optionIndex?: number) => {
    setAllQues((prevAllQues) => {
      const updatedQuestions = prevAllQues.map((question, qIndex) => {
        if (qIndex === questionIndex) {
          const updatedOptions = question.competency_test_option.map((option: IcompetencyTestOption, oIndex: number) => {
            if (oIndex === optionIndex) {
              return { ...option, option: e.target.value }
            }
            return option
          })
          return { ...question, competency_test_option: updatedOptions }
        }
        return question
      })
      return updatedQuestions
    })
  }

  const handleChangeExistingQue = (e: RadioChangeEvent, questionIndex: number, optionIndex: number) => {
    const updatedQuestions = [...allQues]
    updatedQuestions[questionIndex].competency_test_option.forEach((option: IcompetencyTestOption, index: number) => {
      if (index !== optionIndex) {
        option.is_answer = false
      }
    })
    updatedQuestions[questionIndex].competency_test_option[optionIndex].is_answer = e.target.checked
    setAllQues(updatedQuestions)
  }

  const deleteQuestion = async (questionIndex: number, queId?: string) => {
    setVisibleDetails(allQues?.map(() => false))
    const updatedQuestions = [...allQues]
    form.setFieldValue(`question-${questionIndex}`, '')
    updatedQuestions.splice(questionIndex, 1)
    updatedQuestions.forEach((eachQuestion: ICompetencyTestQue, index: number) => {
      form.setFieldValue(`question-${index}`, eachQuestion.question)
    })
    setAllQues(updatedQuestions)

    if (editId && queId) {
      setDeleteQueIds((prev) => [...prev, queId])
    }
  }

  const deleteOption = async (questionIndex: number, index: number, optId?: string) => {
    const updatedQuestions = [...allQues]
    form.setFieldValue(`option-${questionIndex}`, '')

    updatedQuestions[questionIndex].competency_test_option.splice(index, 1)
    setAllQues(updatedQuestions)

    if (editId && optId) {
      setDeleteOptionIds((prev) => [...prev, optId])
    }
  }

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, quesionIndex: number, index: number) => {
    e.dataTransfer.setData('questionIndex', quesionIndex.toString())
    e.dataTransfer.setData('optionIndex', index.toString())
  }

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
    const questionIndex = parseInt(e.dataTransfer.getData('questionIndex'), 10)
    const optionIndex = parseInt(e.dataTransfer.getData('optionIndex'), 10)
    if (isNaN(questionIndex) || isNaN(optionIndex) || optionIndex === dropIndex) return
    const updatedQuestions = [...allQues]
    const dragOption = updatedQuestions[questionIndex].competency_test_option[optionIndex]
    updatedQuestions[questionIndex].competency_test_option.splice(optionIndex, 1)
    updatedQuestions[questionIndex].competency_test_option.splice(dropIndex, 0, dragOption)
    updatedQuestions[questionIndex].competency_test_option.forEach((option: { order: number }, index: number) => {
      option.order = index + 1
    })
    setAllQues(updatedQuestions)
  }

  const handleQueDragStart = (e: React.DragEvent<HTMLDivElement>, questionIndex: number) => {
    e.dataTransfer.setData('questionIndex', questionIndex.toString())
  }

  const handleQueDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleQueDrop = (e: React.DragEvent<HTMLDivElement>, targetQuestionIndex: number) => {
    e.preventDefault()
    const sourceQuestionIndex = parseInt(e.dataTransfer.getData('questionIndex'), 10)
    if (isNaN(sourceQuestionIndex) || sourceQuestionIndex === targetQuestionIndex) return

    const updatedQuestions = [...allQues]
    const [draggedQuestion] = updatedQuestions.splice(sourceQuestionIndex, 1)
    updatedQuestions.splice(targetQuestionIndex, 0, draggedQuestion)

    const reorderedQuestions = updatedQuestions.map((question, index) => {
      form.setFieldValue(`question-${index}`, question.question)
      return { ...question, order: index + 1 }
    })

    setAllQues(reorderedQuestions)
    setVisibleDetails(allQues?.map(() => false))
  }

  const addQuestionAns = () => {
    if (allQues?.length === 0 || (lastObject?.question && lastObject?.competency_test_option?.length >= 2)) {
      setVisibleDetails(allQues?.map(() => false))
      const data = {
        question: '',
        order: allQues?.length + 1,
        competency_test_option: [],
      }
      setVisibleDetails((prevState) => {
        const newState = [...prevState]
        newState[allQues.length] = !newState[allQues.length]
        return newState
      })
      setAllQues((prev) => [...prev, data])
      form.setFieldValue(`question-${allQues.length}`, '')
    } else {
      void message.error(VALIDATION_ERROR.COMP_NEW_QUES)
    }
  }

  // const handleQuestionBlur = (e: React.FocusEvent<HTMLInputElement>, questionIndex: number) => {
  //   const newQuestionText = e.target.value
  //   setAllQues((prevAllQues) => {
  //     const updatedQuestions = prevAllQues.map((question, qIndex) => {
  //       if (qIndex === questionIndex) {
  //         return { ...question, question: newQuestionText }
  //       }
  //       return question
  //     })
  //     return updatedQuestions
  //   })
  // }

  // const toggleDetails = (index: number) => {
  //   setVisibleDetails((prevState) => {
  //     const newState = new Array(prevState.length).fill(false)
  //     newState[index] = !prevState[index] ? true : !prevState[index]
  //     return newState
  //   })
  // }

  const onFinish = (values: ICompetencyTestForm) => {
    const validation = validateQuestionsForCompentancy(allQues)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    const data: any = {
      name: values.name,
      required_score: String(values.required_score),
      duration: values.duration,
      competency_test_question: allQues,
      ...(Object.keys(settingOption).length > 0 && { global_test_setting: settingOption }),
    }
    if (editId) {
      const newData = addCompentencyDeletedData(data, deleteQueIds, deleteOptionIds)
      void handleUpdate(editId, newData)
    } else {
      void handleSubmit(data)
    }
  }

  const handleSubmit = async (data: ICompetencyTestForm) => {
    dispatch(setGlobalLoader(true))
    const response = await createCompetencyTest(data)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/settings/compentency-test')
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleUpdate = async (editId: string, data: ICompetencyTestForm) => {
    dispatch(setGlobalLoader(true))
    const response = await updateCompetencyTest(editId, data)
    if (response?.data?.statusCode === 1) {
      setDeleteOptionIds([])
      setDeleteQueIds([])
      void message.success(response?.data?.message)
      navigate('/settings/compentency-test')
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleQuestionBlur = (e: React.FocusEvent<HTMLInputElement>, questionIndex: number) => {
    const newQuestionText = e.target.value
    setAllQues((prevAllQues) => {
      const updatedQuestions = prevAllQues.map((question, qIndex) => {
        if (qIndex === questionIndex) {
          return { ...question, question: newQuestionText }
        } else {
          return question
        }
      })
      return updatedQuestions
    })
  }

  const toggleDetails = (index: number) => {
    setVisibleDetails((prevState) => {
      const newState = new Array(prevState.length).fill(false)
      newState[index] = !prevState[index] ? true : !prevState[index]
      return newState
    })
  }

  const handlePreviewTest = () => {
    const validation = validateQuestionsForCompentancyPreview(allQues)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    const state: ICompetencyTestForm = {
      competency_test_question: allQues,
      name: form.getFieldValue('name') ?? '',
      required_score: form.getFieldValue('required_score') ?? '',
      duration: form.getFieldValue('duration') ?? '',
      delete_question: deleteQueIds,
      delete_option: deleteOptionIds,
      editId,
      global_test_setting: settingOption || {},
    }
    localStorage.setItem('compentancyState', JSON.stringify(state))
    if (editId) {
      navigate('/settings/compentency-test/edit-test/preview')
    } else {
      navigate('/settings/compentency-test/add-test/preview')
    }
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        className="flex flex-col gap-6 items-center w-full"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
      >
        <div className="flex items-center bg-white competency-addtest w-full rounded-4">
          <div className="flex items-center gap-4">
            <CustomBtn
              isIcon
              svgIcon={<Settings className="svg" />}
              onClick={() => {
                setSettingModalOpen(true)
              }}
              className="user-Icon rounded-3 width-50"
            />
            <CustomBtn
              text={t('button.preview')}
              className="rounded-3 w-100"
              onClick={() => {
                handlePreviewTest()
              }}
              disabled={allQues?.length === 0 || !form.getFieldValue('name') || !form.getFieldValue('required_score') || !form.getFieldValue('duration')}
            />
            <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="rounded-3 w-120" />
          </div>
        </div>
        <div className="flex flex-col w-915 gap-6 mobile-version question-block">
          {/* test form */}
          <div className="bg-white p-8 rounded-3">
            <div className="w-full">
              <InputField
                placeholder={t('label.testName')}
                rules={commonInputFieldValidation(VALIDATION_STRINGS.TEST_NAME)}
                name="name"
                error={errors.name}
                value={form.getFieldValue('name')}
                delay={100}
              />
            </div>
            <div className="flex gap-1rem testform-mobile">
              <div className="w-full dropdown-gap">
                <FloatingLabelSelect
                  name="required_score"
                  label={t('placeholder.requiredScore')}
                  error={errors?.required_score}
                  options={scoreOption}
                  rules={commonSingleSelectValidation(VALIDATION_STRINGS.PASSING_SCORE)}
                  value={form.getFieldValue('required_score')}
                />
              </div>
              <div className="w-full dropdown-gap">
                <FloatingLabelSelect
                  name="duration"
                  label={t('placeholder.testTime')}
                  error={errors?.duration}
                  options={durationOption}
                  rules={commonSingleSelectValidation(VALIDATION_STRINGS.TEST_TIME)}
                  value={form.getFieldValue('duration')}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6">
            {/* question */}
            {allQues?.map((question: ICompetencyTestQue, questionIndex: number) => {
              const isVisible = visibleDetails[questionIndex]
              return (
                <div
                  key={question.order}
                  className="bg-white rounded-3 question-container"
                  draggable
                  onDragStart={(e) => {
                    handleQueDragStart(e, questionIndex)
                  }}
                  onDragOver={handleQueDragOver}
                  onDrop={(e) => {
                    handleQueDrop(e, questionIndex)
                  }}
                >
                  <div className="ques-move-icon">
                    <DraggableDots />
                  </div>
                  <div className={`flex items-center px-8 pt-8 ${!isVisible ? 'pb-8' : ''}`}>
                    {question.question === '' || isVisible ? (
                      <div className="w-full">
                        <InputField
                          wrapperClass="m-0"
                          placeholder={t('placeholder.question')}
                          name={`question-${questionIndex}`}
                          onBlur={(e) => {
                            handleQuestionBlur(e, questionIndex)
                          }}
                          delay={100}
                        />
                      </div>
                    ) : (
                      <div className="w-full mobile-heading-h5 font-medium text-neutral-800 break-all">{question.question}</div>
                    )}
                    <div
                      className="flex items-center justify-end"
                      onClick={() => {
                        toggleDetails(questionIndex)
                      }}
                      style={{ transform: isVisible ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                    >
                      <DropDownIcon />
                    </div>
                  </div>
                  <div className="flex-col mt-6 pr-8 pb-8 relative" style={{ display: isVisible ? 'flex' : 'none' }}>
                    <div className="flex flex-col gap-6 w-full mb-4">
                      {question?.competency_test_option.map((optionTxt: IcompetencyTestOption, index: number) => {
                        const isOptionEmpty = optionTxt.option.trim() === ''
                        return (
                          <>
                            <div
                              key={optionTxt.order}
                              draggable
                              onDragStart={(e) => {
                                handleDragStart(e, questionIndex, index)
                              }}
                              onDragOver={handleDragOver}
                              onDrop={(e) => {
                                handleDrop(e, index)
                              }}
                              className="radio-option-container flex items-center w-full"
                            >
                              <div className="radio-move-icon flex items-center">
                                <DraggableDots />
                              </div>

                              <Radio
                                key={index}
                                value={optionTxt.option}
                                checked={!!optionTxt.is_answer}
                                onChange={(e) => {
                                  handleChangeExistingQue(e, questionIndex, index)
                                }}
                                className="add-option activity-text w-60"
                              >
                                <div className="flex items-center justify-between gap-6 w-full">
                                  <div className="option-text">
                                    <input
                                      type="text"
                                      value={optionTxt.option}
                                      onChange={(e) => {
                                        onOptionTextChange(e, questionIndex, index)
                                      }}
                                      placeholder="Add Option"
                                      className="radio-option-input"
                                    />
                                  </div>
                                </div>
                              </Radio>
                              <div className="flex gap-6 w-full items-center justify-end">
                                <span className="option-status flex items-center justify-end">
                                  {optionTxt.is_answer && <span className="correct-ans text-center">Correct Answer</span>}
                                </span>
                                <span
                                  className="option-delete flex items-center justify-end gap-6"
                                  onClick={() => {
                                    void deleteOption(questionIndex, index, optionTxt.id)
                                  }}
                                >
                                  <CloseIcon />
                                </span>
                              </div>
                            </div>
                            {isOptionEmpty && <p className="error-container-options pl-8">{VALIDATION_ERROR.BLANK_OPT}</p>}
                          </>
                        )
                      })}
                      {question.competency_test_option.length < 4 && (
                        <Radio className="pl-7 add-option activity-text w-full add-option-mobile" disabled>
                          <div className="flex items-center justify-between w-full">
                            <input
                              type="text"
                              name="add-option"
                              ref={inputRadioRef}
                              placeholder="Add Option"
                              className="radio-option-input"
                              onKeyDown={(e) => {
                                if ('key' in e && e.key === 'Enter') {
                                  void handleAddOption(e, questionIndex, inputRadioRef)
                                }
                              }}
                              onBlur={(e) => {
                                void handleAddOption(e, questionIndex, inputRadioRef)
                                setInputErrors((prevErrors) => {
                                  const updatedErrors = [...prevErrors]
                                  updatedErrors[questionIndex] = ''
                                  return updatedErrors
                                })
                              }}
                              // Clear the error when user starts typing
                              onChange={() => {
                                const clearedErrors = [...inputErrors]
                                clearedErrors[questionIndex] = ''
                                setInputErrors(clearedErrors)
                              }}
                            />
                          </div>
                        </Radio>
                      )}
                      {inputErrors[questionIndex] && <p className="error-container pl-8">{inputErrors[questionIndex]}</p>}
                    </div>
                    <div className="flex justify-end test-delete-icon">
                      <DeleteIcon
                        className="#d1293d"
                        onClick={() => {
                          void deleteQuestion(questionIndex, question?.id)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          {/* button */}
          <div className="flex justify-end items-center">
            <CustomBtn isIcon svgIcon={<CirclePlus className="svg" />} text={t('button.addNewQue')} className="user-Icon rounded-3" onClick={addQuestionAns} />
          </div>
        </div>
      </Form>
      {settingModalOpen && (
        <CustomModal
          open={settingModalOpen}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal setting-modal"
          onCancel={() => {
            setSettingModalOpen(false)
          }}
          content={<SettingsModal setIsModalOpen={setSettingModalOpen} editId={editId} type={'create'} data={settingOption} setOption={setSettingOption} />}
          width={600}
        />
      )}
    </>
  )
}

export default AddEditTest
