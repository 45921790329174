import React from 'react'
import { Form, message } from 'antd'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddCategory, type ICategoryModalsProps, type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { createOneCategory } from '../api'

const AddNewCategory = ({ setCategoryModalOpen, handleGetAllCategory, form, error, setError, setCategoryName }: ICategoryModalsProps) => {
  const dispatch = useAppDispatch()

  const onFinish = (values: IAddCategory) => {
    const payload = {
      name: values?.name,
    }

    void handleAddEditCategory(payload)
  }

  const handleAddEditCategory = async (payload: { name: string }) => {
    dispatch(setGlobalLoader(true))

    const response = await createOneCategory(payload)

    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      setCategoryModalOpen(false)
      handleGetAllCategory()
      form.setFieldValue('name', '')
    }
    dispatch(setGlobalLoader(false))
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div>
      <div className="flex p-4 flex-col items-start gap-8">
        <HeadingText classString="text-neutral-800 activity-text font-normal" text={t('label.addNewCategory')} />
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="w-full"
        >
          <div className="flex flex-col items-start w-full">
            <div className="flex flex-col w-full">
              <div className={`h-48 aline-self ${error?.name ? 'mb-0' : 'mb-6'}`}>
                <InputField rules={commonInputFieldValidation(VALIDATION_STRINGS.CATEGORY)} placeholder={t('placeholder.categoryName')} name="name" value={getValue('name')} />
              </div>
              {error?.name && <p className="error-container">{error.name}</p>}
            </div>
            <div className="flex items-start gap-4 w-full">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  form.setFieldValue('name', '')
                  setCategoryModalOpen(false)
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AddNewCategory
