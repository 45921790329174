import { useEffect, useState } from 'react'
import { message, Popover, Switch } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_PROVIDER_VERIFICATION_REJECTION_REASONS } from 'constants/Constant'
import { PROVIDER_VERIFICATION } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddProviderVerification, type IProviderVerificationColumn } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddProviderVerification from './modal/AddProviderVerification'
import { deleteProviderRejectReason, getAllProviderRejectReason, updateProviderRejectReason } from './api'

const ProviderVerificationRejection = () => {
  const [search, setSearch] = useState<string>('')
  const [isAddNewProviderVerifi, setAddNewProviderVerifi] = useState<boolean>(false)
  const [listData, setListData] = useState([])
  const [editId, setEditId] = useState<string>('')
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [providerVerfiData, setProviderVerfiData] = useState<IProviderVerificationColumn>({
    reason: '',
    description: '',
    status: '',
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.providerVerification'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetProviderVerifiList(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit, paginationInfo.current, search, isAddNewProviderVerifi, sortTable])

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleGetProviderVerifiList = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getAllProviderRejectReason(limit, offset, search, order, field)
    if (response?.status === 200) {
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    handlePageChange(1)
  }

  const handlePageChange = (page: number, pageSize: number = paginationInfo?.limit) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
      limit: pageSize,
    }))
  }

  const handleDeleteProviderVerfi = async (id: string) => {
    const response = await deleteProviderRejectReason(id)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleGetProviderVerifiList(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }
  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const handleGetData = async (record: IProviderVerificationColumn) => {
    const { id, reason, description, status } = record
    setEditId(id ?? '')
    setProviderVerfiData({ reason, description, status })
    setAddNewProviderVerifi(true)
  }

  const onSwitchChange = (checked: boolean, data: IAddProviderVerification, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const newData = { ...data, status: newStatus }
    void handleEditUserData(newData, id)
  }

  const handleEditUserData = async (values: IAddProviderVerification, id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await updateProviderRejectReason(id, values)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      void handleGetProviderVerifiList(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const getMenu = (record: IProviderVerificationColumn, index: number) => {
    return (
      <div className="dropDown-action w-full">
        <div
          className="p-4 cursor-pointer w-full"
          key="1"
          onClick={() => {
            void handleGetData(record)
            handleActionMenuClick(index)
          }}
        >
          <p className="flex items-center gap-2">
            <EditPenIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{t('button.edit')}</span>
          </p>
        </div>
        <div className="p-4 cursor-pointer w-full" key="2">
          <p className="flex items-center justify-between gap-2">
            <span className="mobile-heading-h6 font-normal text-neutral-800">{t('placeholder.active')}</span>{' '}
            <Switch
              checked={record.status === 'active'}
              onChange={(checked) => {
                onSwitchChange(checked, record, record?.id)
                handleActionMenuClick(index)
              }}
            />
          </p>
        </div>
        <div
          className="p-4 cursor-pointer"
          key="3"
          onClick={() => {
            setIsDeleteModalOpen(true)
            setDeleteId(record.id ?? '')
            setDeleteName(record.reason)
            handleActionMenuClick(index)
          }}
        >
          <p className="flex items-center gap-2">
            <DeleteIcon className="#D1293D" />
            <span className="delete-text mobile-heading-h6 font-normal">{t('button.delete')}</span>
          </p>
        </div>
      </div>
    )
  }

  const columns: ColumnsType<IProviderVerificationColumn> = [
    {
      title: t('table.providerVerification.reason'),
      dataIndex: 'reason',
      key: 'reason',
      align: 'start',
      width: 460,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return <HeadingText text={record.reason} classString={'text-neutral-800 activity-text font-medium'} />
      },
    },
    {
      title: t('table.providerVerification.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      width: 460,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return <HeadingText text={record?.description ?? '-'} classString={'line-clamp-table text-neutral-800 font-small para-p3'} />
      },
    },
    {
      title: t('table.providerVerification.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'start',
      width: 460,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === PROVIDER_VERIFICATION.INVITED ? 'invited' : record.status === PROVIDER_VERIFICATION.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.providerVerification.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <div className="action-cell flex items-center justify-center three-dot-icon">
              <Popover
                trigger={'click'}
                content={getMenu(record, _value.id)}
                placement="bottomRight"
                open={actionMenuOpen[_value.id]}
                onOpenChange={(open) => {
                  handleOpenChange(_value.id, open)
                }}
              >
                <ThreeDotIcon className="cursor-pointer icon three-dot-icon" />
              </Popover>
            </div>
          </div>
        )
      },
    },
  ]

  const addRejectReasonBtg = (
    <CustomBtn
      isIcon
      svgIcon={<PlusIcon />}
      text={t('button.addReason')}
      onClick={() => {
        setEditId('')
        setProviderVerfiData({ reason: '', description: '', status: '' })
        setAddNewProviderVerifi(true)
      }}
      type="primary"
    />
  )

  return (
    <div>
      <CustomTable<IProviderVerificationColumn>
        column={columns}
        data={listData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        search={search}
        onChangePage={handlePageChange}
        name={t('label.providerVerifiRejectionList')}
        isSearch
        onSearch={onSearch}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        searchPlaceholder={t('placeholder.search')}
        tableButton={addRejectReasonBtg}
        onChange={handleTableChange}
      />
      {isAddNewProviderVerifi && (
        <CustomModal
          open={isAddNewProviderVerifi}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setAddNewProviderVerifi(false)
          }}
          content={<AddProviderVerification setIsModalOpen={setAddNewProviderVerifi} editId={editId} data={providerVerfiData} setSortTable={setSortTable} />}
          width={600}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_PROVIDER_VERIFICATION_REJECTION_REASONS.CANCEL}
          okClick={() => {
            void handleDeleteProviderVerfi(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_PROVIDER_VERIFICATION_REJECTION_REASONS.DELETE_DESC}
          mainHead={DELETE_PROVIDER_VERIFICATION_REJECTION_REASONS.DELETE_HEAD_LOB}
          okText={DELETE_PROVIDER_VERIFICATION_REJECTION_REASONS.DELETE}
        />
      )}
    </div>
  )
}

export default ProviderVerificationRejection
