import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'
import { type IAddUpdateDOcumentCategory } from 'types'

export const getFindAllCategory = async () => {
  try {
    const response = http.get(`/admin-document`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const editCategory = async (id: string, payload: IAddUpdateDOcumentCategory) => {
  try {
    const response = http.patch(`/admin-document/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const removeCategory = async (id: string) => {
  try {
    const response = http.delete(`/admin-document/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const createNewCategory = async (payload: IAddUpdateDOcumentCategory) => {
  try {
    const response = http.post(`/admin-document`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
