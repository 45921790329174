import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Image, Layout, message, Popover } from 'antd'
import { profile } from 'assets/imgs'
import { DownloadIcon, HeaderBack, LogOut, MyProfileIcon, NotificationIcon, SearchIcon, SettingsIconColor, SupportRequest, TaskManagerIcon } from 'assets/svgs'
import { BACK_MODAL_TEXT, pathsWithSpecialStyles } from 'constants/Constant'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { logOut } from 'services/StaticTokenServices'
import { setLogOut } from 'store/slice/AuthSlice'
import {
  selectCommon,
  setCurrentData,
  setCurrentNameData,
  setCurrentSkillData,
  setCurrentTestData,
  setGlobalLoader,
  setPreviousData,
  setPreviousNameData,
  setPreviousSkillData,
  setPreviousTestData,
  setType,
} from 'store/slice/CommonSlice'
import type { ILayoutProps } from 'types'
import { deepCompareArrays, deepMatch, isEqualData } from 'utils/Helper'

import Breadcrumbs from 'components/common/Breadcrumb'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'

import NotificationDrawer from './NotificationDrawer'
import Sidebar from './Sidebar'

const { Header, Sider, Content } = Layout

const AppLayout = ({ children }: ILayoutProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const contentRef = useRef<HTMLDivElement>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const { isBack, pageTitle, previousData, currentData, type, previousTestData, currentTestData, previousNameData, currentNameData, previousSkillData, currentSkillData } =
    useSelector(selectCommon)
  const { t } = useTranslation()
  const profileData = useAppSelector((state) => state?.auth?.auth?.profileDetails)

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0)
    }
  }, [location.pathname])

  const handleBack = () => {
    const dynamicPathPattern = /^\/settings\/(referance-form-setting|compentency-test|skill-checklist)\/(edit|add|edit-form|add-form|edit-test|add-test)(\/[a-zA-Z0-9-]+)?$/
    if (
      [
        '/settings/referance-form-setting/edit-form/referance-form-preview',
        '/settings/referance-form-setting/add-form/referance-form-preview',
        '/settings/compentency-test/add-test/preview',
        '/settings/compentency-test/edit-test/preview',
        '/settings/skill-checklist/add/preview',
        '/settings/skill-checklist/add/preview/subtopic',
        '/settings/skill-checklist/edit/preview',
        '/settings/skill-checklist/edit/preview/subtopic',
      ].includes(location.pathname)
    ) {
      navigate(-1)
      return
    }

    if (
      dynamicPathPattern.test(location.pathname) && // Match location.pathname
      (!deepMatch(previousNameData, currentNameData, type) || // Compare name data
        (type === 'skill'
          ? !deepCompareArrays(previousSkillData, currentSkillData) // Compare arrays for 'skill'
          : !isEqualData(
              type === 'test' ? previousTestData : previousData, // Compare test or data
              type === 'test' ? currentTestData : currentData,
              type
            )))
    ) {
      setIsDeleteModalOpen(true)
      return
    }
    if (dynamicPathPattern.test(location?.pathname)) {
      dispatch(setType(''))
      dispatch(setCurrentNameData({}))
      dispatch(setPreviousNameData({}))
    }
    navigate(-1)
  }

  const handleNotificationDrawerOpen = () => {
    setIsNotificationDrawerOpen(true)
  }

  const isSpecialPath = (path: string) => {
    return pathsWithSpecialStyles.some((basePath) => path === basePath || path.startsWith(`${basePath}/`))
  }

  const handleLogout = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await logOut()
      if (response?.data?.statusCode === 1) {
        dispatch(setLogOut())
        navigate(`/login`)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }

    dispatch(setGlobalLoader(false))
  }

  const handleLogoutClick = () => {
    void handleLogout()
  }

  const content = (
    <div>
      <div className="cursor-pointer p-4 flex gap-2 items-center">
        <span>
          <DownloadIcon className="flex" />
        </span>
        <p className="para-p1 text-neutral-800">{t('button.downloads')}</p>
      </div>
      <div className="cursor-pointer p-4 flex gap-2 items-center">
        <span>
          <SettingsIconColor className="flex" />
        </span>
        <p className="para-p1 text-neutral-800">{t('button.preferences')}</p>
      </div>
      <div className="cursor-pointer p-4 flex gap-2 items-center">
        <span>
          <TaskManagerIcon className="flex" />
        </span>
        <p className="para-p1 text-neutral-800">{t('button.taskManager')}</p>
      </div>
      <div
        className="cursor-pointer p-4 flex gap-2 items-center"
        onClick={() => {
          navigate('/my-profile')
        }}
      >
        <span>
          <MyProfileIcon className="flex" />
        </span>
        <p className="para-p1 text-neutral-800">{t('button.myProfile')}</p>
      </div>
      <div className="cursor-pointer p-4 flex gap-2 items-center border-t-stroke" onClick={handleLogoutClick}>
        <LogOut className="flex" />
        <p className="para-p1 text-error-500">{t('button.logout')}</p>
      </div>
    </div>
  )

  const isAddTestPath = isSpecialPath(location.pathname)
  return (
    <>
      <Layout className="wrapper-layout">
        <Sider trigger={null} collapsible collapsed={collapsed} className={`${collapsed ? 'collapsed-sidebar' : 'sider-layout'}  sidebar`}>
          <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        </Sider>
        <Layout className="ml-6">
          <Header className="header-wrapper flex justify-between items-center">
            <div className="header-content-div flex items-center gap-6">
              {isBack && (
                <div onClick={handleBack} className="div-handle-back">
                  <HeaderBack />
                </div>
              )}
              <p className="desktop-heading-h6 text-neutral-800 font-semi-bold header-heading-text">{pageTitle}</p>
            </div>

            <div className="flex gap-4 justify-end">
              <div className="flex gap-6 items-center">
                <div className="header-icon">
                  <SearchIcon color="#220EB1" />
                </div>
                <div className="header-icon">
                  <SupportRequest color="#220EB1" />
                </div>
                <div className="header-icon">
                  <NotificationIcon onClick={handleNotificationDrawerOpen} color="#220EB1" />
                </div>

                <Popover content={content} placement="bottom">
                  <Image
                    preview={false}
                    src={profileData?.image && profileData?.base_url ? `${profileData?.base_url}${profileData?.image}` : profile}
                    className="object-cover img-profile-picture"
                  />
                </Popover>
              </div>
            </div>
          </Header>

          <Content>
            {location?.pathname !== '/' && <Breadcrumbs />}
            <div
              ref={contentRef}
              /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
              className={` ${location?.pathname === '/' && 'mt-6'} ${isAddTestPath ? 'special-class' : 'wrapper-content overflow-y-auto'}`}
            >
              {children}
            </div>
          </Content>
        </Layout>
      </Layout>

      {isNotificationDrawerOpen && <NotificationDrawer isNotificationDrawerOpen={isNotificationDrawerOpen} setIsNotificationDrawerOpen={setIsNotificationDrawerOpen} />}
      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={BACK_MODAL_TEXT.CANCEL}
          okClick={() => {
            const isReferanceFormPath = location.pathname.includes('referance-form')
            const isCompentnacyPath = location?.pathname.includes('compentency-test')
            navigate(isReferanceFormPath ? '/settings/referance-form-setting' : isCompentnacyPath ? '/settings/compentency-test' : '/settings/skill-checklist')
            dispatch(setType(''))
            dispatch(setCurrentNameData({}))
            dispatch(setPreviousNameData({}))
            dispatch(setCurrentTestData([]))
            dispatch(setPreviousTestData([]))
            dispatch(setPreviousData([]))
            dispatch(setCurrentData([]))
            dispatch(setCurrentSkillData([]))
            dispatch(setPreviousSkillData([]))
            setIsDeleteModalOpen(false)
          }}
          subHead={BACK_MODAL_TEXT.DELETE_DESC}
          mainHead={BACK_MODAL_TEXT.DELETE_HEAD_LOB}
          okText={BACK_MODAL_TEXT.DELETE}
        />
      )}
    </>
  )
}

export default AppLayout
