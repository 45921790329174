/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'
import type { ICredentialDetails, ICredentialDocuments } from 'types'

export const getCertificate = async () => {
  try {
    const response = http.get('/dropdown/certificate')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getLicence = async () => {
  try {
    const response = http.get('/dropdown/licence')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getAllCategory = async (search: string) => {
  try {
    const response = http.get(`/credentials-category?${search && `&search=${search}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getCategoryById = async (id: string) => {
  try {
    const response = http.get(`/credentials-category/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const createOneCategory = async (payload: { name: string }) => {
  try {
    const response = http.post('/credentials-category', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateCategory = async (id: string, payload: { name: string }) => {
  try {
    const response = http.patch(`/credentials-category/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteCategory = async (id: string) => {
  try {
    const response = http.delete(`/credentials-category/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const createDocument = async (payload: ICredentialDocuments) => {
  try {
    const response = http.post('/credentials-document', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateDocument = async (payload: ICredentialDocuments, id: string) => {
  try {
    const response = http.patch(`/credentials-document/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteDocument = async (id: string) => {
  try {
    const response = http.delete(`/credentials-document/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getDocumentById = async (id: string) => {
  try {
    const response = http.get(`/credentials-document/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getCredentialRequirements = async (query?: string) => {
  try {
    const response = http.get(`/credentials-requirement?${query && `${query}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const createCredentialRequirements = async (payload: ICredentialDetails) => {
  try {
    const response = http.post('/credentials-requirement', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || 'Something went wrong')
  }
}

export const updateCredentialRequirements = async (payload: ICredentialDetails, id: string) => {
  try {
    const response = http.patch(`/credentials-requirement/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteCredentialRequirements = async (id: string) => {
  try {
    const response = http.delete(`/credentials-requirement/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteCredentialRequirementsCategory = async (id: string) => {
  try {
    const response = http.delete(`/credentials-requirement/delete-document/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getCertificateAndSpeciality = async () => {
  try {
    const response = http.get('/dropdown/certificate-and-speciality')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getCredentialDocuments = async () => {
  try {
    const response = http.get('/dropdown/credentials')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getState = async () => {
  try {
    const response = http.get('/dropdown/state?country_id=bd1ff32f-6fc2-4a01-8820-cea3a96b9a3d')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
