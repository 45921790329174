import { useCallback, useEffect, useState } from 'react'
import { Button, Form, message, Switch } from 'antd'
import { MinusIcon, PlusIcon } from 'assets/svgs'
import { ScheduleAndRequestText } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { debounce } from 'lodash'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IScheduleOrShiftItem } from 'types'

import { getScheduleAndRequest, updateScheduleAndRequest } from './api/index'

import './ScheduleAndRequest.scss'

const ScheduleAndRequest = () => {
  const dispatch = useAppDispatch()
  const [hours, setHours] = useState<number>(0)
  const [shiftRequestCount, setShiftRequestCount] = useState<number>(0)
  const [scheduleAndRequestData, setScheduleAndRequestData] = useState<IScheduleOrShiftItem[]>([])
  const [prevDefaultValues, setPrevDefaultValues] = useState<{
    hours: number
    shiftRequestCount: number
  }>({
    hours: 0,
    shiftRequestCount: 0,
  })

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.scheduleAndRequest'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetscheduleAndRequestSettings()
  }, [])

  useEffect(() => {
    if (scheduleAndRequestData) {
      const initialMinutes = Number(
        scheduleAndRequestData?.filter((it: IScheduleOrShiftItem) => it?.setting === ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST)?.[0]?.value
      )
      const initialShiftRequestCount = scheduleAndRequestData?.filter((it: IScheduleOrShiftItem) => it?.setting === ScheduleAndRequestText.HOW_MANY_TEMPS_CAN_REQUEST_SHIFT)?.[0]
        ?.value

      setHours(initialMinutes / 60)
      setShiftRequestCount(Number(initialShiftRequestCount))
    }
  }, [scheduleAndRequestData])

  const debouncedUpdateStatus = useCallback(
    debounce(async (id: string, data: { value: string }) => {
      dispatch(setGlobalLoader(true))
      const response = await updateScheduleAndRequest(id, data)
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        await handleGetscheduleAndRequestSettings()
      }
      dispatch(setGlobalLoader(false))
    }, 800),
    []
  )

  const formatFieldName = (fieldName: string) => {
    const result = fieldName.replace(/_/g, ' ')
    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  const handleGetscheduleAndRequestSettings = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getScheduleAndRequest()
    if (response?.status === 200) {
      setScheduleAndRequestData(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleHoursMinusClick = (id: string) => {
    if (hours >= 0.5) {
      const updatedHours = parseFloat((hours - 0.5).toFixed(2))
      setHours(updatedHours)
      const data = {
        value: (updatedHours * 60).toString(),
      }
      void debouncedUpdateStatus(id, data)
    }
  }

  const handleHoursPlusClick = (id: string) => {
    const updatedHours = parseFloat((hours + 0.5).toFixed(2))
    setHours(updatedHours)
    const data = {
      value: (updatedHours * 60).toString(),
    }
    void debouncedUpdateStatus(id, data)
  }

  const handleToggle = async (id: string, value: string, update = true) => {
    dispatch(setGlobalLoader(true))
    const payload = { value }
    const response = await updateScheduleAndRequest(id, payload)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      if (update) {
        void handleGetscheduleAndRequestSettings()
      }
    }
    dispatch(setGlobalLoader(false))
  }

  const handleChangeInputValue = (id: string, setting: string, newValue: string) => {
    if (setting !== ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST) {
      setScheduleAndRequestData((prevData) => prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item)))
    } else {
      setHours(parseFloat(newValue))
    }
  }

  const handleChangeValue = (id: string, setting: string, newValue: string) => {
    if (setting === ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST && hours !== prevDefaultValues.hours) {
      setScheduleAndRequestData((prevData) => prevData.map((item) => (item.id === id ? { ...item, value: (Number(newValue) * 60)?.toString() } : item)))
      void debouncedUpdateStatus(id, { value: (Number(newValue) * 60)?.toString() })
      setPrevDefaultValues({ ...prevDefaultValues, hours: Number(newValue) })
    } else if (setting !== ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST && shiftRequestCount !== prevDefaultValues.shiftRequestCount) {
      setScheduleAndRequestData((prevData) => prevData.map((item) => (item.id === id ? { ...item, value: newValue } : item)))
      void debouncedUpdateStatus(id, { value: newValue })
      setPrevDefaultValues({ ...prevDefaultValues, shiftRequestCount: Number(newValue) })
    }
  }

  return (
    <Form className="schedule-and-request-container flex flex-col items-start flex-grow rounded-4 border-stroke">
      {/* Schedule Section */}
      <div className="flex items-start flex-grow w-full">
        <div className="flex flex-col w-full">
          {scheduleAndRequestData.map((item: IScheduleOrShiftItem) => (
            <div key={item.id} className="flex border-b-stroke h-52">
              <div className="w-70 flex p-4 items-center gap-2 justify-start flex-grow w-70">
                <p className="text-neutral-800 para-p2 font-medium">{formatFieldName(item.setting)}</p>
              </div>
              <div className="w-30 flex p-4 items-center gap-2 justify-center flex-grow">
                {['active', 'in_active']?.includes(item?.value) ? (
                  <Switch
                    defaultChecked={item?.value === 'active'}
                    onChange={(checked) => {
                      void handleToggle(item?.id, checked ? 'active' : 'in_active', true)
                    }}
                  />
                ) : (
                  <div className="flex">
                    <div className="w-full flex p-4 items-center gap-2 justify-center flex-grow">
                      <div className="counter-box-mini">
                        <Button
                          icon={<MinusIcon />}
                          onClick={() => {
                            handleHoursMinusClick(item.id)
                          }}
                          className="counter-btn-min"
                        />
                        <div className="counter-text-wrapper">
                          <input
                            type="number"
                            min="0"
                            step="0.01"
                            className="counter-text"
                            value={item.setting === ScheduleAndRequestText.HOURS_BEFORE_TEMPS_CAN_TAKE_BACK_REQUEST ? parseFloat(hours.toFixed(2)) : shiftRequestCount}
                            onFocus={() => {
                              setPrevDefaultValues({
                                hours,
                                shiftRequestCount,
                              })
                            }}
                            onChange={(e) => {
                              const inputValue = e.target.value
                              handleChangeInputValue(item.id, item.setting, inputValue)
                            }}
                            onBlur={(e) => {
                              const newValue = parseFloat(e.target.value || '0').toFixed(2)
                              handleChangeValue(item.id, item.setting, newValue)
                            }}
                          />
                        </div>
                        <Button
                          icon={<PlusIcon className="plus-icon-solid" />}
                          onClick={() => {
                            handleHoursPlusClick(item.id)
                          }}
                          className="counter-btn-plus"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Form>
  )
}

export default ScheduleAndRequest
