import { useEffect, useState } from 'react'
import { message, Tabs } from 'antd'
import { useAppDispatch } from 'hooks/reduxHooks'
import { useWindowSize } from 'hooks/useWindowSize'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type ISection, type ISiteUploadData } from 'types'

import CustomBtn from 'components/common/CustomBtn'

import AccessSiteLogo from './Modal/AccessSiteLogo'
import AccessSiteTheme from './Modal/AccessSiteTheme'
import FacilityProfile from './Modal/FacilityProfile'
import { getMyFacilitySettings, getMyFacilitySiteAndTheme, updateMyFacilitySiteAndTheme } from './api'

import './MyFacilitySettings.scss'

const MyFacility = () => {
  const size = useWindowSize()
  const dispatch = useAppDispatch()

  const [selectedTabId, setSelectedTabId] = useState<string>('1')
  const [isSettingUpdated, setIsSettingUpdated] = useState<boolean>(false)
  const [isLogoOrThemeUpdated, setIsLogoOrThemeUpdated] = useState<boolean>(false)
  const [siteAndTheme, setSiteAndTheme] = useState()
  const [siteAndThemeUploadData, setSiteAndThemeUploadData] = useState<ISiteUploadData>({
    image: '',
    theme_color: '',
  })
  const [myFacilitySettingsData, setMyFacilitySettingsData] = useState<ISection[]>([])

  const items = [
    {
      key: '1',
      label: 'Facility Profile',
      children: <FacilityProfile myFacilitySettingsData={myFacilitySettingsData} setIsSettingUpdated={setIsSettingUpdated} />,
    },
    {
      key: '2',
      label: 'Client Access Site Logo',
      children: (
        <AccessSiteLogo
          setIsLogoOrThemeUpdated={setIsLogoOrThemeUpdated}
          siteAndTheme={siteAndTheme}
          siteAndThemeUploadData={siteAndThemeUploadData}
          setSiteAndThemeUploadData={setSiteAndThemeUploadData}
        />
      ),
    },
    {
      key: '3',
      label: 'Client Access Site Theme',
      children: (
        <AccessSiteTheme
          setIsLogoOrThemeUpdated={setIsLogoOrThemeUpdated}
          siteAndTheme={siteAndTheme}
          siteAndThemeUploadData={siteAndThemeUploadData}
          setSiteAndThemeUploadData={setSiteAndThemeUploadData}
        />
      ),
    },
  ]

  useEffect(() => {
    void handleGetMyFacilitySettings()
    setIsSettingUpdated(false)
  }, [isSettingUpdated])

  useEffect(() => {
    void handleGetSiteAndTheme()
  }, [selectedTabId])

  const handleGetMyFacilitySettings = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getMyFacilitySettings()
    if (response?.status === 200) {
      setMyFacilitySettingsData(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetSiteAndTheme = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getMyFacilitySiteAndTheme()
    if (response?.status === 200) {
      setSiteAndTheme(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleUpdateAccessSetting = async () => {
    dispatch(setGlobalLoader(true))
    const payload: Partial<ISiteUploadData> = {}

    if (siteAndThemeUploadData.image) {
      payload.image = siteAndThemeUploadData.image
    }
    if (siteAndThemeUploadData.theme_color) {
      payload.theme_color = siteAndThemeUploadData.theme_color
    }

    if (Object.keys(payload).length > 0) {
      const response = await updateMyFacilitySiteAndTheme(payload)
      if (response?.data.statusCode === 1) {
        void message.success(response?.data?.message)
        void handleGetMyFacilitySettings()
        setIsLogoOrThemeUpdated(false)
      }
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <div className="my-facility-setting-wrapper flex p-6 pt-1 items-start gap-6 w-full rounded-4 bg-white">
      <div className="w-full flex justify-end pr-6">
        {selectedTabId !== '1' ? (
          <CustomBtn
            disabled={!isLogoOrThemeUpdated}
            text={t('button.saveSettings')}
            onClick={() => {
              void handleUpdateAccessSetting()
            }}
            type="primary"
            className="w-150 h-[49] mt-6"
          />
        ) : (
          ''
        )}
      </div>
      <Tabs
        rootClassName="facility-setting w-full p-0"
        defaultActiveKey="1"
        tabPosition={size.width > 1230 ? 'left' : 'top'}
        items={items}
        onChange={(key) => {
          setSelectedTabId(key)
        }}
      />
    </div>
  )
}

export default MyFacility
