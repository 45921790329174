import React, { useEffect, useRef, useState } from 'react'
import { Checkbox, Form, Input, Select } from 'antd'
import { DownIcon, SearchIcon } from 'assets/svgs'

import './Select.scss'

const CustomMultiSelect = ({ Options, placeholder, handleChange, icon, value, rules, name, isIcon, updatedTag, error, searchPlaceholder, isSearch }: any) => {
  const selectRef = useRef<HTMLDivElement>(null)
  const [search, setSearch] = useState<string>('')
  const [selected, setSelected] = useState<any[]>(value || [])
  const [filteredOptions, setFilteredOptions] = useState<any[]>(Options || [])

  useEffect(() => {
    setSelected(value || [])
  }, [value])

  const handleClickInside = (event: any) => {
    event.stopPropagation()
  }

  const onSearch = (value: string) => {
    setSearch(value.trim().toLowerCase())
    filterOptions(value.trim().toLowerCase())
  }

  const filterOptions = (input: string) => {
    const filtered = Options?.filter((option: { name: string }) => option.name.toLowerCase().includes(input.toLowerCase()))
    setFilteredOptions(filtered)
  }

  const onInputKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
    }
  }

  const handleSelectChange = (selectedValues: any) => {
    setSelected(selectedValues)
    handleChange(selectedValues)
  }

  return (
    <>
      <div ref={selectRef} className="dropdown-menu">
        <Form.Item rules={selected.length === 0 ? rules : []} name={name} initialValue={selected}>
          <Select
            showSearch={false}
            placeholder={
              <span className="flex g-10 items-center text-neutral-800 font-normal items-center placeholder show-placeholder para-p2">
                {isIcon && icon} {placeholder}
              </span>
            }
            rootClassName={`dropdown-option-checkbox`}
            suffixIcon={
              <div className="flex items-center">
                {selected?.length > 0 && (
                  <span className="bg-primary-50 text-primary-500 font-size-12 flex items-center justify-center h-20 w-20 font-medium p-1 rounded-full">{selected?.length}</span>
                )}
                <DownIcon />
              </div>
            }
            maxTagCount={0}
            menuItemSelectedIcon={null}
            mode="multiple"
            variant="borderless"
            dropdownStyle={{ border: 'none', padding: '0px' }}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            className={`text-neutral-800 w-full para-p3 text-neutral-700 rounded-3  ${Options?.some((e: { value: any }) => value?.includes(e?.value)) && 'h-16'} ${selected?.length === 0 && error && 'status-error'} `}
            onChange={handleSelectChange}
            value={selected}
            onClick={() => {
              // Initially filter options based on current search input
              filterOptions(search)
            }}
            allowClear
            dropdownRender={(menu) => (
              <div>
                {isSearch && (
                  <div className="anchor-search-wrapper flex py-4 h-49 items-center">
                    <Input
                      prefix={<SearchIcon />}
                      placeholder={searchPlaceholder}
                      onPressEnter={() => {
                        onSearch(search)
                      }}
                      onChange={(e) => {
                        onSearch(e.target.value)
                      }}
                      allowClear
                      className="custom-searchbar"
                      onKeyDown={onInputKeyDown}
                      onClick={handleClickInside}
                    />
                  </div>
                )}
                <div>{menu}</div>
              </div>
            )}
          >
            {search === ''
              ? Options?.map((item: { value: React.Key | null | undefined; name: string }) => (
                  <Select.Option key={item?.value} value={item?.value} className="">
                    <div className="flex w-full gap-3">
                      <span className="flex justify-start gap-2 items-start">
                        <Checkbox checked={selected?.includes(item?.value)} />
                        <p style={{ paddingRight: '5px' }} className="para-p2-regular text-hidden whitespace-normal word-wrap break-words">
                          {item?.name}
                        </p>
                      </span>
                    </div>
                  </Select.Option>
                ))
              : filteredOptions?.map((item: { value: React.Key | null | undefined; name: string }) => (
                  <Select.Option key={item?.value} value={item?.value} className="">
                    <div className="flex w-full gap-3">
                      <span className="flex justify-start gap-2 items-start">
                        <Checkbox checked={selected?.includes(item?.value)} />
                        <p style={{ paddingRight: '5px' }} className="para-p2-regular text-hidden whitespace-normal word-wrap break-words">
                          {item?.name}
                        </p>
                      </span>
                    </div>
                  </Select.Option>
                ))}
          </Select>
          {placeholder && (
            <span className="pointer-events-none common-input multiselect-dropdown flex g-10 items-center items-center placeholder show-placeholder">
              {isIcon && icon} {placeholder}
            </span>
          )}
          {selected?.length === 0 && <p className="error-container-options">{error}</p>}
        </Form.Item>
      </div>
    </>
  )
}

export default CustomMultiSelect
