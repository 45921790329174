import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Image, message, Modal, Switch } from 'antd'
import { profile } from 'assets/imgs'
import { CloseIcon, EditPenIcon } from 'assets/svgs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IAddMember, IProfileModalProps } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'

import { editContact } from '../api'

import '../AdminContact.scss'

const MemberProfileModal = ({ isModalOpen, setIsModalOpen, viewActivityModal, contactDetails, setEditOpenModal }: IProfileModalProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [isChecked, setChecked] = useState(false)

  useEffect(() => {
    if (contactDetails) {
      setChecked(contactDetails?.status === 'active')
    }
  }, [contactDetails])
  const onSwitchChange = (checked: boolean, data: any, id: string) => {
    setChecked(checked)
    const newStatus = checked ? 'active' : 'in_active'

    const newData = { ...data, status: newStatus }
    void handleEditUserData(id, newData)
  }

  const handleEditUserData = async (editId: string, values: IAddMember) => {
    const payload = {
      ...values,
      role: values.role.id,
    }
    dispatch(setGlobalLoader(true))
    const response = await editContact(editId, payload)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <Modal
      className="activity-modal"
      open={isModalOpen}
      centered
      footer={null}
      closable={false}
      maskClosable
      onCancel={() => {
        setIsModalOpen(false)
      }}
    >
      <div className="wrapper-connect-modal flex flex-col">
        <div className="header-modal flex gap-6 justify-between items-center">
          <HeadingText classString="text-neutral-800 desktop-head-h5" text={t('heading.contactProfile')} />
          <Button
            icon={<CloseIcon />}
            className="modal-back-arrow"
            onClick={() => {
              setIsModalOpen(false)
            }}
          />
        </div>

        {contactDetails && (
          <div className="member-content-modal p-8">
            <div className="w-851 flex flex-col items-center justify-center gap-12">
              <div className="w-612 flex items-center justify-center gap-6">
                <Image
                  // eslint-disable-next-line
                  src={contactDetails?.image && contactDetails?.base_url ? `${contactDetails?.base_url}${contactDetails.image}` : profile}
                  preview={false}
                  height={160}
                  width={160}
                  className="w-160 rounded-100"
                />
                <div className="flex flex-col items-start gap-6 w-452">
                  <div className="flex flex-col items-start gap-1">
                    <span className="activity-text text-neutral-800 font-medium">
                      {contactDetails?.first_name} {contactDetails?.last_name}
                    </span>
                  </div>
                  <div className="flex justify-center items-start gap-6">
                    <div className="flex flex-col items-start gap-2 w-200">
                      <span className="para-p3 font-normal text-neutral-500">{t('heading.contactNo')}</span>
                      <span className="para-p2 font-normal text-neutral-800">{contactDetails?.mobile_no}</span>
                    </div>
                    <div className="flex flex-col items-start gap-2 w-200">
                      <span className="para-p3 font-normal text-neutral-500">{t('heading.email')}</span>
                      <span className="para-p2 font-normal text-neutral-800">{contactDetails?.email}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-612 flex items-start gap-6">
                <div className="flex flex-col items-start gap-6 ">
                  <div className="flex flex-col items-start gap-2">
                    <span className="flex items-start gap-2 para-p3 font-normal text-neutral-500">
                      {t('label.role')}
                      <span
                        onClick={() => {
                          setEditOpenModal(true)
                          setIsModalOpen(false)
                        }}
                      >
                        {<EditPenIcon />}
                      </span>
                    </span>
                    <div className="flex gap-2 items-center role-tag">{contactDetails?.role?.name}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={`footer-modal flex items-center gap-6 justify-between`}>
          <div>
            <span>{t('label.userStatus')}</span>{' '}
            <Switch
              checked={isChecked}
              onChange={(checked) => {
                onSwitchChange(checked, contactDetails, contactDetails?.id ?? '')
              }}
            />
          </div>
          <div className="flex gap-6">
            {contactDetails?.status !== 'invited' && (
              <CustomBtn
                text={t('button.viewActivity')}
                onClick={() => {
                  viewActivityModal(true)
                  setIsModalOpen(false)
                }}
                type="default"
                className="w-160 rounded-3"
              />
            )}
            <CustomBtn
              text={t('button.close')}
              onClick={() => {
                setIsModalOpen(false)
              }}
              type="primary"
              className="w-160 border-0 rounded-3"
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default MemberProfileModal
