import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DrawerClose, DrawerOpen, Logo } from 'assets/svgs'
import { siderMenu } from 'constants/SiderMenu'
import { useWindowSize } from 'hooks/useWindowSize'
import type { ISideBar } from 'types'

const Sidebar = ({ collapsed, setCollapsed }: ISideBar) => {
  const windowSize = useWindowSize()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setCollapsed(windowSize?.width <= 950)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize])

  return (
    <div>
      <div className={`flex ${!collapsed ? 'justify-between' : 'justify-center'}`}>
        {!collapsed && (
          <div className="flex items-center gap-1">
            <Logo />
          </div>
        )}

        {windowSize?.width >= 950 ? (
          <div
            className="cursor-pointer"
            onClick={() => {
              setCollapsed(!collapsed)
            }}
          >
            {collapsed ? <DrawerOpen className="cursor-pointer" /> : <DrawerClose className="cursor-pointer" />}
          </div>
        ) : (
          <Logo />
        )}
      </div>

      <div className="pt-10 scroll-container-sider">
        <div className="wrapper-menu-div">
          {siderMenu.map((data) => {
            const isSelected = data.path === '/' ? location.pathname === '/' : location.pathname.startsWith(data.path)

            return (
              <div
                key={data.id}
                className={`${isSelected ? 'submenu-div-selected' : ''} flex gap-2 items-center submenu-div`}
                onClick={() => {
                  navigate(data.path)
                }}
              >
                <div className="flex">{isSelected ? data.selectedIcon : data.icon}</div>
                <p style={{ color: isSelected ? '#fff' : '#C4C5C7' }} className={`submenu-name para-p2 font-medium ${collapsed ? 'none' : 'block'}`}>
                  {data.name}
                </p>
              </div>
            )
          })}
        </div>

        {/* <div className="wrapper-menu-div">
          {siderMenu.map((data) => {
            const isSelected = location.pathname.startsWith(data.path) // Check if the path starts with data.path
            return (
              <div
                key={data.id}
                className={`${isSelected ? 'submenu-div-selected' : ''} flex gap-2 items-center submenu-div`}
                onClick={() => {
                  navigate(data.path)
                }}
              >
                <div className="flex">{isSelected ? data.selectedIcon : data.icon}</div>
                <p style={{ color: isSelected ? '#fff' : '#C4C5C7' }} className={`submenu-name para-p2 font-medium ${collapsed ? 'none' : 'block'}`}>
                  {data.name}
                </p>
              </div>
            )
          })}
        </div> */}
      </div>
    </div>
  )
}

export default Sidebar
