import React from 'react'

const Logo = () => {
  return (
    // <svg width="187" height="31" viewBox="0 0 187 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M37.7122 10.502H31.3797C27.9197 10.502 24.7697 11.8244 22.4072 13.9944C25.0522 16.4219 26.7097 19.9044 26.7097 23.7769C26.7097 21.1994 28.7997 19.1095 31.3772 19.1095H37.7097C38.3197 19.1095 38.8122 18.6169 38.8122 18.0069V11.6069C38.8122 10.9969 38.3197 10.5045 37.7097 10.5045L37.7122 10.502Z"
    //     fill="#6111B9"
    //   />
    //   <g style={{ mixBlendMode: 'multiply' }}>
    //     <path
    //       d="M22.4075 13.9925C20.045 11.825 16.895 10.5 13.435 10.5H7.10249C6.49249 10.5 6 10.9925 6 11.6025V18.0025C6 18.6125 6.49249 19.105 7.10249 19.105H13.435C16.0125 19.105 18.1025 21.195 18.1025 23.7725C18.1025 19.9 19.76 16.4175 22.405 13.99L22.4075 13.9925Z"
    //       fill="#59D1F4"
    //     />
    //   </g>
    //   <path
    //     d="M22.407 13.9922C19.762 16.4197 18.1045 19.9022 18.1045 23.7747V29.7772C18.1045 30.0822 18.227 30.3572 18.427 30.5572C18.477 30.6072 18.532 30.6522 18.5895 30.6922C18.7645 30.8122 18.977 30.8797 19.207 30.8797H25.607C25.8345 30.8797 26.047 30.8097 26.2245 30.6922C26.282 30.6522 26.337 30.6072 26.387 30.5572C26.587 30.3572 26.7095 30.0822 26.7095 29.7772V23.7747C26.7095 19.9022 25.052 16.4197 22.407 13.9922Z"
    //     fill="#220EB1"
    //   />
    //   <path
    //     d="M22.4078 0C20.0203 0 17.8628 1.1825 16.3153 3.09C16.1778 3.26 16.1478 3.51499 16.2378 3.72749L17.8353 7.06C17.9228 7.2675 18.1078 7.385 18.2978 7.36C19.6478 7.18 21.0203 7.08501 22.4103 7.08501C23.8003 7.08501 25.1728 7.18 26.5228 7.36C26.7128 7.385 26.8978 7.2675 26.9853 7.06L28.5828 3.72749C28.6728 3.51749 28.6403 3.26 28.5053 3.09C26.9578 1.185 24.8003 0 22.4103 0H22.4078Z"
    //     fill="#220EB1"
    //   />
    //   <path
    //     d="M44.7672 24.3248V11.1873H48.9921V12.5773C49.4821 11.9598 50.0621 11.5073 50.7296 11.2248C51.3996 10.9423 52.1021 10.7998 52.8421 10.7998C54.0796 10.7998 55.0971 11.0423 55.8946 11.5273C56.6921 12.0123 57.2846 12.6398 57.6646 13.4073C58.0471 14.1748 58.2372 14.9898 58.2372 15.8473V24.3223H54.0121V17.1748C54.0121 16.3848 53.8046 15.7623 53.3871 15.3073C52.9696 14.8523 52.3596 14.6248 51.5521 14.6248C51.0371 14.6248 50.5871 14.7398 50.2071 14.9673C49.8246 15.1948 49.5271 15.5098 49.3121 15.9148C49.0971 16.3173 48.9896 16.7873 48.9896 17.3198V24.3273H44.7646L44.7672 24.3248Z"
    //     fill="#6111B9"
    //   />
    //   <path
    //     d="M65.7741 24.7096C64.5816 24.7096 63.5941 24.4746 62.8116 24.0071C62.0291 23.5396 61.4491 22.8921 61.0666 22.0621C60.6841 21.2346 60.4941 20.2696 60.4941 19.1696V11.1846H64.7191V18.5396C64.7191 19.2596 64.9091 19.8321 65.2916 20.2521C65.6741 20.6721 66.2291 20.8821 66.9591 20.8821C67.4316 20.8821 67.8466 20.7771 68.2016 20.5671C68.5566 20.3571 68.8316 20.0671 69.0266 19.6971C69.2216 19.3271 69.3166 18.8996 69.3166 18.4096V11.1846H73.5416V24.3221H69.8966V22.9321C69.4766 23.4046 68.9441 23.8196 68.2991 24.1746C67.6541 24.5296 66.8141 24.7096 65.7741 24.7096Z"
    //     fill="#6111B9"
    //   />
    //   <path
    //     d="M76.0523 24.3244V11.1869H80.2773V12.7194C80.6723 12.0419 81.1948 11.5469 81.8498 11.2369C82.5023 10.9269 83.2098 10.7744 83.9748 10.7744C84.2673 10.7744 84.5298 10.7919 84.7598 10.8269C84.9923 10.8619 85.2198 10.8994 85.4423 10.9419L84.9398 15.2944C84.6473 15.1819 84.3398 15.0969 84.0123 15.0369C83.6848 14.9769 83.3598 14.9469 83.0323 14.9469C82.2348 14.9469 81.5748 15.1744 81.0548 15.6294C80.5348 16.0844 80.2748 16.7544 80.2748 17.6394V24.3244H76.0498H76.0523Z"
    //     fill="#6111B9"
    //   />
    //   <path
    //     d="M91.4051 24.6849C90.1851 24.6849 89.0726 24.5349 88.0626 24.2349C87.0526 23.9349 86.1851 23.5099 85.4551 22.9599L86.7676 20.0624C87.4801 20.5174 88.2451 20.8749 89.0601 21.1374C89.8751 21.3999 90.6476 21.5299 91.3776 21.5299C92.0476 21.5299 92.5576 21.4374 92.9101 21.2524C93.2626 21.0674 93.4376 20.7999 93.4376 20.4474C93.4376 20.1374 93.2776 19.9099 92.9551 19.7649C92.6326 19.6199 92.0051 19.4599 91.0676 19.2874C89.2976 18.9874 88.0051 18.4924 87.1851 17.8049C86.3651 17.1174 85.9551 16.1699 85.9551 14.9599C85.9551 14.1024 86.1901 13.3499 86.6626 12.7049C87.1351 12.0599 87.7901 11.5599 88.6276 11.2049C89.4651 10.8474 90.4276 10.6699 91.5201 10.6699C92.6126 10.6699 93.5926 10.8124 94.5401 11.0949C95.4876 11.3774 96.3026 11.7724 96.9801 12.2799L95.7051 15.1899C95.3526 14.9149 94.9476 14.6749 94.4876 14.4699C94.0276 14.2649 93.5526 14.1049 93.0651 13.9924C92.5776 13.8799 92.1001 13.8249 91.6351 13.8249C91.0601 13.8249 90.5926 13.9124 90.2376 14.0899C89.8801 14.2674 89.7026 14.5074 89.7026 14.8174C89.7026 15.1274 89.8576 15.3724 90.1651 15.5249C90.4751 15.6799 91.0926 15.8549 92.0201 16.0524C93.8926 16.4299 95.2176 16.9399 96.0001 17.5799C96.7801 18.2199 97.1726 19.1149 97.1726 20.2649C97.1726 21.1574 96.9301 21.9349 96.4451 22.5974C95.9601 23.2574 95.2851 23.7724 94.4226 24.1374C93.5601 24.5024 92.5526 24.6849 91.4026 24.6849H91.4051Z"
    //     fill="#6111B9"
    //   />
    //   <path
    //     d="M105.147 24.7098C103.705 24.7098 102.437 24.4198 101.342 23.8398C100.247 23.2598 99.3973 22.4498 98.7923 21.4048C98.1873 20.3623 97.8848 19.1398 97.8848 17.7398C97.8848 16.7273 98.0598 15.7948 98.4123 14.9448C98.7648 14.0948 99.2623 13.3598 99.9073 12.7423C100.552 12.1248 101.31 11.6448 102.18 11.3073C103.052 10.9673 104.01 10.7998 105.057 10.7998C106.207 10.7998 107.235 11.0048 108.135 11.4123C109.037 11.8198 109.782 12.3873 110.37 13.1123C110.957 13.8373 111.362 14.6923 111.587 15.6748C111.81 16.6573 111.82 17.7248 111.612 18.8748H101.747C101.755 19.3898 101.91 19.8398 102.21 20.2273C102.51 20.6148 102.922 20.9173 103.447 21.1348C103.97 21.3548 104.58 21.4623 105.277 21.4623C106.05 21.4623 106.785 21.3623 107.48 21.1598C108.175 20.9573 108.84 20.6473 109.475 20.2273L110.802 23.0873C110.4 23.3973 109.877 23.6723 109.237 23.9173C108.597 24.1623 107.92 24.3548 107.202 24.4973C106.485 24.6398 105.8 24.7098 105.147 24.7098ZM102.005 16.0298H107.595C107.577 15.3698 107.332 14.8398 106.86 14.4448C106.387 14.0498 105.747 13.8523 104.94 13.8523C104.132 13.8523 103.447 14.0498 102.912 14.4448C102.375 14.8398 102.072 15.3673 102.005 16.0298Z"
    //     fill="#6111B9"
    //   />
    //   <path
    //     d="M118.155 24.6849C116.935 24.6849 115.823 24.5349 114.813 24.2349C113.803 23.9349 112.935 23.5099 112.205 22.9599L113.518 20.0624C114.23 20.5174 114.995 20.8749 115.81 21.1374C116.625 21.3999 117.398 21.5299 118.128 21.5299C118.798 21.5299 119.308 21.4374 119.66 21.2524C120.013 21.0674 120.188 20.7999 120.188 20.4474C120.188 20.1374 120.028 19.9099 119.705 19.7649C119.383 19.6199 118.755 19.4599 117.818 19.2874C116.048 18.9874 114.755 18.4924 113.935 17.8049C113.115 17.1174 112.705 16.1699 112.705 14.9599C112.705 14.1024 112.94 13.3499 113.413 12.7049C113.885 12.0599 114.54 11.5599 115.378 11.2049C116.215 10.8474 117.178 10.6699 118.268 10.6699C119.358 10.6699 120.34 10.8124 121.288 11.0949C122.235 11.3774 123.05 11.7724 123.728 12.2799L122.453 15.1899C122.1 14.9149 121.695 14.6749 121.235 14.4699C120.775 14.2649 120.303 14.1049 119.813 13.9924C119.323 13.8799 118.848 13.8249 118.383 13.8249C117.808 13.8249 117.34 13.9124 116.985 14.0899C116.63 14.2674 116.45 14.5074 116.45 14.8174C116.45 15.1274 116.605 15.3724 116.913 15.5249C117.22 15.6774 117.84 15.8549 118.768 16.0524C120.64 16.4299 121.965 16.9399 122.748 17.5799C123.53 18.2199 123.92 19.1149 123.92 20.2649C123.92 21.1574 123.678 21.9349 123.193 22.5974C122.708 23.2574 122.033 23.7724 121.17 24.1374C120.308 24.5024 119.3 24.6849 118.15 24.6849H118.155Z"
    //     fill="#6111B9"
    //   />
    //   <path
    //     d="M125.895 24.325V11.575H128.727V13.1975C129.26 12.485 129.877 11.9725 130.582 11.6575C131.287 11.345 132.017 11.1875 132.772 11.1875C133.94 11.1875 134.905 11.42 135.67 11.8825C136.435 12.345 137.002 12.9425 137.377 13.6725C137.75 14.4025 137.937 15.155 137.937 15.9275V24.325H135.105V16.815C135.105 15.895 134.832 15.165 134.287 14.62C133.742 14.075 132.985 13.8025 132.015 13.8025C131.38 13.8025 130.815 13.9425 130.32 14.22C129.825 14.5 129.437 14.8925 129.155 15.3975C128.872 15.905 128.73 16.4875 128.73 17.15V24.325H125.897H125.895Z"
    //     fill="#59D1F4"
    //   />
    //   <path
    //     d="M147.037 24.71C145.697 24.71 144.507 24.4025 143.462 23.79C142.417 23.1775 141.602 22.355 141.01 21.33C140.417 20.305 140.122 19.1775 140.122 17.95C140.122 17.04 140.292 16.18 140.63 15.3675C140.97 14.555 141.447 13.8375 142.065 13.21C142.682 12.5825 143.415 12.09 144.26 11.7275C145.105 11.3675 146.03 11.1875 147.035 11.1875C148.365 11.1875 149.552 11.495 150.595 12.1075C151.637 12.7225 152.455 13.5425 153.047 14.5675C153.64 15.595 153.935 16.72 153.935 17.9475C153.935 18.85 153.765 19.7075 153.427 20.5225C153.087 21.3375 152.61 22.06 151.992 22.6875C151.375 23.315 150.645 23.8075 149.802 24.1675C148.96 24.5275 148.037 24.7075 147.032 24.7075L147.037 24.71ZM147.037 22.1975C147.817 22.1975 148.517 22.0225 149.13 21.675C149.745 21.3275 150.227 20.835 150.58 20.2C150.932 19.565 151.107 18.8125 151.107 17.945C151.107 17.0775 150.932 16.325 150.58 15.685C150.227 15.045 149.745 14.5525 149.13 14.21C148.515 13.8675 147.815 13.695 147.025 13.695C146.235 13.695 145.532 13.8675 144.92 14.21C144.305 14.5525 143.825 15.045 143.477 15.685C143.13 16.325 142.955 17.0775 142.955 17.945C142.955 18.8125 143.13 19.5625 143.482 20.2C143.835 20.835 144.317 21.3275 144.932 21.675C145.547 22.0225 146.247 22.1975 147.037 22.1975Z"
    //     fill="#59D1F4"
    //   />
    //   <path
    //     d="M159.197 24.3242L155.192 11.5742H158.012L160.742 21.0542L163.73 11.5742H166.5L169.295 21.0542L172.077 11.5742H174.77L170.765 24.3242H167.842L165.035 15.1792L162.125 24.3242H159.202H159.197Z"
    //     fill="#59D1F4"
    //   />
    //   <path
    //     d="M176.695 13.4799V11.8199H176.103V11.3799H177.78V11.8199H177.183V13.4799H176.695ZM178.118 13.4799V11.3799H178.583L179.325 12.5299L180.055 11.3799H180.518V13.4799H180.048L180.058 12.2124L179.483 13.0799H179.155L178.58 12.2124V13.4799H178.118Z"
    //     fill="#59D1F4"
    //   />
    // </svg>

    <svg width="175" height="31" viewBox="0 0 175 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.7125 10.502H25.38C21.92 10.502 18.77 11.8244 16.4075 13.9944C19.0525 16.4219 20.71 19.9044 20.71 23.7769C20.71 21.1994 22.8 19.1095 25.3775 19.1095H31.71C32.32 19.1095 32.8125 18.6169 32.8125 18.0069V11.6069C32.8125 10.9969 32.32 10.5045 31.71 10.5045L31.7125 10.502Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M16.4075 13.9925C14.045 11.825 10.895 10.5 7.435 10.5H1.10249C0.492493 10.5 0 10.9925 0 11.6025V18.0025C0 18.6125 0.492493 19.105 1.10249 19.105H7.435C10.0125 19.105 12.1025 21.195 12.1025 23.7725C12.1025 19.9 13.76 16.4175 16.405 13.99L16.4075 13.9925Z"
        fill="white"
        fillOpacity="0.9"
      />
      <path
        d="M16.4075 13.9922C13.7625 16.4197 12.105 19.9022 12.105 23.7747V29.7772C12.105 30.0822 12.2275 30.3572 12.4275 30.5572C12.4775 30.6072 12.5325 30.6522 12.59 30.6922C12.765 30.8122 12.9775 30.8797 13.2075 30.8797H19.6075C19.835 30.8797 20.0475 30.8097 20.225 30.6922C20.2825 30.6522 20.3375 30.6072 20.3875 30.5572C20.5875 30.3572 20.71 30.0822 20.71 29.7772V23.7747C20.71 19.9022 19.0525 16.4197 16.4075 13.9922Z"
        fill="white"
      />
      <path
        d="M16.4076 0C14.0201 0 11.8626 1.1825 10.3151 3.09C10.1776 3.26 10.1476 3.51499 10.2376 3.72749L11.8351 7.06C11.9226 7.2675 12.1076 7.385 12.2976 7.36C13.6476 7.18 15.0201 7.08501 16.4101 7.08501C17.8001 7.08501 19.1726 7.18 20.5226 7.36C20.7126 7.385 20.8976 7.2675 20.9851 7.06L22.5826 3.72749C22.6726 3.51749 22.6401 3.26 22.5051 3.09C20.9576 1.185 18.8001 0 16.4101 0H16.4076Z"
        fill="white"
      />
      <path
        d="M38.7674 24.3248V11.1873H42.9924V12.5773C43.4824 11.9598 44.0624 11.5073 44.7299 11.2248C45.3999 10.9423 46.1024 10.7998 46.8424 10.7998C48.0799 10.7998 49.0974 11.0423 49.8949 11.5273C50.6924 12.0123 51.2849 12.6398 51.6649 13.4073C52.0474 14.1748 52.2374 14.9898 52.2374 15.8473V24.3223H48.0124V17.1748C48.0124 16.3848 47.8049 15.7623 47.3874 15.3073C46.9699 14.8523 46.3599 14.6248 45.5524 14.6248C45.0374 14.6248 44.5874 14.7398 44.2074 14.9673C43.8249 15.1948 43.5274 15.5098 43.3124 15.9148C43.0974 16.3173 42.9899 16.7873 42.9899 17.3198V24.3273H38.7649L38.7674 24.3248Z"
        fill="white"
      />
      <path
        d="M59.7749 24.7096C58.5824 24.7096 57.5949 24.4746 56.8124 24.0071C56.0299 23.5396 55.4499 22.8921 55.0674 22.0621C54.6849 21.2346 54.4949 20.2696 54.4949 19.1696V11.1846H58.7199V18.5396C58.7199 19.2596 58.9099 19.8321 59.2924 20.2521C59.6749 20.6721 60.2299 20.8821 60.9599 20.8821C61.4324 20.8821 61.8474 20.7771 62.2024 20.5671C62.5574 20.3571 62.8324 20.0671 63.0274 19.6971C63.2224 19.3271 63.3174 18.8996 63.3174 18.4096V11.1846H67.5424V24.3221H63.8974V22.9321C63.4774 23.4046 62.9449 23.8196 62.2999 24.1746C61.6549 24.5296 60.8149 24.7096 59.7749 24.7096Z"
        fill="white"
      />
      <path
        d="M70.0523 24.3244V11.1869H74.2773V12.7194C74.6723 12.0419 75.1948 11.5469 75.8498 11.2369C76.5023 10.9269 77.2098 10.7744 77.9748 10.7744C78.2673 10.7744 78.5298 10.7919 78.7598 10.8269C78.9923 10.8619 79.2198 10.8994 79.4423 10.9419L78.9398 15.2944C78.6473 15.1819 78.3398 15.0969 78.0123 15.0369C77.6848 14.9769 77.3598 14.9469 77.0323 14.9469C76.2348 14.9469 75.5748 15.1744 75.0548 15.6294C74.5348 16.0844 74.2748 16.7544 74.2748 17.6394V24.3244H70.0498H70.0523Z"
        fill="white"
      />
      <path
        d="M85.4048 24.6849C84.1848 24.6849 83.0723 24.5349 82.0623 24.2349C81.0523 23.9349 80.1848 23.5099 79.4548 22.9599L80.7673 20.0624C81.4798 20.5174 82.2448 20.8749 83.0598 21.1374C83.8748 21.3999 84.6473 21.5299 85.3773 21.5299C86.0473 21.5299 86.5573 21.4374 86.9098 21.2524C87.2623 21.0674 87.4373 20.7999 87.4373 20.4474C87.4373 20.1374 87.2773 19.9099 86.9548 19.7649C86.6323 19.6199 86.0048 19.4599 85.0673 19.2874C83.2973 18.9874 82.0048 18.4924 81.1848 17.8049C80.3648 17.1174 79.9548 16.1699 79.9548 14.9599C79.9548 14.1024 80.1898 13.3499 80.6623 12.7049C81.1348 12.0599 81.7898 11.5599 82.6273 11.2049C83.4648 10.8474 84.4273 10.6699 85.5198 10.6699C86.6123 10.6699 87.5923 10.8124 88.5398 11.0949C89.4873 11.3774 90.3023 11.7724 90.9798 12.2799L89.7048 15.1899C89.3523 14.9149 88.9473 14.6749 88.4873 14.4699C88.0273 14.2649 87.5523 14.1049 87.0648 13.9924C86.5773 13.8799 86.0998 13.8249 85.6348 13.8249C85.0598 13.8249 84.5923 13.9124 84.2373 14.0899C83.8798 14.2674 83.7023 14.5074 83.7023 14.8174C83.7023 15.1274 83.8573 15.3724 84.1648 15.5249C84.4748 15.6799 85.0923 15.8549 86.0198 16.0524C87.8923 16.4299 89.2173 16.9399 89.9998 17.5799C90.7798 18.2199 91.1723 19.1149 91.1723 20.2649C91.1723 21.1574 90.9298 21.9349 90.4448 22.5974C89.9598 23.2574 89.2848 23.7724 88.4223 24.1374C87.5598 24.5024 86.5523 24.6849 85.4023 24.6849H85.4048Z"
        fill="white"
      />
      <path
        d="M99.1475 24.7098C97.705 24.7098 96.4375 24.4198 95.3425 23.8398C94.2475 23.2598 93.3975 22.4498 92.7925 21.4048C92.1875 20.3623 91.885 19.1398 91.885 17.7398C91.885 16.7273 92.06 15.7948 92.4125 14.9448C92.765 14.0948 93.2625 13.3598 93.9075 12.7423C94.5525 12.1248 95.31 11.6448 96.18 11.3073C97.0525 10.9673 98.01 10.7998 99.0575 10.7998C100.208 10.7998 101.235 11.0048 102.135 11.4123C103.038 11.8198 103.783 12.3873 104.37 13.1123C104.958 13.8373 105.363 14.6923 105.588 15.6748C105.81 16.6573 105.82 17.7248 105.613 18.8748H95.7475C95.755 19.3898 95.91 19.8398 96.21 20.2273C96.51 20.6148 96.9225 20.9173 97.4475 21.1348C97.97 21.3548 98.58 21.4623 99.2775 21.4623C100.05 21.4623 100.785 21.3623 101.48 21.1598C102.175 20.9573 102.84 20.6473 103.475 20.2273L104.803 23.0873C104.4 23.3973 103.878 23.6723 103.238 23.9173C102.598 24.1623 101.92 24.3548 101.203 24.4973C100.485 24.6398 99.8 24.7098 99.1475 24.7098ZM96.005 16.0298H101.595C101.578 15.3698 101.333 14.8398 100.86 14.4448C100.388 14.0498 99.7475 13.8523 98.94 13.8523C98.1325 13.8523 97.4475 14.0498 96.9125 14.4448C96.375 14.8398 96.0725 15.3673 96.005 16.0298Z"
        fill="white"
      />
      <path
        d="M112.155 24.6849C110.935 24.6849 109.822 24.5349 108.812 24.2349C107.802 23.9349 106.935 23.5099 106.205 22.9599L107.517 20.0624C108.23 20.5174 108.995 20.8749 109.81 21.1374C110.625 21.3999 111.397 21.5299 112.127 21.5299C112.797 21.5299 113.307 21.4374 113.66 21.2524C114.012 21.0674 114.187 20.7999 114.187 20.4474C114.187 20.1374 114.027 19.9099 113.705 19.7649C113.382 19.6199 112.755 19.4599 111.817 19.2874C110.047 18.9874 108.755 18.4924 107.935 17.8049C107.115 17.1174 106.705 16.1699 106.705 14.9599C106.705 14.1024 106.94 13.3499 107.412 12.7049C107.885 12.0599 108.54 11.5599 109.377 11.2049C110.215 10.8474 111.177 10.6699 112.267 10.6699C113.357 10.6699 114.34 10.8124 115.287 11.0949C116.235 11.3774 117.05 11.7724 117.727 12.2799L116.452 15.1899C116.1 14.9149 115.695 14.6749 115.235 14.4699C114.775 14.2649 114.302 14.1049 113.812 13.9924C113.322 13.8799 112.847 13.8249 112.382 13.8249C111.807 13.8249 111.34 13.9124 110.985 14.0899C110.63 14.2674 110.45 14.5074 110.45 14.8174C110.45 15.1274 110.605 15.3724 110.912 15.5249C111.22 15.6774 111.84 15.8549 112.767 16.0524C114.64 16.4299 115.965 16.9399 116.747 17.5799C117.53 18.2199 117.92 19.1149 117.92 20.2649C117.92 21.1574 117.677 21.9349 117.192 22.5974C116.707 23.2574 116.032 23.7724 115.17 24.1374C114.307 24.5024 113.3 24.6849 112.15 24.6849H112.155Z"
        fill="white"
      />
      <path
        d="M119.895 24.325V11.575H122.727V13.1975C123.26 12.485 123.877 11.9725 124.582 11.6575C125.287 11.345 126.017 11.1875 126.772 11.1875C127.94 11.1875 128.905 11.42 129.67 11.8825C130.435 12.345 131.002 12.9425 131.377 13.6725C131.75 14.4025 131.937 15.155 131.937 15.9275V24.325H129.105V16.815C129.105 15.895 128.832 15.165 128.287 14.62C127.742 14.075 126.985 13.8025 126.015 13.8025C125.38 13.8025 124.815 13.9425 124.32 14.22C123.825 14.5 123.437 14.8925 123.155 15.3975C122.872 15.905 122.73 16.4875 122.73 17.15V24.325H119.897H119.895Z"
        fill="white"
      />
      <path
        d="M141.037 24.71C139.697 24.71 138.507 24.4025 137.462 23.79C136.417 23.1775 135.602 22.355 135.01 21.33C134.417 20.305 134.122 19.1775 134.122 17.95C134.122 17.04 134.292 16.18 134.63 15.3675C134.97 14.555 135.447 13.8375 136.065 13.21C136.682 12.5825 137.415 12.09 138.26 11.7275C139.105 11.3675 140.03 11.1875 141.035 11.1875C142.365 11.1875 143.552 11.495 144.595 12.1075C145.637 12.7225 146.455 13.5425 147.047 14.5675C147.64 15.595 147.935 16.72 147.935 17.9475C147.935 18.85 147.765 19.7075 147.427 20.5225C147.087 21.3375 146.61 22.06 145.992 22.6875C145.375 23.315 144.645 23.8075 143.802 24.1675C142.96 24.5275 142.037 24.7075 141.032 24.7075L141.037 24.71ZM141.037 22.1975C141.817 22.1975 142.517 22.0225 143.13 21.675C143.745 21.3275 144.227 20.835 144.58 20.2C144.932 19.565 145.107 18.8125 145.107 17.945C145.107 17.0775 144.932 16.325 144.58 15.685C144.227 15.045 143.745 14.5525 143.13 14.21C142.515 13.8675 141.815 13.695 141.025 13.695C140.235 13.695 139.532 13.8675 138.92 14.21C138.305 14.5525 137.825 15.045 137.477 15.685C137.13 16.325 136.955 17.0775 136.955 17.945C136.955 18.8125 137.13 19.5625 137.482 20.2C137.835 20.835 138.317 21.3275 138.932 21.675C139.547 22.0225 140.247 22.1975 141.037 22.1975Z"
        fill="white"
      />
      <path
        d="M153.197 24.3242L149.192 11.5742H152.012L154.742 21.0542L157.73 11.5742H160.5L163.295 21.0542L166.077 11.5742H168.77L164.765 24.3242H161.842L159.035 15.1792L156.125 24.3242H153.202H153.197Z"
        fill="white"
      />
      <path
        d="M170.695 13.4799V11.8199H170.103V11.3799H171.78V11.8199H171.183V13.4799H170.695ZM172.118 13.4799V11.3799H172.583L173.325 12.5299L174.055 11.3799H174.518V13.4799H174.048L174.058 12.2124L173.483 13.0799H173.155L172.58 12.2124V13.4799H172.118Z"
        fill="white"
      />
    </svg>
  )
}

export default Logo
