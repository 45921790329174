import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Checkbox, Dropdown, type MenuProps, message } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_FLOOR_DETAILS_TEXT, settingTab } from 'constants/Constant'
import { t } from 'i18next'
import { deleteFloorDetail, getAllFloorData } from 'pages/facilities/api'
import AddFloor from 'pages/facilities/Modal/AddFloor'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IEditFloor } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

const Floors = ({ facilityId }: { facilityId?: string }) => {
  const dispatch = useDispatch()
  const [isAddFloor, setIsAddFloor] = useState(false)
  const [floorsList, setFloorsList] = useState([])
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(floorsList?.length).fill(false))
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [editData, setEditData] = useState<IEditFloor>({
    name: '',
    beds: Number(''),
    po_number: '',
    cost_center: '',
    phone_number: '',
    description: '',
    speciality: {
      name: '',
      abbreviation: '',
      text_color: '',
      background_color: '',
    },
    client_contact: {
      email: '',
      first_name: '',
      last_name: '',
      base_url: '',
      image: '',
    },
    default_order_contact: {
      email: '',
      first_name: '',
      last_name: '',
      base_url: '',
      image: '',
    },
  })

  useEffect(() => {
    void getFloorsList(facilityId, paginationInfo.limit, paginationInfo.offset)
  }, [paginationInfo.limit, paginationInfo.offset, isEdit])

  const getFloorsList = async (facilityId: string | undefined, limit: number, offset: number) => {
    dispatch(setGlobalLoader(true))
    const response = await getAllFloorData(facilityId, limit, offset)
    if (response?.status === 200) {
      setFloorsList(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    }
    dispatch(setGlobalLoader(false))
  }

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleAddFloorClick = () => {
    setIsAddFloor(true)
    setIsEdit(false)
  }

  const handleDeleteFloorDetail = async (id: string) => {
    const response = await deleteFloorDetail(id)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void getFloorsList(facilityId, paginationInfo.limit, newOffset)
      }
    }
  }

  const getMenu = (record: IEditFloor, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            setEditData(record)
            setIsAddFloor(true)
            setIsEdit(true)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-medium text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '3',
          onClick: () => {
            setIsDeleteModalOpen(true)
            setDeleteId(record.id ?? '')
            setDeleteName(record.name)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-medium text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: t('table.evaluation.floorName'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 180,
      render(value, record) {
        return <p className="text-neutral-800 para-p2 font-medium">{record.name}</p>
      },
    },
    {
      title: t('table.evaluation.billToClient'),
      dataIndex: 'billToClient',
      key: 'billToClient',
      align: 'start',
      width: 194,
      render(value, record) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return <p className="text-neutral-800 para-p2 font-medium">{record?.client_contact ? `${record?.client_contact?.first_name} ${record?.client_contact?.first_name}` : ''}</p>
      },
    },
    {
      title: t('table.evaluation.phone'),
      dataIndex: 'phone_number',
      key: 'phone',
      align: 'start',
      width: 139,
      render(value, record) {
        return <p className="text-neutral-800 para-p2 font-medium">{record?.phone_number}</p>
      },
    },
    {
      title: t('table.evaluation.costCenter'),
      dataIndex: 'cost_center',
      key: 'costCenter',
      align: 'start',
      width: 140,
      render(value, record) {
        return <p className="text-neutral-800 para-p2 font-medium">{record?.cost_center}</p>
      },
    },
    {
      title: t('table.evaluation.defaultOrder'),
      dataIndex: 'defaultOrder',
      key: 'defaultOrder',
      align: 'start',
      width: 204,
      render(value, record) {
        return (
          <p className="text-neutral-800 para-p2 font-medium">
            {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
            {record?.default_order_contact ? `${record?.default_order_contact?.first_name} ${record?.default_order_contact?.last_name}` : ''}
          </p>
        )
      },
    },
    {
      title: t('table.evaluation.poNumber'),
      dataIndex: 'po_number',
      key: 'poNumber',
      align: 'start',
      width: 106,
      render(value, record) {
        return <p className="text-neutral-800 para-p2 font-medium">{record?.po_number}</p>
      },
    },
    {
      title: t('table.evaluation.speciality'),
      dataIndex: 'speciality',
      key: 'speciality',
      align: 'start',
      width: 107,
      render(value, record) {
        return (
          <div className="flex justify-center">
            <HeadingText
              style={{ background: record?.speciality?.background_color, color: record?.speciality?.text_color }}
              classString="status"
              text={record?.speciality?.abbreviation}
            />
          </div>
        )
      },
    },
    {
      title: t('table.evaluation.action'),
      dataIndex: '',
      key: 'floorName',
      align: 'center',
      width: 74,
      render: (_value, record: any) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return (
    <div className="floors-container flex flex-col items-start gap-6 w-full">
      <div className="flex items-start aline-self rounded-4 border-stroke ">
        <div className=" border-r-stroke max-w-400 flex p-4 justify-start items-center gap-2 aline-self">
          <p className="text-neutral-800 activity-text font-medium">{settingTab.CLIENT_SPECIAL_FLOOR}</p>
        </div>
        <div className="flex p-4 justify-start items-center gap-2 aline-self ">
          <div className="flex items-start gap-2 align-self">
            <Checkbox />
            <div className="flex flex-col items-start gap-2">
              <p className="text-neutral-800 activity-text font-semi-bold">{settingTab.ASSOCIATE_WITH_SPEC}</p>
              <p className="text-neutral-600 para-p3 font-medium">{settingTab.ASSOCIATE_WITH_SPEC_SUB}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end gap-4 aline-self">
        <CustomBtn type="default" text={t('button.addFloor')} isIcon svgIcon={<PlusIcon className="svg" />} className="plus-icon" onClick={handleAddFloorClick} />
        <CustomTable
          onChangePage={handlePageChange}
          column={columns}
          data={floorsList}
          total={paginationInfo.total}
          limit={paginationInfo.limit}
          current={paginationInfo.current}
          name={t('label.evaluations')}
        />
      </div>
      {isAddFloor && (
        <CustomModal
          open={isAddFloor}
          centered
          footer={null}
          onCancel={() => {
            setIsAddFloor(false)
          }}
          closable={false}
          title={isEdit ? t('label.editFloor') : t('label.addFloor')}
          classStrings="add-certificate-modal"
          content={
            <AddFloor
              isModalOpen={setIsAddFloor}
              facilityId={facilityId}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              editData={editData}
              pageLimit={paginationInfo.limit}
              offset={paginationInfo.offset}
              getFloorsList={getFloorsList}
            />
          }
          width={915}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_FLOOR_DETAILS_TEXT.CANCEL}
          okClick={() => {
            void handleDeleteFloorDetail(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_FLOOR_DETAILS_TEXT.DELETE_DESC}
          mainHead={DELETE_FLOOR_DETAILS_TEXT.DELETE_HEAD_LOB}
          okText={DELETE_FLOOR_DETAILS_TEXT.DELETE}
        />
      )}
    </div>
  )
}

export default Floors
