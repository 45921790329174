import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { MinusIcon, PlusIcon } from 'assets/svgs'
import { settingTab } from 'constants/Constant'
import { type IInvoiceSettingsProps } from 'types'

const InvoiceSettings = ({ setInvoiceCancellationDays }: IInvoiceSettingsProps) => {
  const [counter, setCounter] = useState(0)

  const handleMinusClick = () => {
    setCounter(counter > 0 ? counter - 1 : 0)
  }

  const handlePlusClick = () => {
    setCounter(counter + 1)
  }

  useEffect(() => {
    setInvoiceCancellationDays?.(counter)
  }, [counter])

  return (
    <div className="flex items-start aline-self rounded-4 border-stroke">
      <div className="flex p-4 items-center gap-2 aline-self flex-grow-1">
        <p className="text-neutral-800 para-p2 font-medium">{settingTab.ALLOW_CANCEL}</p>
      </div>
      <div className="flex p-4 items-center gap-2 aline-self flex-grow-1">
        <div className="counter-box">
          <Button icon={<MinusIcon />} onClick={handleMinusClick} className="counter-btn-min" />
          <div className="counter-text-wrapper">
            <input
              type="number"
              min="0"
              className="counter-text"
              value={counter}
              onKeyDown={(e) => {
                if (e.key === '.' || e.key === ',' || (e.key === 'e' && 'E')) {
                  e.preventDefault()
                }
              }}
              onChange={(e) => {
                const inputValue = e.target.value
                setCounter(parseFloat(inputValue))
              }}
            />
          </div>
          <Button icon={<PlusIcon className="plus-icon-solid " />} onClick={handlePlusClick} className="counter-btn-plus" />
        </div>
        <p className="text-neutral-800 para-p3 font-small">{settingTab.DAYS}</p>
      </div>
    </div>
  )
}

export default InvoiceSettings
