import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'
import { type IGeneralPayload, type IGeneralSettingCreate } from 'types'

export const getGeneralSettings = async () => {
  try {
    const response = http.get('/provider-general-setting/all')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const addSourceName = async (payload: IGeneralSettingCreate) => {
  try {
    const response = http.post('/provider-general-setting/create-source', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateSettings = async (payload: IGeneralPayload) => {
  try {
    const statusData = payload.placeholder ? { status: payload.status, placeholder: payload.placeholder } : { status: payload.status }
    const response = http.patch(`/provider-general-setting/${payload.id}`, statusData)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
