import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from 'store/Store'
import { type IFacility } from 'types'

const initialState: { selectedFacility: IFacility | null } = {
  selectedFacility: null,
}

export const facilitiesSlice = createSlice({
  name: 'facilities',
  initialState,
  reducers: {
    setSelectedFacility: (state, action) => {
      state.selectedFacility = action.payload
    },
  },
})

export const { setSelectedFacility } = facilitiesSlice.actions

export const selectFacilities = (state: RootState) => state.facilities

export default facilitiesSlice.reducer
