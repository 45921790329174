import React from 'react'

const DrawerOpen = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 6L18.2929 11.2929C18.6834 11.6834 18.6834 12.3166 18.2929 12.7071L13 18M7 6L12.2929 11.2929C12.6834 11.6834 12.6834 12.3166 12.2929 12.7071L7 18"
      stroke="#C4C5C7"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default DrawerOpen
