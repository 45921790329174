import { useState } from 'react'
import { type ColumnsType } from 'antd/es/table'
import { InfoIcon } from 'assets/svgs'
import { PROVIDER_STRING, TABLE_COLUMNS } from 'constants/Constant'

import CustomModal from 'components/common/CustomModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import CompleteEvaluation from '../modal/CompleteEvaluation'

const tableData = [
  {
    id: '1',
    evaDate: '15/03/2023',
    evaluation: 'Performance Evaluation',
    client: 'Autumn Lane Health and Rehabilitation',
    servicePeriod: '04/01/2021 - 31/03/2022',
    result: '100%',
  },
]

const Evaluation = () => {
  const [isModalOpen, setOpenModal] = useState<boolean>(false)
  const [paginationInfo, setPaginationInfo] = useState({
    total: 1,
    offset: 0,
    limit: 10,
    current: 1,
  })

  const handlePageChange = (page: number) => {
    const newOffset = (page - 1) * paginationInfo.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
    }))
  }

  const handleClick = () => {
    setOpenModal(true)
  }

  const columns: ColumnsType = [
    {
      title: TABLE_COLUMNS.EVALUATION.EVAL_DATE,
      dataIndex: 'evaDate',
      key: 'evaDate',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.evaDate - b.evaDate,
      width: 140,
    },
    {
      title: TABLE_COLUMNS.EVALUATION.EVALUATION,
      dataIndex: 'evaluation',
      key: 'evaluation',
      align: 'start',
      width: 250,
    },
    {
      title: TABLE_COLUMNS.EVALUATION.CLIENT,
      dataIndex: 'client',
      key: 'client',
      align: 'start',
      width: 290,
      render: (_value, record) => {
        return <HeadingText classString="font-small para-p3 line-clamp-table" text={record.client} />
      },
    },
    {
      title: TABLE_COLUMNS.EVALUATION.SERVICE_PERIOD,
      dataIndex: 'servicePeriod',
      key: 'servicePeriod',
      align: 'start',
      width: 255,
    },
    {
      title: TABLE_COLUMNS.EVALUATION.RESULT,
      dataIndex: 'result',
      key: 'result',
      align: 'start',
      width: 117,
      render(value, record) {
        return <HeadingText classString={` shift-status scheduled`} text={record.result} />
      },
    },
    {
      title: TABLE_COLUMNS.EVALUATION.ACTION,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 100,
      render(value, record) {
        return (
          <div className="flex w-full justify-center p-2">
            <div className="action-cell flex items-center justify-center" onClick={handleClick}>
              <InfoIcon className="cursor-pointer" />
            </div>
            {isModalOpen && (
              <CustomModal
                width={1063}
                open={isModalOpen}
                onCancel={() => {
                  setOpenModal(false)
                }}
                title={PROVIDER_STRING.COMPLETE_EVALUATION}
                footer={null}
                // className="hide-closeicon-modal"
                content={<CompleteEvaluation setIsModalOpen={setOpenModal} />}
              />
            )}
          </div>
        )
      },
    },
  ]

  return (
    <div className="shift-history w-full px-40 pb-10">
      <CustomTable
        column={columns}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        data={tableData}
        name={PROVIDER_STRING.EVALUATION}
      />
    </div>
  )
}

export default Evaluation
