import { useEffect, useState } from 'react'
import { Form, message } from 'antd'
import { COMMON } from 'constants/Constant'
import { commonSelectFieldValidation } from 'constants/Validation'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomSelect from 'components/common/Select/CustomSelect'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import { CANCEL_SHIFT, GET_CANCEL_REASON } from '../../api'
import type { ICancelReason, ICancelShift, ICancelShiftModal } from '../../types'

const CancelShift = ({ id, setIsModalOpen, isCancel, setIsCancel }: ICancelShiftModal) => {
  const [error, setError] = useState({
    cancel_reason: '',
  })
  const [cancelReasons, setCancelReasons] = useState<ICancelReason[]>([])
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()
  useEffect(() => {
    void getCancelReasonList()
  }, [])

  const onFinish = async (values: ICancelShift) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await CANCEL_SHIFT(id, values)
      if (response?.data?.statusCode === 1) {
        setIsModalOpen(false)
        setIsCancel(!isCancel)
        void message.success(response?.data?.message)
      } else {
        void message.success(response?.data?.message)
      }
    } catch (error) {}
    dispatch(setGlobalLoader(false))
  }

  const getCancelReasonList = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_CANCEL_REASON()
      if (response?.data?.statusCode === 1) {
        setCancelReasons(response?.data?.data)
      } else {
        void message.error(response?.data?.message[0])
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleBackClick = () => {
    setIsModalOpen(false)
  }

  return (
    <Form
      form={form}
      onFinish={(values) => {
        void onFinish(values)
      }}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
      className="flex flex-col"
    >
      <CustomSelect
        label={COMMON.SELECT_REASON}
        options={cancelReasons?.map((item: { reason: string; id: string }) => ({
          label: item?.reason,
          value: item?.id,
        }))}
        name="cancel_reason"
        error={error.cancel_reason}
        rules={commonSelectFieldValidation('cancel reason')}
      />
      <div className="w-full">
        <CommonTextArea name="cancel_reason_description" placeholder={COMMON.ADDITIONAL_DETAIL} rows={4} />
      </div>
      <div className="w-full flex justify-end pt-2 gap-4">
        <CustomBtn type="default" text={COMMON.BACK} className="w-160 h-49" onClick={handleBackClick} />
        <CustomBtn text={COMMON.CANCEL} type="text" htmlType="submit" className="cancel-shift-btn w-160 h-49" />
      </div>
    </Form>
  )
}

export default CancelShift
