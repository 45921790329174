import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import { commonMultiSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import DropDown from 'components/common/Select/DropDown'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import '../Facilites.scss'

const RejectReasonData = [
  {
    id: '1',
    name: 'Seems suspicious',
  },
  {
    id: '2',
    name: 'Fake Credentials',
  },
  {
    id: '3',
    name: 'Other',
  },
]

const RejectFacility = ({ isModalOpen, openRejectModal }: any) => {
  const [rejectTag, setRejectTag] = useState()
  const [error, setError] = useState({
    rejectReason: '',
  })
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const onFinish = (values: any) => {
    const rejectReason = rejectTag
    const updateValue = { description: values.rejectDescription, reject_reason: rejectReason }
    console.log(updateValue)
    openRejectModal(false)
  }

  const handleRejectChange = (selectedValue: any) => {
    setRejectTag(selectedValue)
  }

  const hancleCancelClick = () => {
    isModalOpen(true)
    openRejectModal(false)
  }

  return (
    <Form
      className="flex flex-col items-start justify-start w-full h-full p-8"
      form={form}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div className={`cursor-pointer w-full ${error.rejectReason ? '' : 'mb-6'} `}>
        <DropDown
          name="rejectReason"
          handleChange={handleRejectChange}
          Options={RejectReasonData}
          placeholder={t('placeholder.selectReason')}
          optionWidth={200}
          rules={commonMultiSelectValidation(VALIDATION_STRINGS.REASON)}
          updatedTag={rejectTag}
        />
      </div>
      {error?.rejectReason && <p className="error-container mb-6">{error.rejectReason}</p>}

      <CommonTextArea name="rejectDescription" placeholder={t('placeholder.description')} rows={4} />
      <div className="flex gap-4 w-full items-end justify-end ">
        <CustomBtn type="default" text={t('button.back')} className="w-160" onClick={hancleCancelClick} />
        <CustomBtn text={t('button.reject')} type="text" htmlType="submit" className="w-160 cancel-btn text-white " />
      </div>
    </Form>
  )
}

export default RejectFacility
