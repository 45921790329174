import { useEffect, useState } from 'react'
import { Avatar, message, Modal, Tabs, type TabsProps } from 'antd'
import { profile } from 'assets/imgs'
import { BuildingIcon, ClockIcon, CloseIcon, EveningIcon, FlexibleIcon, LocationIcon, MoonIcon, ParagraphIcon, SunIcon } from 'assets/svgs'
import { SHIFTS } from 'constants/Constant'
import { PERIOD_STATUS, SHIFT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { type IDetailsModalData } from 'pages/facilities/facilityProfile/types'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IDetailShiftModalProps } from 'types'
import { formatTitle } from 'utils/Helper'
import { formatDateStrings } from 'utils/utils'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'

import { GET_ALL_INVITED_USER, GET_AUTO_SCHEDULED_USER, GET_REQUESTED_USER, GET_SINGLE_SHIFT } from '../../../api'
import CancelShift from '../CancelShift'

import AutoSchedulingTab from './Tabs/AutoSchedulingTab'
import InvitesTab from './Tabs/InvitesTab'
import ProviderTracking from './Tabs/ProviderTab'
import RequestTab from './Tabs/RequestTab'
import ShiftDetailTab from './Tabs/ShiftDetailTab'

const DetailShift = ({
  id,
  setRowId,
  isDetailShiftOpen,
  setIsCreateShiftModalOpen,
  setIsDetailShiftOpen,
  isCancel,
  setIsCancel,
  tabId,
  setTabId,
  isEdit,
  setIsEdit,
}: IDetailShiftModalProps) => {
  const [isActivity, setIsActivity] = useState<boolean>(false)
  const [requestedData, setRequestedData] = useState<any>([])
  const [isOpenCancelModal, setIsCancelModal] = useState<boolean>(false)
  const [modalData, setModalData] = useState<IDetailsModalData>()
  const [invitedData, setInvitedData] = useState<any>()
  const [isWithdrawn, setIsWithdrawn] = useState<boolean>(false)
  const [isAssigned, setIsAssigned] = useState<boolean>()
  // const [isEdit, setIsEdit] = useState(false)
  const [autoScheduledData, setAutoScheduledData] = useState<any>()
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [requestedPaginationInfo, setRequestedPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [autoSchedulePagination, setAutoSchedulePagination] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    void getDetailsShift()
    // eslint-disable-next-line
  }, [isAssigned, !isAssigned, !isEdit, isCancel])

  useEffect(() => {
    if (modalData?.id) {
      void getAllAutoScheduledData(modalData?.id, paginationInfo?.limit, paginationInfo?.offset)
    }
    // eslint-disable-next-line
  }, [modalData, paginationInfo?.limit, paginationInfo?.offset])

  useEffect(() => {
    if (modalData?.id) {
      void getAllRequestedData(modalData?.id, requestedPaginationInfo?.limit, requestedPaginationInfo?.offset)
    }
    // eslint-disable-next-line
  }, [modalData, isWithdrawn, !isWithdrawn, requestedPaginationInfo?.limit, requestedPaginationInfo?.offset])

  useEffect(() => {
    if (modalData?.id) {
      void handleGetInvitedUser(modalData?.id, paginationInfo?.limit, paginationInfo?.offset)
    }
    // eslint-disable-next-line
  }, [modalData, isWithdrawn, !isWithdrawn, paginationInfo?.limit, paginationInfo?.offset])

  const getAllRequestedData = async (id: string, limit: number, offset: number) => {
    dispatch(setGlobalLoader(true))
    const response = await GET_REQUESTED_USER(id, limit, offset)
    if (response?.data?.statusCode === 1) {
      setRequestedData(response?.data)
      setRequestedPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
      if (response?.data?.total < 1 && tabId === '3') {
        setTabId('1')
      }
    }
    dispatch(setGlobalLoader(false))
  }

  const getAllAutoScheduledData = async (id: string, limit: number, offset: number) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_AUTO_SCHEDULED_USER(id, limit, offset)
      if (response?.data?.statusCode === 1) {
        setAutoScheduledData(response?.data)
        setAutoSchedulePagination((prev) => ({
          ...prev,
          total: response?.data?.total,
          limit: response?.data?.limit,
        }))
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetInvitedUser = async (id: string, limit: number, offset: number) => {
    dispatch(setGlobalLoader(true))

    try {
      const response = await GET_ALL_INVITED_USER(id, limit, offset)
      if (response?.data?.statusCode === 1) {
        setInvitedData(response?.data)
        setPaginationInfo((prev) => ({
          ...prev,
          total: response?.data?.total,
          limit: response?.data?.limit,
        }))
      }
    } catch (error) {}
    dispatch(setGlobalLoader(false))
  }

  const handlePageChange = (page: number, pageSize: number) => {
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    }))
  }

  const handleAutoScheduledDataPageChange = (page: number, pageSize: number) => {
    setRequestedPaginationInfo((prev) => ({
      ...prev,
      current: page,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    }))
  }

  const handleRequestedDataPageChange = (page: number, pageSize: number) => {
    setRequestedPaginationInfo((prev) => ({
      ...prev,
      current: page,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    }))
  }

  const onTabChange = (key: string) => {
    setTabId(key)
  }

  const getDetailsShift = async () => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await GET_SINGLE_SHIFT(id)
      if (response?.status === 200) {
        setModalData(response?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleCloseClick = () => {
    setIsDetailShiftOpen(false)
    setRowId('')
    setIsEdit(false)
    setTabId('1')
  }

  const handleActivityClick = () => {
    setIsActivity(!isActivity)
  }

  const tabData: TabsProps['items'] = [
    {
      key: '1',
      label: 'Details',
      children: (
        <ShiftDetailTab
          isEdit={isEdit}
          setIsCreateShiftModalOpen={setIsCreateShiftModalOpen}
          setIsEdit={setIsEdit}
          data={modalData}
          setIsCancelModal={setIsCancelModal}
          status={modalData?.status}
          isActivity={isActivity}
          setIsDetailShiftOpen={setIsDetailShiftOpen}
        />
      ),
    },
    ...(modalData?.status !== SHIFT_STATUS.VOID
      ? [
          {
            key: '2',
            label: 'Provider Tracking',
            children: <ProviderTracking isActivity={isActivity} shiftStatus={modalData?.status} />,
          },
        ]
      : []),
  ]

  if (requestedData?.total) {
    tabData.push({
      key: '3',
      label: `Requests (${requestedData?.total as number})`,
      children: (
        <RequestTab
          paginationInfo={requestedPaginationInfo}
          handlePageChange={handleRequestedDataPageChange}
          status={modalData?.status}
          data={requestedData}
          setIsAssigned={setIsAssigned}
          isAssigned={isAssigned}
        />
      ),
    })
  }

  if (invitedData?.data?.length) {
    tabData.push({
      key: '4',
      label: `Invite (${invitedData?.data?.length as number})`,
      children: (
        <InvitesTab
          isWithdrawn={isWithdrawn}
          setIsWithdrawn={setIsWithdrawn}
          status={modalData?.status}
          data={invitedData}
          paginationInfo={paginationInfo}
          handlePageChange={handlePageChange}
        />
      ),
    })
  }

  if (autoScheduledData?.data?.length) {
    tabData.push({
      key: '5',
      label: `Auto Scheduling (${autoScheduledData?.total as number})`,
      children: (
        <AutoSchedulingTab status={modalData?.status} data={autoScheduledData} paginationInfo={autoSchedulePagination} handlePageChange={handleAutoScheduledDataPageChange} />
      ),
    })
  }

  return (
    <Modal
      open={isDetailShiftOpen}
      centered
      maskClosable
      onCancel={() => {
        setIsDetailShiftOpen(false)
        setRowId('')
        setIsEdit(false)
        setTabId('1')
      }}
      wrapClassName="modal-wrapper"
      closable={false}
      closeIcon={null}
      footer={null}
      className="create-shift-modal"
    >
      <div className="flex wrapper-create-shift">
        <div className="details-div">
          <div
            className={`w-auto px-3 py-6px w-fit common-tags ${modalData?.status === SHIFT_STATUS.OPEN ? 'tertiary' : modalData?.status === SHIFT_STATUS.CANCELLED ? 'error' : modalData?.status === SHIFT_STATUS.COMPLETED ? 'neutral-tag' : modalData?.status === SHIFT_STATUS.SCHEDULE ? 'success' : modalData?.status === SHIFT_STATUS.VOID ? 'void' : 'warning'}`}
          >
            {formatTitle(modalData?.status as string)}
          </div>
          <div className="mt-6">
            <p className="font-medium text-neutral-800 mobile-heading-h5">{modalData?.facility?.name}</p>
            <p className="mt-2 para-p2 text-neutral-700">{dayjs(modalData?.start_date).format('ddd, DD MMM YYYY')}</p>
          </div>

          <div className="pt-4 flex gap-2">
            <p className="invited-tag">{modalData?.certificate?.name}</p>
            <p className="inactive">{modalData?.speciality?.name}</p>
            <span className="rounded bg-neutral-50 p-3px">
              {modalData?.period === PERIOD_STATUS.NIGHT ? (
                <MoonIcon />
              ) : modalData?.period === PERIOD_STATUS.DAY ? (
                <SunIcon />
              ) : modalData?.period === PERIOD_STATUS.EVENING ? (
                <EveningIcon />
              ) : (
                <FlexibleIcon />
              )}
            </span>
          </div>

          <div className="flex gap-2 mt-6">
            <ClockIcon fill="#919195" />
            <p className="text-neutral-600 para-p2 font-normal">
              {dayjs(modalData?.start_time, 'HH:mm:ss').format('h:mm A')} - {dayjs(modalData?.end_time, 'HH:mm:ss').format('h:mm A')}
            </p>
          </div>
          <div className="flex gap-2 mt-6">
            <BuildingIcon />
            <p className="text-neutral-600 para-p2 font-normal">Floor : {modalData?.floor?.name ?? '-'}</p>
          </div>
          <div className="flex gap-2 mt-6">
            <LocationIcon />
            <p className="text-neutral-600 para-p2 font-normal">
              {/* eslint-disable-next-line */}
              {modalData?.facility?.city && modalData?.facility?.state ? `${modalData?.facility?.city}, ${modalData?.facility?.state}` : '-'}
            </p>
          </div>

          {modalData?.provider && Object.keys(modalData?.provider).length > 0 ? (
            <div className="mt-6">
              <p className="text-neutral-500 para-p3 font-normal mb-2">{SHIFTS.PROVIDER}</p>
              <div className="flex gap-2 items-center">
                <Avatar src={modalData?.provider?.profile_image ? `${modalData?.provider?.base_url}${modalData?.provider?.profile_image}` : profile} alt="Provider" />
                {/* eslint-disable-next-line */}
                <p className="para-p2 text-decoration-line">{modalData?.provider?.first_name ? `${modalData?.provider?.first_name} ${modalData?.provider?.last_name}` : '-'}</p>
              </div>
            </div>
          ) : null}

          {modalData?.status === SHIFT_STATUS.CANCELLED && (
            <div className="mt-6">
              <p className="text-neutral-500 para-p3 font-normal mb-2">{SHIFTS.CANCEL_BY}</p>
              <div className="flex gap-2 items-center">
                <Avatar src={modalData?.cancelled_by?.image ? `${modalData?.cancelled_by?.base_url}${modalData?.cancelled_by?.image}` : profile} />
                <p className="para-p2 text-decoration-line">{modalData?.cancelled_by?.name}</p>
              </div>
            </div>
          )}
          <div className="mt-6">
            <p className="text-neutral-500 para-p3 font-normal mb-2">{SHIFTS.CONTACT}</p>
            <div className="flex gap-2 items-center">
              <Avatar src={modalData?.follower?.image ? `${modalData?.follower?.base_url}${modalData?.follower?.image}` : profile} />
              <p className="para-p2 text-decoration-line">
                {modalData?.follower?.first_name} {modalData?.follower?.last_name}
              </p>
            </div>
          </div>
          {modalData?.status === SHIFT_STATUS.CANCELLED && (
            <>
              <div className="mt-6">
                <p className="text-neutral-500 para-p3 font-normal mb-2">{SHIFTS.CANCEL_ON}</p>
                <p className="para-p2 text-neutral-800">{formatDateStrings(modalData?.cancelled_by?.cancelled_on)}</p>
              </div>
              <div className="mt-6">
                <p className="text-neutral-500 para-p3 font-normal mb-2">{SHIFTS.CANCEL_REASON}</p>
                <p className="para-p2 text-neutral-800">{modalData?.cancelled_by?.reason ? modalData?.cancelled_by?.reason : '-'}</p>
              </div>
            </>
          )}
          <div className="mt-6 w-full bg-neutral-50 p-2 flex flex-col gap-3 items-start rounded-2">
            <div className="flex flex-col w-full items-start gap-1">
              <p className="text-neutral-500 para-p4 ">Shift ID</p>
              <p className="text-neutral-800 para-p4">{modalData?.shift_id}</p>
            </div>
            <div className="flex flex-col w-full items-start gap-1">
              <p className="text-neutral-500 para-p4 ">Created</p>
              <p className="text-neutral-800 para-p4">
                {modalData?.created_at ? dayjs(modalData?.created_at).format('M/D/YY h:mmA') : '-'}, {modalData?.created_by?.name ? modalData?.created_by?.name : '-'}
              </p>
            </div>
            <div className="flex flex-col w-full items-start gap-1">
              <p className="text-neutral-500 para-p4 ">Modified</p>
              <p className="text-neutral-800 para-p4">
                {modalData?.updated_at ? dayjs(modalData?.updated_at).format('M/D/YY h:mmA') : '-'}, {modalData?.updated_by?.name ? modalData?.updated_by?.name : '-'}
              </p>
            </div>
            <div className="flex flex-col w-full items-start gap-1">
              <p className="text-neutral-500 para-p4 ">Temp Conf.</p>
              <p className="text-neutral-800 para-p4">
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                {modalData?.temp_conf_at && modalData?.provider?.first_name && modalData?.provider?.last_name
                  ? `${dayjs(modalData?.temp_conf_at).format('M/D/YY h:mmA')}, ${modalData?.provider?.first_name} ${modalData?.provider?.last_name}`
                  : '-'}
              </p>
            </div>
            <div className="flex flex-col w-full items-start gap-1">
              <p className="text-neutral-500 para-p4 ">Client Conf.</p>
              <p className="text-neutral-800 para-p4">
                {modalData?.client_conf_at && modalData?.follower?.first_name && modalData?.follower?.last_name
                  ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    `${dayjs(modalData?.client_conf_at).format('M/D/YY h:mmA')}, ${modalData?.follower?.first_name} ${modalData?.follower?.last_name}`
                  : '-'}
              </p>
            </div>
            <div className="flex flex-col w-full items-start gap-1">
              <p className="text-neutral-500 para-p4 ">Evaluation</p>
              <p className="text-neutral-800 para-p4">Not Evalueated</p>
            </div>
            <div className="flex flex-col w-full items-start gap-1">
              <p className="text-neutral-500 para-p4 ">Paid</p>
              <p className="text-neutral-800 para-p4">-</p>
            </div>
          </div>
        </div>
        <div className="tab-wrapper h-90">
          <div className="btn-wrapper">
            <CustomBtn
              isIcon
              svgIcon={<ParagraphIcon className="svg" />}
              type="default"
              text="ActivityFeed"
              className={`${isActivity ? 'activity-active' : ''} activity-btn user-icon`}
              onClick={handleActivityClick}
            />
            <div className="shift-close-btn" onClick={handleCloseClick}>
              <CloseIcon className="cursor-pointer" />
            </div>
          </div>
          <Tabs defaultActiveKey="1" items={tabData} className="shift-tab-gap-0" onChange={onTabChange} activeKey={tabId} tabBarGutter={24} />
        </div>
      </div>

      {isOpenCancelModal && (
        <CustomModal
          onCancel={() => {
            setIsCancelModal(false)
          }}
          footer={null}
          closable={false}
          width={600}
          open={isOpenCancelModal}
          title={SHIFTS.CANCEL_SHIFT}
          content={<CancelShift id={id} isCancel={isCancel} setIsCancel={setIsCancel} setIsModalOpen={setIsCancelModal} />}
        />
      )}
    </Modal>
  )
}

export default DetailShift
