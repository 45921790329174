import { Button, message } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { CloseIcon } from 'assets/svgs'
import { BUTTON_STRING, SHIFTS } from 'constants/Constant'
import { SHIFT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { ASSIGN_USER_REQUEST, REJECT_USER_REQUEST } from 'pages/facilities/facilityProfile/api'
import { setGlobalLoader } from 'store/slice/CommonSlice'

import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'
import TableImage from 'components/common/Table/TableImage'

const RequestTab = ({ paginationInfo, handlePageChange, status, data, setIsAssigned, isAssigned }: any) => {
  const dispatch = useAppDispatch()

  const handleAssignShiftRequest = async (id: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await ASSIGN_USER_REQUEST(id)
      if (response?.data?.statusCode === 1) {
        setIsAssigned(!isAssigned)
        void message.success(response?.data?.message)
      }
    } catch (error) {}
    dispatch(setGlobalLoader(false))
  }

  const handleRejectShiftRequest = async (id: string) => {
    dispatch(setGlobalLoader(true))
    try {
      const response = await REJECT_USER_REQUEST(id)
      if (response?.data?.statusCode === 1) {
        setIsAssigned(!isAssigned)
        void message.success(response?.data?.message)
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error: any) {
      void message.error(error)
    }
    dispatch(setGlobalLoader(false))
  }

  const columns: ColumnsType = [
    {
      title: SHIFTS.REQUEST_TABLE.REQUESTED_BY,
      dataIndex: 'requestTo',
      key: 'requestTo',
      align: 'center',
      width: 250,
      render: (_value, record) => {
        return (
          <div className="cursor-pointer">
            <TableImage
              baseUrl={record?.provider?.base_url}
              image={record?.provider?.profile_image}
              name={`${record?.provider?.first_name as string} ${record?.provider?.last_name as string}`}
              imageClass="rounded-50"
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.REQUEST_TABLE.LICENSE,
      dataIndex: ['shift', 'certificate', 'name'],
      key: 'certificate',
      align: 'center',
      width: 375,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText
              classString="status"
              style={{ background: record?.shift?.certificate?.background_color, color: record?.shift?.certificate?.text_color }}
              text={record?.shift?.certificate?.name}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.REQUEST_TABLE.REQUESTED_ON,
      dataIndex: 'requestOn',
      key: 'requestOn',
      align: 'center',
      width: 375,
      sortDirections: ['ascend', 'descend'],
      sorter: (a, b) => a.requestOn.localeCompare(b.requestOn),
      render: (_value, record) => {
        return <div>{dayjs(record?.created_at).format('MM/DD/YYYY, hh:mm A')}</div>
      },
    },
    {
      title: SHIFTS.REQUEST_TABLE.ACTION,
      dataIndex: '',
      key: 'requestTo',
      align: 'center',
      width: 375,
      render: (_value, record) => {
        return (
          <div className="flex items-center justify-center gap-2 cursor-pointer">
            <Button
              disabled={![SHIFT_STATUS.OPEN, SHIFT_STATUS.REQUESTED, SHIFT_STATUS.UNCONFIRMED, SHIFT_STATUS.AUTO_SCHEDULING].includes(status)}
              icon={<CloseIcon />}
              type="default"
              className="cancel-btn-h flex p-2 justify-center items-center rounded-2 box-shadow"
              onClick={() => {
                void handleRejectShiftRequest(record?.id)
              }}
            />
            <Button
              // disabled={[SHIFT_STATUS.SCHEDULE, SHIFT_STATUS.CANCELLED, SHIFT_STATUS.ON_GOING, SHIFT_STATUS.COMPLETED, SHIFT_STATUS.UNSUBMITTED].includes(status)}
              disabled={![SHIFT_STATUS.OPEN, SHIFT_STATUS.REQUESTED, SHIFT_STATUS.UNCONFIRMED, SHIFT_STATUS.AUTO_SCHEDULING].includes(status)}
              type="default"
              className="assign-btn flex justify-center items-center w-100px "
              onClick={() => {
                void handleAssignShiftRequest(record?.id)
              }}
            >
              {record?.status === 'assigned' ? 'Assigned' : BUTTON_STRING.ASSIGN}
            </Button>
          </div>
        )
      },
    },
  ]
  return (
    <div className="request-table-wrapper flex p-6 flex-col items-start gap-4 h-full">
      <CustomTable
        column={columns}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        data={data?.data}
        name={SHIFTS.REQUESTED}
      />
    </div>
  )
}

export default RequestTab
