import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Select } from 'antd'
import { CalendarOutline, DropDownIcon } from 'assets/svgs'

// import { BackArrow, CalendarOutline, DownIcon } from 'assets/svgs'
// import { ActivityDot, DropDownIcon } from 'assets/svgs'
import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'

export interface IModalProps {
  isModalOpen?: boolean
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  editId?: string
  setEditId?: any
  setFormData?: any[]
  contactDetails?: any
  setContactDetails?: any
  data?: any | undefined
  settings?: any
  editData?: any
  isOpenDirectly?: any
  setOpenDirectly?: any
  setEditData?: React.Dispatch<React.SetStateAction<any>> | any
  setSortTable?: React.Dispatch<React.SetStateAction<{ order: string; field: string }>>
}

const ActivityModal = ({ isModalOpen, setIsModalOpen, isOpenDirectly, setOpenDirectly }: IModalProps) => {
  const { t } = useTranslation()

  const handleChange = (value: string) => {
    console.log(`selected ${value}`)
  }

  const mockData = [
    {
      id: 1,
      icon: <CalendarOutline />,
      name: 'Created shift for',
      value: 'CNA at rocky mount care of heber on 22/12/2023 .',
      time: '9:05 pm',
    },
    {
      id: 2,
      icon: <CalendarOutline />,
      name: 'Assigned a shift to',
      value: 'Victoria perry.',
      time: '10:00 pm',
    },
    {
      id: 3,
      icon: <CalendarOutline />,
      name: 'Cancelled a Shift',
      value: 'CNA at rocky mount care of heber on 22/12/2023 .',
      time: '10:30 pm',
    },
    {
      id: 4,
      icon: <CalendarOutline />,
      name: 'Visited',
      value: 'schedule',
      time: '10:30 pm',
    },
    {
      id: 5,
      icon: <CalendarOutline />,
      name: 'Visited',
      value: 'providers',
      time: '10:30 pm',
    },
    {
      id: 6,
      icon: <CalendarOutline />,
      name: 'Approved timecard for shift',
      value: 'CNA at rocky mount care of heber on 22/12/2023.',
      time: '10:30 pm',
    },
  ]

  return (
    <Modal
      className="activity-modal"
      open={isModalOpen}
      centered
      footer={null}
      closable={false}
      maskClosable
      onCancel={() => {
        setIsModalOpen(false)
      }}
    >
      <div className="wrapper-connect-modal flex flex-col modal-height-787">
        <div className={'header-modal flex gap-6 justify-between items-center'}>
          <div>
            <p className="desktop-head-h5 font-semi-bold">Brooklyn’s Activity</p>
          </div>
          <div className="flex gap-4 items-end">
            <Select
              value="today"
              className="w-120 h-45 activity-select"
              onChange={handleChange}
              suffixIcon={<DropDownIcon />}
              options={[
                { value: 'today', label: 'Today' },
                { value: 'yesterday', label: 'Yesterday' },
              ]}
            />
            <Select
              value="clock_in"
              className="w-120 h-45 rounded-lg activity-select"
              onChange={handleChange}
              suffixIcon={<DropDownIcon />}
              options={[
                { value: 'clock_in', label: 'Clock Ins' },
                { value: 'clock_out', label: 'Clock Out' },
              ]}
            />
          </div>
        </div>

        <div className="content-modal h-full">
          <p className="para-p3 text-neutral-500 mb-6">12TH DECEMBER, 2023</p>
          <div>
            {mockData.map((item) => {
              return (
                <div key={item.id} className="flex justify-between">
                  <div>
                    <div className="flex gap-3 items-center">
                      <div
                        style={{ height: '24px', width: '24px', border: '1px solid #C4C5C7', borderRadius: '100px', marginLeft: '-4px' }}
                        className="flex items-center justify-center"
                      >
                        {item.icon}
                      </div>
                      <p className="text-neutral-500 flex gap-2 font-small activity-text">
                        {item.name}
                        <span className="ml-2 text-neutral-800 font-normal text-decoration-line">{item.value}</span>
                      </p>
                    </div>
                    {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                    <div className={`${mockData.length !== item.id && 'activity-dot full-height-line'}`} />
                  </div>
                  <HeadingText text={item.time} classString="text-neutral-500 font-small para-p3" />
                </div>
              )
            })}
          </div>
        </div>

        <div className="footer-modal flex items-end justify-end">
          <CustomBtn
            text={t('button.close')}
            onClick={() => {
              setIsModalOpen(false)
            }}
            type="primary"
            className="w-160 rounded-3 border-0"
          />
        </div>
      </div>
    </Modal>
  )
}

export default ActivityModal
