import React, { type ReactNode, useEffect, useRef, useState } from 'react'
import { DatePicker, Form } from 'antd'
import { DropDownIcon } from 'assets/svgs'
import dayjs from 'dayjs'

import './CustomDatePicker.scss'

interface IDatePicker {
  name: string
  format?: string
  rules?: any
  placeholder?: string
  icon?: ReactNode
  suffixIcon?: ReactNode
  inputClass?: string
  labelClass?: string
  textClass?: string
  mainClass?: string
  wrapperClass?: string
  error?: string | boolean
  disabled?: boolean
  disablePastDates?: (current: any) => boolean
  form?: any
  value?: any
  reset?: any
  onChange?: (e: any) => void
}

const CustomDatePickerField = ({ ...props }: IDatePicker) => {
  const {
    wrapperClass,
    mainClass,
    inputClass,
    labelClass,
    textClass,
    error,
    placeholder,
    rules,
    name,
    icon = '',
    format,
    disablePastDates,
    suffixIcon,
    onChange,
    form,
    value,
    reset,
    disabled,
  } = props

  const [focused, setFocused] = useState(false)
  const [hasValue, setValue] = useState(false)
  const dateRef = useRef<any>(null)

  useEffect(() => {
    const initialValue = dateRef.current?.nativeElement?.children[0]?.children[0]?.value
    const isValidDate = initialValue ? initialValue !== '' : false
    setFocused(isValidDate)
    setValue(form?.getFieldValue(name) || isValidDate)
  }, [dateRef])

  useEffect(() => {
    if (reset) {
      setFocused(false)
      setValue(false)
    }
  }, [reset])

  useEffect(() => {
    if (value) {
      setFocused(true)
      setValue(true)
    } else {
      setFocused(false)
      setValue(false)
    }
  }, [value])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    if (dateRef.current?.nativeElement?.children[0]?.children[0]?.value) {
      setValue(true)
      setFocused(true)
    } else {
      setValue(false)
      setFocused(false)
    }
  }

  const handleChange = (date: any) => {
    const isValidDate = dayjs(date)?.isValid()
    setValue(isValidDate)
    setFocused(isValidDate)
    onChange?.(date)
  }

  return (
    <div className={`${mainClass as string} w-full`}>
      <div className={`${wrapperClass as string} datePicker-wrapper ${error ? '' : 'mb-6'} `}>
        <div className={`floatingLabelInput ${inputClass as string}`}>
          {icon !== '' && <div className="icon-container">{icon}</div>}
          <label
            htmlFor={name}
            className={`label-float ${focused || hasValue ? 'actives' : ''} ${labelClass as string} ${icon !== '' ? 'left-50' : ''} ${disabled ? 'text-neutral-300' : ''}`}
          >
            {placeholder}
          </label>
          <Form.Item name={name} rules={rules} className=" datePicker-content">
            <DatePicker
              id={name}
              ref={dateRef}
              suffixIcon={suffixIcon ?? <DropDownIcon />}
              popupClassName="date-menu-wrapper"
              className={`${textClass as string}`}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              disabledDate={disablePastDates}
              format="MM/DD/YYYY"
              style={{
                border: '1px solid #e5e5e6',
                ...(icon !== '' && { paddingLeft: '50px' }),
              }}
              {...props}
            />
          </Form.Item>
        </div>
      </div>
      {error && <p className="error-container">{error}</p>}
    </div>
  )
}

export default CustomDatePickerField
