import React, { useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd'
import { DropDownIcon, SearchIcon } from 'assets/svgs'

import './Select.scss'

const { Option, OptGroup } = Select

interface FloatingLabelSelectProps {
  label: string
  options: any[]
  value?: string | string[] // Support string or array of strings
  onChange?: (value: string | string[]) => void // Handle both single and multiple values
  name: string
  prefixIcon?: React.ReactNode
  disabled?: boolean
  isIcon?: boolean
  error?: string
  rules?: any
  initialValue?: string | string[]
  labelClass?: string
  mainClass?: string
  wrapperClass?: string
  multiple?: boolean // Flag to indicate if multiple selection is enabled
  isSearch?: boolean
  searchPlaceholder?: string
  allowClear?: boolean
}

interface IOption {
  value: string
  label: string
  htmlContent?: string
}

interface IOptionGroup {
  label: string
  title: string
  options: IOption[]
}

const isOptionGroup = (option: IOption | IOptionGroup): option is IOptionGroup => {
  return (option as IOptionGroup).options !== undefined
}

const FloatingLabelSelect: React.FC<FloatingLabelSelectProps> = ({
  disabled,
  mainClass,
  label,
  name,
  prefixIcon,
  options,
  value,
  onChange,
  isIcon,
  error,
  rules,
  labelClass,
  wrapperClass,
  multiple, // Destructure the multiple prop
  isSearch,
  searchPlaceholder,
  allowClear,
  ...props
}) => {
  const [focused, setFocused] = useState(false)
  const [selectedValue, setSelectedValue] = useState<string | string[]>(value ?? '')
  const [search, setSearch] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options || [])

  useEffect(() => {
    setSelectedValue(value ?? (multiple ? [] : ''))
  }, [value, multiple])

  useEffect(() => {
    const hasValue = Array.isArray(selectedValue) ? selectedValue.length > 0 : selectedValue !== ''
    setFocused(hasValue)
  }, [selectedValue])

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = () => {
    const hasValue = Array.isArray(selectedValue) ? selectedValue.length > 0 : selectedValue !== ''
    setFocused(hasValue)
  }

  const handleChange = (newValue: string | string[]) => {
    setSelectedValue(newValue)
    if (onChange) {
      onChange(newValue)
    }
  }

  const onSearch = (value: string) => {
    setSearch(value.trim().toLowerCase())
    filterOptions(value.trim().toLowerCase())
  }

  const filterOptions = (input: string) => {
    const filtered = options?.filter((option: { name: string }) => option.name.toLowerCase().includes(input.toLowerCase()))
    setFilteredOptions(filtered)
  }

  const onInputKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.stopPropagation()
    }
  }

  const handleClickInside = (event: any) => {
    event.stopPropagation()
  }

  return (
    <div className={`${mainClass as string} custom-select w-full`}>
      <Form.Item className={`floating-label-select ${wrapperClass as string} ${error ? '' : 'mb-6'}`} name={name} rules={rules} initialValue={value}>
        <Select
          {...props}
          value={value}
          mode={multiple ? 'multiple' : undefined} // Enable multiple mode based on the prop
          disabled={disabled}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          allowClear={allowClear}
          suffixIcon={<DropDownIcon />}
          className={`select ${isIcon ? '' : 'no-icon'}`}
          placeholder=""
          dropdownRender={(menu) => (
            <div>
              {isSearch && (
                <div className="anchor-search-wrapper flex py-4 h-49 items-center">
                  <Input
                    prefix={<SearchIcon />}
                    placeholder={searchPlaceholder}
                    onPressEnter={() => {
                      onSearch(search)
                    }}
                    onChange={(e) => {
                      onSearch(e.target.value)
                    }}
                    allowClear
                    className="custom-searchbar"
                    onKeyDown={onInputKeyDown}
                    onClick={handleClickInside}
                  />
                </div>
              )}
              <div>{menu}</div>
            </div>
          )}
        >
          {search === ''
            ? options.map((option: any) => {
                return isOptionGroup(option) ? (
                  <OptGroup key={option?.title} label={<span>{option?.label}</span>}>
                    {option?.options.map((opt) => {
                      return (
                        <Option key={opt?.value} value={opt?.value}>
                          {typeof opt.label === 'string' ? <span>{opt.label}</span> : opt.label}
                        </Option>
                      )
                    })}
                  </OptGroup>
                ) : (
                  <Option key={option.value} value={option.value}>
                    <div className="flex gap-3">
                      {option.icon ? option.icon : ''}
                      {option.label}
                    </div>
                  </Option>
                )
              })
            : filteredOptions?.map((option: any) => {
                return isOptionGroup(option) ? (
                  <OptGroup key={option?.title} label={<span>{option?.label}</span>}>
                    {option?.options.map((opt) => {
                      return (
                        <Option key={opt?.value} value={opt?.value}>
                          {typeof opt.label === 'string' ? <span>{opt.label}</span> : opt.label}
                        </Option>
                      )
                    })}
                  </OptGroup>
                ) : (
                  <Option key={option.value} value={option.value}>
                    <div className="flex gap-3">
                      {option.icon ? option.icon : ''}
                      {option.label}
                    </div>
                  </Option>
                )
              })}
        </Select>
      </Form.Item>
      <label className={`${focused || (Array.isArray(value) ? value.length > 0 : value) ? 'focused filled' : ''} `}>
        {isIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
        <span className={`floating-label ${isIcon ? '' : 'floating-icon-label'} ${labelClass ?? ''} `}>{label}</span>
      </label>
      {error && <p className="error-container mb-6">{error}</p>}
    </div>
  )
}

export default FloatingLabelSelect
