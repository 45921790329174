import React from 'react'
import { profile } from 'assets/imgs'

import HeadingText from '../HeadingText'

const TableImage = ({
  name,
  email,
  image,
  baseUrl,
  imageClass,
  imageToolTip,
}: {
  baseUrl?: string
  image: string | any
  email?: string
  name: string | any
  imageClass?: string
  imageToolTip?: string
}) => {
  return (
    <div className="flex gap-3 items-center w-max">
      {image && baseUrl ? (
        <img src={`${baseUrl}${image as string}`} loading="lazy" className={`table-cover-img ${imageClass as string}`} alt="property pic" />
      ) : (
        <img src={profile} loading="lazy" className="table-cover-img" alt="empty state" title={imageToolTip} />
      )}
      <div className="flex flex-col">
        <HeadingText text={name} classString="para-p3 text-neutral-800" />
        {email && <HeadingText text={email} classString="para-p4 text-neutral-400" />}
      </div>
    </div>
  )
}

export default TableImage
