import React from 'react'

const DrawerClose = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.0001 10L9.7072 15.2929C9.31668 15.6834 9.31668 16.3166 9.7072 16.7071L15.0001 22M21.0001 10L15.7072 15.2929C15.3167 15.6834 15.3167 16.3166 15.7072 16.7071L21.0001 22"
      stroke="#C4C5C7"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

export default DrawerClose
