import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'
import { type IShiftCancelReason, type IShiftTimeColumn } from 'types'

export const getAllShiftCancelReason = async (limit: number, offset: number, search: string, order: string, field: string, userType: string) => {
  try {
    const response = http.get(
      `/shift-cancel-reason?limit=${limit}&offset=${offset}&user_type=${userType}${order && field && `&order[${field}]=${order}`}${search && `&search=${search}`}`
    )
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getShiftCancelReasonById = async (id: string) => {
  try {
    const response = http.get(`/shift-cancel-reason/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const addShiftCancelReason = async (payload: IShiftCancelReason) => {
  try {
    const response = http.post('/shift-cancel-reason', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const editShiftCancelReason = async (id: string, payload: IShiftCancelReason) => {
  try {
    const response = http.patch(`/shift-cancel-reason/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteShiftCancelReason = async (id: string) => {
  try {
    const response = http.delete(`/shift-cancel-reason/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getAllShiftTimes = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/facility-shift-setting?limit=${limit}&offset=${offset}${order && field && `&order[${field}]=${order}`}${search && `&search=${search}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getOneShiftTime = async (id: string) => {
  try {
    const response = http.get(`/facility-shift-setting/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const createNewShiftTime = async (payload: IShiftTimeColumn) => {
  try {
    const response = http.post('/facility-shift-setting', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateShiftTime = async (id: string, payload: IShiftTimeColumn) => {
  try {
    const response = http.patch(`/facility-shift-setting/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteShiftTime = async (id: string) => {
  try {
    const response = http.delete(`/facility-shift-setting/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
