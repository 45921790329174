import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'

import type { IAutoSchedulingData } from '../types'

export const getAutoScheduleData = async () => {
  try {
    const response = http.get('/auto-scheduling-setting')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateAutoScheduleData = async (id: string, payload: IAutoSchedulingData) => {
  try {
    const response = http.patch(`/auto-scheduling-setting/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
