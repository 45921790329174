import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, message, Radio } from 'antd'
import { EditPenIcon } from 'assets/svgs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IPreviewReferanceFormQue, type IReferanceForm, type IReferanceFormFieldOption, type IReferanceFormQue } from 'types'
import { formatTitle, referanceFormDeletedData, validateReferanceFormJSON } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import { createReferenceForm, editReferenceForm, getReferanceFormDetail } from './api'

import './ReferanceForm.scss'

const ReferanceFormPreview = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()

  const [data, setData] = useState({
    id: '',
    name: '',
    delete_question: [],
    delete_option: [],
    reference_form_option: [],
    editId: '',
  })

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: location.state ? formatTitle(location.state.name) : formatTitle(data.name),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data])

  useEffect(() => {
    getLocalStorageData()
  }, [])

  useEffect(() => {
    if (location.state) {
      if (localStorage.getItem('referanceForm')) {
        getLocalStorageData()
        return
      }
      const getUrlData = location?.state
      if (getUrlData?.id) void handleFindSingleFormData(getUrlData?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const getLocalStorageData = () => {
    const stateString = localStorage.getItem('referanceForm')
    const getData = stateString ? JSON.parse(stateString) : {}
    getData.reference_form_option = getData?.reference_form_option?.sort((a: IReferanceFormQue, b: IReferanceFormQue) => a.order - b.order)
    setData(getData)
  }

  const handleFindSingleFormData = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getReferanceFormDetail(id)
    if (response?.status === 200) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { name, reference_form_design, status } = response.data.data
      const sortedQuestions = reference_form_design?.sort((a: IPreviewReferanceFormQue, b: IPreviewReferanceFormQue) => a.order - b.order)

      const transformedQuestions = sortedQuestions?.map((eachField: IPreviewReferanceFormQue, index: number) => {
        const sortedOptions = eachField?.reference_form_option?.sort((a: IReferanceFormFieldOption, b: IReferanceFormFieldOption) => a.order - b.order)
        form.setFieldValue(`name-${index}`, eachField.name)
        form.setFieldValue(`field-${index}`, eachField.option_type)

        return {
          id: eachField.id ? eachField.id : '',
          name: eachField.name ?? '',
          option_type: eachField.option_type ?? 'multiple_choice',
          order: eachField.order ?? 0,
          options: sortedOptions ?? [],
        }
      })
      const previewData = {
        id: id ?? '',
        name: name ?? '',
        delete_question: [],
        delete_option: [],
        reference_form_option: transformedQuestions ?? [],
        editId: '',
        status: status ?? '',
      }
      setData(previewData)
      localStorage.setItem('referanceForm', JSON.stringify(previewData))
    } else {
      void message.error(response?.data?.message)
      return null
    }
    dispatch(setGlobalLoader(false))
  }

  const onFinish = () => {
    const validation = validateReferanceFormJSON(data?.reference_form_option)
    if (!validation.isValid) {
      void message.error(validation.error)
    }
    const payload = {
      name: data?.name ?? '',
      reference_form: data?.reference_form_option ?? [],
      ...(data?.editId ? {} : { status: 'active' }),
    }
    if (data.editId) {
      const newData = referanceFormDeletedData(payload, data.delete_question, data.delete_option)
      void handleUpdate(data.editId, newData)
    } else {
      void handleSubmit(payload)
    }
  }

  const handleSubmit = async (data: IReferanceForm) => {
    dispatch(setGlobalLoader(true))
    const response = await createReferenceForm(data)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/settings/referance-form-setting')
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(true))
  }

  const handleUpdate = async (editId: string, data: IReferanceForm) => {
    dispatch(setGlobalLoader(true))
    const response = await editReferenceForm(editId, data)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/settings/referance-form-setting')
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(true))
  }

  return (
    <Form form={form} onFinish={onFinish} className="flex flex-col gap-6 items-center w-full">
      <div className="flex items-center bg-white w-full competency-preview" style={{ paddingTop: location.state ? '25px' : '', paddingBottom: location.state ? '25px' : '' }}>
        <div className="flex items-center justify-end gap-4 flex-1">
          {data.id && (
            <CustomBtn
              isIcon
              svgIcon={<EditPenIcon className="svg" />}
              text={t('button.edit')}
              type="default"
              className="rounded-3 w-120 user-Icon"
              onClick={() => {
                localStorage.removeItem('referanceForm')
                navigate(`/settings/referance-form-setting/edit-form/${data.id && data.id}`)
              }}
            />
          )}
          {!location.state && <CustomBtn text={data.editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="rounded-3 w-120" />}
        </div>
      </div>

      <div className="flex flex-col w-915 gap-6 mobile-version">
        <div className="flex flex-col gap-6">
          {/* question */}
          {data.reference_form_option?.map((fields: IReferanceFormQue, fieldsIndex: number) => {
            return (
              <div key={fieldsIndex} className="flex flex-col items-start bg-white rounded-3 p-8">
                <div className={`flex items-center`}>
                  <div className="w-full mobile-heading-h5 font-medium text-neutral-800 break-all">{fields.name}</div>
                </div>
                <div className="mt-6 w-full">
                  {/* multi choice */}
                  {fields.option_type === 'multiple_choice' && (
                    <Radio.Group className="grid grid-cols-4 gap-1rem multiple-choice-option">
                      {fields?.options?.map((option: IReferanceFormFieldOption, index: number) => (
                        <div key={index} className="radio-option-container flex items-center">
                          <Radio value={option.name} className="add-option activity-text">
                            <div className="flex items-center justify-between gap-6 w-full">
                              <HeadingText classString="text-neutral-800 mobile-heading-h6 font-medium break-all w-full" text={option.name} />
                            </div>
                          </Radio>
                        </div>
                      ))}
                    </Radio.Group>
                  )}
                  {/* dropdown */}
                  {fields.option_type === 'dropdown' && (
                    <div className="w-full">
                      <FloatingLabelSelect
                        options={
                          fields?.options?.map((option: IReferanceFormFieldOption) => ({
                            label: String(option?.name),
                            value: String(option?.name),
                          })) ?? []
                        }
                        label={t('placeholder.select')}
                        name={`select-${fieldsIndex}`}
                      />
                    </div>
                  )}
                  {/* textarea */}
                  {fields.option_type === 'textarea' && (
                    <div className="w-full">
                      <CommonTextArea wrapperClass="m-0" name={`textarea-${fieldsIndex}`} placeholder={t('placeholder.description')} rows={4} />
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Form>
  )
}

export default ReferanceFormPreview
