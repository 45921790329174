import React from 'react'
import { Form, message, Switch } from 'antd'
import { DEFAULT_SOURCE_SECTION } from 'constants/Constant'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFieldData, IGeneralSettingCreate, IRefferalProps } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { addSourceName } from '../api'

const AddNewReferralSource = ({ isNewRefAdd, setIsNewRefAdd, setIsModalOpen, settings, form, setError, error, statusCheck, setStatusCheck }: IRefferalProps) => {
  const dispatch = useAppDispatch()

  const onChange = (switchData: boolean) => {
    setStatusCheck(switchData)
  }

  const onFinish = (values: IGeneralSettingCreate) => {
    const statusValue = statusCheck ? 'active' : 'in_active'
    const sections = settings.find((setting: { id: string }) => setting.id === DEFAULT_SOURCE_SECTION)
    const updatedValues = { ...values, status: statusValue, section: sections.id }
    void handleAddSourceName(updatedValues)
  }

  const handleAddSourceName = async (values: IGeneralSettingCreate) => {
    dispatch(setGlobalLoader(true))
    const response = await addSourceName(values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      form.resetFields()
      void message.success(response?.data.message)
      setIsNewRefAdd(!isNewRefAdd)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <div>
      <div className="flex p-4 flex-col items-start gap-8">
        <HeadingText classString="text-neutral-800 activity-text font-normal" text={t('label.addReferralSource')} />
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="w-full"
        >
          <div className="flex flex-col items-start w-full">
            <div className="flex flex-col w-full">
              <InputField rules={commonInputFieldValidation(VALIDATION_STRINGS.SOURCE_NAME)} placeholder={t('placeholder.sourceName')} name="name" error={error.name} />
            </div>
            <div className="flex items-center gap-2 mb-8">
              <p className="text-neutral-600 para-p3 font-normal">{t('label.displayOnForm')}</p>
              <Switch checked={statusCheck} onChange={onChange} />
            </div>
            <div className="flex items-start gap-4 w-full">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  setIsModalOpen(false)
                  form.resetFields()
                  setError({ name: '' })
                  setStatusCheck(true)
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default AddNewReferralSource
