import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'
import { type IAddCertificate } from 'types'

export const getAllCertificate = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/certificate?limit=${limit}&offset=${offset}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getCertificate = async (id: string) => {
  try {
    const response = http.get(`/certificate/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const addCertificate = async (payload: IAddCertificate) => {
  try {
    const response = http.post('/certificate', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteCertificate = async (id: string) => {
  try {
    const response = http.delete(`/certificate/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const editCertificate = async (id: string, payload: IAddCertificate) => {
  try {
    const response = http.patch(`/certificate/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getLineOfBusinessDetails = async () => {
  try {
    const response = http.get('/dropdown/line-of-business')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getSpeciality = async () => {
  try {
    const response = http.get(`/dropdown/speciality`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
