import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { DropDownIcon, ForgotPasswordIcon, GmailLogo, LogoIcon, OutlookLogo } from 'assets/svgs'

import './AuthFlow.scss'

const ForgotPassword = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const email = searchParams.get('email')
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate('/login')
  }

  return (
    <div className="bg-neutral-50 justify-center min-h-screen">
      <div className="back-btn-wrapper flex justify-center items-center p-2 absolute" onClick={handleBackClick}>
        <DropDownIcon className="#220eb1" />
        <p className="text-primary-500 para-p2">{t(`common.back`)}</p>
      </div>
      <div className="bg-neutral-50 mx-auto max-w-400 w-full min-h-screen flex flex-col gap-10 justify-center items-center py-10">
        <div className="flex gap-6 flex-col items-center">
          <LogoIcon />
          <div className="flex flex-col items-center gap-1">
            <p className="mobile-heading-h2 font-medium">{t('forgotPass.checkMail')}</p>
            <p className="text-neutral-500 text-center para-p2">
              {t('forgotPass.checkMailSub')} <span>{email}</span>
            </p>
          </div>
        </div>

        <ForgotPasswordIcon />

        <div className="flex gap-7 w-full">
          <Link to="https://mail.google.com" target="_blank" className="text-decoration-none">
            <Button className="email-btns">
              <span className="flex">
                <GmailLogo />
              </span>
              {t('forgotPass.gmail')}
            </Button>
          </Link>
          <Link to="https://outlook.com" target="_blank" className="text-decoration-none">
            <Button className="email-btns">
              <span className="flex">
                <OutlookLogo />
              </span>
              {t('forgotPass.outlook')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
