import React from 'react'

const FlagIcon = ({ color }: { color: string }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
      <path
        d="M13.5583 1.9C13.3681 1.76293 13.1482 1.67263 12.9166 1.63643C12.6849 1.60023 12.448 1.61916 12.225 1.69167C11.6368 1.85707 11.0276 1.93568 10.4167 1.925C9.51382 1.87599 8.62859 1.65539 7.80833 1.275C6.79501 0.80636 5.69894 0.542963 4.58333 0.5C2.17499 0.5 1.24999 1.33333 1.08333 1.45C1.00358 1.52816 0.940362 1.62155 0.897416 1.72462C0.854469 1.82769 0.832675 1.93834 0.833327 2.05V14.6667C0.833327 14.8877 0.921125 15.0996 1.07741 15.2559C1.23369 15.4122 1.44565 15.5 1.66666 15.5C1.88767 15.5 2.09964 15.4122 2.25592 15.2559C2.4122 15.0996 2.49999 14.8877 2.49999 14.6667V11.0833C3.16402 10.8291 3.87288 10.7128 4.58333 10.7417C5.48617 10.7907 6.3714 11.0113 7.19166 11.3917C8.20498 11.8603 9.30105 12.1237 10.4167 12.1667C11.4213 12.2046 12.4206 12.0047 13.3333 11.5833C13.5793 11.4678 13.7879 11.2854 13.9352 11.057C14.0826 10.8286 14.1628 10.5634 14.1667 10.2917V3.09167C14.1664 2.85915 14.1111 2.62999 14.0054 2.42289C13.8997 2.21579 13.7465 2.03662 13.5583 1.9ZM12.5 10.1583C11.8357 10.4117 11.127 10.5279 10.4167 10.5C9.5127 10.4521 8.62674 10.2285 7.80833 9.84167C6.79308 9.37979 5.69782 9.11942 4.58333 9.075C3.88124 9.0629 3.18067 9.14417 2.49999 9.31667V2.51667C3.16247 2.25611 3.87208 2.1369 4.58333 2.16667C5.48729 2.21457 6.37324 2.43818 7.19166 2.825C8.20691 3.28687 9.30217 3.54725 10.4167 3.59167C11.1192 3.60204 11.8199 3.51795 12.5 3.34167V10.1583Z"
        fill={color ?? '#000000'}
      />
    </svg>
  )
}

export default FlagIcon
