import { useEffect, useState } from 'react'
import { Form, message, Switch } from 'antd'
import { ModalClose } from 'assets/svgs'
import { COMMON } from 'constants/Constant'
import { commonInputFieldValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddUpdateDOcumentCategory, type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'

import { createNewCategory, editCategory } from '../api'

const AddNewCategory = ({
  setIsModalOpen,
  setEditModalOpen,
  categoryId,
  editId,
  data,
}: {
  setIsModalOpen?: React.Dispatch<React.SetStateAction<number | null>>
  setEditModalOpen?: React.Dispatch<React.SetStateAction<boolean>>
  categoryId?: string
  editId?: string
  data?: any
}) => {
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const [error, setError] = useState({
    name: '',
  })
  const [active, setActive] = useState(true)

  useEffect(() => {
    if (editId && data) {
      form.setFieldsValue(data)
      setActive(data.status === 'active')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, data])

  const onFinish = (values: IAddUpdateDOcumentCategory) => {
    if (!categoryId) return
    const activeValue = active ? 'active' : 'in_active'
    const updatedValues = { ...values, status: activeValue, category: categoryId }
    void handleAddEditDocument(updatedValues, editId ?? '')
  }

  const handleAddEditDocument = async (values: IAddUpdateDOcumentCategory, editId: string) => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      response = await editCategory(editId, values)
    } else {
      response = await createNewCategory(values)
    }
    if (response?.data.statusCode === 1) {
      if (setIsModalOpen) setIsModalOpen(null)
      if (setEditModalOpen) setEditModalOpen(false)
      void message.success(response?.data?.message)
      form.resetFields()
    }
    dispatch(setGlobalLoader(false))
  }

  const handleCancel = () => {
    if (setIsModalOpen) setIsModalOpen(null)
    if (setEditModalOpen) setEditModalOpen(false)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={editId ? t('label.editCategory') : t('label.addNewCategory')} />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <div className="flex flex-col items-start gap-8">
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="form-certi-wrapper w-full"
        >
          <>
            <div className="flex flex-col items-start gap-5 w-full">
              <div className="flex flex-col w-full gap-5">
                <div className={`h-64 aline-self ${error?.name ? 'mb-6' : ''}`}>
                  <InputField
                    rules={commonInputFieldValidation(VALIDATION_STRINGS.CATEGORY)}
                    placeholder={t('placeholder.categoryName')}
                    name="name"
                    error={error?.name}
                    delay={100}
                  />
                </div>
                <div className={`h-64 aline-self `}>
                  <InputField placeholder={t('placeholder.note')} name="note" delay={100} />
                </div>
                <div className="flex gap-2 toggle-switch items-center">
                  <span>{t('label.active')}</span>
                  <Switch
                    checked={active}
                    onChange={(checked) => {
                      setActive(checked)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-start gap-4 w-full mt-4">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  handleCancel()
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={editId ? COMMON.UPDATE : t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </>
        </Form>
      </div>
    </div>
  )
}

export default AddNewCategory
