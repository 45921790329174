import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'

export const getFindAllChecklist = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/skill-checklist-template?${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}&limit=${limit}&offset=${offset}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getFindChecklistById = async (id: string) => {
  try {
    const response = http.get(`/skill-checklist-template/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const removeChecklistTemplate = async (id: string) => {
  try {
    const response = http.delete(`/skill-checklist-template/template/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getCertificate = async () => {
  try {
    const response = http.get('/dropdown/certificate')
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const addChecklist = async (payload: any) => {
  try {
    const response = http.post('/skill-checklist-template', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const editChecklist = async (id: string, payload: any) => {
  try {
    const response = http.patch(`/skill-checklist-template/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const removeCheckListModule = async (id: string) => {
  try {
    const response = http.delete(`/skill-checklist-template/module/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const removeCheckListSubModule = async (id: string) => {
  try {
    const response = http.delete(`/skill-checklist-template/sub-module/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const removeCheckListQuestion = async (id: string) => {
  try {
    const response = http.delete(`/skill-checklist-template/question/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
