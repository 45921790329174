import { message } from 'antd'
import http from 'services/Service'

export const getRoleDropdown = async () => {
  try {
    const response = http.get(`/dropdown/roles`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const GET_CERTIFICATE = async (search?: string) => {
  try {
    const url = `/dropdown/certificate${search ? `?search=${encodeURIComponent(search)}` : ''}`
    const response = http.get(url)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const GET_SPECIALITY = async (search?: string) => {
  try {
    const url = `/dropdown/speciality${search ? `?search=${encodeURIComponent(search)}` : ''}`
    const response = http.get(url)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const GET_FACILITY = async () => {
  try {
    const url = `/dropdown/facility`
    const response = http.get(url)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const GET_FACILITY_USER = async (id: string, search?: string) => {
  try {
    const url = `/dropdown/facility-user/${id}${search ? `?search=${encodeURIComponent(search)}` : ''}`
    const response = http.get(url)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
