import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { message, Switch, Table, Tabs } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { TABLE_COLUMNS } from 'constants/Constant'
import { useWindowSize } from 'hooks/useWindowSize'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IOptionData, type ISectionData, type ISettingsData, type ISubSectionData } from 'types'

import { getProviderById, getProviderSetting, updateById } from './api'

import '../myFacility/MyFacilitySettings.scss'

const ProviderProfileSettings = () => {
  const size = useWindowSize()
  const dispatch = useDispatch()
  const [settings, setSettings] = useState<ISettingsData[]>([])
  const [optionData, setOptionData] = useState<IOptionData[] | any>([])
  const [selectedTabId, setSelectedTabId] = useState('')

  useEffect(() => {
    if (settings) {
      setSelectedTabId(settings[0]?.id)
    }
  }, [settings])

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.providerProfile'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void getProviderSettingsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedTabId) {
      void getSingleProviderSetting(selectedTabId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTabId])

  const getProviderSettingsData = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getProviderSetting()
    if (response?.data?.statusCode === 1 && Array.isArray(response?.data?.data)) {
      setSettings(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const getSingleProviderSetting = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getProviderById(id)
    if (response?.data?.statusCode === 1) {
      setOptionData(response?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const updateMapData = async (mapData: ISubSectionData, newStatus: string, newIsRequired: boolean) => {
    dispatch(setGlobalLoader(true))
    const updatedPayload = {
      id: mapData?.id,
      status: newStatus,
      is_required: newIsRequired,
    }
    const response = await updateById(mapData?.id, updatedPayload)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
    return {
      ...mapData,
      status: newStatus,
      is_required: newIsRequired,
    }
  }

  const onChange = async (id: string, field: string) => {
    try {
      const updatedOptionData = optionData?.section?.map((item: ISectionData) => {
        return {
          ...item,
          sub_section: item?.sub_section?.map((mapData: ISubSectionData) => {
            if (mapData?.id === id) {
              let newStatus = mapData?.status
              let newIsRequired = mapData?.is_required

              if (field === 'active') {
                newStatus = mapData?.status === 'active' ? 'in_active' : 'active'
              } else if (field === 'is_required') {
                newIsRequired = !mapData.is_required
              }

              void updateMapData(mapData, newStatus, newIsRequired)

              return {
                ...mapData,
                status: newStatus,
                is_required: newIsRequired,
              }
            }
            return mapData
          }),
        }
      })

      setOptionData({
        ...optionData,
        section: updatedOptionData,
      })
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  const columns: ColumnsType = [
    {
      title: TABLE_COLUMNS.FIELD_NAME,
      dataIndex: 'name',
      key: 'name',
      width: '60%',
    },
    {
      title: TABLE_COLUMNS.DISPLAY_ON_FORM,
      dataIndex: 'is_required',
      key: 'is_required',
      align: 'center',
      width: '20%',
      render: (_value, record) => {
        return (
          <Switch
            checked={record.status === 'active'}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={async () => {
              await onChange(record.id, 'active')
            }}
          />
        )
      },
    },
    {
      title: TABLE_COLUMNS.IS_FIELD_MANDATORY,
      dataIndex: 'is_required',
      key: 'is_required',
      align: 'center',
      width: '20%',
      render: (_value, record) => {
        return (
          <Switch
            disabled={record?.status !== 'active'}
            checked={record?.is_required}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={async () => {
              await onChange(record?.id, 'is_required')
            }}
          />
        )
      },
    },
  ]

  const columnsWithoutDisplayForm: ColumnsType = [
    {
      title: TABLE_COLUMNS.FIELD_NAME,
      dataIndex: 'name',
      key: 'name',
      width: '80%',
    },
    {
      title: TABLE_COLUMNS.IS_FIELD_MANDATORY,
      dataIndex: 'is_required',
      key: 'is_required',
      align: 'center',
      width: '20%',
      render: (_value, record) => {
        return (
          <Switch
            disabled={record?.status !== 'active'}
            checked={record?.is_required}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={async () => {
              await onChange(record?.id, 'is_required')
            }}
          />
        )
      },
    },
  ]

  return (
    <div className="my-facility-setting-wrapper p-6 w-full rounded-4 bg-white">
      <Tabs
        className="facility-setting"
        defaultActiveKey="1"
        tabPosition={size.width >= 1230 ? 'left' : 'top'}
        items={settings?.map((data: ISettingsData, mainIndex) => {
          const id = data?.id
          return {
            label: data.name,
            key: id,
            children: (
              <div className="">
                {optionData?.section?.map((option: ISectionData) => {
                  return (
                    <div key={option?.id} className="mb-10 w-full">
                      <p className="mb-4 text-neutral-800 para-p1 font-semi-bold">{option?.name}</p>

                      <Table className="table-settings" columns={mainIndex === 0 ? columnsWithoutDisplayForm : columns} dataSource={option?.sub_section} pagination={false} />
                    </div>
                  )
                })}
              </div>
            ),
          }
        })}
        onChange={(key) => {
          setSelectedTabId(key)
        }}
      />
    </div>
  )
}

export default ProviderProfileSettings
