import React, { forwardRef } from 'react'

const ThreeDotIcon = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>((props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>, ref) => {
  return (
    // <svg ref={ref} width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    //   <path
    //     d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
    //     // eslint-disable-next-line react/prop-types
    //     stroke={props?.className ?? '#494a4c'}
    //     strokeWidth="2"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z"
    //     // eslint-disable-next-line react/prop-types
    //     stroke={props?.className ?? '#494a4c'}
    //     strokeWidth="2"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    //   <path
    //     d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
    //     // eslint-disable-next-line react/prop-types, @typescript-eslint/prefer-nullish-coalescing
    //     stroke={props?.className || '#494a4c'}
    //     strokeWidth="2"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>

    <svg ref={ref} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.99996 10.8346C10.4602 10.8346 10.8333 10.4615 10.8333 10.0013C10.8333 9.54106 10.4602 9.16797 9.99996 9.16797C9.53972 9.16797 9.16663 9.54106 9.16663 10.0013C9.16663 10.4615 9.53972 10.8346 9.99996 10.8346Z"
        fill="#494A4C"
        // eslint-disable-next-line react/prop-types
        stroke={props?.className ?? '#494a4c'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99996 4.9987C10.4602 4.9987 10.8333 4.6256 10.8333 4.16536C10.8333 3.70513 10.4602 3.33203 9.99996 3.33203C9.53972 3.33203 9.16663 3.70513 9.16663 4.16536C9.16663 4.6256 9.53972 4.9987 9.99996 4.9987Z"
        // eslint-disable-next-line react/prop-types
        stroke={props?.className ?? '#494a4c'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99996 16.6667C10.4602 16.6667 10.8333 16.2936 10.8333 15.8333C10.8333 15.3731 10.4602 15 9.99996 15C9.53972 15 9.16663 15.3731 9.16663 15.8333C9.16663 16.2936 9.53972 16.6667 9.99996 16.6667Z"
        // eslint-disable-next-line react/prop-types
        stroke={props?.className ?? '#494a4c'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
})

ThreeDotIcon.displayName = 'ThreeDotIcon'

export default ThreeDotIcon
