import React, { useEffect, useState } from 'react'
import { Form, message, Radio, type RadioChangeEvent } from 'antd'
import { CloseIcon } from 'assets/svgs'
import { BUTTON_STRING, COMMON, PROVIDER_STRING } from 'constants/Constant'
import { commonSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData } from 'types'
import { handleFieldsChange } from 'utils/Helper'
import { formatQuerry } from 'utils/utils'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import CustomMultiSelect from 'components/common/Select/CustomMultiSelect'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import { GET_ALL_DNR_REASON, UPDATE_FLAG_AS_DNR } from '../api'

const FlagAsDnr = ({
  isModalOpen,
  updateId,
  flagId,
  updateList,
}: {
  isModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  updateId: string
  flagId: string
  updateList: () => void
}) => {
  const dispatch = useAppDispatch()
  const [value, setValue] = useState('clinical')
  const [listData, setListData] = useState([])
  const [selectedReason, setSelectedReason] = useState<string[]>([])
  const [form] = Form.useForm()
  const [error, setError] = useState({
    reason: '',
  })

  const onChange = (e: RadioChangeEvent) => {
    setValue(e?.target?.value)
  }

  const handleCancel = () => {
    isModalOpen(false)
  }

  const handleGellAllDnrReasons = async () => {
    dispatch(setGlobalLoader(true))
    const query = formatQuerry({ reason_type: value })
    const response = await GET_ALL_DNR_REASON(query)
    if (response?.data?.statusCode === 1) {
      const data = response?.data?.data?.map((item: { reason: string; id: string }) => ({
        name: item?.reason,
        value: item?.id,
      }))
      setListData(data)
    }
    dispatch(setGlobalLoader(false))
  }

  useEffect(() => {
    if (value) {
      setSelectedReason([])
      void handleGellAllDnrReasons()
    }
  }, [value])

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const updatedValues = {
          dnr_type: value,
          dnr_reason: selectedReason,
          dnr_description: values?.description,
          flag: flagId,
        }
        if (updateId) {
          void submitData(updatedValues)
        }
      })
      .catch((errorInfo) => {
        console.log('Validate Failed:', errorInfo)
      })
  }

  const submitData = async (updatedValues: any) => {
    dispatch(setGlobalLoader(true))

    const response = await UPDATE_FLAG_AS_DNR(updateId, updatedValues)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      updateList()
      handleCancel()
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <Form
      className="flex flex-col w-full gap-2"
      form={form}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div className="flex flex-col w-full dnr-modal">
        <div className="radio-wrapper">
          <p className="para-p2 text-neutral-800 font-medium">{PROVIDER_STRING.REASON_FOR_DNR}</p>
          <div className="flex items-start gap-4 w-full mb-6 ">
            <Radio.Group onChange={onChange} value={value} rootClassName="flag-radio-button">
              <Radio value={PROVIDER_STRING.CLINICAL_VALUE} className="radio-placeholder">
                {PROVIDER_STRING.CLINICAL}
              </Radio>
              <Radio value={PROVIDER_STRING.PROFESSIONAL_VALUE} className="radio-placeholder">
                {PROVIDER_STRING.PROFESSIONAL}
              </Radio>
            </Radio.Group>
          </div>
        </div>
        <div className="flex flex-row w-full gap-4">
          <div className="flex flex-col w-full">
            <div className={`cursor-pointer ${error?.reason && selectedReason?.length === 0 ? 'mb-6' : ''}`}>
              <CustomMultiSelect
                name="reason"
                handleChange={(value: string[]) => {
                  setSelectedReason(value)
                }}
                Options={listData}
                placeholder={value === 'clinical' ? PROVIDER_STRING.SELECT_CLINICAL : PROVIDER_STRING.SELECT_PROFESSIONAL}
                value={selectedReason}
                error={error?.reason}
                rules={commonSelectValidation(VALIDATION_STRINGS.REASON)}
              />
            </div>
          </div>
        </div>
        {selectedReason?.length > 0 && (
          <div className="flex flex-col gap-4 w-full mt-4">
            <HeadingText text={PROVIDER_STRING.REASONS} classString="para-p4 text-neutral-400" />
            <div className="flex gap-4 w-full flex-wrap">
              {selectedReason?.map((tagId: any, index: number) => {
                // eslint-disable-next-line
                const option: any = listData?.find((item: any) => item?.value === tagId)
                if (option) {
                  return (
                    <div key={tagId} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                      {option?.name}
                      <span
                        className="flex items-center cursor-pointer"
                        onClick={() => {
                          const updatedValue = selectedReason?.filter((data) => data !== tagId)
                          setSelectedReason(updatedValue)
                        }}
                      >
                        <CloseIcon />
                      </span>
                    </div>
                  )
                } else {
                  return <></>
                }
              })}
            </div>
          </div>
        )}
        <div className="w-full mt-6">
          <CommonTextArea name={'description'} rows={4} placeholder={COMMON.DESCRIPTION} />
        </div>
      </div>
      <div className="flex justify-end gap-4 w-full">
        <CustomBtn type="default" onClick={handleCancel} text={BUTTON_STRING.CANCEL} className="w-160" />
        <CustomBtn text={BUTTON_STRING.ADD} onClick={onFinish} type="primary" htmlType="submit" className="w-160" />
      </div>
    </Form>
  )
}

export default FlagAsDnr
