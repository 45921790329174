import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'
import type { IAddProviderVerification } from 'types/index'

export const getAllProviderRejectReason = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/provider-reject-reason?limit=${limit}&offset=${offset}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getProviderRejectReasonById = async (id: string) => {
  try {
    const response = http.get(`/provider-reject-reason/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const addProviderRejectReason = async (payload: IAddProviderVerification) => {
  try {
    const response = http.post('/provider-reject-reason', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateProviderRejectReason = async (id: string, payload: IAddProviderVerification) => {
  try {
    const response = http.patch(`/provider-reject-reason/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteProviderRejectReason = async (id: string) => {
  try {
    const response = http.delete(`/provider-reject-reason/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
