import React from 'react'
import { Tabs } from 'antd'
import { useWindowSize } from 'hooks/useWindowSize'
import { type ISettingProps } from 'types'

import FacilityPortalSetting from './components/FacilityPortalSetting'
import Floors from './components/Floors'
import GeneralSettings from './components/GeneralSettings'
import InvoiceSettings from './components/InvoiceSettings'
import ShiftOptions from './components/ShiftOptions'
import TimeAndApproval from './components/TimeAndApproval'

const Settings = ({
  activeTab,
  setSettingTab,
  facilityId,
  settingData,
  timeEntrySetting,
  setTimeEntrySetting,
  clientSelected,
  setClientSelected,
  systemOptionData,
  setSystemOptionData,
  defaultOrderType,
  setDefaultOrderType,
  workCompCode,
  setWorkCompCode,
  facilityPortalSetting,
  setFacilityPortalSetting,
  setInvoiceCancellationDays,
}: ISettingProps) => {
  const size = useWindowSize()
  const items = [
    {
      key: '1',
      label: 'General Settings',
      children: (
        <GeneralSettings
          activeTab={activeTab}
          defaultOrderTypeData={defaultOrderType}
          workCompCodeData={settingData?.work_comp_code}
          setDefaultOrderType={setDefaultOrderType}
          workCompCode={workCompCode}
          setWorkCompCode={setWorkCompCode}
        />
      ),
    },
    {
      key: '2',
      label: 'Floors',
      children: <Floors facilityId={facilityId} />,
    },
    {
      key: '3',
      label: 'Shift Options',
      children: (
        <ShiftOptions
          settingData={settingData?.shift_setting}
          clientSelected={clientSelected}
          setClientSelected={setClientSelected}
          systemOptionData={systemOptionData}
          setSystemOptionData={setSystemOptionData}
        />
      ),
    },
    {
      key: '4',
      label: 'Time Entry & Approval',
      children: (
        <TimeAndApproval activeTab={activeTab} timeEntrySetting={timeEntrySetting} setTimeEntrySetting={setTimeEntrySetting} settingData={settingData?.time_entry_setting} />
      ),
    },
    {
      key: '5',
      label: 'Facility Portal Settings',
      children: <FacilityPortalSetting activeTab={activeTab} facilityPortalSetting={facilityPortalSetting} setFacilityPortalSetting={setFacilityPortalSetting} />,
    },
    {
      key: '6',
      label: 'Invoice Settings',
      children: <InvoiceSettings activeTab={activeTab} setInvoiceCancellationDays={setInvoiceCancellationDays} />,
    },
  ]
  return (
    <div className="facility-setting-wrapper flex p-10 items-start gap-6 w-full rounded-4 bg-white">
      <Tabs
        rootClassName="facility-setting-tab w-full p-0"
        defaultActiveKey="1"
        tabPosition={size.width > 1230 ? 'left' : 'top'}
        items={items}
        onChange={(key) => {
          setSettingTab(key)
        }}
      />
    </div>
  )
}

export default Settings
