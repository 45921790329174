import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'
import { type ISiteUploadData } from 'types'

export const getMyFacilitySettings = async () => {
  try {
    const response = http.get(`/facility-profile-setting`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateMyFacilitySettings = async (id: string, payload: { status: string }) => {
  try {
    const response = http.patch(`/facility-profile-setting/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getMyFacilitySiteAndTheme = async () => {
  try {
    const response = http.get(`/site-access-setting`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateMyFacilitySiteAndTheme = async (payload: Partial<ISiteUploadData>) => {
  try {
    const response = http.post(`/site-access-setting`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
