import React from 'react'

const HeaderBack = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.9999 11.4999H9.4099L12.7099 8.20994C12.8982 8.02164 13.004 7.76624 13.004 7.49994C13.004 7.23364 12.8982 6.97825 12.7099 6.78994C12.5216 6.60164 12.2662 6.49585 11.9999 6.49585C11.7336 6.49585 11.4782 6.60164 11.2899 6.78994L6.2899 11.7899C6.19886 11.885 6.12749 11.9972 6.0799 12.1199C5.97988 12.3634 5.97988 12.6365 6.0799 12.8799C6.12749 13.0027 6.19886 13.1148 6.2899 13.2099L11.2899 18.2099C11.3829 18.3037 11.4935 18.3781 11.6153 18.4288C11.7372 18.4796 11.8679 18.5057 11.9999 18.5057C12.1319 18.5057 12.2626 18.4796 12.3845 18.4288C12.5063 18.3781 12.6169 18.3037 12.7099 18.2099C12.8036 18.117 12.878 18.0064 12.9288 17.8845C12.9796 17.7627 13.0057 17.632 13.0057 17.4999C13.0057 17.3679 12.9796 17.2372 12.9288 17.1154C12.878 16.9935 12.8036 16.8829 12.7099 16.7899L9.4099 13.4999H16.9999C17.2651 13.4999 17.5195 13.3946 17.707 13.207C17.8945 13.0195 17.9999 12.7652 17.9999 12.4999C17.9999 12.2347 17.8945 11.9804 17.707 11.7928C17.5195 11.6053 17.2651 11.4999 16.9999 11.4999Z"
        fill="#494A4C"
      />
    </svg>
  )
}

export default HeaderBack
