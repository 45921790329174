import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from 'store/Store'

const initialState = {
  globalLoader: false,
  pageTitle: 'Hello,',
  isBack: '',
  isClientInfo: false,
  previousData: [],
  currentData: [],
  type: '',
  currentTestData: [],
  previousTestData: [],
  previousNameData: {},
  currentNameData: {},
  previousSkillData: [],
  currentSkillData: [],
}

export const commonSlice = createSlice({
  name: 'commonData',
  initialState,
  reducers: {
    changeHeaderTitle: (state, action) => {
      state.pageTitle = action.payload.pageTitle
      state.isBack = action.payload.isBack
      state.isClientInfo = action.payload.isClientInfo
    },
    setGlobalLoader: (state, action) => {
      state.globalLoader = action.payload
    },
    setPreviousData: (state, action) => {
      state.previousData = action.payload
    },
    setCurrentData: (state, action) => {
      state.currentData = action.payload
    },
    setType: (state, action) => {
      state.type = action.payload
    },
    setPreviousNameData: (state, action) => {
      state.previousNameData = action.payload
    },
    setCurrentNameData: (state, action) => {
      state.currentNameData = action.payload
    },
    setPreviousSkillData: (state, action) => {
      state.previousSkillData = action.payload.map((item: any) => ({
        ...item,
        // If you have nested properties, ensure you deep copy them as well
        skill_checklist_sub_module: item.skill_checklist_sub_module?.map((subModule: any) => ({
          ...subModule,
          skill_checklist_question: subModule.skill_checklist_question?.map((question: any) => ({
            ...question,
          })),
        })),
      }))
    },
    setCurrentSkillData: (state, action) => {
      state.currentSkillData = action.payload.map((item: any) => ({
        ...item,
        // If you have nested properties, ensure you deep copy them as well
        skill_checklist_sub_module: item.skill_checklist_sub_module?.map((subModule: any) => ({
          ...subModule,
          skill_checklist_question: subModule.skill_checklist_question?.map((question: any) => ({
            ...question,
          })),
        })),
      }))
    },
    setCurrentTestData: (state, action) => {
      state.currentTestData = action.payload.map((item: any) => ({
        ...item,
        competency_test_option: item.competency_test_option.map((option: any) => ({
          ...option, // deep copy of nested object
          is_answer: option.is_answer,
        })),
      }))
    },
    setPreviousTestData: (state, action) => {
      state.previousTestData = action.payload.map((item: any) => ({
        ...item,
        competency_test_option: item.competency_test_option.map((option: any) => ({
          ...option, // deep copy of nested object
          is_answer: option.is_answer,
        })),
      }))
    },
  },
})

export const {
  changeHeaderTitle,
  setGlobalLoader,
  setPreviousData,
  setCurrentSkillData,
  setPreviousSkillData,
  setPreviousNameData,
  setCurrentNameData,
  setCurrentData,
  setType,
  setCurrentTestData,
  setPreviousTestData,
} = commonSlice.actions

export const selectCommon = (state: RootState) => state.common

export default commonSlice.reducer
