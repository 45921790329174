import { useEffect } from 'react'
import { message, Switch, Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { TABLE_COLUMNS } from 'constants/Constant'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFacilityProfileProps } from 'types'
import { formatTitle } from 'utils/Helper'

import { updateMyFacilitySettings } from '../api'

const FacilityProfile = ({ myFacilitySettingsData, setIsSettingUpdated }: IFacilityProfileProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: 'My Facility Settings',
      })
    )
  }, [dispatch])

  const getValuesForSwitch = (value: string) => {
    return myFacilitySettingsData?.flatMap((options) => options?.form_fields || []).find((item) => item.key === value)
  }

  // const handleUpdateDisplayOnForm = async (text: string) => {
  //   dispatch(setGlobalLoader(true))
  //   const valuesToUpdate = getValuesForSwitch(text)
  //   console.log(valuesToUpdate)
  //   if (valuesToUpdate) {
  //     const id = valuesToUpdate?.id
  //     const data = {
  //       status: valuesToUpdate?.status === 'active' ? 'in_active' : 'active',
  //     }
  //     const response = await updateMyFacilitySettings(id, data)

  //     if (response?.data.statusCode === 1) {
  //       void message.success(response?.data?.message)
  //       setIsSettingUpdated(true)
  //     }
  //   }
  //   dispatch(setGlobalLoader(false))
  // }

  const handleUpdateIsMandatory = async (text: string) => {
    dispatch(setGlobalLoader(true))
    const valuesToUpdate = getValuesForSwitch(text)
    if (valuesToUpdate?.status === 'active') {
      const id = valuesToUpdate?.id
      const data = {
        status: valuesToUpdate?.status,
        is_required: !valuesToUpdate?.is_required,
      }
      const response = await updateMyFacilitySettings(id, data)
      if (response?.data.statusCode === 1) {
        void message.success(response?.data?.message)
        setIsSettingUpdated(true)
      }
    }
    dispatch(setGlobalLoader(false))
  }

  const columns: ColumnsType = [
    {
      title: TABLE_COLUMNS.FIELD_NAME,
      dataIndex: 'name',
      key: 'name',
      width: '70%',
    },
    // {
    //   title: TABLE_COLUMNS.DISPLAY_ON_FORM,
    //   dataIndex: 'status',
    //   key: 'status',
    //   align: 'center',
    //   width: '20%',
    //   render: (_value, record) => {
    //     return (
    //       <Switch
    //         onChange={() => {
    //           void handleUpdateDisplayOnForm(record?.key)
    //         }}
    //         checked={record?.status === 'active'}
    //       />
    //     )
    //   },
    // },

    {
      title: TABLE_COLUMNS.IS_FIELD_MANDATORY,
      dataIndex: 'is_required',
      key: 'is_required',
      align: 'center',
      // width: '30%',
      render: (_value, record) => {
        return (
          <Switch
            onChange={() => {
              void handleUpdateIsMandatory(record?.key)
            }}
            checked={record?.is_required}
            disabled={record?.status !== 'active'}
          />
        )
      },
    },
  ]
  return (
    <div className="">
      {myFacilitySettingsData?.map((option: any) => {
        return (
          <div key={option?.id} className="mb-10 w-full">
            <p className="mb-4 text-neutral-800 para-p1 font-semi-bold">{formatTitle(option?.section)}</p>
            <Table className="table-settings" columns={columns} dataSource={option?.form_fields} pagination={false} />
          </div>
        )
      })}
    </div>
  )
}

export default FacilityProfile
