import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Col, Input, Row } from 'antd'
import type { SearchProps } from 'antd/es/input'
import { EmptySearchIcon, SearchIcon } from 'assets/svgs'
import {
  AutoScheduleIcon,
  BellIcon,
  BillIcon,
  CalenderCheckIcon,
  CircleIcon,
  ClockIcon,
  ComplineIcon,
  DocumentIcon,
  FacilityIcon,
  GameIcon,
  LanguageIcon,
  LicenceIcon,
  LightIcon,
  LineOfBusinessIcon,
  NoteIcon,
  ProviderIcon,
  ProviderRejectIcon,
  ScheduleIcon,
  SettingIcon,
  ShiftCancelIcon,
  ShiftsIcon,
  SkillCheckIcon,
  SpecialityIcon,
  UserIcon,
  WorkCompCode,
} from 'assets/svgs/settingsIcons/Index'
import { useAppDispatch } from 'hooks/reduxHooks'
import usePermissionsBySectionName from 'hooks/usePermission'
import { changeHeaderTitle } from 'store/slice/CommonSlice'

import HeadingText from 'components/common/HeadingText'

const { Search } = Input

const AdminSettings = () => {
  const [search, setSearch] = useState<string>('')
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const permit = usePermissionsBySectionName('master_admin_setting')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.settings'),
      })
    )
  }, [dispatch, t])

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
  }

  const adminSettingsMenu = [
    {
      id: 1,
      name: 'Provider App Settings',
      data: [
        {
          id: 1,
          icon: <LightIcon />,
          title: 'Competency Test (Quiz)',
          subTitle: 'Manage test questions for different categories.',
          navigate: '/settings/compentency-test',
        },
        {
          id: 2,
          icon: <SkillCheckIcon />,
          title: 'Skills Checklist',
          subTitle: 'Manage skills for different categories. ',
          navigate: '/settings/skill-checklist',
        },
        {
          id: 3,
          icon: <ShiftCancelIcon />,
          title: 'Shift Cancel/Rejected Reasons',
          subTitle: 'Manage default reasons for cancel shifts. This will be shown when cancelling a Shift.',
          navigate: '/settings/shift-reject-reason',
        },
        // {
        //   id: 4,
        //   icon: <GameIcon />,
        //   title: 'Gamification',
        //   subTitle: 'Manage and edit required metrics for each badge level.',
        //   navigate: '/settings/',
        // },
        // {
        //   id: 5,
        //   icon: <CalenderCheckIcon />,
        //   title: 'Attendance Score',
        //   subTitle: 'Manage attendance score parameters and categories.',
        //   navigate: '/settings/',
        // },
        {
          id: 6,
          icon: <ProviderIcon />,
          title: 'Provider Profile',
          subTitle: 'Manage required fields for provider profile.',
          navigate: '/settings/provider-profile',
        },
        {
          id: 7,
          icon: <ScheduleIcon />,
          title: 'Schedule and Request',
          subTitle: 'Schedule permissions and parameters.',
          navigate: '/settings/schedule-and-request',
        },
        {
          id: 8,
          icon: <ClockIcon />,
          title: 'Time Entry Approval',
          subTitle: 'Settings related to time entry approval',
          navigate: '/settings/time-entry-approval',
        },
        {
          id: 9,
          icon: <SettingIcon />,
          title: 'General Settings',
          subTitle: 'Settings related to general activities',
          navigate: '/settings/general-settings',
        },
      ],
    },
    {
      id: 2,
      name: 'Facility Portal Settings',
      data: [
        // {
        //   id: 1,
        //   icon: <SettingIcon />,
        //   title: 'General',
        //   subTitle: 'Choose what to show or not from different modules of facility portal.',
        //   navigate: '/settings/facility-general-settings',
        // },
        {
          id: 2,
          icon: <ShiftsIcon />,
          title: 'Shifts',
          subTitle: 'Manage shift timings & cancellation reasons.',
          navigate: '/settings/shifts-settings',
        },
        {
          id: 3,
          icon: <ProviderIcon />,
          title: 'Provider',
          subTitle: 'Manage Provider DNR Reasons.',
          navigate: '/settings/facility-provider-setting',
        },
        {
          id: 4,
          icon: <ClockIcon />,
          title: 'Time and Attendance',
          subTitle: 'Manage Reject Timecard Reasons',
          navigate: '/settings/time-and-attendance',
        },
        {
          id: 5,
          icon: <BillIcon />,
          title: 'Billing Payment Methods',
          subTitle: 'Manage payment methods, add new or remove.',
          navigate: '/settings/',
        },
        {
          id: 6,
          icon: <FacilityIcon />,
          title: 'My facility',
          subTitle: 'Schedule permissions and parameters.',
          navigate: '/settings/my-facility',
        },
        // {
        //   id: 7,
        //   icon: <DocumentIcon />,
        //   title: 'Document Categories',
        //   subTitle: 'Add, delete and manage document categories.',
        //   navigate: '/settings/document-categories',
        // },
      ],
    },
    {
      id: 3,
      name: 'Admin Portal Settings',
      data: [
        ...(permit?.certification?.view
          ? [
              {
                id: 1,
                icon: <LicenceIcon />,
                title: 'License',
                subTitle: 'Manage default reasons for cancel shifts. This will be shown when cancelling a Shift.',
                navigate: '/settings/license',
              },
            ]
          : []),
        ...(permit?.specialty?.view
          ? [
              {
                id: 2,
                icon: <SpecialityIcon />,
                title: 'Specialties',
                subTitle: 'Manage skills for different categories. ',
                navigate: '/settings/specialties',
              },
            ]
          : []),

        // {
        //   id: 3,
        //   icon: <ScheduleIcon />,
        //   title: 'Shift Type',
        //   subTitle: 'Manage and edit required metrics for each badge level.',
        //   navigate: '/settings/shift-type',
        // },
        {
          id: 4,
          icon: <NoteIcon />,
          title: 'Note Type',
          subTitle: 'Manage attendance score parameters and categories.',
          navigate: '/settings/',
        },
        {
          id: 5,
          icon: <ProviderRejectIcon />,
          title: 'Provider Rejection Reasons',
          subTitle: 'Manage attendance score parameters and categories.',
          navigate: '/settings/provider-verification-rejection',
        },
        {
          id: 6,
          icon: <FacilityIcon />,
          title: 'Facility Rejection Reasons',
          subTitle: 'Manage attendance score parameters and categories.',
          navigate: '/settings/facility-verification-rejection',
        },
        // {
        //   id: 7,
        //   icon: <FileCheckIcon />,
        //   title: 'Evaluation Designer',
        //   subTitle: 'Add, delete and manage document categories.',
        //   navigate: '/settings/',
        // },
        {
          id: 8,
          icon: <DocumentIcon />,
          title: 'Document Categories',
          subTitle: 'Schedule permissions and parameters.',
          navigate: '/settings/admin-document-category',
        },
        // {
        //   id: 9,
        //   icon: <WorkCompCode />,
        //   title: 'Workers Comp Code',
        //   subTitle: 'Manage preferences for provider app.',
        //   navigate: '/settings/',
        // },
        {
          id: 10,
          icon: <LanguageIcon />,
          title: 'Languages',
          subTitle: 'Settings related to time entry approval',
          navigate: '/settings/',
        },
        {
          id: 11,
          icon: <CircleIcon />,
          title: 'Status Option',
          subTitle: 'Settings related to general activities',
          navigate: '/settings/status-option',
        },
        {
          id: 12,
          icon: <BellIcon />,
          title: 'Notification Settings',
          subTitle: 'Settings related to general activities',
          navigate: '/settings/',
        },
        {
          id: 13,
          icon: <ComplineIcon />,
          title: 'Compliance Manager',
          subTitle: 'Settings related to general activities',
          navigate: '/settings/compliance-manager',
        },
        {
          id: 14,
          icon: <UserIcon />,
          title: 'Users',
          subTitle: 'Settings related to general activities',
          navigate: '/settings/user',
        },
        {
          id: 15,
          icon: <AutoScheduleIcon />,
          title: 'Shift Auto Schedule Settings',
          subTitle: 'Settings related to auto schedule',
          navigate: '/settings/auto-schedule',
        },
        {
          id: 16,
          icon: <DocumentIcon />,
          title: 'Reference Form',
          subTitle: 'Settings related to auto schedule',
          navigate: '/settings/referance-form-setting',
        },
        {
          id: 17,
          icon: <LineOfBusinessIcon />,
          title: 'Facility Type',
          subTitle: 'Add, delete and manage facility type.',
          navigate: '/settings/facility-type',
        },
      ],
    },
  ]

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
    }
  }

  const EmptyScreen = (
    <div className="flex w-full justify-center items-center flex-col h-80">
      <EmptySearchIcon />
      <div className="flex flex-col gap-1 items-center w-300 text-center ">
        <p className="text-neutral-800 para-p1 font-medium gap-1 flex ">
          {t('label.noSearchFound')}
          <span className="text-neutral-800 para-p1 font-medium">{search}</span>
        </p>
        <p className="text-neutral-500 para-p3 font-medium">{t('heading.noSearchSub')}</p>
      </div>
    </div>
  )

  const filteredMenu =
    search?.length > 0
      ? adminSettingsMenu
          .map((item) => ({
            ...item,
            data: item.data.filter((singleMenu) => singleMenu.title.toLowerCase().includes(search) || singleMenu.subTitle.toLowerCase().includes(search)),
          }))
          .filter((item) => item.data.length > 0)
      : adminSettingsMenu

  return (
    <div className="w-full h-full">
      <Row className="mb-6">
        <Col xxl={6} xl={6} lg={6} md={24} sm={24}>
          <Search
            placeholder={t('placeholder.searchSettings')}
            prefix={<SearchIcon />}
            onSearch={onSearch}
            allowClear
            className="custom-searchbar"
            onChange={(e) => {
              handleChange(e.target.value)
            }}
          />
        </Col>
      </Row>

      {filteredMenu.length !== 0 ? (
        <div>
          {filteredMenu.map((item) => {
            return (
              <div className="p-6 bg-white rounded-4 settings-boxshadow mb-6" key={item.id}>
                <HeadingText classString="text-neutral-800 mobile-heading-h4 font-medium pb-6" text={item.name} />

                <Row gutter={[24, 24]} className="mt-6" justify="start">
                  {item.data.map((singleMenu) => {
                    return (
                      <Col
                        xxl={8}
                        xl={8}
                        lg={12}
                        md={12}
                        sm={24}
                        key={singleMenu.id}
                        className="cursor-pointer"
                        onClick={() => {
                          navigate(singleMenu.navigate)
                        }}
                      >
                        <div className="p-4 rounded-2 border-neutral-200 items-center flex gap-4 h-full w-full ">
                          <div className="flex p-2 items-center gap-2 rounded bg-neutral-50">{singleMenu.icon}</div>
                          <div className="flex flex-col justify-center items-start gap-1 w-full">
                            <HeadingText text={singleMenu.title} classString="text-neutral-800 activity-text font-medium" />
                            <HeadingText text={singleMenu.subTitle} classString="text-neutral-500 para-p3" />
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            )
          })}
        </div>
      ) : (
        EmptyScreen
      )}
    </div>
  )
}

export default AdminSettings
