import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'

import { type IAddMemberFacility } from '../type'

export const getBillingContact = async (id: string, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/facility/billing-contacts/${id}?${search && `search=${search}`}${order && field && `&order[${field}]=${order}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getFacilityContact = async (id: string, search: string, order: string, field: string, offset: number, limit: number) => {
  try {
    const response = http.get(`/facility/all-contacts/${id}?limit=${limit}&offset=${offset}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const editUserContact = async (id: string, payload: IAddMemberFacility) => {
  try {
    const response = http.patch(`/facility/edit-contact/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const addUserContact = async (payload: IAddMemberFacility) => {
  try {
    const response = await http.post(`/facility/add-contacts`, payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getContactProfile = async (id: string) => {
  try {
    const response = await http.get(`/facility-user/contact-profile/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getFacilityPermissiom = async () => {
  try {
    const response = await http.get('/dropdown/facility-permission')
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getFacilityAccess = async () => {
  try {
    const response = await http.get('/dropdown/facility')
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getAdminFacilityAccess = async (id: string) => {
  try {
    const response = await http.get(`/dropdown/all-facility/${id}`)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const sendInvitation = async (payload: { email: string }) => {
  try {
    const response = await http.patch('/auth/send-invitation/facility_user', payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
