import { useEffect, useState } from 'react'
import { Form, Radio, type RadioChangeEvent } from 'antd'
import SelectedThemeIcon from 'assets/svgs/SelectedThemeIcon'
import { MY_FACILITY_FLAG_COLORS } from 'constants/Constant'
import { type IAccessSiteLogoProps } from 'types'

const AccessSiteTheme = ({ siteAndTheme, siteAndThemeUploadData, setSiteAndThemeUploadData, setIsLogoOrThemeUpdated }: IAccessSiteLogoProps) => {
  const [form] = Form.useForm()
  const [currentSelectedColor, setCurrentSelectedColor] = useState<string>('')

  const handleColorChange = (e: RadioChangeEvent) => {
    setIsLogoOrThemeUpdated(true)
    setCurrentSelectedColor(e.target.value)
    setSiteAndThemeUploadData({ ...siteAndThemeUploadData, theme_color: e.target.value })
  }

  useEffect(() => {
    const color = form.getFieldValue('color')
    if (color) setCurrentSelectedColor(siteAndTheme?.theme_color ?? color)
  }, [])

  return (
    <Form className="add-provider-status-wrapper site-theme" form={form} initialValues={{ color: siteAndTheme?.theme_color }}>
      <div className="badge-color-wrapper">
        <div className="badge-wrapper">
          <Form.Item name="color">
            <Radio.Group className="badge-radio-button gap-6" onChange={handleColorChange}>
              {MY_FACILITY_FLAG_COLORS?.map((color: string) => (
                <Radio value={color} key={color} style={{ background: color }}>
                  <div className="custom-radio flex items-center justify-center">{currentSelectedColor === color && <SelectedThemeIcon />}</div>
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      </div>
    </Form>
  )
}

export default AccessSiteTheme
