import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown, type MenuProps, message } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PasswordVisibleIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_SKILLS_CHECKLIST_SETTINGS } from 'constants/Constant'
import { SKILLCHECKLIST_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setCurrentSkillData, setGlobalLoader, setPreviousSkillData } from 'store/slice/CommonSlice'
import { type IChecklistData } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import { getFindAllChecklist, removeChecklistTemplate } from './api'

const SkillCheckList = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState<string>('')
  const [listData, setListData] = useState<IChecklistData[]>([])
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.skillChecklist'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleFindAllCheckList(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit, paginationInfo.current, search, sortTable])

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleFindAllCheckList = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getFindAllChecklist(limit, offset, search, order, field)
    if (response?.status === 200) {
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    handlePageChange(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const handlePageChange = (page: number, pageSize: number = paginationInfo?.limit) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
      limit: pageSize,
    }))
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleDeleteCheckList = async (id: string) => {
    const response = await removeChecklistTemplate(id)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleFindAllCheckList(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const getMenu = (record: IChecklistData, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            handleActionMenuClick(index)
            localStorage.removeItem('skillState')
            navigate(`/settings/skill-checklist/edit/${record?.id}`)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon height={24} width={24} className="#2A2A2B" />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          onClick: () => {
            localStorage.removeItem('skillState')
            handleActionMenuClick(index)
            navigate(`/settings/skill-checklist/preview`, { state: { record, type: 'preview' } })
          },
          label: (
            <div className="option-wrapper">
              <PasswordVisibleIcon height={24} width={24} color="#2A2A2B" />
              <span className="activity-text font-normal text-neutral-800">{t('button.preview')}</span>
            </div>
          ),
        },
        {
          key: '3',
          onClick: () => {
            localStorage.removeItem('skillState')
            setIsDeleteModalOpen(true)
            setDeleteId(record.id ?? '')
            setDeleteName(record.name)
            handleActionMenuClick(index)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon height={24} width={24} className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType<IChecklistData> = [
    {
      title: t('table.skillCheckList.checklistName'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 400,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        return <HeadingText text={record?.name} classString={'text-neutral-800 activity-text font-medium'} />
      },
    },
    {
      title: t('table.skillCheckList.totalModules'),
      dataIndex: 'total_module',
      key: 'total_module',
      align: 'start',
      width: 261,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('table.skillCheckList.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'start',
      width: 261,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === SKILLCHECKLIST_STATUS.INVITED ? 'invited' : record.status === SKILLCHECKLIST_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.skillCheckList.lastUpdated'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 261,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY')
        // const formattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY, hh:mm A')
        return <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
      },
    },
    {
      title: t('table.skillCheckList.actions'),
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 100,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value?.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value?.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value?.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const newChecklistBtn = (
    <CustomBtn
      isIcon
      svgIcon={<PlusIcon />}
      text={t('button.newChecklist')}
      onClick={() => {
        dispatch(setCurrentSkillData([]))
        dispatch(setPreviousSkillData([]))
        localStorage.removeItem('skillState')
        navigate('/settings/skill-checklist/add')
      }}
      type="primary"
      className="rounded-3"
    />
  )

  return (
    <div>
      <CustomTable<IChecklistData>
        column={columns}
        data={listData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        search={search}
        isSearch
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        name={t('label.skillCheckList')}
        tableButton={newChecklistBtn}
        searchPlaceholder={t('placeholder.search')}
        onSearch={onSearch}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
      />

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_SKILLS_CHECKLIST_SETTINGS.CANCEL}
          okClick={() => {
            void handleDeleteCheckList(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_SKILLS_CHECKLIST_SETTINGS.DELETE_DESC}
          mainHead={DELETE_SKILLS_CHECKLIST_SETTINGS.DELETE_HEAD_LOB}
          okText={DELETE_SKILLS_CHECKLIST_SETTINGS.DELETE}
        />
      )}
    </div>
  )
}

export default SkillCheckList
