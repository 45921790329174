import { message, Popover, Switch } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, FlagIcon, ThreeDotIcon } from 'assets/svgs'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { editDnrReason, editFlagSetting } from 'services/SettingServices/FacilityProviderService'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IPaginationInfo } from 'types'
import { formatTitle } from 'utils/Helper'

import HeadingText from 'components/common/HeadingText'

export interface IColumns {
  actionMenuOpen: any
  setActionMenuOpen: any
  paginationInfo: IPaginationInfo
  setPaginationInfo: React.Dispatch<React.SetStateAction<IPaginationInfo>>
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>
  setEditData: React.Dispatch<React.SetStateAction<string>>
  showAddModal: () => void
  selectedValue: 'flag_status' | 'dnr_reason'
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setDeleteName: React.Dispatch<React.SetStateAction<string>>
  setDeleteId: React.Dispatch<React.SetStateAction<string>>
}

export const useColumn = ({
  setDeleteId,
  setDeleteName,
  setIsDeleteModalOpen,
  actionMenuOpen,
  setActionMenuOpen,
  setPaginationInfo,
  paginationInfo,
  setRefreshData,
  setEditData,
  showAddModal,
  selectedValue,
}: IColumns) => {
  const dispatch = useAppDispatch()

  const handleStatusChange = async (id: string, status: string) => {
    try {
      dispatch(setGlobalLoader(true))
      const response = selectedValue === 'dnr_reason' ? await editDnrReason(id, { status }) : await editFlagSetting(id, { status })
      if (response?.data?.statusCode === 1) {
        void message.success(response?.data?.message)
        setRefreshData(true)
      }
    } catch (err) {
      console.log(err)
    } finally {
      dispatch(setGlobalLoader(false))
    }
  }

  const getMenu = (record: any, index: number, isDefault = false) => {
    return (
      <div className="dropDown-action w-full rounded-3">
        <div
          className="p-4 cursor-pointer w-full"
          onClick={() => {
            handleOpenChange(record?.id, false)
            setEditData(record)
            showAddModal()
          }}
        >
          <p className="flex items-center gap-2">
            <EditPenIcon /> <span className="para-p1-regular font-normal text-neutral-800">{t('button.edit')}</span>
          </p>
        </div>
        {!isDefault && (
          <>
            <div className="p-4 cursor-pointer w-full">
              <p className="flex items-center justify-between gap-2">
                <span className="para-p1-regular font-normal text-neutral-800">{t('button.active')}</span>{' '}
                <Switch
                  value={record?.status === 'active'}
                  onChange={(checked) => {
                    if (record?.id && selectedValue) {
                      void handleStatusChange(record?.id, checked ? 'active' : 'in_active')
                    }
                  }}
                />
              </p>
            </div>
            <div
              className="p-4 cursor-pointer"
              onClick={() => {
                handleOpenChange(record?.id, false)
                setIsDeleteModalOpen(true)
                setDeleteId(record?.id)
                setDeleteName(record?.name ?? record?.reason)
              }}
            >
              <p className="flex items-center gap-2">
                <DeleteIcon className="red-flag" />
                <span className="para-p1-regular font-normal text-error-500">{t('button.delete')}</span>
              </p>
            </div>
          </>
        )}
      </div>
    )
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen: any) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handlePageChange = (page: number, pageSize: number = paginationInfo?.limit) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
      limit: pageSize,
    }))
  }

  const dnrColumns: ColumnsType = [
    {
      title: t('table.dnrReason.reason'),
      dataIndex: 'reason',
      key: 'reason',
      align: 'start',
      width: 460,
      sorter: true,
      render(value, record) {
        return <HeadingText classString="line-clamp-table text-neutral-800 para-p1 font-medium" text={record.reason} />
      },
    },
    {
      title: t('table.dnrReason.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      sorter: true,
      width: 460,
      render(value, record) {
        return <HeadingText classString="line-clamp-table text-neutral-800 para-p3 font-small" text={record.description ?? '-'} />
      },
    },
    {
      title: t('table.dnrReason.reasonType'),
      dataIndex: 'reason_type',
      key: 'reason_type',
      align: 'start',
      width: 230,
      sorter: true,
      render(value: string) {
        return <span>{value?.charAt(0)?.toUpperCase() + value?.slice(1)}</span>
      },
    },
    {
      title: t('table.dnrReason.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'start',
      width: 230,
      sorter: true,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record?.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record?.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record?.status)}
          />
        )
      },
    },
    {
      title: t('table.dnrReason.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <Popover
            content={getMenu(record, _value.id)}
            placement="bottomRight"
            open={actionMenuOpen[_value.id]}
            onOpenChange={(open) => {
              handleOpenChange(_value.id, open)
            }}
            trigger="click"
            destroyTooltipOnHide
          >
            <div className="flex w-full justify-center p-2 cursor-pointer">
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="icon" />
              </div>
            </div>
          </Popover>
        )
      },
    },
  ]

  const flagColumns: ColumnsType = [
    {
      title: t('table.providerStatus.statusName'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 345,
      sorter: true,
      render(value, record) {
        return <HeadingText classString="text-neutral-800 para-p1 font-medium" text={record.name} />
      },
    },
    {
      title: t('table.providerStatus.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      sorter: true,
      width: 345,
      render(value) {
        return value ?? '-'
      },
    },
    {
      title: t('table.providerStatus.flagColor'),
      dataIndex: 'color',
      key: 'color',
      align: 'center',
      width: 345,
      className: 'center-align',
      render(value) {
        return <FlagIcon color={value} />
      },
    },
    {
      title: t('table.providerStatus.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 345,
      sorter: true,
      className: 'center-align',
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.providerStatus.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      className: 'center-aligned',
      render: (_value, record) => {
        return (
          <Popover
            content={getMenu(record, _value.id, record?.is_default)}
            placement="bottomRight"
            open={actionMenuOpen[_value.id]}
            onOpenChange={(open) => {
              handleOpenChange(_value.id, open)
            }}
            trigger="click"
            className="ant-pop-static"
            rootClassName="ant-pop-static"
          >
            <div className="flex w-full justify-center p-2 cursor-pointer">
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="icon" />
              </div>
            </div>
          </Popover>
        )
      },
    },
  ]

  const providerColumns: ColumnsType = [
    {
      title: t('table.providerStatus.statusName'),
      dataIndex: 'statusName',
      key: 'statusName',
      align: 'start',
      width: 345,
      render(value, record) {
        return <HeadingText classString="text-neutral-800 para-p1 font-medium" text={record.statusName} />
      },
    },
    {
      title: t('table.providerStatus.description'),
      dataIndex: 'description',
      key: 'description',
      align: 'start',
      width: 345,
    },
    {
      title: t('table.providerStatus.badgeColor'),
      dataIndex: 'badgeColor',
      key: 'badgeColor',
      align: 'center',
      width: 345,
      render(value, record) {
        return (
          <HeadingText
            classString={`provider-status-wrapper ${record.color === 'success' ? 'green-badge' : record.color === 'neutral' ? 'neutral-badge' : record.color === 'warning' ? 'orange-badge' : 'red-badge'}`}
            text={record.badgeColor}
          />
        )
      },
    },
    {
      title: t('table.providerStatus.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 345,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.INVITED ? 'invited' : record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.providerStatus.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <div className="action-cell flex items-center justify-center three-dot-icon">
              <Popover
                content={getMenu(record, _value.id)}
                placement="bottomRight"
                open={actionMenuOpen[_value.id]}
                onOpenChange={(open) => {
                  handleOpenChange(_value.id, open)
                }}
              >
                <ThreeDotIcon className="cursor-pointer icon" />
              </Popover>
            </div>
          </div>
        )
      },
    },
  ]
  return { dnrColumns, flagColumns, providerColumns, handlePageChange }
}
