import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, message } from 'antd'
import { DropDownIcon } from 'assets/svgs'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type ICreateChecklistData, type ISkillChecklistModule, type ISkillChecklistSubModule } from 'types'
import { addSkillChecklistDeletedData, validateJSON } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'

import { addChecklist, editChecklist, getFindChecklistById } from '../api'

import '../SkillChecklist.scss'

const PreviewCheckList = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()

  const [data, setData] = useState({
    name: '',
    status: false,
    delete_module: [],
    delete_sub_module: [],
    delete_question: [],
    skill_checklist_module: [],
    editId: '',
    type: '',
  })

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: location.state || data.type === 'preview' ? t('pageTitle.preview') : t('pageTitle.livePreview'),
        isBack: true,
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data])

  useEffect(() => {
    getLocalStorageData()
  }, [])

  useEffect(() => {
    if (location.state) {
      if (localStorage.getItem('skillState')) {
        getLocalStorageData()
        return
      }
      const getUrlData = location?.state?.record
      if (getUrlData?.id) void handleFindSingleTestData(getUrlData?.id, location?.state?.type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const getLocalStorageData = () => {
    const stateString = localStorage.getItem('skillState')
    const getData = stateString ? JSON.parse(stateString) : {}
    getData.skill_checklist_module = getData?.skill_checklist_module?.sort((a: ISkillChecklistModule, b: ISkillChecklistModule) => a.order - b.order)
    setData(getData)
  }

  const handleFindSingleTestData = async (id: string, type: string) => {
    try {
      dispatch(setGlobalLoader(true))
      const response = await getFindChecklistById(id)
      if (response?.status === 200) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { name, skill_checklist_module, status } = response.data.data
        const sortedSkillChecklistModule = skill_checklist_module?.sort((a: ISkillChecklistModule, b: ISkillChecklistModule) => a.order - b.order)
        const previewData: any = {
          name: name ?? '',
          status: status === 'active',
          delete_module: [],
          delete_sub_module: [],
          delete_question: [],
          skill_checklist_module: sortedSkillChecklistModule ?? [],
          editId: '',
          type: type ?? '',
        }
        setData(previewData)
        localStorage.setItem('skillState', JSON.stringify(previewData))
      } else {
        void message.error(response?.data?.message)
      }
    } catch (error) {
      console.error('Error fetching checklist data:', error)
    } finally {
      dispatch(setGlobalLoader(false))
    }
  }

  const onFinish = () => {
    if (data.skill_checklist_module.length === 0) {
      void message.error(VALIDATION_ERROR.COMMON.ENTERONEQUE)
      return
    }
    const validation = validateJSON(data.skill_checklist_module)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    const newstatus = data.status ? 'active' : 'in_active'
    const updatedValues = { name: data.name, status: newstatus, skill_checklist_module: data.skill_checklist_module }
    if (data.editId) {
      const newData = addSkillChecklistDeletedData(updatedValues, data.delete_module, data.delete_sub_module, data.delete_question)
      void handleEditCheckList(data.editId, newData)
    } else {
      void handleAddCheckList(updatedValues)
    }
  }

  const handleAddCheckList = async (payload: ICreateChecklistData) => {
    dispatch(setGlobalLoader(true))
    const response = await addChecklist(payload)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      navigate('/settings/skill-checklist')
    } else {
      void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditCheckList = async (id: string, values: ICreateChecklistData) => {
    dispatch(setGlobalLoader(true))
    const response = await editChecklist(id, values)
    if (response?.status === 200) {
      void message.success(response?.data?.message)
      navigate('/settings/skill-checklist')
    } else {
      void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handlePreviewSubtopic = (subModuleName: string, subModuleQues: ISkillChecklistSubModule[]) => {
    if (data.type === 'preview' || location?.state?.type === 'preview') {
      navigate('/settings/skill-checklist/preview/subtopic', {
        state: { subModuleName, subModuleQues, listPreview: true, editId: data.editId },
      })
    } else if (data.editId) {
      navigate('/settings/skill-checklist/edit/preview/subtopic', {
        state: { subModuleName, subModuleQues, listPreview: false, editId: data.editId },
      })
    } else if (!location.state && !data.editId) {
      navigate('/settings/skill-checklist/add/preview/subtopic', {
        state: { subModuleName, subModuleQues, listPreview: false, editId: data.editId },
      })
    }
  }

  return (
    <>
      <Form form={form} onFinish={onFinish} className="flex flex-col gap-6 items-center w-full addedit-checklist">
        <div
          className="rounded-4 flex items-center justify-between bg-white w-full skillchecklist-preview content-header-spacing"
          style={{ paddingTop: location.state || data.type === 'preview' ? '25px' : '', paddingBottom: location.state || data.type === 'preview' ? '25px' : '' }}
        >
          <div className="flex gap-8 items-center justify-between skill-heading ">
            <HeadingText classString="w-full text-neutral-800 mobile-heading-h6 font-medium" text={data.name} />
          </div>
          <div className="flex items-center justify-end gap-4">
            {!location.state && data.type !== 'preview' && (
              <CustomBtn text={data.editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="rounded-3 w-120" />
            )}
          </div>
        </div>
        <div className="flex flex-col w-955 px-10 w-full gap-6">
          <div className="flex flex-col gap-6">
            {data.skill_checklist_module?.map((question: ISkillChecklistModule, index: number) => {
              return (
                <div key={index} className="bg-white rounded-3 question-container">
                  <div className="flex flex-col gap-6 p-8 bg-white rounded-3">
                    <div className="flex items-center">
                      <HeadingText classString="w-full mobile-heading-h5 font-medium p-0 m-0" text={question.name} />
                      <div
                        className="flex items-center justify-end transform-arrow"
                        onClick={() => {
                          handlePreviewSubtopic(question.name, question.skill_checklist_sub_module)
                        }}
                      >
                        <DropDownIcon width={25} height={24} />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Form>
    </>
  )
}

export default PreviewCheckList
