import { message } from 'antd'
import http from 'services/Service'
import { type IAddDnrReasonPayload, type IAddFlagStatusPayload } from 'types'

export const getAllFlagSetting = async (query: string) => {
  try {
    const response = http.get(`/flag-setting?${query}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const addFlagStatus = async (data: IAddFlagStatusPayload) => {
  try {
    const response = http.post(`/flag-setting`, data)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const deleteFlagStatus = async (id: string) => {
  try {
    const response = http.delete(`/flag-setting/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
export const editFlagSetting = async (id: string, data: any) => {
  try {
    const response = http.patch(`/flag-setting/${id}`, data)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const getAllDnrReason = async (query: string) => {
  try {
    const response = http.get(`/dnr-reason?${query}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const addDnrReason = async (data: IAddDnrReasonPayload) => {
  try {
    const response = http.post(`/dnr-reason`, data)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}

export const deleteDnrReason = async (id: string) => {
  try {
    const response = http.delete(`/dnr-reason/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
export const editDnrReason = async (id: string, data: any) => {
  try {
    const response = http.patch(`/dnr-reason/${id}`, data)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message)
  }
}
