import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'
import { type IGetStatusOptionColumn } from 'types'

export const getAllStatusOptions = async (statusFor: string, limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(
      `/status-setting?status_for=${statusFor}&limit=${limit}&offset=${offset}${order && field && `&order[${field}]=${order}`}${search && `&search=${search}`}`
    )
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message ?? VALIDATION_ERROR.SOMETHING)
  }
}

export const addStatusOption = async (payload: IGetStatusOptionColumn) => {
  try {
    const response = http.post('/status-setting', payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message ?? VALIDATION_ERROR.SOMETHING)
  }
}

export const updateStatusOption = async (id: string, payload: IGetStatusOptionColumn) => {
  try {
    const response = http.patch(`/status-setting/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message ?? VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteStatusOption = async (id: string) => {
  try {
    const response = http.delete(`/status-setting/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message ?? VALIDATION_ERROR.SOMETHING)
  }
}
