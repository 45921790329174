// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-btn-wrapper {
  left: 134px;
  top: 36px;
  gap: 6px;
}
.back-btn-wrapper svg {
  rotate: 90deg;
}
@media (min-width: 320px) and (max-width: 700px) {
  .back-btn-wrapper {
    left: 0%;
    top: 15px;
  }
}
@media (min-width: 700px) and (max-width: 1400px) {
  .back-btn-wrapper {
    left: 5%;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/authflow/AuthFlow.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,SAAA;EACA,QAAA;AADF;AAEE;EACE,aAAA;AAAJ;AAEE;EAPF;IAQI,QAAA;IACA,SAAA;EACF;AACF;AAAE;EAXF;IAYI,QAAA;EAGF;AACF","sourcesContent":["@import '../../style/variables';\n\n.back-btn-wrapper {\n  left: 134px;\n  top: 36px;\n  gap: 6px;\n  svg {\n    rotate: 90deg;\n  }\n  @media (min-width: 320px) and (max-width: 700px) {\n    left: 0%;\n    top: 15px;\n  }\n  @media (min-width: 700px) and (max-width: 1400px) {\n    left: 5%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
