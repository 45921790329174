// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.schedule-and-request-container {
  background-color: #ffffff !important;
  margin: 0 75px;
}
@media (max-width: 1230px) {
  .schedule-and-request-container {
    margin: 0 25px;
  }
}
.schedule-and-request-container .setting-custom-dropDown {
  height: 38px;
  width: 210px !important;
}
.schedule-and-request-container .setting-custom-dropDown .ant-select-selector {
  padding: 0 !important;
  border: 1px solid #c4c5c7 !important;
  border-radius: 12px !important;
  box-shadow: none !important;
}
.schedule-and-request-container .setting-custom-dropDown .ant-select-selection-placeholder {
  padding: 0 !important;
  padding-left: 16px !important;
}
.schedule-and-request-container .setting-custom-dropDown .ant-select-selection-item {
  padding-left: 16px !important;
}
.schedule-and-request-container .ant-form-item {
  margin-bottom: 0 !important;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}`, "",{"version":3,"sources":["webpack://./src/pages/schedule-and-request/ScheduleAndRequest.scss"],"names":[],"mappings":"AAEA;EACE,oCAAA;EACA,cAAA;AADF;AAGE;EAJF;IAKI,cAAA;EAAF;AACF;AAEE;EACE,YAAA;EACA,uBAAA;AAAJ;AACI;EACE,qBAAA;EACA,oCAAA;EACA,8BAAA;EACA,2BAAA;AACN;AACI;EACE,qBAAA;EACA,6BAAA;AACN;AACI;EACE,6BAAA;AACN;AAEE;EACE,2BAAA;AAAJ;;AAIA;;EAEE,wBAAA;EACA,SAAA;AADF;;AAIA;EACE,0BAAA;AADF","sourcesContent":["@import '../../style/variables';\n\n.schedule-and-request-container {\n  background-color: #ffffff !important;\n  margin: 0 75px;\n\n  @media (max-width: 1230px) {\n    margin: 0 25px;\n  }\n\n  .setting-custom-dropDown {\n    height: 38px;\n    width: 210px !important;\n    .ant-select-selector {\n      padding: 0 !important;\n      border: 1px solid $neutral-200 !important;\n      border-radius: 12px !important;\n      box-shadow: none !important;\n    }\n    .ant-select-selection-placeholder {\n      padding: 0 !important;\n      padding-left: 16px !important;\n    }\n    .ant-select-selection-item {\n      padding-left: 16px !important;\n    }\n  }\n  .ant-form-item {\n    margin-bottom: 0 !important;\n  }\n}\n\ninput[type='number']::-webkit-outer-spin-button,\ninput[type='number']::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\ninput[type='number'] {\n  -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
