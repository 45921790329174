// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-user-form-wrapper {
  height: calc(65vh - 300px);
  padding-top: 10px;
  overflow-y: auto;
}
.add-user-form-wrapper::-webkit-scrollbar {
  display: none;
}

.activity-modal {
  width: 915px !important;
}
.activity-modal .ant-modal-content {
  padding: 0 !important;
  border-radius: 16px;
}
.activity-modal .ant-modal-content .ant-modal-body .wrapper-connect-modal .header-modal {
  padding: 16px 24px;
  border-bottom: 1px solid #e5e5e6;
}
.activity-modal .ant-modal-content .ant-modal-body .wrapper-connect-modal .content-modal {
  padding: 16px 32px;
  height: calc(90vh - 280px);
  padding-top: 10px;
  overflow-y: auto;
}
.activity-modal .ant-modal-content .ant-modal-body .wrapper-connect-modal .content-modal::-webkit-scrollbar {
  display: none;
}
.activity-modal .ant-modal-content .ant-modal-body .wrapper-connect-modal .member-content-modal {
  padding: 16px 32px;
  height: calc(70vh - 300px);
  padding-top: 10px;
  overflow-y: auto;
}
.activity-modal .ant-modal-content .ant-modal-body .wrapper-connect-modal .member-content-modal::-webkit-scrollbar {
  display: none;
}
.activity-modal .ant-modal-content .ant-modal-body .wrapper-connect-modal .footer-modal {
  padding: 16px 24px;
  background: var(--Shades-White, #fff);
  box-shadow: 0px 0px 8px 4px rgba(85, 85, 85, 0.06);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/adminContacts/AdminContact.scss"],"names":[],"mappings":"AAEA;EACE,0BAAA;EACA,iBAAA;EACA,gBAAA;AADF;AAEE;EACE,aAAA;AAAJ;;AAIA;EACE,uBAAA;AADF;AAEE;EACE,qBAAA;EACA,mBAAA;AAAJ;AAIQ;EACE,kBAAA;EACA,gCAAA;AAFV;AAKQ;EACE,kBAAA;EACA,0BAAA;EACA,iBAAA;EACA,gBAAA;AAHV;AAIU;EACE,aAAA;AAFZ;AAMQ;EACE,kBAAA;EACA,0BAAA;EACA,iBAAA;EACA,gBAAA;AAJV;AAKU;EACE,aAAA;AAHZ;AAOQ;EACE,kBAAA;EACA,qCAAA;EACA,kDAAA;EACA,+BAAA;EACA,gCAAA;AALV","sourcesContent":["@import '../../style/variables';\n\n.add-user-form-wrapper {\n  height: calc(65vh - 300px);\n  padding-top: 10px;\n  overflow-y: auto;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}\n\n.activity-modal {\n  width: 915px !important;\n  .ant-modal-content {\n    padding: 0 !important;\n    border-radius: 16px;\n\n    .ant-modal-body {\n      .wrapper-connect-modal {\n        .header-modal {\n          padding: 16px 24px;\n          border-bottom: 1px solid $stroke-color;\n        }\n\n        .content-modal {\n          padding: 16px 32px;\n          height: calc(90vh - 280px);\n          padding-top: 10px;\n          overflow-y: auto;\n          &::-webkit-scrollbar {\n            display: none;\n          }\n        }\n\n        .member-content-modal {\n          padding: 16px 32px;\n          height: calc(70vh - 300px);\n          padding-top: 10px;\n          overflow-y: auto;\n          &::-webkit-scrollbar {\n            display: none;\n          }\n        }\n\n        .footer-modal {\n          padding: 16px 24px;\n          background: var(--Shades-White, #fff);\n          box-shadow: 0px 0px 8px 4px rgba(85, 85, 85, 0.06);\n          border-bottom-left-radius: 16px;\n          border-bottom-right-radius: 16px;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
