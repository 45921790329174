import { useEffect, useState } from 'react'
import { Form, InputNumber, message, Select, Space } from 'antd'
import { DropDownIcon, ModalClose } from 'assets/svgs'
import { durationType } from 'constants/Constant'
import { commonInputFieldValidation, commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData, type IGlobalSetting, type ISettingCompentancy } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'

import { createGlobalSetting, getFindOneTest, getGlobalSetting, updateIndividualSetting } from '../api'

import '../Compentancy.scss'

const { Option } = Select

const SettingsModal = ({ setIsModalOpen, editId, data, setOption, type }: ISettingCompentancy) => {
  const [form] = Form.useForm()
  const dispatch = useAppDispatch()

  const [error, setError] = useState({
    expires_in: '',
    expiration_duration_type: '',
    total_attempts: '',
    reassignment_duration: '',
    reassignment_duration_type: '',
  })

  useEffect(() => {
    if (editId && data) {
      form.setFieldsValue(data)
    } else if (editId && type === 'individual') {
      void handleGetData(editId)
    } else if (type === 'global') {
      void handleGetGlobalData()
      // } else if (localStorage.getItem('compentancyState')) {
      //   const getPrevData = localStorage.getItem('compentancyState')
      //   const getData = getPrevData ? JSON.parse(getPrevData) : {}
      //   form.setFieldsValue(getData.global_test_setting)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editId, data, type])

  const handleGetGlobalData = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getGlobalSetting()
    if (response?.status === 200) {
      const data = {
        expires_in: response?.data?.data?.expires_in ?? 1,
        expiration_duration_type: response?.data?.data?.expiration_duration_type ?? 'year',
        total_attempts: response?.data?.data?.total_attempts ?? 10,
        reassignment_duration: response?.data?.data?.reassignment_duration ?? 365,
        reassignment_duration_type: response?.data?.data?.reassignment_duration_type ?? 'day',
      }
      form.setFieldsValue(data)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
      return null
    }
    dispatch(setGlobalLoader(false))
  }

  const handleGetData = async (editId: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getFindOneTest(editId)
    if (response?.status === 200) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { test_setting } = response.data.data
      const data = {
        expires_in: test_setting?.expires_in ?? 1,
        expiration_duration_type: test_setting?.expiration_duration_type ?? 'year',
        total_attempts: test_setting?.total_attempts ?? 10,
        reassignment_duration: test_setting?.reassignment_duration ?? 365,
        reassignment_duration_type: test_setting?.reassignment_duration_type ?? 'day',
      }
      form.setFieldsValue(data)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
      return null
    }
    dispatch(setGlobalLoader(false))
  }

  const onFinish = (values: IGlobalSetting) => {
    const data = {
      expires_in: values.expires_in,
      expiration_duration_type: values.expiration_duration_type,
      total_attempts: Number(values.total_attempts),
      reassignment_duration: values.reassignment_duration,
      reassignment_duration_type: values.reassignment_duration_type,
    }
    if (type === 'create') {
      if (setOption) setOption(data)
      if (setIsModalOpen) setIsModalOpen(false)
    } else if (type === 'global') {
      void handleGlobalSetting(data)
    } else if (type === 'individual' && editId) {
      void handleIndividualSetting(data, editId)
    }
  }

  const handleGlobalSetting = async (data: IGlobalSetting) => {
    dispatch(setGlobalLoader(true))
    const response = await createGlobalSetting(data)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      if (setIsModalOpen) setIsModalOpen(false)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleIndividualSetting = async (data: IGlobalSetting, editId: string) => {
    dispatch(setGlobalLoader(true))
    const response = await updateIndividualSetting(data, editId)
    if (response?.status === 200) {
      void message.success(response?.data?.message)
      if (setIsModalOpen) setIsModalOpen(false)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleCancel = () => {
    if (setIsModalOpen) setIsModalOpen(false)
  }

  return (
    <div className="main-wrapper rounded-4">
      <div className="header-wrapper-certi">
        <HeadingText
          classString="text-neutral-800 desktop-head-h5 font-semi-bold"
          // eslint-disable-next-line
          text={type === 'create' || type === 'individual' ? t('label.competencySettings') : t('label.competencyGobalSettings')}
        />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <Form
        form={form}
        requiredMark={false}
        onFinish={onFinish}
        className="px-8 py-10"
        onFieldsChange={(allFields: IFieldData[]) => {
          handleFieldsChange(allFields, setError)
        }}
        initialValues={{
          expires_in: 1,
          expiration_duration_type: 'year',
          total_attempts: 3,
          reassignment_duration: 365,
          reassignment_duration_type: 'day',
        }}
      >
        <>
          <div className="flex flex-col items-start gap-8 w-full setting-form">
            <div className="w-full">
              <div className="flex justify-between items-center w-full">
                <HeadingText classString="text-neutral-800 para-p2 font-medium" text={t('heading.testExpire')} />
                <div className="flex items-center">
                  <Space className="gap-0 space-field">
                    <Form.Item name="expires_in" rules={commonInputFieldValidation(VALIDATION_STRINGS.TEST_EXPIRATION_TIME)} noStyle>
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item name="expiration_duration_type" noStyle>
                      <Select suffixIcon={<DropDownIcon />}>
                        {durationType.map((eachType: { value: string; label: string }, index: number) => {
                          return (
                            <Option key={index} value={eachType.value}>
                              {eachType.label}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Space>
                </div>
              </div>
              {(error?.expires_in || error?.expiration_duration_type) && <p className="p-0 m-0 error-container mb-6">{error.expires_in || error?.expiration_duration_type}</p>}
            </div>

            <div className="w-full">
              <div className="flex justify-between items-center w-full">
                <HeadingText classString="text-neutral-800 para-p2 font-medium" text={t('heading.attempts')} />
                <div className="flex items-center">
                  <Space className="gap-0 attemps">
                    <Form.Item name="total_attempts" rules={commonSingleSelectValidation(VALIDATION_STRINGS.NUMBER_OF_ATTEMP)} noStyle>
                      <Select suffixIcon={<DropDownIcon />}>
                        {Array.from({ length: 10 }, (_, index) => (
                          <Option key={index + 1} value={index + 1}>
                            {index + 1}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space>
                </div>
              </div>
              {error?.total_attempts && <p className="p-0 m-0 error-container mb-6">{error.total_attempts}</p>}
            </div>

            <div className="w-full">
              <div className="flex justify-between items-center w-full">
                <HeadingText classString="text-neutral-800 para-p2 font-medium" text={t('heading.reassignment')} />
                <div className="flex items-center">
                  <Space className="gap-0 space-field">
                    <Form.Item name="reassignment_duration" rules={commonInputFieldValidation(VALIDATION_STRINGS.REASSIGNMENT_DURATION)} noStyle>
                      <InputNumber min={1} max={365} />
                    </Form.Item>
                    <Form.Item name="reassignment_duration_type" noStyle>
                      <Select suffixIcon={<DropDownIcon />}>
                        {durationType.map((eachType: { value: string; label: string }, index: number) => {
                          return (
                            <Option key={index} value={eachType.value}>
                              {eachType.label}
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Space>
                </div>
              </div>
              {(error?.reassignment_duration || error?.reassignment_duration_type) && (
                <p className="p-0 m-0 error-container mb-6">{error.reassignment_duration || error?.reassignment_duration_type}</p>
              )}
            </div>
          </div>
          {/* buttons */}
          <div className="flex items-start justify-end gap-4 w-full footer-sticky">
            <CustomBtn
              text={t('button.cancel')}
              type="default"
              onClick={() => {
                if (setIsModalOpen) setIsModalOpen(false)
              }}
              className="w-160 rounded-3"
            />
            <CustomBtn text={t('button.save')} type="primary" htmlType="submit" className="w-160 rounded-3" />
          </div>
        </>
      </Form>
    </div>
  )
}

export default SettingsModal
