// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-wrapper {
  border: 1px solid #e5e5e6;
  border-radius: 0 0 16px 16px;
  border-top: 0 !important;
}

.add-requirement-form {
  height: calc(80vh - 200px);
  overflow-y: scroll;
}
.add-requirement-form::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.add-document-form {
  height: calc(56vh - 100px);
  overflow-y: scroll;
}
.add-document-form::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.add-document-form .document-form {
  padding-top: 6px;
}

.checkbox-group .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.checkbox-group .ant-checkbox-inner,
.checkbox-group .ant-checkbox-checked {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}

.document-checkbox .ant-checkbox {
  align-self: flex-start !important;
  margin-top: 8px;
}
.document-checkbox .ant-checkbox-inner,
.document-checkbox .ant-checkbox-checked {
  height: 20px;
  width: 20px;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/complianceManager/ComplianceManager.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;EACA,4BAAA;EACA,wBAAA;AADF;;AAIA;EACE,0BAAA;EACA,kBAAA;AADF;AAEE;EACE,UAAA;EACA,uBAAA;AAAJ;;AAIA;EACE,0BAAA;EACA,kBAAA;AADF;AAEE;EACE,UAAA;EACA,uBAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ;;AAKE;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AAFJ;AAIE;;EAEE,YAAA;EACA,WAAA;EACA,kBAAA;AAFJ;;AAOE;EACE,iCAAA;EACA,eAAA;AAJJ;AAME;;EAEE,YAAA;EACA,WAAA;EACA,kBAAA;AAJJ","sourcesContent":["@import '../../style/variables';\n\n.div-wrapper {\n  border: 1px solid $stroke-color;\n  border-radius: 0 0 16px 16px;\n  border-top: 0 !important;\n}\n\n.add-requirement-form {\n  height: calc(80vh - 200px);\n  overflow-y: scroll;\n  &::-webkit-scrollbar {\n    width: 0px;\n    background: transparent;\n  }\n}\n\n.add-document-form {\n  height: calc(56vh - 100px);\n  overflow-y: scroll;\n  &::-webkit-scrollbar {\n    width: 0px;\n    background: transparent;\n  }\n  .document-form {\n    padding-top: 6px;\n  }\n}\n\n.checkbox-group {\n  .ant-checkbox-group {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n  }\n  .ant-checkbox-inner,\n  .ant-checkbox-checked {\n    height: 20px;\n    width: 20px;\n    border-radius: 4px;\n  }\n}\n\n.document-checkbox {\n  .ant-checkbox {\n    align-self: flex-start !important;\n    margin-top: 8px;\n  }\n  .ant-checkbox-inner,\n  .ant-checkbox-checked {\n    height: 20px;\n    width: 20px;\n    border-radius: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
