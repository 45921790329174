import { useEffect, useState } from 'react'
import { Form, message, Switch } from 'antd'
import { CloseIcon, UsaIcon } from 'assets/svgs'
import { FacilityIcon } from 'assets/svgs/settingsIcons/Index'
import { commonInputFieldValidation, commonSelectFieldValidation, emailValidation, mobileValidation } from 'constants/Validation'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFieldData } from 'types'
import { formatTitle, handleFieldsChange, removeSorting } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import DropDown from 'components/common/Select/DropDown'

import { addUserContact, editUserContact, getAdminFacilityAccess, getFacilityPermissiom } from '../api'
import { type IAddMemberFacility, type IAddMemberFacilityModal, type IPermissionOption } from '../type'

import '../FacilityContacts.scss'

const AddMember = ({ openModal, setOpenModal, editId, contactDetails, setContactDetails, setSortTable, facilityId }: IAddMemberFacilityModal) => {
  const [statusCheck, setStatusCheck] = useState(true)
  const [permissionTag, setPermissionTag] = useState<any[]>([])
  const [facilityTag, setFacilityTag] = useState<any[]>([])
  const [permissionOption, setPermissionOption] = useState<IPermissionOption[]>([])
  const [failityAccessOption, setFacilityAccessOption] = useState<IPermissionOption[]>([])
  const [error, setError] = useState({
    first_name: '',
    mobile_no: '',
    last_name: '',
    email: '',
    permissions: '',
    facility_id: '',
  })
  const [contactData, setContactData] = useState({
    first_name: '',
    mobile_no: '',
    last_name: '',
    email: '',
  })

  const dispatch = useAppDispatch()
  const [form] = Form.useForm()

  useEffect(() => {
    if (openModal) {
      void getFacilitiesPermissionOption()
      void getFacilitiesAccessOption()
    }

    form.setFieldsValue(contactDetails)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])

  useEffect(() => {
    if (contactDetails) {
      const checkMark = contactDetails?.status === 'active'
      setStatusCheck(checkMark)
      const permissionIds: any = contactDetails?.permission?.map((permission) => permission?.permission_id)
      setPermissionTag(permissionIds)
      const facilityIds = contactDetails?.facility?.map((item: { id: string }) => {
        const option = failityAccessOption.find((option) => option?.id === item?.id)
        return option ? option.id : null
      })
      setFacilityTag(facilityIds)
    }
  }, [contactDetails, failityAccessOption])

  const usa = t('placeholder.countryCode')

  const onFinish = (values: IAddMemberFacility) => {
    const dialCode: any = usa
    const statusValue = statusCheck ? 'active' : 'in_active'
    const updatedValues = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email?.toLowerCase(),
      mobile_no: values?.mobile_no,
      country_code: dialCode,
      permissions: permissionTag,
      facility_id: facilityTag,
      status: statusValue,
    }
    void handleAddUser(updatedValues)
  }

  const handleCancel = () => {
    setContactDetails()
    setOpenModal(false)
  }

  const onSwitchChange = (checked: boolean) => {
    if (checked) {
      setStatusCheck(checked)
    } else {
      setStatusCheck(checked)
    }
  }

  const handlePermissionChange = (selectedValue: any[]) => {
    setPermissionTag(selectedValue)
  }

  const handleAddUser = async (values: IAddMemberFacility) => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      response = await editUserContact(editId, values)
    } else {
      response = await addUserContact(values)
    }
    if (response?.data.statusCode === 1) {
      setSortTable && removeSorting(setSortTable)
      void message.success(response?.data?.message)
      setOpenModal(false)
      form.resetFields()
    }
    dispatch(setGlobalLoader(false))
  }

  const getFacilitiesPermissionOption = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getFacilityPermissiom()
    if (response?.data.statusCode === 1) {
      const permission = response?.data?.data
      const modifiedPermissions = permission.map((item: IPermissionOption) => ({
        ...item,
        value: item.id,
        name: formatTitle(item?.name),
      }))
      setPermissionOption(modifiedPermissions)
    }
    dispatch(setGlobalLoader(false))
  }

  const getFacilitiesAccessOption = async () => {
    if (facilityId) {
      dispatch(setGlobalLoader(true))
      const response = await getAdminFacilityAccess(facilityId)
      if (response?.data?.statusCode === 1) {
        const facilityOpt = response?.data?.data
        const modifiedFacility = facilityOpt?.map((item: IPermissionOption) => ({
          ...item,
          value: item?.id,
          name: item?.name?.replace(/_/g, ' ').replace(/^\w/, (c) => c?.toUpperCase()),
        }))
        setFacilityAccessOption(modifiedFacility)
      }
      dispatch(setGlobalLoader(false))
    }
  }

  const handleDeleteTag = (id: number) => {
    const updatedPermissionTag = [...permissionTag]
    updatedPermissionTag.splice(id, 1)
    setPermissionTag(updatedPermissionTag)
  }

  const handleFacilityChange = (selectedValue: any[]) => {
    setFacilityTag(selectedValue)
  }

  const handleFacilityDeleteTag = (id: number) => {
    const updatedFailityTag = [...facilityTag]
    updatedFailityTag.splice(id, 1)
    setFacilityTag(updatedFailityTag)
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <Form
      form={form}
      autoComplete="off"
      layout="vertical"
      className="flex flex-col"
      requiredMark={false}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div className="add-member-form-wrapper pb-4">
        <div className="flex flex-col  w-full">
          <div className="flex flex-row w-full gap-4">
            <div className="flex flex-col w-full">
              <InputField
                name="first_name"
                placeholder={t('label.profileForm.fname')}
                rules={commonInputFieldValidation('your first name')}
                error={error.first_name}
                value={getValue('first_name')}
                onChange={(e: { target: { value: any } }) => {
                  setContactData({
                    ...contactData,
                    first_name: e.target.value,
                  })
                }}
              />
            </div>
            <div className="flex flex-col w-full">
              <InputField
                name="last_name"
                placeholder={t('label.profileForm.lname')}
                rules={commonInputFieldValidation('your last name')}
                error={error.last_name}
                value={getValue('last_name')}
              />
            </div>
          </div>
          <div className="flex flex-row w-full gap-4">
            {/* <div className="flex flex-col w-full"> */}
            <div className="flex gap-2 mobile-country-code w-full">
              <div className="country-code-wrapper w-120 h-48">
                <div className="country-flag">
                  <UsaIcon />
                </div>
                <p className="para-p2 font-normal text-neutral-800 ">{usa}</p>
              </div>
              <div className="flex flex-col w-full ">
                <InputField
                  name="mobile_no"
                  rules={mobileValidation}
                  placeholder={t('label.profileForm.mobile')}
                  error={error.mobile_no}
                  wrapperClass="w-full"
                  value={getValue('mobile_no')}
                />
              </div>
            </div>
            {/* </div> */}
            <div className="flex flex-col w-full">
              <InputField name="email" disabled={!!editId} placeholder={t('label.profileForm.email')} rules={emailValidation} error={error.email} value={getValue('email')} />
            </div>
          </div>
        </div>
        <div className="flex flex-row w-full gap-4 mb-6">
          <div className="flex flex-col w-full relative">
            <div className={`cursor-pointer relative`}>
              <DropDown
                name="permissions"
                handleChange={handlePermissionChange}
                Options={permissionOption}
                placeholder={t('placeholder.selectPermission')}
                rules={commonSelectFieldValidation('permission')}
                updatedTag={permissionTag}
                error={error.permissions}
              />
            </div>
          </div>
        </div>
        {permissionTag?.length > 0 && (
          <div className="flex flex-col gap-4 w-full mb-6">
            {permissionTag?.length > 0 && <HeadingText text={t('heading.permission')} classString="para-p4 text-neutral-400" />}
            <div className="flex gap-4 w-full flex-wrap">
              {permissionTag?.map((tagId: string, index: number) => {
                const option = permissionOption.find((item) => item.id === tagId)
                return (
                  <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                    {option?.name}
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleDeleteTag(index)
                      }}
                    >
                      {<CloseIcon />}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div className="cursor-pointer mb-6">
          <DropDown
            searchPlaceholder={t('label.searchFacility')}
            isIcon
            isSearch
            icon={<FacilityIcon />}
            name="facility_id"
            handleChange={handleFacilityChange}
            Options={failityAccessOption}
            placeholder={t('placeholder.facilityAccess')}
            rules={commonSelectFieldValidation('facility access')}
            updatedTag={facilityTag}
            error={error.facility_id}
          />
        </div>
        {facilityTag?.length > 0 && (
          <div className="flex flex-col gap-4 w-full mt-6">
            {facilityTag?.length > 0 && <HeadingText text={t('heading.facilityAccess')} classString="para-p4 text-neutral-400" />}
            <div className="flex gap-4 w-full flex-wrap">
              {facilityTag?.map((tagId: string, index: number) => {
                const option = failityAccessOption.find((item) => item.id === tagId)
                return (
                  <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                    {option?.name}
                    <span
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        handleFacilityDeleteTag(index)
                      }}
                    >
                      {<CloseIcon />}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between items-center mt-4">
        <div className="flex gap-2 toggle-switch">
          <span>{t('label.userStatus')}</span> <Switch value={statusCheck} onChange={onSwitchChange} />
        </div>
        <div className="flex gap-4">
          <CustomBtn type="default" text={t('button.cancel')} className="w-160 h-49" onClick={handleCancel} />
          <CustomBtn htmlType="submit" text={editId ? t('button.update') : t('button.add')} type="primary" className="w-160 h-49" />
        </div>
      </div>
    </Form>
  )
}

export default AddMember
