import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, message, Popover, Switch } from 'antd'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import type { IGeneralPayload, IGeneralSetting } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import CommonTextAreaWithoutForm from 'components/common/TextArea/CommonTextAreaWithoutForm'

import AddNewReferralSource from './Modal/AddNewReferralSourc'
import { getGeneralSettings, updateSettings } from './api'

const GeneralSetting = () => {
  const dispatch = useDispatch()
  const [actionMenuOpen, setActionMenuOpen] = useState(false)
  const [settings, setSettings] = useState<IGeneralSetting[]>([])
  const [statusCheck, setStatusCheck] = useState<boolean>(true)
  const [isNewRefAdd, setIsNewRefAdd] = useState<boolean>(false)
  const [form] = Form.useForm()
  const [error, setError] = useState({
    name: '',
  })

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.generalSettings'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void getSettingsData()
  }, [isNewRefAdd])

  const onChange = (switchData: boolean, settingId: number, dataId: string) => {
    let payload = { id: '', status: '' }

    const updatedSettings = settings.map((setting) => {
      if (setting.id === settingId) {
        return {
          ...setting,
          sub_section: setting.sub_section.map((dataItem) => {
            if (dataItem.id === dataId) {
              payload = {
                id: dataId,
                status: switchData ? 'active' : 'in_active',
              }
              return { ...dataItem, status: switchData }
            }
            return dataItem
          }),
        }
      }
      return setting
    })

    setSettings(updatedSettings as any) // Optimistically update UI
    void handleUpdateSetting(payload) // Update on the server
  }

  const handleUpdateSetting = async (payload: IGeneralPayload) => {
    dispatch(setGlobalLoader(true))
    const response = await updateSettings(payload)
    if (response?.data?.statusCode === 1) {
      await getSettingsData() // Refetch settings after successful update
      void message.success(response?.data?.message)
    } else {
      void message.error(response?.data?.message || 'Update failed')
    }
    dispatch(setGlobalLoader(false))
  }

  const getSettingsData = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getGeneralSettings()
    if (response?.data?.statusCode === 1 && Array.isArray(response?.data?.data)) {
      setSettings(response?.data?.data)
    } else {
      void message.error(response?.data?.message || 'Failed to load settings')
    }
    dispatch(setGlobalLoader(false))
  }

  const handleTextAreaBlur = (value: string, dataId: string, settingId: number) => {
    let payload = { id: dataId, status: '', placeholder: '' }

    setSettings((prevSettings) =>
      prevSettings.map((setting) => {
        if (setting.id === settingId) {
          return {
            ...setting,
            sub_section: setting.sub_section.map((dataItem) => {
              if (dataItem.id === dataId) {
                payload = {
                  id: dataId,
                  status: dataItem.status,
                  placeholder: value,
                }
                return { ...dataItem, placeholder: value }
              }
              return dataItem
            }),
          }
        }
        return setting
      })
    )

    if (value) {
      void handleUpdateSetting(payload)
    }
  }

  // Updated onChange handler to use local state for placeholder
  const handleLocalPlaceholderChange = (id: string, value: string) => {
    const updatedSettings = settings.map((setting) => {
      return {
        ...setting,
        sub_section: setting?.sub_section?.map((dataItem) => {
          if (dataItem.id === id) {
            return { ...dataItem, placeholder: value }
          }
          return dataItem
        }),
      }
    })

    setSettings(updatedSettings) // Update the settings in the state
  }

  return (
    <div className="max-w-1200 mx-auto">
      <div className="flex justify-end mb-6 md:flex-col">
        <div className="flex gap-6">
          <Popover
            trigger={['click']}
            content={
              <AddNewReferralSource
                statusCheck={statusCheck}
                setStatusCheck={setStatusCheck}
                setIsModalOpen={setActionMenuOpen}
                settings={settings}
                form={form}
                setError={setError}
                error={error}
                isNewRefAdd={isNewRefAdd}
                setIsNewRefAdd={setIsNewRefAdd}
              />
            }
            placement="bottomRight"
            open={actionMenuOpen}
            rootClassName="general-setting-popover"
            onOpenChange={(open) => {
              if (!open) {
                form.resetFields()
                setActionMenuOpen(false)
                setError({ name: '' })
                form.setFieldValue('name', '')
                setStatusCheck(true)
              }
            }}
          >
            <CustomBtn
              text={t('button.addReferralSource')}
              type="default"
              onClick={() => {
                setActionMenuOpen(true)
              }}
            />
          </Popover>
        </div>
      </div>
      <div className="flex flex-col rounded-4 justify-start aline-self">
        {settings.map((setting) => {
          return (
            <div className="w-full" key={setting.id}>
              <div>
                <p className="para-p1 text-neutral-800 font-semi-bold mb-4">{setting.name}</p>
              </div>
              <div className="border-stroke flex flex-col w-full bg-white rounded-3 mb-10">
                <div className="h-52 flex flex-row w-full justify-between bg-neutral-50 rounded-tl-tr-3 border-b-stroke">
                  <div className="pl-4 flex items-center h-full">
                    <p className="field-title text-neutral-600">{t('label.fieldName')}</p>
                  </div>
                  <div className="flex justify-center items-center p-4">
                    <p className="field-title">{t('label.displayOnForm')}</p>
                  </div>
                </div>
                {setting.sub_section.map((data) => {
                  return (
                    <div
                      className={`flex flex-row w-full justify-between border-b-stroke min-h-52 ${settings.length - 1 === settings.indexOf(setting) ? 'border-b-0' : ''}`}
                      key={data.id}
                    >
                      {data.type !== 'text' ? (
                        <div className="pl-4 flex items-center">
                          <p className="field-name">{data.name}</p>
                        </div>
                      ) : (
                        <div className="p-4 w-full">
                          <CommonTextAreaWithoutForm
                            name="placeholder"
                            placeholder="Please Enter"
                            value={data.placeholder}
                            rows={3}
                            wrapperClass="general-text-area m-0"
                            onChange={(e) => {
                              handleLocalPlaceholderChange(data.id, e.target.value)
                              // handleTextAreaBlur(e.target.value, data.id, setting.id)
                            }}
                            onBlur={(e) => {
                              handleTextAreaBlur(e.target.value, data.id, setting.id)
                            }}
                          />
                        </div>
                      )}
                      <div className="flex justify-center items-center w-156 switch-wrapper">
                        <Switch
                          checked={data.status === 'active'}
                          onChange={(value) => {
                            onChange(value, setting.id, data.id)
                          }}
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default GeneralSetting
