import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { message } from 'antd'
import { MAX_FILE_SIZE_MB } from 'constants/Constant'
import dayjs from 'dayjs'
import { pickBy } from 'lodash'

export const getsessionUUID = async () => {
  const fp = await FingerprintJS.load()
  const { visitorId } = await fp.get()
  return visitorId
}

export const paginationDetails = (limit: number | string | undefined, currentPage: number | string | undefined = 1, total: number | string | undefined = 0) => {
  const numLimit = Number(limit) || 10
  const numCurrentPage = Math.max(1, Number(currentPage))
  const numTotal = Number(total) || 0

  let start = (numCurrentPage - 1) * numLimit + 1
  if (start > numTotal) {
    start = numTotal > 0 ? numTotal : 0
  }

  let end = numCurrentPage * numLimit
  if (end > numTotal) {
    end = numTotal
  }

  if (numTotal === 0) {
    start = 0
    end = 0
  }

  if (start < 1 && numTotal > 0) {
    start = 1
  }

  return `Showing ${start} - ${end} of ${numTotal}`
}

export const userToken = () => {
  const authPersist = localStorage.getItem('persist:root') ?? ''
  const jsonAuth = JSON?.parse(authPersist)
  const { accessToken } = JSON.parse(jsonAuth.auth)
  return accessToken
}

// export const firstName = () => {
//   const authPersist = localStorage.getItem('persist:root') ?? ''
//   const jsonAuth = JSON?.parse(authPersist)
//   const profileData = JSON?.parse(jsonAuth.auth)
//   return profileData?.profileDetails?.first_name || ''
// }

// export const lastName = () => {
//   const authPersist = localStorage.getItem('persist:root') ?? ''
//   const jsonAuth = JSON?.parse(authPersist)
//   const profileData = JSON?.parse(jsonAuth.auth)
//   return profileData?.profileDetails?.last_name || ''
// }

// export const role = () => {
//   const authPersist = localStorage.getItem('persist:root') ?? ''
//   const jsonAuth = JSON?.parse(authPersist)
//   const profileData = JSON?.parse(jsonAuth.auth)
//   return profileData?.role?.name || ''
// }

// export const profileImage = () => {
//   const authPersist = localStorage?.getItem('persist:root') ?? ''
//   const jsonAuth = JSON?.parse(authPersist)
//   const profileData = JSON?.parse(jsonAuth.auth)
//   return profileData?.profileDetails?.image || ''
// }

const customPredicate = (value: any) => value !== null && value !== undefined && value !== ''

export const formatQuerry = (querry: any) => {
  const query: any = pickBy(querry, customPredicate)

  const queryParams = new URLSearchParams(query)

  // Get the query string
  const queryString = queryParams.toString()

  return queryString
}
export const validateFileSize = (file: File, maxSizeMB: number): string | null | boolean => {
  const fileSizeInMB = file.size / (1024 * 1024) // Convert file size to MB
  if (fileSizeInMB > maxSizeMB) {
    // eslint-disable-next-line
    void message.error(`File size should be less than ${MAX_FILE_SIZE_MB}MB`)
    return false
  }
  return true
}

const getOrdinalSuffix = (day: number): string => {
  if (day > 3 && day < 21) return 'th' // handles 11th, 12th, 13th
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export const formatDateStrings = (dateString: any) => {
  const date = dayjs(dateString)
  const day = date?.date()
  const month = date?.format('MMM')
  const time = date?.format('h:mm A')
  return `${day}${getOrdinalSuffix(day)} ${month}, ${time}`
}

export const formatToTitleCase = (text: string) => {
  return text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
