import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'

export const getAllReferenceForm = async (limit: number, offset: number, search: string, order: string, field: string) => {
  try {
    const response = http.get(`/reference-form-design?${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}&limit=${limit}&offset=${offset}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const deleteReferenceDesign = async (id: string) => {
  try {
    const response = http.delete(`/reference-form-design/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const getReferanceFormDetail = async (id: string) => {
  try {
    const response = http.get(`/reference-form-design/${id}`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const editReferenceForm = async (id: string, payload: any) => {
  try {
    const response = http.patch(`/reference-form-design/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const createReferenceForm = async (payload: any) => {
  try {
    const response = http.post(`/reference-form-design`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
