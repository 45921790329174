import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PasswordVisibleIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_MODAL_REFERENCE_TEXT } from 'constants/Constant'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setCurrentData, setGlobalLoader, setPreviousData } from 'store/slice/CommonSlice'
import { type IReferanceFormColumn } from 'types'

import CustomBtn from 'components/common/CustomBtn'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import { deleteReferenceDesign, editReferenceForm, getAllReferenceForm, getReferanceFormDetail } from './api'

import './ReferanceForm.scss'

const ReferanceForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [listData, setListData] = useState<IReferanceFormColumn[]>([])
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData?.length).fill(false))
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.referanceForm'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleFindAllReferanceForm(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationInfo.limit, paginationInfo.current, search, sortTable])

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleFindAllReferanceForm = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getAllReferenceForm(limit, offset, search, order, field)
    if (response?.status === 200) {
      setListData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    handlePageChange(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const handlePageChange = (page: number, pageSize: number = paginationInfo?.limit) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
      limit: pageSize,
    }))
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleFindSingleFormData = async (id: string) => {
    const response = await getReferanceFormDetail(id)
    if (response?.status === 200) {
      return response?.data?.data
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
      return null
    }
  }

  const onActiveSwitchChange = async (checked: boolean, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const singleForm = await handleFindSingleFormData(id)
    if (singleForm) {
      void handleEditActiveData(id, newStatus, singleForm)
    }
  }

  const handleEditActiveData = async (id: string, status: string, singleTest: IReferanceFormColumn) => {
    if (singleTest?.id === id) {
      const data = {
        ...singleTest,
        status,
      }
      const response = await editReferenceForm(id, data)
      if (response?.status === 200) {
        void message.success(response?.data?.message)
        void handleFindAllReferanceForm(paginationInfo.limit, paginationInfo.offset, sortTable.order, sortTable.field)
      } else {
        if (response?.data?.message) void message.error(response?.data?.message)
      }
    }
  }

  const getMenu = (record: IReferanceFormColumn, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            localStorage.removeItem('referanceForm')
            handleActionMenuClick(index)
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            navigate(`/settings/referance-form-setting/edit-form/${record.id ?? ''}`)
          },
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: '2',
          onClick: () => {
            localStorage.removeItem('referanceForm')
            handleActionMenuClick(index)
            navigate(`/settings/referance-form-setting/referance-form-preview`, { state: record })
          },
          label: (
            <div className="option-wrapper">
              <PasswordVisibleIcon color="#2A2A2B" />
              <span className="activity-text font-normal text-neutral-800">{t('button.preview')}</span>
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div className="option-wrapper">
              <div className=" cursor-pointer w-full" key="2">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>
                  <Switch
                    checked={record.status === 'active'}
                    onChange={(checked) => {
                      if (record.id !== undefined) {
                        void handleFindSingleFormData(record.id)
                        void onActiveSwitchChange(checked, record.id)
                      }
                      handleActionMenuClick(index)
                    }}
                  />
                </p>
              </div>
            </div>
          ),
        },
        {
          key: '4',
          onClick: () => {
            setDeleteId(record.id)
            handleActionMenuClick(index)
            setIsDeleteModalOpen(true)
            setDeleteName(record.name)
          },
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const handleDeleteRefForm = async (id: string) => {
    const response = await deleteReferenceDesign(id)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleFindAllReferanceForm(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const columns: ColumnsType<IReferanceFormColumn> = [
    {
      title: t('table.referanceForm.formName'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 400,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        return <HeadingText text={record.name} classString={'text-neutral-800 activity-text font-medium'} />
      },
    },
    {
      title: t('table.referanceForm.totalQuestion'),
      dataIndex: 'total_questions',
      key: 'total_questions',
      align: 'start',
      width: 522,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: t('table.referanceForm.lastUpdated'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 522,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY')
        return <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
      },
    },
    {
      title: t('table.referanceForm.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 100,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const tableButton = (
    <div className="flex gap-6">
      <CustomBtn
        isIcon
        svgIcon={<PlusIcon />}
        text={t('button.newForm')}
        onClick={() => {
          dispatch(setPreviousData([]))
          dispatch(setCurrentData([]))
          localStorage.removeItem('referanceForm')
          navigate('/settings/referance-form-setting/add-form')
        }}
        type="primary"
      />
    </div>
  )

  return (
    <div>
      <CustomTable
        column={columns}
        data={listData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        search={search}
        isSearch
        onSearch={onSearch}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        name={t('label.members')}
        searchPlaceholder={t('placeholder.search')}
        tableButton={tableButton}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
      />

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_MODAL_REFERENCE_TEXT.CANCEL}
          okClick={() => {
            void handleDeleteRefForm(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_MODAL_REFERENCE_TEXT.DELETE_DESC}
          mainHead={DELETE_MODAL_REFERENCE_TEXT.DELETE_HEAD_LOB}
          okText={DELETE_MODAL_REFERENCE_TEXT.DELETE}
        />
      )}
    </div>
  )
}

export default ReferanceForm
