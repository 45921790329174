import { type ColumnsType } from 'antd/es/table'
import { SHIFTS } from 'constants/Constant'
import { SHIFT_INVITATION_STATUS_COLOR } from 'constants/ConstantStatus'
import dayjs from 'dayjs'

import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'
import TableImage from 'components/common/Table/TableImage'

const AutoSchedulingTab = ({ status, data, paginationInfo, handlePageChange }: any) => {
  const columns: ColumnsType = [
    {
      title: SHIFTS.INVITE_TABLE.INVITEE,
      dataIndex: 'requestTo',
      key: 'requestTo',
      align: 'center',
      width: 250,
      render: (_value, record) => {
        return (
          <div className="cursor-pointer">
            <TableImage
              baseUrl={record?.provider?.base_url}
              image={record?.provider?.profile_image}
              name={`${record?.provider?.first_name as string} ${record?.provider?.last_name as string}`}
              imageClass="rounded-50"
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.INVITE_TABLE.REQUIRE_CREDENTIAL,
      dataIndex: 'requireCredential',
      key: 'requireCredential',
      align: 'center',
      width: 281,
      render: (_value, record) => {
        return <div>4/4</div>
      },
    },
    {
      title: SHIFTS.INVITE_TABLE.LICENSE,
      dataIndex: ['shift', 'certificate', 'name'],
      key: 'certificate',
      align: 'center',
      width: 375,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText
              classString="status"
              style={{ background: record?.shift?.certificate?.background_color, color: record?.shift?.certificate?.text_color }}
              text={record?.shift?.certificate?.name}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.INVITE_TABLE.STATUS,
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 375,
      render: (_value: string, record) => {
        return (
          <div className="flex justify-center">
            <HeadingText
              classString="status"
              style={{ background: SHIFT_INVITATION_STATUS_COLOR[_value]?.bgColor, color: SHIFT_INVITATION_STATUS_COLOR[_value]?.color }}
              text={_value}
            />
          </div>
        )
      },
    },
    {
      title: SHIFTS.INVITE_TABLE.INVITED_ON,
      dataIndex: 'invitedOn',
      key: 'invitedOn',
      align: 'center',
      width: 375,
      render: (_value, record) => {
        return <div>{dayjs(record?.created_at).format('MM/DD/YYYY, hh:mm A')}</div>
      },
    },
  ]
  return (
    <div className="request-table-wrapper flex p-6 flex-col items-start gap-4 h-full">
      <CustomTable
        column={columns}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        onChangePage={handlePageChange}
        data={data?.data}
        name={SHIFTS.INVITE}
      />
    </div>
  )
}

export default AutoSchedulingTab
