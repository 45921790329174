import { Modal } from 'antd'
import { type ICustomModal } from 'types'

const CustomModal = ({
  title,
  open,
  onOk,
  onCancel,
  okText,
  cancelText,
  content,
  closable,
  showCancelBtn,
  footer,
  centered,
  classStrings,
  width,
  closeIcon,
  bodyStyle,
}: ICustomModal) => {
  return (
    <Modal
      title={title}
      open={open}
      centered={centered}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      footer={footer}
      closable={closable}
      closeIcon={closeIcon}
      maskClosable
      cancelText={cancelText}
      cancelButtonProps={{ style: { display: showCancelBtn } }}
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      className={`custom-modal ${classStrings}`}
      width={width}
      bodyStyle={bodyStyle}
    >
      {content}
    </Modal>
  )
}
export default CustomModal
