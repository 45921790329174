import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, message } from 'antd'
import { commonInputFieldValidation, commonSingleSelectValidation, mobileValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddFloorProps, type IErrorState, type IFieldData, type IFloor, type IOrderAndClientContact, type ISelectLabel, type ISpecialtyFloor } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import InputField from 'components/common/InputField/InputField'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'
import CommonTextArea from 'components/common/TextArea/CommonTextArea'

import { createFloor, editFloorDetail, getOrderAndClientContact, getSpecialities } from '../api'

import '../Facilites.scss'

const AddFloor = ({ isModalOpen, facilityId, isEdit, setIsEdit, editData, pageLimit, offset, getFloorsList }: IAddFloorProps) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [specialitiesDropdownValues, setSpecialitiesDropdownValues] = useState<ISpecialtyFloor[]>([])
  const [orderAndClientContact, setOrderAndClientContact] = useState<IOrderAndClientContact[]>([])
  const [customSpecialitiesData, setCustomSpecialitiesdData] = useState<ISelectLabel[]>([])
  const [customOrderAndClientContactData, setOrderAndClientContactData] = useState<ISelectLabel[]>([])
  const [isBilling, setIsBilling] = useState<boolean>(true)
  const [error, setError] = useState<IErrorState>({
    name: '',
    beds: '',
    order_contact: '',
    speciality: '',
    billToClient: '',
    po: '',
    costCenter: '',
    phone: '',
  })

  useEffect(() => {
    const updatedSpecialitiesData = specialitiesDropdownValues?.map((item: ISpecialtyFloor) => ({
      label: item.name,
      value: item.id,
    }))

    const updatedOrderAndClientContactData = orderAndClientContact?.map((item: IOrderAndClientContact) => ({
      label: `${item.first_name} ${item.last_name}`,
      value: item.id,
    }))

    setCustomSpecialitiesdData(updatedSpecialitiesData)
    setOrderAndClientContactData(updatedOrderAndClientContactData)
  }, [specialitiesDropdownValues, orderAndClientContact])

  useEffect(() => {
    if (editData && isEdit) {
      form.setFieldsValue({
        name: editData?.name,
        beds: editData?.beds,
        po: editData?.po_number,
        costCenter: editData?.cost_center,
        phone: editData?.phone_number,
        description: editData?.description,
      })
    }
  }, [editData, form])

  const handelCancelClick = () => {
    isModalOpen(false)
  }

  useEffect(() => {
    void getDropDownValues()
  }, [])

  const onFinish = (values: IFloor) => {
    if (!isEdit) {
      void handleAddFloor(values)
    } else {
      void handleEditFloor(values)
    }
  }

  const getDropDownValues = async () => {
    dispatch(setGlobalLoader(true))
    const specialityResponse = await getSpecialities()
    const orderAndClientContactResponse = await getOrderAndClientContact({ id: facilityId ?? '', isBilling })

    if (specialityResponse?.status === 200) {
      setSpecialitiesDropdownValues(specialityResponse?.data?.data)
    }

    if (orderAndClientContactResponse?.status === 200) {
      setOrderAndClientContact(orderAndClientContactResponse?.data?.data)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleAddFloor = async (values: IFloor) => {
    const floorData = {
      name: values.name,
      beds: Number(values.beds),
      po_number: values.po ?? Number(values.po),
      cost_center: values.costCenter ?? Number(values.costCenter),
      phone_number: values.phone ?? Number(values.phone),
      description: values.description ?? '',
      default_order_contact: values.order_contact ?? '',
      client_contact: values.billToClient ?? '',
      speciality: values.speciality ?? '',
      facility: facilityId ?? '',
    }
    dispatch(setGlobalLoader(true))
    const response = await createFloor(floorData)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      void getFloorsList(facilityId ?? '', pageLimit, offset)
      isModalOpen(false)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditFloor = async (values: IFloor) => {
    const filteredBillToContact: IOrderAndClientContact | undefined = orderAndClientContact?.find((item: IOrderAndClientContact) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const fullName = `${item?.first_name} ${item?.last_name}`.trim()
      return fullName === values.billToClient || item?.id === values.billToClient
    })

    const filteredClientContact: IOrderAndClientContact | undefined = orderAndClientContact?.find((item: IOrderAndClientContact) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const fullName = `${item?.first_name} ${item?.last_name}`.trim()
      return fullName === values.order_contact || item?.id === values.order_contact
    })

    const floorData = {
      name: values.name ?? '',
      beds: Number(values.beds),
      po_number: values.po ?? Number(''),
      cost_center: values.costCenter ?? Number(''),
      phone_number: values.phone ?? Number(values.phone),
      description: values.description ?? '',
      default_order_contact: filteredClientContact?.id ?? '',
      client_contact: filteredBillToContact?.id ?? '',
      speciality: specialitiesDropdownValues?.filter((item: ISpecialtyFloor) => item?.name === values.speciality)?.[0]?.id,
      facility: facilityId ?? '',
    }
    dispatch(setGlobalLoader(true))
    const response = await editFloorDetail(editData?.id ?? '', floorData)
    if (response?.data?.statusCode === 1) {
      void message.success(response?.data?.message)
      setIsEdit(false)
      isModalOpen(false)
    }
    dispatch(setGlobalLoader(false))
  }

  return (
    <Form
      className="add-floor-wrapper"
      form={form}
      onFinish={onFinish}
      onFieldsChange={(allFields: IFieldData[]) => {
        handleFieldsChange(allFields, setError)
      }}
    >
      <div className="grid grid-cols-2 gap-4 w-full ">
        <div className="flex flex-col w-full">
          <InputField
            placeholder={t('placeholder.floorName')}
            name="name"
            wrapperClass="w-full"
            error={error.name}
            rules={commonInputFieldValidation(VALIDATION_STRINGS.FLOOR_NAME)}
            delay={100}
          />
        </div>
        <div className="flex flex-col w-full">
          <InputField
            placeholder={t('placeholder.beds')}
            type="number"
            name="beds"
            wrapperClass="w-full"
            error={error.beds}
            rules={commonInputFieldValidation(VALIDATION_STRINGS.BEDS)}
            delay={100}
          />
        </div>
      </div>
      <div className={`grid grid-cols-2 gap-4 w-full ${error.speciality || error.order_contact ? '' : 'mb-6'}`}>
        <div className="flex flex-col w-full">
          <FloatingLabelSelect
            label={t('placeholder.speciality')}
            options={customSpecialitiesData}
            name="speciality"
            value={isEdit ? editData?.speciality?.name : ''}
            error={error.speciality}
            rules={commonSingleSelectValidation('speciality')}
          />
        </div>
        <div className="flex flex-col w-full">
          <FloatingLabelSelect
            label={t('placeholder.orderContact')}
            options={customOrderAndClientContactData}
            name="order_contact"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            value={isEdit && editData?.default_order_contact ? `${editData?.default_order_contact?.first_name} ${editData?.default_order_contact?.last_name}` : ''}
            error={error.order_contact}
            rules={commonSingleSelectValidation('order contact')}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex flex-col w-full">
          <FloatingLabelSelect
            label={t('placeholder.billToClient')}
            options={customOrderAndClientContactData}
            name="billToClient"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            value={isEdit && editData?.client_contact ? `${editData?.client_contact?.first_name} ${editData?.client_contact?.last_name}` : ''}
            error={error.billToClient}
            rules={commonSingleSelectValidation('bill to client')}
          />
        </div>
        <div className="flex flex-col w-full">
          <InputField placeholder={t('placeholder.po')} name="po" wrapperClass="w-full" error={error.po} rules={commonInputFieldValidation(VALIDATION_STRINGS.PO)} delay={100} />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full ">
        <div className="flex flex-col w-full">
          <InputField
            placeholder={t('placeholder.costCenter')}
            name="costCenter"
            wrapperClass="w-full"
            error={error.costCenter}
            rules={commonInputFieldValidation(VALIDATION_STRINGS.COST_CENTER)}
            delay={100}
          />
        </div>
        <div className="flex flex-col w-full">
          <InputField placeholder={t('placeholder.phone')} name="phone" wrapperClass="w-full" error={error.phone} rules={mobileValidation} delay={100} />
        </div>
      </div>
      <CommonTextArea name="description" placeholder={t('placeholder.description')} rows={4} />
      <div className="flex  w-full justify-end items-center gap-4">
        <CustomBtn text={t('button.cancel')} type="default" className="w-160 rounded-3" onClick={handelCancelClick} />
        <CustomBtn text={isEdit ? t('button.update') : t('button.save')} type="primary" htmlType="submit" className="w-160 border-0 rounded-3" />
      </div>
    </Form>
  )
}

export default AddFloor
