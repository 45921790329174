import { type MouseEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Dropdown, Form, message } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { BlackFlag, CloseIcon, FileIcon, FlagIcon, HistoryIcon, RequestIcon, ThreeDotIcon } from 'assets/svgs'
import { ChatsIcon } from 'assets/svgs/siderMenuIcons/SiderMenuIcons'
import { BUTTON_STRING, COMMON, PAGE_TITLE_STRING, PROVIDER_STRING, TABLE_COLUMNS } from 'constants/Constant'
import { flagsData } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { GET_CERTIFICATE, GET_SPECIALITY } from 'services/common/Dropdown'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IFacility, IShiftProvider } from 'types'
import { formatQuerry } from 'utils/utils'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import HeadingText from 'components/common/HeadingText'
import CustomSelect from 'components/common/Select/CustomSelect'
import CustomTable from 'components/common/Table/CustomTable'
import TableImage from 'components/common/Table/TableImage'

import FlagAsDnr from './modal/FlagAsDnr'
import ReqProviderToWork from './modal/RequestProviderToWork'
import { GET_ALL_PROVIDER_LIST, UPDATE_PROVIDER_FLAG } from './api'
import { type IFacilityProfileFilter } from './types'

import './Provider.scss'

const Providers = ({ facilityCity, facilityState }: any) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isFlagAsDnr, setIsFlagAsDnrOpen] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [listData, setListData] = useState<IShiftProvider[]>([])
  const [certificateData, setCertificateData] = useState<IFacility[]>([])
  const [certificateOriginData, setCertificateOriginData] = useState<IFacility[]>([])
  const [specialityData, setSpecialityData] = useState<IFacility[]>([])
  const [specialityOriginData, setSpecialityOriginData] = useState<IFacility[]>([])
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [selectedRowData, setSelectedRowData] = useState<IShiftProvider | null>(null)
  const [isReqProviderToWork, setIsOpenReqProvToWork] = useState<boolean>(false)
  const { id: facilityId } = useParams() ?? ''
  // const [flags, setFlags] = useState<IFlags[]>([])
  const [filterApply, setFilterApply] = useState(false)
  const initialFilters = {
    certificate: '',
    speciality: '',
    flag: '',
  }
  const [filters, setFilters] = useState<IFacilityProfileFilter>({ ...initialFilters })
  const [selectedFlagId, setSelectedFlagId] = useState('')
  const [form] = Form.useForm()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  useEffect(() => {
    void getCertificate()
    void getSpeciality()
    // void handleGetAllFlag()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setTotal(0)
    setCurrent(1)
    setLimit(10)
  }, [facilityId])

  useEffect(() => {
    void handleGetProviderList(limit, current, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, current, search, sortTable, !isFlagAsDnr, facilityId])

  useEffect(() => {
    if (filterApply) {
      if (current !== 1) {
        setCurrent(1)
      } else {
        void handleGetProviderList(limit, current, sortTable?.order, sortTable?.field)
      }
    }
    // eslint-disable-next-line
  }, [filterApply])

  const handleGetProviderList = async (limit: number, current: number, order: string, field: string) => {
    if (facilityId) {
      dispatch(setGlobalLoader(true))

      const params = {
        [`flag[0]`]: filters?.flag,
        [`certificate[0]`]: filters?.certificate,
        [`speciality[0]`]: filters?.speciality,
      }
      const query = formatQuerry({ ...params })
      const response = await GET_ALL_PROVIDER_LIST(limit, current, search, order, field, facilityId, query)
      if (response?.status === 200) {
        setListData(response?.data?.data || [])
        setTotal(response?.data?.total)
      }

      setFilterApply(false)
      dispatch(setGlobalLoader(false))
    }
  }

  // const handleGetAllFlag = async () => {
  //   dispatch(setGlobalLoader(true))

  //   const response = await GET_ALL_FLAG()
  //   if (response?.status === 200) {
  //     setFlags(response?.data?.data)
  //   }

  //   dispatch(setGlobalLoader(false))
  // }

  const getCertificate = async () => {
    const response = await GET_CERTIFICATE()
    if (response?.status === 200) {
      setCertificateData(response?.data?.data)
      setCertificateOriginData(response?.data?.data)
    }
  }

  const getSpeciality = async () => {
    const response = await GET_SPECIALITY()
    if (response?.status === 200) {
      setSpecialityData(response?.data?.data)
      setSpecialityOriginData(response?.data?.data)
    }
  }

  const updateFlag = async (flagId: string, id: string) => {
    dispatch(setGlobalLoader(true))

    const response = await UPDATE_PROVIDER_FLAG(id, { flag: flagId })
    if (response?.status === 200) {
      void message.success(response?.data?.message)
      void handleGetProviderList(limit, current, sortTable?.order, sortTable?.field)
    }

    dispatch(setGlobalLoader(false))
  }

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrent(page)
    if (pageSize) setLimit(pageSize)
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setCurrent(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setCurrent(1)
    }
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleRowClick = (id: string, record: any) => {
    navigate('provider-profile', { state: { record, facilityCity, facilityState } })
  }

  const columns: ColumnsType = [
    {
      title: TABLE_COLUMNS.PROVIDERS.PROVIDER,
      dataIndex: 'p.first_name',
      key: 'p.first_name',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 205,
      render: (_value, record) => {
        const firstName = String(record?.provider?.first_name || '')
        const lastName = String(record?.provider?.last_name || '')
        return (
          <div className="flex items-center w-full">
            <TableImage
              imageClass="rounded-50"
              image={record?.provider?.profile_image as string}
              baseUrl={record?.provider?.base_url as string}
              name={`${firstName} ${lastName}`.trim()}
            />
          </div>
        )
      },
    },
    {
      title: TABLE_COLUMNS.PROVIDERS.LICENSE,
      dataIndex: 'c.name',
      key: 'c.name',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 205,
      render: (_value, record) => {
        return (
          <div className="flex items-center w-full">
            <div
              className="py-1 px-2 rounded-2 w-max"
              style={{
                background: record?.certificate?.background_color ? record?.certificate?.background_color : 'none',
              }}
            >
              <HeadingText
                text={record?.certificate?.name}
                classString="font-medium para-p3"
                style={{ color: record?.certificate?.text_color ? record?.certificate?.text_color : 'text-neutral-800' }}
              />
            </div>
          </div>
        )
      },
    },
    {
      title: TABLE_COLUMNS.PROVIDERS.SPECIALTIES,
      dataIndex: 'sp.name',
      key: 'sp.name',
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      width: 205,
      render: (_value, record) => {
        return (
          <div className="flex items-center w-full">
            <div
              className="py-1 px-2 rounded-2 w-max"
              style={{
                background: record?.speciality?.background_color ? record?.speciality?.background_color : 'none',
              }}
            >
              <HeadingText
                text={record?.speciality?.name}
                classString="font-medium para-p3"
                style={{ color: record?.speciality?.text_color ? record?.speciality?.text_color : 'text-neutral-800' }}
              />
            </div>
          </div>
        )
      },
    },
    {
      title: TABLE_COLUMNS.PROVIDERS.LAST_SCHEDULED,
      dataIndex: 'last_scheduled',
      key: 'last_scheduled',
      align: 'start',
      width: 205,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        const date = record?.last_scheduled ? dayjs(record.last_scheduled).format('MM/DD/YYYY, hh:mm A') : '-'
        return <HeadingText text={date} classString={'text-neutral-800 font-medium'} />
      },
    },
    {
      title: TABLE_COLUMNS.PROVIDERS.NEXT_SCHEDULED,
      dataIndex: 'next_scheduled',
      key: 'next_scheduled',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      align: 'start',
      width: 205,
      render: (_value, record) => {
        const date = record?.next_scheduled ? dayjs(record.next_scheduled).format('MM/DD/YYYY, hh:mm A') : '-'
        return <HeadingText text={date} classString={'text-neutral-800 font-medium'} />
      },
    },
    {
      title: TABLE_COLUMNS.PROVIDERS.SHIFT_WORK,
      dataIndex: 'shifts_worked',
      key: 'shifts_worked',
      width: 205,
      align: 'start',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: TABLE_COLUMNS.PROVIDERS.FLAG,
      dataIndex: 'flag',
      key: 'flag',
      width: 115,
      align: 'start',
      // className: 'flag-cell',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (_value, record) => {
        return (
          <Dropdown
            rootClassName={`p-4`}
            menu={{
              items: flagsData?.map((flag, index) => ({
                label: (
                  <div className={`flag-option-wrapper ${record?.flag === flag?.type ? 'opacity-30 cursor-disable' : ''}`}>
                    <BlackFlag color={flag?.color} />
                    <span className="text-neutral-800 para-p3 font-normal">{flag?.name}</span>
                  </div>
                ),
                key: index,
                onClick: (event) => {
                  event.domEvent.stopPropagation()
                  if (record?.flag !== flag?.type) {
                    setSelectedRowData(record as IShiftProvider)
                    if (flag?.type === 'dnr') {
                      setIsFlagAsDnrOpen(true)
                      setSelectedFlagId(flag?.type)
                    } else {
                      void updateFlag(flag?.type, record?.id)
                    }
                  }
                },
              })),
            }}
            trigger={['click']}
          >
            <div className="w-full items-center flex">
              <div
                className={`rounded-1-half flex items-center cursor-pointer justify-center  ${!record.flag ? 'border-neutral-200 h-28 w-28' : record?.flag === 'preferred' ? 'color-success-500' : 'color-error-500'}`}
              >
                <FlagIcon color={record?.flag === 'preferred' ? '#359766' : record?.flag === 'dnr' ? '#d1293d' : '#7D7E82'} />
              </div>
            </div>
          </Dropdown>
        )
      },
    },
    {
      title: TABLE_COLUMNS.PROVIDERS.ACTION,
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 200,
      render: (_value, record) => {
        return (
          <div className="flex gap-2 justify-center">
            <Dropdown
              rootClassName={`p-4 action-dropdown`}
              menu={{
                items: [
                  {
                    label: (
                      <div className="cursor-pointer w-full p-1" key="0">
                        <p className="flex justify-start items-start gap-2">
                          <FileIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{BUTTON_STRING.VIEW_CREDENTIALS}</span>
                        </p>
                      </div>
                    ),
                    key: 0,
                    onClick: () => {
                      navigate('provider-profile', { state: { record, tabNo: '3' } })
                    },
                  },
                  {
                    label: (
                      <div className="cursor-pointer p-1" key="1">
                        <p className="flex items-center gap-2">
                          <RequestIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{BUTTON_STRING.REQ_TO_WORK}</span>
                        </p>
                      </div>
                    ),
                    key: 1,
                    onClick: () => {
                      setSelectedRowData(record as IShiftProvider)
                      setIsOpenReqProvToWork(true)
                    },
                  },
                  {
                    label: (
                      <div className="cursor-pointer w-full p-1" key="2">
                        <p className="flex items-center gap-2">
                          <HistoryIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{BUTTON_STRING.SHIFT_HISTORY}</span>
                        </p>
                      </div>
                    ),
                    key: 2,
                    onClick: () => {
                      navigate('provider-profile', { state: { record, tabNo: '4' } })
                    },
                  },
                  {
                    label: (
                      <div className="cursor-pointer w-full p-1" key="3">
                        <p className="flex items-center gap-2">
                          <ChatsIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{BUTTON_STRING.CHAT}</span>
                        </p>
                      </div>
                    ),
                    key: 3,
                  },
                ],
              }}
              trigger={['click']}
            >
              <CustomBtn isIcon svgIcon={<ThreeDotIcon className="cursor-pointer user-icon" />} text="" className="p-1-imp w-fit h-fit three-dot-icon" type={'default'} />
            </Dropdown>
          </div>
        )
      },
      onCell: (record: any) => ({
        onClick: (event: MouseEvent<HTMLTableRowElement>) => {
          event.stopPropagation()
        },
      }),
    },
  ]

  const handleSearchLicense = (search: string) => {
    if (search !== '') {
      const filteredData = certificateData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setCertificateData(filteredData)
      return
    }
    setCertificateData(certificateOriginData)
  }

  const handleSearchSpeciality = (search: string) => {
    if (search !== '') {
      const filteredData = specialityData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setSpecialityData(filteredData)
      return
    }
    setSpecialityData(specialityOriginData)
  }

  const applyFilter = (value?: { license?: string; speciality?: string; flag?: string }, isReset?: boolean) => {
    setFilters({
      certificate: value?.license ?? '',
      speciality: value?.speciality ?? '',
      flag: value?.flag ?? '',
    })
    if (!isReset) {
      setIsDrawerOpen(false)
    }
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  const handleDrawerOpenChange = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen)
    if (isOpen) {
      form.setFieldsValue({
        license: filters?.certificate ?? '',
        speciality: filters?.speciality ?? '',
        flag: filters?.flag ?? '',
      })
    }
  }

  const handleReset = () => {
    form.resetFields()
    form.setFieldsValue({
      license: '',
      flag: '',
      speciality: '',
    })
    setFilters({
      certificate: '',
      speciality: '',
      flag: '',
    })
  }

  const filterOptions = (
    <div className="h-full">
      <Form
        form={form}
        onFinish={(value) => {
          setFilterApply(true)
          applyFilter(value)
        }}
        className="h-full flex flex-col justify-between filters"
      >
        <div className="flex flex-col gap-2">
          <CustomSelect
            label={COMMON.LICENSE}
            options={certificateData?.map((item: { name: string; id: string }) => ({
              label: item?.name,
              value: item?.id,
            }))}
            value={getValue('license')}
            isSearch
            searchPlaceholder={COMMON.SEARCH_LICENCE}
            name="license"
            searchData={handleSearchLicense}
          />
          <CustomSelect
            label={COMMON.SPECIALITY}
            options={specialityData?.map((item: { name: string; id: string }) => ({
              label: item?.name,
              value: item?.id,
            }))}
            value={getValue('speciality')}
            isSearch
            searchPlaceholder={COMMON.SEARCH_SPECIALITY}
            name="speciality"
            searchData={handleSearchSpeciality}
          />
          <CustomSelect
            label={COMMON.FLAG}
            options={flagsData?.map((item: { type: string; color: string }) => ({
              label: (
                <div className="flag-option-wrapper">
                  <FlagIcon color={item?.color} />
                  <span className="text-neutral-800 para-p3 font-normal">{item?.type}</span>
                </div>
              ),
              value: item?.type,
            }))}
            value={getValue('flag')}
            name="flag"
          />
        </div>
        <div className="flex gap-2">
          <CustomBtn
            onClick={() => {
              handleReset()
              applyFilter({}, true)
              setFilterApply(true)
            }}
            text={BUTTON_STRING.RESET}
            className="bg-primary-50 text-primary-600 border-0 h-49 w-160 reset-btn-hover"
            htmlType="reset"
            type="default"
          />
          <CustomBtn type="primary" text={BUTTON_STRING.APPLY} htmlType="submit" className="h-49 w-160" />
        </div>
      </Form>
    </div>
  )

  return (
    <>
      <CustomTable
        name={PAGE_TITLE_STRING.PROVIDERS}
        column={columns}
        data={listData}
        total={total}
        limit={limit}
        current={current}
        search={search}
        onChange={handleTableChange}
        onChangePage={handleChangePage}
        setModalOpen={handleRowClick}
        isSearch
        onSearch={onSearch}
        searchPlaceholder={COMMON.SEARCH_PROVIDER}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        isFilter
        filterOption={filterOptions}
        filterSelected={filters}
        onDrawerOpenChange={handleDrawerOpenChange}
        showFilterDrawer={isDrawerOpen}
        wrapperClass="table-top-border-radius-0"
        className="row-selected"
      />
      {isFlagAsDnr && (
        <CustomModal
          width={600}
          onCancel={() => {
            setIsFlagAsDnrOpen(false)
          }}
          open={isFlagAsDnr}
          // eslint-disable-next-line
          title={`${PROVIDER_STRING.FLAG_DNR} ${selectedRowData ? selectedRowData?.provider?.first_name : ''}?`}
          footer={null}
          classStrings="hide-closeicon-modal"
          closable={false}
          content={
            <FlagAsDnr
              isModalOpen={setIsFlagAsDnrOpen}
              updateId={selectedRowData?.id as string}
              flagId={selectedFlagId}
              updateList={() => {
                void handleGetProviderList(limit, current, sortTable?.order, sortTable?.field)
              }}
            />
          }
        />
      )}
      {isReqProviderToWork && (
        <CustomModal
          width={1063}
          closeIcon={<CloseIcon />}
          closable={false}
          classStrings="flex justify-center items-center h-full w-full p-0 top-0"
          bodyStyle={{ minHeight: '300px', maxHeight: '85vh', overflow: 'auto', width: '1063px', maxWidth: '90vw' }}
          onCancel={() => {
            setIsOpenReqProvToWork(false)
          }}
          open={isReqProviderToWork}
          // eslint-disable-next-line
          title={`${COMMON.REQUEST} ${selectedRowData ? selectedRowData?.provider?.first_name : ''} ${COMMON.FOR_WORK}`}
          footer={null}
          content={<ReqProviderToWork setIsModalOpen={setIsOpenReqProvToWork} selectedRowData={selectedRowData} facilityCity={facilityCity} facilityState={facilityState} />}
        />
      )}
    </>
  )
}

export default Providers
