import { useEffect, useState } from 'react'
import { Form, message, Switch } from 'antd'
import { commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddEditShiftTimeProps, type IFieldData, type IShiftTimeColumn } from 'types'
import { handleFieldsChange, removeSorting } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import TimePickerField from 'components/common/TimePicker/TimePicker'

import { createNewShiftTime, updateShiftTime } from '../api'

const AddTiming = ({ setIsModalOpen, editId, data, setSortTable }: IAddEditShiftTimeProps) => {
  const dispatch = useAppDispatch()
  const [statusCheck, setStatusCheck] = useState<boolean>(true)
  const [error, setError] = useState({
    name: '',
    start_time: '',
    end_time: '',
    id: '',
  })
  const [startTime, setStartTime] = useState<string | dayjs.Dayjs>('00:00')
  const [endTime, setEndTime] = useState<string | dayjs.Dayjs>('00:00')
  const [form] = Form.useForm()

  useEffect(() => {
    if (data && editId) {
      const checked = data.status === 'active'
      setStatusCheck(checked)
      const startTime = data.start_time || ''
      const endTime = data.end_time || ''
      const formattedData = {
        ...data,
        start_time: dayjs(startTime, 'HH:mm:ss').isValid() ? dayjs(startTime, 'HH:mm:ss') : '00:00',
        end_time: dayjs(endTime, 'HH:mm:ss').isValid() ? dayjs(endTime, 'HH:mm:ss') : '00:00',
      }
      setStartTime(typeof formattedData.start_time === 'string' ? formattedData.start_time : formattedData.start_time.format('hh:mm A'))
      setEndTime(typeof formattedData.end_time === 'string' ? formattedData.end_time : formattedData.end_time.format('hh:mm A'))
      form.setFieldsValue(formattedData)
    }
  }, [data, editId, form])

  useEffect(() => {
    if (startTime || endTime) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      form.setFieldValue('name', `${startTime} - ${endTime}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime])

  const onFinish = (values: IShiftTimeColumn) => {
    const statusValue = statusCheck ? 'active' : 'in_active'
    const updatedValues = {
      ...values,
      status: statusValue,
      start_time: dayjs(values.start_time).format('HH:mm:ss'),
      end_time: dayjs(values.end_time).format('HH:mm:ss'),
    }
    if (editId && data) {
      void handleEditShiftTime(editId, updatedValues)
    } else {
      void handleAddShiftTime(updatedValues)
    }
  }

  const handleAddShiftTime = async (values: IShiftTimeColumn) => {
    dispatch(setGlobalLoader(true))
    const response = await createNewShiftTime(values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      setSortTable && removeSorting(setSortTable)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditShiftTime = async (id: string, values: IShiftTimeColumn) => {
    dispatch(setGlobalLoader(true))
    const response = await updateShiftTime(id, values)
    if (response?.data.statusCode === 1) {
      setIsModalOpen(false)
      setSortTable && removeSorting(setSortTable)
      void message.success(response?.data?.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onChange = (checked: boolean) => {
    setStatusCheck(checked)
  }

  const handleChangeData = (allFields: IFieldData[]) => {
    allFields.forEach((field: IFieldData) => {
      const fieldName = field.name[0]
      const fieldValue = field.value ?? ''
      if (fieldName === 'start_time') {
        setStartTime(dayjs(fieldValue, 'HH:mm').format('hh:mm A') || '00:00')
      }
      if (fieldName === 'end_time') {
        setEndTime(dayjs(fieldValue, 'HH:mm').format('hh:mm A') || '00:00')
      }
    })
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={editId ? t('label.editTime') : t('label.createNewTime')} />
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        onFieldsChange={(allFields: IFieldData[]) => {
          handleChangeData(allFields)
          handleFieldsChange(allFields, setError)
        }}
        className="add-time-content-wrapper"
      >
        <InputField name="name" placeholder={t('placeholder.shiftName')} wrapperClass="w-full" disabled={true} value={getValue('name')} />
        <div className="flex gap-4 items-center aline-self">
          <div className="cursor-pointer w-full ">
            <TimePickerField
              name="start_time"
              format="h:mm A"
              placeholder={t('placeholder.startTime')}
              rules={commonSingleSelectValidation(VALIDATION_STRINGS.START_TIME)}
              error={error?.start_time}
              form={form}
              value={getValue('start_time')}
            />
          </div>
          <div className="cursor-pointer w-full ">
            <TimePickerField
              name="end_time"
              format="h:mm A"
              placeholder={t('placeholder.endTime')}
              rules={commonSingleSelectValidation(VALIDATION_STRINGS.END_TIME)}
              error={error?.end_time}
              form={form}
              value={getValue('end_time')}
            />
          </div>
        </div>
        <div className="flex items-center justify-between hide-label w-full">
          <div className="flex gap-2 toggle-switch">
            <span>{t('label.active')}</span> <Switch checked={statusCheck} onChange={onChange} />
          </div>
          <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} htmlType="submit" type="primary" className="w-120" />
        </div>
      </Form>
    </div>
  )
}

export default AddTiming
