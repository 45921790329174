import { useEffect, useState } from 'react'
import { Checkbox, Form, message } from 'antd'
import { CloseIcon, ModalClose } from 'assets/svgs'
import { checkboxOptions, COMPLIENCE_MANAGER } from 'constants/Constant'
import { commonInputFieldValidation, commonSingleSelectValidation } from 'constants/Validation'
import { VALIDATION_STRINGS } from 'constants/ValidationStrings'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import type { IDocumentModalsProps, IFieldData, ISelectOptionData } from 'types'
import { handleFieldsChange } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import DropDown from 'components/common/Select/DropDown'

import { createDocument, getCertificate, getDocumentById, updateDocument } from '../api'

import '../ComplianceManager.scss'

const AddDocument = ({ handleGetAllCategory, setIsModalOpen, editId, setEditId, setEditDocumentId, categoryId, setCategoryId }: IDocumentModalsProps) => {
  const [form] = Form.useForm()
  const [error, setError] = useState({
    name: '',
    certificate: '',
    documentEssential: '',
    groupCheckbox: '',
    certificate_ids: '',
  })
  const [certificateTag, setCertificateTag] = useState<any[]>([])
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [licenceOption, setLicenceOption] = useState<ISelectOptionData[] | any>([])
  const [singleDocData, setSingleDocData] = useState<any>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    void getCertificateDropDownOpt()
  }, [])

  useEffect(() => {
    if (editId) void getSingleDoc(editId)
  }, [editId, licenceOption])

  useEffect(() => {
    setError((prevError) => ({
      ...prevError,
      certificate_ids: certificateTag?.length > 0 ? '' : prevError.certificate_ids,
    }))
  }, [certificateTag])

  const getCertificateDropDownOpt = async () => {
    const response = await getCertificate()
    if (response?.status === 200) {
      const modifiedLicence = response?.data?.data?.map((item: { id: string; name: string }) => ({
        ...item,
        id: item.id,
        name: item?.name,
      }))

      const allOption = {
        id: 'all',
        name: 'All',
      }
      const optionsWithAll = [allOption, ...modifiedLicence]
      setLicenceOption(optionsWithAll)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
  }

  const getSingleDoc = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getDocumentById(id)
    if (response?.data?.statusCode === 1) {
      const licenceIds: string[] = response?.data?.data?.certificate_ids?.map((id: string) => id)
      if (licenceIds?.length + 1 === licenceOption?.length) {
        setCertificateTag([...licenceIds, 'all'])
      } else {
        setCertificateTag(licenceIds)
      }
      setSingleDocData(response?.data?.data)
      form.setFieldValue('name', response?.data?.data?.name)
      form.setFieldValue('is_required', response?.data?.data?.is_required)

      const checkedOptions: any = checkboxOptions.filter((option) => response?.data?.data?.[option.value] === true).map((option) => option.value) // Get the values of the checked options

      // Set the checkbox group
      form.setFieldValue('groupCheckbox', checkedOptions)
      // setCategoryId(checkedOptions)
      const listData = checkboxOptions.reduce((acc: any, option) => {
        acc[option.value] = checkedOptions.includes(option.value)
        return acc
      }, {})
      setCheckedList(listData)
    }
    dispatch(setGlobalLoader(false))
  }

  const onChange = (checkedValues: string[]) => {
    const listData = checkboxOptions.reduce((acc: any, option) => {
      acc[option.value] = checkedValues.includes(option.value)
      return acc
    }, {})
    setCheckedList(listData)
  }

  const handleCertificateChange = (selectedValue: string[]) => {
    const allIds = licenceOption?.map((item: { id: string }) => item.id)

    if (selectedValue?.includes('all')) {
      setCertificateTag(allIds)
      if (selectedValue?.length < certificateTag?.length) {
        setCertificateTag(selectedValue?.filter((val) => val !== 'all'))
      }
    } else {
      if (certificateTag?.length === allIds?.length) {
        setCertificateTag([])
      } else {
        setCertificateTag(selectedValue)
        if (selectedValue?.length === allIds?.length) {
          setCertificateTag([...allIds, 'all'])
        } else {
          setCertificateTag(selectedValue?.filter((val) => val !== 'all'))
          if (selectedValue?.length === allIds?.length - 1) {
            setCertificateTag(allIds)
          }
        }
      }
    }
  }

  const handleCancel = () => {
    if (setIsModalOpen) setIsModalOpen(false)
  }

  const onFinish = (values: any) => {
    const payload = {
      name: values?.name,
      certificate_ids: certificateTag?.filter((id: string) => id !== 'all'),
      is_required: values?.is_required === undefined ? false : values?.is_required,
      ...checkedList,
      credentials_category: !editId ? categoryId : singleDocData?.credentials_category?.id,
    }

    void handleAddEditDoc(payload)
  }

  const handleAddEditDoc = async (payload: any) => {
    dispatch(setGlobalLoader(true))
    let response
    if (editId) {
      response = await updateDocument(payload, editId)
    } else {
      response = await createDocument(payload)
    }
    if (response?.data?.statusCode === 1) {
      setIsModalOpen(false)
      setCategoryId('')
      setEditDocumentId('')
      void message.success(response?.data?.message)
      handleGetAllCategory()
      setEditId('')
    }
    dispatch(setGlobalLoader(false))
  }

  const handleDeleteTag = (id: number) => {
    const updatedCertificateTag = [...certificateTag]
    updatedCertificateTag.splice(id, 1)
    const filterAllKeyData = updatedCertificateTag?.filter((it) => it !== 'all')
    setCertificateTag([...filterAllKeyData])
  }

  const getValue = (fieldName: string) => {
    return form.getFieldValue(`${fieldName}`)
  }

  return (
    <div className="main-wrapper">
      <div className="header-wrapper-certi">
        <HeadingText classString="text-neutral-800 desktop-head-h5 font-bold" text={editId ? t('label.editDocument') : t('label.addNewDocument')} />
        <div className="p-2 border-stroke flex bg-white rounded-2 common-bs">
          <ModalClose className="cursor-pointer" onClick={handleCancel} />
        </div>
      </div>
      <div className="flex flex-col items-start gap-8">
        <Form
          requiredMark={false}
          onFinish={onFinish}
          onFieldsChange={(allFields: IFieldData[]) => {
            handleFieldsChange(allFields, setError)
          }}
          form={form}
          className="form-certi-wrapper w-full"
        >
          <>
            <div className="flex flex-col items-start gap-5  w-full add-document-form">
              <div className="flex flex-col items-start w-full document-form">
                <div className="flex flex-col w-full">
                  <InputField
                    rules={commonInputFieldValidation(VALIDATION_STRINGS.DOC_NAME)}
                    placeholder={t('placeholder.documentName')}
                    name="name"
                    error={error.name}
                    value={getValue('name')}
                  />
                </div>

                <div className="w-full mb-6">
                  <DropDown
                    name="certificate_ids"
                    handleChange={handleCertificateChange}
                    Options={licenceOption}
                    placeholder={t('placeholder.certification')}
                    updatedTag={certificateTag}
                    rules={commonSingleSelectValidation(VALIDATION_STRINGS.LICENCE)}
                    error={error?.certificate}
                    value={getValue('certificate_ids')}
                  />
                  {error?.certificate_ids && <p className="error-container">{error.certificate_ids}</p>}
                </div>
                {/* show selected option as tag */}
                {certificateTag?.length > 0 && (
                  <div className="flex flex-col gap-4 w-full mb-6">
                    {certificateTag?.length > 0 && <HeadingText text={COMPLIENCE_MANAGER.CERTIFICATE} classString="para-p3 text-neutral-400" />}
                    <div className="flex gap-4 w-full flex-wrap">
                      {certificateTag
                        ?.filter((id: string) => id !== 'all')
                        ?.map((tagId: string, index: number) => {
                          const option = licenceOption.find((item: { id: string }) => item.id === tagId)
                          return (
                            <div key={`${tagId}+${index}`} className="para-p2 font-medium text-neutral-800 selected-option-tag flex justify-center items-center">
                              {option?.name}
                              <span
                                className="flex items-center cursor-pointer"
                                onClick={() => {
                                  handleDeleteTag(index)
                                }}
                              >
                                {<CloseIcon />}
                              </span>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                )}
                <div className="w-full flex gap- 2 flex-col mb-6">
                  <Form.Item name="is_required" valuePropName="checked" className="p-0 m-0" rootClassName="document-checkbox">
                    <Checkbox className="flex gap-2 para-p2 font-normal text-neutral-800">
                      {t('placeholder.documentEssential')}
                      <p className="para-p3 font-normal text-neutral-500">{t('label.instruction')}</p>
                    </Checkbox>
                  </Form.Item>

                  {error?.documentEssential && <p className="error-container">{error.documentEssential}</p>}
                </div>
                <div className="w-full flex flex-col gap-2">
                  <HeadingText classString="text-neutral-800 para-p2 font-normal" text={t('label.selectRequiredFields')} />
                  <Form.Item name="groupCheckbox" rootClassName="checkbox-group" rules={commonSingleSelectValidation(VALIDATION_STRINGS.REQUIRED_FIELD)}>
                    <Checkbox.Group options={checkboxOptions} value={checkedList} onChange={onChange} />
                  </Form.Item>
                  {error?.groupCheckbox && <p className="error-container">{error.groupCheckbox}</p>}
                </div>
              </div>
            </div>
            <div className="flex items-start gap-4 mt-4 w-full document-button">
              <CustomBtn
                text={t('button.cancel')}
                type="default"
                onClick={() => {
                  if (setIsModalOpen) setIsModalOpen(false)
                  setEditDocumentId('')
                }}
                className="w-full flex aline-self rounded-3"
              />
              <CustomBtn text={editId ? t('button.updateBtn') : t('button.save')} type="primary" htmlType="submit" className="w-full flex aline-self rounded-3" />
            </div>
          </>
        </Form>
      </div>
    </div>
  )
}

export default AddDocument
