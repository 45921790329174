import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Dropdown, Image, message, Tabs, type TabsProps } from 'antd'
import { profile } from 'assets/imgs'
import { BlackFlag, ChatProvider, CheckIcon, CloseIcon, FlagIcon, ProviderProfileBadge } from 'assets/svgs'
import { BUTTON_STRING, COMMON, PAGE_TITLE_STRING, PROVIDER_STRING } from 'constants/Constant'
import { flagsData } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import type { IShiftProvider } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'

import Credential from './components/Credential'
import Detail from './components/Detail'
import Evaluation from './components/Evaluation'
import Schedule from './components/Schedule'
import ShiftHistory from './components/ShiftHistory'
import AddEvaluation from './modal/AddEvaluation'
import FlagAsDnr from './modal/FlagAsDnr'
import ReqProviderToWork from './modal/RequestProviderToWork'
import { GET_PROVIDER_SUMMARY, UPDATE_PROVIDER_FLAG } from './api'

import './Provider.scss'

const ProviderProfile = () => {
  const [activeTab, setActiveTab] = useState('1')
  const [isAddEvaluation, setIsAddEvaluation] = useState<boolean>(false)
  const [isReqProviderToWork, setIsOpenReqProvToWork] = useState<boolean>(false)
  const [profileData, setProfileData] = useState({
    profileImg: '',
    name: '',
    first_name: '',
    email: '',
    mobile_no: '',
    country_code: '',
    certificate: '',
    certificate_bgcolor: '',
    certificate_textcolor: '',
    attendance: '100%',
    hoursWork: '',
    onTimeRate: '',
    shiftCompleted: '',
    showRate: '',
    city: '',
    state: '',
  })
  const [selectedRowData, setSelectedRowData] = useState<IShiftProvider | null>(null)
  const [isFlagAsDnr, setIsFlagAsDnrOpen] = useState(false)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const { record, tabNo, facilityCity, facilityState } = location.state || {}
  const [flagColor, setFlagColor] = useState<string>(record?.flag === 'preferred' ? '#359766' : record?.flag === 'dnr' ? '#d1293d' : '')
  const [selectedFlagId, setSelectedFlagId] = useState(record?.flag?.id ?? '')
  const [dnrFlagId, setDnrFlagId] = useState('')
  // const [flags, setFlags] = useState<IFlags[]>([])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Details',
      children: <Detail profileDetailId={record?.provider?.id ?? ''} tabNo={activeTab} />,
    },
    {
      key: '2',
      label: 'Schedule',
      children: <Schedule providerId={record?.provider?.id ?? ''} tabNo={activeTab} />,
    },
    {
      key: '3',
      label: 'Credentials',
      children: <Credential providerId={record?.provider?.id ?? ''} tabNo={activeTab} />,
    },
    {
      key: '4',
      label: 'Shifts History',
      children: <ShiftHistory providerId={record?.provider?.id ?? ''} tabNo={activeTab} />,
    },
    {
      key: '5',
      label: 'Evaluations',
      children: <Evaluation />,
    },
  ]

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: PAGE_TITLE_STRING.PROVIDER_PROFILE,
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    if (record?.provider?.id) {
      if (record?.provider?.id) void getProfileData(record?.provider?.id)
    }
    // eslint-disable-next-line
  }, [record?.provider?.id])

  const getProfileData = async (profileId: any) => {
    dispatch(setGlobalLoader(true))

    const response = await GET_PROVIDER_SUMMARY(profileId)
    if (response?.status === 200) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { first_name, last_name, profile_image, base_url, email, mobile_no, certificate, address, total_worked, shifts_completed, show_rate, on_time_rate, country_code } =
        // eslint-disable-next-line no-unsafe-optional-chaining
        response?.data?.data
      setProfileData({
        profileImg: profile_image ? `${base_url as string}${profile_image as string}` : profile,
        name: `${String(first_name)} ${String(last_name)}`,
        first_name: `${String(first_name)}`,
        email: email ?? '',
        mobile_no: mobile_no ?? '',
        country_code: country_code ?? '',
        certificate: certificate?.name ?? '',
        certificate_bgcolor: certificate?.background_color ?? '',
        certificate_textcolor: certificate?.text_color ?? '',
        attendance: '0%',
        hoursWork: total_worked ?? 0,
        onTimeRate: on_time_rate ?? 0,
        shiftCompleted: shifts_completed ?? 0,
        showRate: show_rate ?? 0,
        city: address?.city ?? '',
        state: address?.state ?? '',
      })
    }
    dispatch(setGlobalLoader(false))
  }

  // const handleGetAllFlag = async () => {
  //   dispatch(setGlobalLoader(true))

  //   const response = await GET_ALL_FLAG()
  //   if (response?.status === 200) {
  //     setFlags(response?.data?.data)
  //   }

  //   dispatch(setGlobalLoader(false))
  // }

  // useEffect(() => {
  //   void handleGetAllFlag()
  //   // eslint-disable-next-line
  // }, [])

  const updateFlag = async (flagId: string, id: string) => {
    dispatch(setGlobalLoader(true))

    const response = await UPDATE_PROVIDER_FLAG(id, { flag: flagId })
    if (response?.status === 200) {
      void message.success(response?.data?.message)
      setFlagColor('#359766')
      setSelectedFlagId(flagId)
    }
    dispatch(setGlobalLoader(false))
  }

  const onChange = (key: string) => {
    setActiveTab(key)
  }

  const handleEvaluationClick = () => {
    setIsAddEvaluation(true)
  }

  const handleOpenReqToWorkClick = () => {
    setSelectedRowData(record as IShiftProvider)
    setIsOpenReqProvToWork(true)
  }

  return (
    <div className="provider-profile-container flex justify-start items-start rounded-4 w-full">
      <div className="profile-main-wrapper flex flex-col items-center gap-6">
        <div className="profile-pick-container flex flex-col items-center gap-5">
          <div className="img-wrapper">
            <Image src={profileData?.profileImg ? profileData?.profileImg : profile} className="profile-img" preview={false} />
            <div className="profile-badge">
              <ProviderProfileBadge />
            </div>
          </div>
        </div>
        <div className="profile-info-wrapper flex flex-col items-center gap-2">
          {profileData?.name && <p className="mobile-heading-h6 font-medium text-neutral-800">{formatTitle(profileData?.name)}</p>}
          {profileData?.certificate && (
            <div className="badge-info flex justify-center items-start gap-2">
              <p
                className="profile-certificate-text flex justify-center items-center gap-2"
                style={{
                  background: profileData?.certificate_bgcolor || 'none',
                  color: profileData?.certificate_textcolor ?? '',
                }}
              >
                {profileData?.certificate}
              </p>
            </div>
          )}
        </div>

        <div className="emp-detail-wrapper flex items-start justify-start gap-2 flex-wrap">
          <div className="attendance-box detail-box">
            <p className="text-20 font-semi-bold text-neutral-800">{profileData?.attendance}</p>
            <p className="text-neutral-600 para-p3 font-normal">{PROVIDER_STRING.ATTENDANCE_SCORE}</p>
          </div>
          <div className="grid grid-col-2 gap-2 w-full">
            <div className="hours-box">
              <p className="text-20 font-semi-bold text-neutral-800">{profileData?.hoursWork}</p>
              <p className="text-neutral-600 para-p3 font-normal text-center">{PROVIDER_STRING.HOUSE_WORKED}</p>
            </div>
            <div className="hours-box">
              <p className="text-20 font-semi-bold text-neutral-800">{profileData?.onTimeRate}</p>
              <p className="text-neutral-600 para-p3 font-normal text-center">{PROVIDER_STRING.ON_TIME_RATE}</p>
            </div>
            <div className="hours-box shift-box">
              <p className="text-20 font-semi-bold text-neutral-800">{profileData?.shiftCompleted}</p>
              <p className="text-neutral-600 para-p3 font-normal text-center">{PROVIDER_STRING.SHIFT_COMPLETED}</p>
            </div>
            <div className="hours-box">
              <p className="text-20 font-semi-bold text-neutral-800">{profileData?.showRate}</p>
              <p className="text-neutral-600 para-p3 font-normal text-center">{PROVIDER_STRING.SHOW_RATE}</p>
            </div>
          </div>
        </div>
        <div className="location-box">
          {(profileData?.city || profileData?.state) && (
            <div className="location-wrapper">
              <p className="text-neutral-500 para-p3 font-normal">{PROVIDER_STRING.LOCATION}</p>
              <p className="text-neutral-800 para-p2 font-normal">
                {profileData?.city}
                {profileData?.city && profileData?.state ? ', ' : ''}
                {profileData?.state}
              </p>
            </div>
          )}
          <div className="mobile-box">
            <p className="text-neutral-500 para-p3 font-normal">{PROVIDER_STRING.MOBILE_EMAIL}</p>
            <div className="contact-wrapper">
              {profileData?.mobile_no && (
                <div className="mobile-wrapper">
                  <p className="text-neutral-800 para-p2 font-normal">
                    {`${profileData?.country_code ?? ''} `}
                    {profileData?.mobile_no}
                  </p>
                  <CheckIcon />
                </div>
              )}
              {profileData?.email && (
                <div className="mobile-wrapper">
                  <p className="text-neutral-800 para-p2 font-normal">{profileData?.email}</p>
                  <CheckIcon />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="tab-wrapper">
        <Tabs defaultActiveKey={tabNo || '1'} items={items} onChange={onChange} rootClassName="provider-tab" />
        <div className="btn-wrapper">
          <Dropdown
            rootClassName={`p-4 action-dropdown`}
            menu={{
              items: flagsData?.map((flag, index) => ({
                label: (
                  <div className={`flag-option-wrapper ${selectedFlagId === flag?.type ? 'opacity-30 cursor-disable' : ''}`}>
                    <BlackFlag color={flag?.color} />
                    <span className="text-neutral-800 para-p3 font-normal">{flag?.name}</span>
                  </div>
                ),
                key: index,
                onClick: (event) => {
                  event.domEvent.stopPropagation()
                  if (selectedFlagId !== flag?.type) {
                    setSelectedRowData(record as IShiftProvider)
                    if (flag?.name === 'DNR') {
                      setIsFlagAsDnrOpen(true)
                      setDnrFlagId(flag?.type)
                    } else {
                      void updateFlag(flag?.type, record?.id)
                    }
                  }
                },
              })),
            }}
            trigger={['click']}
          >
            <div className={`flex items-center cursor-pointer justify-center h-36 w-36 rounded-2 ${!flagColor ? 'border-neutral-200' : ''}`}>
              <FlagIcon color={flagColor || '#494A4C'} />
            </div>
          </Dropdown>
          <div className="h-36 w-36 rounded-2 cursor-pointer flex justify-center items-center border-neutral-200">
            <ChatProvider />
          </div>
          {activeTab === '5' ? (
            <CustomBtn type="primary" text={BUTTON_STRING.ADD_EVALUATION} className="rounded-2 border-0 btn-text para-p3 h-38" onClick={handleEvaluationClick} />
          ) : (
            <CustomBtn type="primary" text={BUTTON_STRING.REQ_TO_WORK} className="rounded-2 border-0 btn-text para-p3 h-38" onClick={handleOpenReqToWorkClick} />
          )}
        </div>
      </div>
      {isAddEvaluation && (
        <CustomModal
          width={1063}
          closeIcon={<CloseIcon />}
          onCancel={() => {
            setIsAddEvaluation(false)
          }}
          open={isAddEvaluation}
          title={PROVIDER_STRING.NEW_EVALUATION}
          footer={null}
          closable={false}
          content={<AddEvaluation setIsModalOpen={setIsAddEvaluation} />}
        />
      )}
      {isReqProviderToWork && (
        <CustomModal
          width={1063}
          classStrings="flex justify-center items-center h-full w-full p-0 top-0"
          bodyStyle={{ minHeight: '300px', maxHeight: '85vh', overflow: 'auto', width: '1063px', maxWidth: '90vw' }}
          closeIcon={<CloseIcon />}
          onCancel={() => {
            setIsOpenReqProvToWork(false)
          }}
          open={isReqProviderToWork}
          title={`${String(COMMON.REQUEST)} ${profileData?.first_name ? profileData?.first_name : ''} ${String(COMMON.FOR_WORK)}`}
          footer={null}
          closable={false}
          content={<ReqProviderToWork setIsModalOpen={setIsOpenReqProvToWork} selectedRowData={selectedRowData} facilityCity={facilityCity} facilityState={facilityState} />}
        />
      )}
      {isFlagAsDnr && (
        <CustomModal
          width={600}
          onCancel={() => {
            setIsFlagAsDnrOpen(false)
          }}
          open={isFlagAsDnr}
          // eslint-disable-next-line
          title={`${PROVIDER_STRING.FLAG_DNR} ${selectedRowData ? selectedRowData?.provider?.first_name : ''}?`}
          footer={null}
          classStrings="hide-closeicon-modal"
          closable={false}
          content={
            <FlagAsDnr
              isModalOpen={setIsFlagAsDnrOpen}
              updateId={selectedRowData?.id as string}
              flagId={dnrFlagId}
              updateList={() => {
                const flagData = flagsData?.find((data) => data?.type === dnrFlagId)
                setFlagColor('#d1293d')
                setSelectedFlagId(flagData?.type)
              }}
            />
          }
        />
      )}
    </div>
  )
}

export default ProviderProfile
