import React from 'react'

const FlagColorSelect = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <path
        d="M14.72 9.29L10.43 13.59L8.78 11.94C8.69036 11.8353 8.58004 11.7503 8.45597 11.6903C8.33191 11.6303 8.19678 11.5965 8.05906 11.5912C7.92134 11.5859 7.78401 11.6091 7.65568 11.6594C7.52736 11.7096 7.41081 11.7859 7.31335 11.8833C7.2159 11.9808 7.13964 12.0974 7.08937 12.2257C7.03909 12.354 7.01589 12.4913 7.02121 12.6291C7.02653 12.7668 7.06026 12.9019 7.12028 13.026C7.1803 13.15 7.26532 13.2604 7.37 13.35L9.72 15.71C9.81344 15.8027 9.92426 15.876 10.0461 15.9258C10.1679 15.9755 10.2984 16.0008 10.43 16C10.6923 15.9989 10.9437 15.8947 11.13 15.71L16.13 10.71C16.2237 10.617 16.2981 10.5064 16.3489 10.3846C16.3997 10.2627 16.4258 10.132 16.4258 10C16.4258 9.86799 16.3997 9.73728 16.3489 9.61542C16.2981 9.49356 16.2237 9.38296 16.13 9.29C15.9426 9.10375 15.6892 8.99921 15.425 8.99921C15.1608 8.99921 14.9074 9.10375 14.72 9.29ZM12 2.5C10.0222 2.5 8.08879 3.08649 6.4443 4.1853C4.79981 5.28412 3.51809 6.8459 2.76121 8.67317C2.00433 10.5004 1.8063 12.5111 2.19215 14.4509C2.578 16.3907 3.53041 18.1725 4.92894 19.5711C6.32746 20.9696 8.10929 21.922 10.0491 22.3079C11.9889 22.6937 13.9996 22.4957 15.8268 21.7388C17.6541 20.9819 19.2159 19.7002 20.3147 18.0557C21.4135 16.4112 22 14.4778 22 12.5C22 11.1868 21.7413 9.88642 21.2388 8.67317C20.7363 7.45991 19.9997 6.35752 19.0711 5.42893C18.1425 4.50035 17.0401 3.76375 15.8268 3.2612C14.6136 2.75866 13.3132 2.5 12 2.5ZM12 20.5C10.4178 20.5 8.87104 20.0308 7.55544 19.1518C6.23985 18.2727 5.21447 17.0233 4.60897 15.5615C4.00347 14.0997 3.84504 12.4911 4.15372 10.9393C4.4624 9.38743 5.22433 7.96197 6.34315 6.84315C7.46197 5.72433 8.88743 4.9624 10.4393 4.65372C11.9911 4.34504 13.5997 4.50346 15.0615 5.10896C16.5233 5.71447 17.7727 6.73984 18.6518 8.05544C19.5308 9.37103 20 10.9177 20 12.5C20 14.6217 19.1572 16.6566 17.6569 18.1569C16.1566 19.6571 14.1217 20.5 12 20.5Z"
        fill="white"
      />
    </svg>
  )
}

export default FlagColorSelect
