import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'

export const GET_ALL_PROVIDER_LIST = async (limit = 10, current = 1, search = '', order = '', field = '', facilityId = '', query = '') => {
  try {
    const offset = (current - 1) * limit
    const url = `/facility-provider/all/${facilityId}?limit=${limit}&offset=${offset}${search && `&search=${search}`}${order && field && `&order[${field}]=${order}`}${query && `&${query}`}`

    const response = await http.get(url)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const UPDATE_FLAG_AS_DNR = async (id: string, payload: any) => {
  try {
    const url = `/facility-provider/dnr/${id}`
    const response = await http.patch(url, payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const UPDATE_PROVIDER_FLAG = async (id: string, payload: any) => {
  try {
    const url = `/facility-provider/update/${id}`
    const response = await http.patch(url, payload)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_PROVIDER_SUMMARY = async (id: string) => {
  try {
    const url = `/facility-provider/summary/${id}`
    const response = await http.get(url)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_PROVIDER_DETAILS = async (id: string) => {
  try {
    const url = `/facility-provider/details/${id}`
    const response = await http.get(url)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_SCHEDULED_SHIFT_CALENDAR = async (id: string, date: string) => {
  try {
    const url = `/facility-provider/calendar/${id}?date=${date}`
    const response = await http.get(url)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_SHIFT_HISTORY = async (id: string, current = 1, limit = 10, order = '', field = '') => {
  try {
    const offset = (current - 1) * limit
    const url = `/facility-provider/shift/${id}?limit=${limit}&offset=${offset}${order && field && `&order[${field}]=${order}`}`
    const response = await http.get(url)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_PROVIDER_CREDENTIAL = async (id: string, order = '', field = '') => {
  try {
    const url = `/facility-provider/credentials/${id}${order && field && `?order[${field}]=${order}`}`
    const response = await http.get(url)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_ALL_FLAG = async () => {
  try {
    const url = `/dropdown/flag`
    const response = await http.get(url)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const requestToWork = async (id: string, payload: any) => {
  try {
    const response = http.post(`/shift/request-work/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const GET_ALL_DNR_REASON = async (query: string) => {
  try {
    const url = `/dropdown/dnr-reason?${query}`
    const response = await http.get(url)
    return response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}
