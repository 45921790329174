import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Form, Input, message, Modal } from 'antd'
import { CloseIcon, DeleteIcon, DraggableDots, EditPenIcon } from 'assets/svgs'
import { type ISkillChecklistQuestion } from 'types'
import { validateQuestionsForSubModule } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import HeadingText from 'components/common/HeadingText'
import FloatingLabelSelect from 'components/common/Select/FloatingLabelSelect'

import '../SkillChecklist.scss'

const AddSubModuleQue = ({ isModalOpen, setIsModalOpen, modalName, subModData, onSave, setDeletedQueId }: any) => {
  const subData = subModData.subModule
  const [changeModalName, setChangeModalName] = useState(modalName)
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [questions, setQuestions] = useState<ISkillChecklistQuestion[]>([])
  const [editingIndex, setEditingIndex] = useState<number | null>(null)
  const [selectedValue, setSelectedValue] = useState<string>()
  const [option, setOption] = useState<Array<{ value: ''; label: '' }>>([])

  useEffect(() => {
    if (subModData) {
      const data = subModData?.subModule
      setQuestions(data[subModData.subModuleIndex]?.skill_checklist_question)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (subData) {
      const options = subData?.map((eachCertiData: { name: string }, index: number) => ({
        value: index,
        label: eachCertiData.name,
      }))
      setOption(options)
    }
  }, [subData])

  const handleEditClick = (index: number) => {
    setEditingIndex(index)
  }

  const handleInputChange = (index: number | null, value: string) => {
    const updatedQuestions = questions.map((question, i) => (i === index ? { ...question, question: value } : question))
    setQuestions(updatedQuestions)
    onSave(subModData.moduleIndex, selectedValue ?? subModData.subModuleIndex, updatedQuestions)
  }

  const handleSave = () => {
    setEditingIndex(null)
  }

  const handleAddQuestion = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Tab' || e.key === 'Enter') {
      const { value } = e.target as HTMLInputElement
      const data = {
        question: value,
        order: questions.length + 1,
      }
      setQuestions((prev) => [...prev, data])
      form.setFieldValue('question', '')
      saveOption(data)
    }
  }

  const saveOption = (data: ISkillChecklistQuestion) => {
    const data1 = [...questions, data]
    onSave(subModData.moduleIndex, selectedValue ?? subModData.subModuleIndex, data1)
  }

  const handleSelectChange = (value: string | string[]) => {
    value = value as string
    const selectedName = subData[value]?.name
    setSelectedValue(value)
    setChangeModalName(selectedName)
    setQuestions(subData[value]?.skill_checklist_question)
  }

  const handleQueDelete = (index: number, quesId?: string) => {
    void deleteQuestion(index, quesId)
  }

  const deleteQuestion = async (questionIndex: number, quesId?: string) => {
    const updatedQuestions = [...questions]
    updatedQuestions.splice(questionIndex, 1)
    onSave(subModData.moduleIndex, selectedValue ?? subModData.subModuleIndex, updatedQuestions)
    setQuestions(updatedQuestions)
    if (quesId) {
      setDeletedQueId((prev: string[]) => [...prev, quesId])
    }
  }

  const onFinish = () => {
    const validation = validateQuestionsForSubModule(questions)
    if (!validation.isValid) {
      void message.error(validation.error)
      return
    }
    onSave(subModData.moduleIndex, selectedValue ?? subModData.subModuleIndex, questions)
    setIsModalOpen(false)
  }

  const handleQueDragStart = (e: React.DragEvent<HTMLDivElement>, index: number) => {
    e.dataTransfer.setData('index', index.toString())
  }

  const handleQueDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
  }

  const handleQueDrop = (e: React.DragEvent<HTMLDivElement>, targetQuestionIndex: number) => {
    e.preventDefault()
    const sourceQuestionIndex = parseInt(e.dataTransfer.getData('index'), 10)
    const updatedQuestions = [...questions]
    const [draggedQuestion] = updatedQuestions.splice(sourceQuestionIndex, 1)
    updatedQuestions.splice(targetQuestionIndex, 0, draggedQuestion)
    updatedQuestions.forEach((question, index) => {
      question.order = index + 1
    })
    onSave(subModData.moduleIndex, selectedValue ?? subModData.subModuleIndex, updatedQuestions)
    setQuestions(updatedQuestions)
  }

  return (
    <Modal
      className="sub-module-modal"
      open={isModalOpen}
      centered
      footer={null}
      closable={false}
      maskClosable
      onCancel={() => {
        setIsModalOpen(false)
      }}
    >
      <div className="wrapper-connect-modal flex flex-col">
        <div className="header-modal flex gap-6 justify-between items-center">
          <HeadingText classString="text-neutral-800 desktop-head-h5 font-semi-bold" text={changeModalName} />
          <Button
            icon={<CloseIcon />}
            className="modal-back-arrow"
            onClick={() => {
              setIsModalOpen(false)
            }}
          />
        </div>

        <Form className="flex flex-col p-8 add-ques-form" form={form}>
          <FloatingLabelSelect name="certificate" label={t('placeholder.selectSubMod')} options={option} value={changeModalName} onChange={handleSelectChange} />

          <div className="flex flex-col gap-6">
            {questions?.map((data: ISkillChecklistQuestion, index: number) => (
              <div
                key={index}
                className="flex gap-1rem items-center sub-module-que"
                draggable
                onDragStart={(e) => {
                  handleQueDragStart(e, index)
                }}
                onDragOver={handleQueDragOver}
                onDrop={(e) => {
                  handleQueDrop(e, index)
                }}
              >
                <div className="flex items-center sub-module-dragicon">
                  <DraggableDots />
                </div>
                <div className="flex items-center justify-between w-full gap-8">
                  {editingIndex === index ? (
                    <Input
                      variant="borderless"
                      value={data?.question}
                      placeholder={t('placeholder.addQue')}
                      className="mobile-heading-h5 font-medium text-neutral-800 p-0 w-full"
                      onChange={(e) => {
                        handleInputChange(index, e.target.value)
                      }}
                      onBlur={handleSave}
                    />
                  ) : (
                    <div className="flex gap-1rem">
                      <span className="mobile-heading-h5 font-medium">{`${index + 1}.`}</span>
                      <HeadingText classString="mobile-heading-h5 font-medium" text={data?.question ?? ''} />
                    </div>
                  )}
                  <div className="flex gap-1rem items-center sub-module-editicon">
                    <div
                      className="flex items-center"
                      onClick={() => {
                        handleQueDelete(index, data?.id)
                      }}
                    >
                      <DeleteIcon className="#919195" />
                    </div>
                    <div
                      className="flex items-center"
                      onClick={() => {
                        handleEditClick(index)
                      }}
                    >
                      <EditPenIcon className="#919195" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex gap-1rem items-center">
              <div className="invisible">
                <DraggableDots />
              </div>
              <div className="w-full">
                <Form.Item name="question" className="dark-placeholder">
                  <Input
                    variant="borderless"
                    placeholder={t('placeholder.addQue')}
                    className="mobile-heading-h5 font-medium text-neutral-800 p-0 w-full"
                    onKeyDown={handleAddQuestion}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
        <div className="footer-modal flex justify-end">
          <CustomBtn text={t('button.save')} htmlType="submit" type="primary" className="w-160 rounded-3" onClick={onFinish} />
        </div>
      </div>
    </Modal>
  )
}

export default AddSubModuleQue
