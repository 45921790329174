import React from 'react'

const SelectedThemeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
      <path
        d="M24.645 8.16406H19.7234C17.0343 8.16406 14.5861 9.1919 12.75 10.8784C14.8057 12.7651 16.0939 15.4717 16.0939 18.4814C16.0939 16.4782 17.7183 14.8538 19.7215 14.8538H24.6431C25.1172 14.8538 25.5 14.471 25.5 13.9969V9.02287C25.5 8.54877 25.1172 8.16601 24.6431 8.16601L24.645 8.16406Z"
        fill="white"
      />
      <path
        d="M12.7519 10.8706C10.9158 9.18603 8.46759 8.15625 5.77848 8.15625H0.856858C0.382766 8.15625 0 8.53902 0 9.01311V13.9872C0 14.4613 0.382766 14.8441 0.856858 14.8441H5.77848C7.78171 14.8441 9.40606 16.4684 9.40606 18.4716C9.40606 15.4619 10.6943 12.7553 12.75 10.8687L12.7519 10.8706Z"
        fill="white"
      />
      <path
        d="M12.7531 10.875C10.6974 12.7617 9.40918 15.4683 9.40918 18.478V23.1431C9.40918 23.3802 9.50438 23.5939 9.65982 23.7493C9.69868 23.7882 9.74143 23.8232 9.78612 23.8542C9.92213 23.9475 10.0873 24 10.266 24H15.2401C15.4169 24 15.5821 23.9456 15.72 23.8542C15.7647 23.8232 15.8075 23.7882 15.8463 23.7493C16.0018 23.5939 16.097 23.3802 16.097 23.1431V18.478C16.097 15.4683 14.8088 12.7617 12.7531 10.875Z"
        fill="white"
      />
      <path
        d="M12.7532 0C10.8976 0 9.22079 0.919036 8.01808 2.40154C7.91121 2.53367 7.88789 2.73185 7.95784 2.89701L9.19941 5.48703C9.26742 5.6483 9.4112 5.73962 9.55887 5.72019C10.6081 5.5803 11.6748 5.50647 12.7551 5.50647C13.8354 5.50647 14.9021 5.5803 15.9513 5.72019C16.099 5.73962 16.2428 5.6483 16.3108 5.48703L17.5524 2.89701C17.6223 2.7338 17.5971 2.53367 17.4921 2.40154C16.2894 0.920979 14.6126 0 12.7551 0H12.7532Z"
        fill="white"
      />
    </svg>
  )
}

export default SelectedThemeIcon
