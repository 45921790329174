import React, { type ChangeEventHandler, useEffect, useState } from 'react'
import { Drawer, Input } from 'antd'
import { FilterIcon, SearchIcon } from 'assets/svgs'
import { t } from 'i18next'

import CustomBtn from '../CustomBtn'

import '../Table/Table.scss'

const { Search } = Input

interface ICustomTableProps {
  isSearch?: boolean
  search?: string
  tableButton?: any
  isWithoutTable?: boolean
  onSearch?: (value: string) => void
  isFilter?: boolean
  handleApplyFilter?: () => void
  handleResetFilter?: () => void
  filterSelected?: Record<string, string | number | boolean | null | undefined | any[]> | any
  filterOption?: any
  onSearchChange?: ChangeEventHandler<HTMLInputElement>
  searchPlaceholder?: string
  onDrawerOpenChange?: (isOpen: boolean) => void
  showFilterDrawer?: boolean
}

const CustomTableHeader = ({
  isSearch,
  isWithoutTable,
  searchPlaceholder,
  onSearch,
  onSearchChange,
  isFilter,
  filterOption,
  handleResetFilter,
  handleApplyFilter,
  tableButton,
  filterSelected,
  onDrawerOpenChange,
  showFilterDrawer,
}: ICustomTableProps) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (onDrawerOpenChange) {
      onDrawerOpenChange(open)
    }
  }, [open])

  useEffect(() => {
    if (showFilterDrawer === false) {
      setOpen(false)
    }
  }, [showFilterDrawer])

  useEffect(() => {
    const maskElement = document.getElementsByClassName('ant-drawer-mask')[0]
    if (maskElement) {
      const handleMaskClick = () => {
        handleResetFilter?.()
      }
      maskElement.addEventListener('click', handleMaskClick)
      return () => {
        maskElement.removeEventListener('click', handleMaskClick)
      }
    }
  }, [open])

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const hasValue = filterSelected
    ? Object.values(filterSelected).some((value) => {
        if (Array.isArray(value)) {
          return value.length > 0
        }
        return value !== undefined && value !== null && value !== ''
      })
    : false

  return (
    <div className={`common-table-wrapper table-header-border ${isSearch ? 'border-stroke' : ''}`}>
      {isSearch && (
        <div className={`filter-wrapper ${isWithoutTable ? 'without-table border-0 ' : ''}`}>
          <div className="search-container">
            <Search
              placeholder={searchPlaceholder}
              prefix={<SearchIcon />}
              onSearch={onSearch}
              allowClear
              className="custom-searchbar table-searchBar-width"
              onChange={onSearchChange}
            />
            {isFilter && (
              <>
                <CustomBtn
                  isIcon
                  svgIcon={<FilterIcon hideRedDot={hasValue} />}
                  onClick={showDrawer}
                  type="default"
                  className="flex flex-col justify-center items-center self-stretch rounded-3 bg-neutral-50 box-shadow filter-btn"
                />
                <Drawer destroyOnClose title={t('button.filter')} onClose={onClose} open={open} className="table-filter-drawer">
                  {filterOption}
                </Drawer>
              </>
            )}
          </div>
          {tableButton}
        </div>
      )}
    </div>
  )
}

export default CustomTableHeader
