import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Divider, Form, Select } from 'antd'
import { DownIcon } from 'assets/svgs'
import { formatTitle } from 'utils/Helper'

interface IOption {
  id?: string | JSX.Element
  name: string
  htmlContent?: string
}

interface IOptionGroup {
  label: string
  title: string
  options: IOption[]
}

const isOptionGroup = (option: IOption | IOptionGroup): option is IOptionGroup => {
  return (option as IOptionGroup).options !== undefined
}

const DropDown = ({ Options, error, placeholder, handleChange, value, rules, name, updatedTag, isIcon, icon, mainClass }: any) => {
  useTranslation()
  const selectRef = useRef<HTMLDivElement>(null)
  const [selected, setSelected] = useState<any[]>(value || [])

  useEffect(() => {
    if (updatedTag) {
      setSelected(updatedTag)
    }
  }, [updatedTag])

  useEffect(() => {
    setSelected(value || [])
  }, [value])

  const handleSelectChange = (selectedValues: any) => {
    // Update the state to reflect selected values
    setSelected(selectedValues)
    handleChange(selectedValues)
  }

  return (
    <>
      <div ref={selectRef} className={`dropdown-menu ${mainClass as string} ${error ? 'dropdown-error' : ''}`}>
        <Form.Item rules={selected.length === 0 ? rules : []} name={name} initialValue={value}>
          <Select
            showSearch={false}
            placeholder={
              <span className="flex g-10 items-center text-neutral-800 font-normal items-center placeholder show-placeholder para-p2">
                {isIcon && icon} {placeholder}
              </span>
            }
            rootClassName={`dropdown-option-checkbox`}
            suffixIcon={
              <div className="flex items-center">
                {selected?.length > 0 && (
                  <span className="bg-primary-50 text-primary-500 font-size-12 flex items-center justify-center h-20 w-20 font-medium p-1 rounded-full">{selected?.length}</span>
                )}
                <DownIcon />
              </div>
            }
            maxTagCount={0}
            menuItemSelectedIcon={null}
            mode="multiple"
            variant="borderless"
            allowClear
            placement="bottomRight"
            dropdownStyle={{ border: 'none', padding: '0px' }}
            style={{ height: '64px', fontSize: '16px', fontWeight: 400 }}
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            className={`text-neutral-800 w-full para-p3 text-neutral-700 rounded-3  ${Options?.some((e: { value: any }) => value?.includes(e?.value)) && 'active h-16'} `}
            onChange={handleSelectChange}
            value={selected}
            dropdownRender={(menu) => {
              return (
                <div className="flex flex-col dropdown-wrapper">
                  <Divider style={{ margin: 0 }} />
                  {menu}
                </div>
              )
            }}
          >
            {/* {open ? (
           isOptionGroup(Options) ? (
            <Select.OptGroup key={Options?.id} label={<span>{Options?.name}</span>}>
              {Options.options.map((opt) => (
                <Select.Option key={opt?.id} value={opt?.id}>
                  {typeof opt.name === 'string' ? <span>{opt.name}</span> : opt.label}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ) : (
            Options?.map((item: { id: string; name: string }) => {
              return (
                <Select.Option key={item?.id} value={item?.id} className="">
                  <div className="flex w-full gap-3">
                    <span className="flex justify-start gap-2 items-center">
                      <Checkbox checked={selected?.includes(item?.id)} />
                      <p className="para-p2-regular text-hidden dropdown-p-text">{item?.name}</p>
                    </span>
                  </div>
                </Select.Option>
              )
            })} */}
            {Options?.map((item: any) =>
              isOptionGroup(item) ? (
                <Select.OptGroup key={item?.title} label={<span>{formatTitle(item?.label)}</span>}>
                  {item.options.map((opt: any) => (
                    <Select.Option key={opt?.id} value={opt?.id} className="">
                      <div className="flex w-full gap-3">
                        <span className="flex justify-start gap-2 items-center">
                          <Checkbox checked={selected?.includes(opt?.id)} />
                          <p className="para-p2-regular text-hidden dropdown-p-text">{opt?.name}</p>
                        </span>
                      </div>
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              ) : (
                <Select.Option key={item?.id} value={item?.id} className="">
                  <div className="flex w-full gap-3">
                    <span className="flex justify-start gap-2 items-center">
                      <Checkbox checked={selected?.includes(item?.id)} />
                      <p className="para-p2-regular text-hidden dropdown-p-text">{item?.name}</p>
                    </span>
                  </div>
                </Select.Option>
              )
            )}
          </Select>
          {placeholder && (
            <span className="pointer-events-none flex g-10 h-full items-center text-neutral-800 font-normal items-center placeholder show-placeholder para-p2">
              {isIcon && icon} {placeholder}
            </span>
          )}
        </Form.Item>
      </div>
      {error && <p className="error-container">{error}</p>}
    </>
  )
}

export default DropDown
