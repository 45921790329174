import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import type { SearchProps } from 'antd/es/input'
import type { ColumnsType } from 'antd/es/table'
import { EditPenIcon, EnvolepUploadIcon, PlusIcon, ThreeDotIcon, TimeLineIcon, UserIcon } from 'assets/svgs'
import { ADMIN_CONTACT_STATUS } from 'constants/ConstantStatus'
import dayjs from 'dayjs'
import { useAppDispatch } from 'hooks/reduxHooks'
import usePermissionsBySectionName from 'hooks/usePermission'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import type { IAddProfile, IAdminContactColumn, IProfileData } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'
import TableImage from 'components/common/Table/TableImage'

import ActivityModal from './Modal/ActivityModal'
import AddUserModal from './Modal/AddUserModal'
import MemberProfileModal from './Modal/MemberProfileModal'
import { editContact, getAllContacts, getProfileById, sendInvitation } from './api'

import './AdminContact.scss'

const AdminContacts = () => {
  const [search, setSearch] = useState<string>('')
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState<boolean>(false)
  const [isActivityModalOpen, setIsActivityModalOpen] = useState<boolean>(false)
  const [hideActivityBtn, setHideActivityBtn] = useState<boolean>(false)
  const [openProfileModal, setProfileModalOpen] = useState<boolean>(false)
  const [contactsData, setContactsData] = useState<IAdminContactColumn[]>()
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const [editId, setEditId] = useState<string>()
  const [contactDetails, setContactDetails] = useState<IProfileData | any>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const permit = usePermissionsBySectionName('admin_contact')
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(contactsData?.length).fill(false))

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.adminContact'),
        isBack: true,
      })
    )
  }, [dispatch, t])

  useEffect(() => {
    void handleGetContacts(paginationInfo?.limit, paginationInfo?.offset, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isAddUserModalOpen, openProfileModal, sortTable, paginationInfo?.current, paginationInfo?.limit])

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    handlePageChange(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const handleActionMenuClick = (index: number) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = false
      return newOpen
    })
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleGetContacts = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getAllContacts(limit, offset, search, order, field)
    if (response?.status === 200) {
      setContactsData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleEditUserData = async (id: string, values: IAddProfile) => {
    dispatch(setGlobalLoader(true))
    const response = await editContact(id, values)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      void handleGetContacts(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const onActiveSwitchChange = (checked: boolean, data: any, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const newData = { ...data, status: newStatus, role: data.role.id }
    void handleEditUserData(id, newData)
  }

  const handlePageChange = (page: number, pageSize: number = paginationInfo?.limit) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
      limit: pageSize,
    }))
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleEdit = (id: string) => {
    setEditId(id)
    void handleGetContactProfile(id)
  }

  const handleGetContactProfile = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getProfileById(id)
    if (response?.data.statusCode === 1) {
      setContactDetails(response?.data.data)
      setIsAddUserModalOpen(true)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleInvitationEmail = (email: string) => {
    void handleInvitation(email)
  }

  const handleInvitation = async (email: string) => {
    dispatch(setGlobalLoader(true))
    const response = await sendInvitation({ email })
    if (response?.data.statusCode === 1) {
      setIsAddUserModalOpen(false)
      setIsActivityModalOpen(false)
      void message.success(response.data.message)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleRowClick = (editId: string) => {
    void handleGetRowContactProfile(editId)
  }

  const handleGetRowContactProfile = async (editId: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getProfileById(editId)
    if (response?.data.statusCode === 1) {
      const newData = { ...response?.data.data, id: editId }
      setContactDetails(newData)
      setEditId(editId)
      setProfileModalOpen(true)
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const getMenu = (record: IAdminContactColumn, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          onClick: () => {
            if (record.id !== undefined) {
              handleEdit(record.id.toString())
            }
            setHideActivityBtn(record.status === 'invited')
            handleActionMenuClick(index)
          },
          label: permit?.admin_contacts?.edit ? (
            <div className="option-wrapper">
              <p className="flex items-center gap-2">
                <EditPenIcon />
                <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
              </p>
            </div>
          ) : null,
        },
        {
          key: '2',
          onClick: () => {
            if (record.status !== 'invited') {
              setIsActivityModalOpen(true)
            } else {
              handleInvitationEmail(record.email)
            }
            handleActionMenuClick(index)
          },
          label: permit?.admin_contacts?.view ? (
            <div className="option-wrapper">
              <p className="flex items-center gap-2">
                {record.status === 'invited' ? <EnvolepUploadIcon /> : <TimeLineIcon />}
                <span className="activity-text font-normal text-neutral-800">{record.status === 'invited' ? 'Send Invitation' : 'View Activity'}</span>
              </p>
            </div>
          ) : null,
        },
        {
          key: '3',
          label: permit?.admin_contacts?.delete && record.status !== 'invited' && (
            <div className="option-wrapper">
              <div className="cursor-pointer w-full" key="3">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>
                  <Switch
                    checked={record.status === 'active'}
                    onChange={(checked) => {
                      if (record.id !== undefined) {
                        onActiveSwitchChange(checked, record, record.id)
                      }
                      handleActionMenuClick(index)
                    }}
                  />
                </p>
              </div>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType<IAdminContactColumn> = [
    {
      title: t('table.adminContact.teamMember'),
      dataIndex: 'first_name',
      key: 'first_name',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 294,
      render: (_value, record) => {
        return (
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          <TableImage name={`${record?.first_name} ${record?.last_name}`} image={record?.image} baseUrl={record?.base_url as string} />
        )
      },
    },
    {
      title: t('table.adminContact.email'),
      dataIndex: 'email',
      key: 'email',
      align: 'left',
      width: 294,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: t('table.adminContact.role'),
      dataIndex: 'role.name',
      key: 'role.name',
      align: 'start',
      width: 294,
      className: 'text-neutral-800 para-p2 ',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return <HeadingText text={formatTitle(record?.role?.name)} />
      },
    },
    {
      title: t('table.adminContact.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'start',
      width: 140,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === ADMIN_CONTACT_STATUS.INVITED ? 'invited' : record.status === ADMIN_CONTACT_STATUS.ACTIVE ? 'active' : 'inactive'}`}
            text={formatTitle(record?.status)}
          />
        )
      },
    },
    {
      title: t('table.adminContact.lastModified'),
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 294,
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        const formattedDate = dayjs(record?.updated_at).format('MM/DD/YYYY, hh:mm A')
        return <HeadingText classString="text-neutral-800 para-p3" text={formattedDate} />
      },
    },
    {
      title: t('table.adminContact.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 200,
      className: 'center-align',
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const contactBtn = (
    <div className="flex gap-6">
      <CustomBtn
        isIcon
        className="user-Icon"
        svgIcon={<UserIcon className="svg" />}
        text={t('button.role')}
        onClick={() => {
          navigate('/settings/user/role-management')
        }}
        type="default"
      />

      <CustomBtn
        isIcon
        svgIcon={<PlusIcon />}
        text={t('button.addUser')}
        onClick={() => {
          setEditId('')
          setIsAddUserModalOpen(true)
        }}
        type="primary"
      />
    </div>
  )

  return (
    <div>
      <CustomTable<IAdminContactColumn>
        column={columns}
        data={contactsData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        search={search}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        setModalOpen={handleRowClick}
        name={t('label.member')}
        isSearch
        onSearch={onSearch}
        searchPlaceholder={t('placeholder.search')}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        tableButton={contactBtn}
      />

      {isAddUserModalOpen && (
        <CustomModal
          open={isAddUserModalOpen}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setEditId('')
            setContactDetails(null)
            setIsAddUserModalOpen(false)
          }}
          content={
            <AddUserModal
              setIsModalOpen={setIsAddUserModalOpen}
              editId={editId}
              contactDetails={contactDetails}
              setContactDetails={setContactDetails}
              setSortTable={setSortTable}
            />
          }
          width={915}
        />
      )}
      {isActivityModalOpen && <ActivityModal isModalOpen={isActivityModalOpen} setIsModalOpen={setIsActivityModalOpen} />}
      {openProfileModal && (
        <MemberProfileModal
          isModalOpen={openProfileModal}
          setIsModalOpen={setProfileModalOpen}
          viewActivityModal={setIsActivityModalOpen}
          hideActivityBtn={hideActivityBtn}
          contactDetails={contactDetails}
          setEditOpenModal={setIsAddUserModalOpen}
        />
      )}
    </div>
  )
}

export default AdminContacts
