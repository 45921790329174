import { message } from 'antd'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import http from 'services/Service'

export const getScheduleAndRequest = async () => {
  try {
    const response = http.get(`/schedule-request-settings`)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message || VALIDATION_ERROR.SOMETHING)
  }
}

export const updateScheduleAndRequest = async (id: string, payload: { value: string }) => {
  try {
    const response = http.patch(`/schedule-request-settings/${id}`, payload)
    return await response
  } catch (err: any) {
    void message.error(err?.response?.data?.message[0] || VALIDATION_ERROR.SOMETHING)
  }
}
