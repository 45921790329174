import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { HomeIcon, SeperatorBreadcrumbs } from 'assets/svgs'
import { BACK_MODAL_TEXT } from 'constants/Constant'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { selectBreadcrumbData, setBreadcrumbMap } from 'store/slice/BreadcrumbSlice'
import { selectCommon, setCurrentNameData, setPreviousNameData, setType } from 'store/slice/CommonSlice'
import { deepCompareArrays, deepMatch, isEqualData, resolveBreadcrumbLabel } from 'utils/Helper'

import DeleteCancelModal from '../DeleteCancelModal/DeleteCancelModal'

const breadcrumbAbout = {
  '/': 'Home',
  '/settings': 'Settings',
  '/settings/compentency-test': 'Competency Test Settings',
  '/settings/compentency-test/quiz-faq': 'Test FAQs',
  '/settings/skill-checklist': 'Skill Checklist',
  '/settings/compentency-test/add-test': 'Create Test',
  '/settings/compentency-test/edit-test': 'Edit Test',
  '/settings/compentency-test/preview': 'Preview Test',
  '/settings/compentency-test/add-test/preview': 'Preview Test',
  '/settings/compentency-test/edit-test/preview': 'Preview Test',
  '/settings/skill-checklist/add': 'Create Checklist',
  '/settings/skill-checklist/edit': 'Edit Checklist',
  '/settings/shift-reject-reason': 'Shift Reject Reason',
  '/settings/provider-profile': 'Provider Profile Settings',
  '/settings/general-settings': 'General Settings',
  // '/settings/facility-general-settings': 'Facility General Settings',
  '/settings/shifts-settings': 'Shift Settings',
  '/settings/facility-provider-setting': 'Provider DNR Reason',
  '/settings/time-and-attendance': 'Reject Timecard Reason',
  '/settings/my-facility': 'My Facility Settings',
  // '/settings/document-categories': 'Document Settings',
  '/settings/facility-type': 'Facility Type',
  '/settings/license': 'License',
  '/settings/specialties': 'Specialties',
  // '/settings/shift-type': 'Shift Type',
  '/settings/provider-verification-rejection': 'Provider Verification Rejection',
  '/settings/facility-verification-rejection': 'Facility Verification Rejection Reason',
  '/settings/compliance-manager': 'Compliance Manager',
  '/settings/user': 'User',
  '/settings/user/role-management': 'Role Management',
  '/settings/user/role-management/create-role': 'Create New Role',
  '/settings/user/role-management/edit-role': 'Edit Role',
  '/settings/auto-schedule': 'Auto Schedule Settings',
  '/facilities': 'Facilities',
  '/facilities/:id': 'Facility Profile',
  // '/facilities/:id/provider-profile': 'Provider Profile',
  '/my-profile': 'Profile',
  '/settings/skill-checklist/add/preview': 'Preview',
  '/settings/skill-checklist/add/preview/subtopic': 'Assessment/Patient Care',
  '/settings/skill-checklist/edit/preview': 'Preview',
  '/settings/skill-checklist/edit/preview/subtopic': 'Assessment/Patient Care',
  '/settings/skill-checklist/preview': 'Preview',
  '/settings/skill-checklist/preview/subtopic': 'Assessment/Patient Care',
  '/settings/referance-form-setting': 'Reference Form Settings',
  '/settings/referance-form-setting/add-form': 'Create New Form',
  '/settings/referance-form-setting/edit-form': 'Edit Form',
  '/settings/referance-form-setting/referance-form-preview': 'Referance form preview',
  '/settings/referance-form-setting/add-form/referance-form-preview': 'Referance form preview',
  '/settings/referance-form-setting/edit-form/referance-form-preview': 'Referance form preview',
  '/settings/compliance-manager/credential-requirement': 'Credential Requirement',
  '/settings/time-entry-approval': 'Time Entry Approval',
  '/settings/status-option': 'Status Options',
  '/settings/admin-document-category': 'Document Categories',
  '/settings/schedule-and-request': 'Schedule & Request',
}

const Breadcrumbs: React.FC = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const breadcrumbMap = useAppSelector(selectBreadcrumbData)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [nextBreadcrumbUrl, setNextBreadcrumbUrl] = useState<string | null>(null)

  const { previousData, previousTestData, currentData, currentTestData, type, previousNameData, currentNameData, previousSkillData, currentSkillData } = useSelector(selectCommon)

  useEffect(() => {
    dispatch(setBreadcrumbMap(breadcrumbAbout))
  }, [dispatch])

  const pathSnippets = location.pathname.split('/').filter((i) => i)

  // Dynamic path matching pattern
  const dynamicPathPattern = /^\/settings\/(referance-form-setting|compentency-test|skill-checklist)\/(edit|add|edit-form|add-form|edit-test|add-test)(\/[a-zA-Z0-9-]+)?$/

  const handleHomeClick = () => {
    if (
      dynamicPathPattern.test(location.pathname) && // Match location.pathname
      (!deepMatch(previousNameData, currentNameData, type) || // Compare name data
        (type === 'skill'
          ? !deepCompareArrays(previousSkillData, currentSkillData) // Compare arrays for 'skill'
          : !isEqualData(
              type === 'test' ? previousTestData : previousData, // Compare test or data
              type === 'test' ? currentTestData : currentData,
              type
            )))
    ) {
      setNextBreadcrumbUrl('/')
      setIsDeleteModalOpen(true)
    } else {
      dispatch(setType(''))
      dispatch(setCurrentNameData({}))
      dispatch(setPreviousNameData({}))
      navigate('/')
    }
  }

  const handleBreadcrumbClick = (url: string) => {
    if (url === location.pathname) {
      return
    }
    if (dynamicPathPattern.test(url)) {
      dispatch(setType(''))
      dispatch(setCurrentNameData({}))
      dispatch(setPreviousNameData({}))
      navigate(url)
      return
    }
    if (
      dynamicPathPattern.test(location.pathname) && // Match location.pathname
      (!deepMatch(previousNameData, currentNameData, type) || // Compare name data
        (type === 'skill'
          ? !deepCompareArrays(previousSkillData, currentSkillData) // Compare arrays for 'skill'
          : !isEqualData(
              type === 'test' ? previousTestData : previousData, // Compare test or data
              type === 'test' ? currentTestData : currentData,
              type
            )))
    ) {
      setNextBreadcrumbUrl(url)
      setIsDeleteModalOpen(true)
    } else {
      dispatch(setType(''))
      dispatch(setCurrentNameData({}))
      dispatch(setPreviousNameData({}))
      navigate(url)
    }
  }

  const extraBreadcrumbItems = pathSnippets
    .map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
      const breadcrumbEntry = Object.keys(breadcrumbMap).find((pattern) => matchPath(pattern, url))
      if (!breadcrumbEntry) return null
      const { url: resolvedUrl, label } = resolveBreadcrumbLabel(url, location, breadcrumbMap)

      return (
        <Breadcrumb.Item key={resolvedUrl}>
          <div
            onClick={() => {
              handleBreadcrumbClick(resolvedUrl)
            }}
            className="cursor-pointer"
          >
            {label}
          </div>
        </Breadcrumb.Item>
      )
    })
    .filter((item): item is React.ReactElement => item !== null)

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <div onClick={handleHomeClick} className="cursor-pointer h-full flex items-center">
        <HomeIcon />
      </div>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems)

  return (
    <>
      <Breadcrumb
        className="mt-4 mb-4"
        separator={
          <div className="flex justify-center items-center h-full">
            <SeperatorBreadcrumbs />
          </div>
        }
      >
        {breadcrumbItems}
      </Breadcrumb>
      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={BACK_MODAL_TEXT.CANCEL}
          okClick={() => {
            if (nextBreadcrumbUrl) {
              navigate(nextBreadcrumbUrl)
            }
            dispatch(setType(''))
            dispatch(setCurrentNameData({}))
            dispatch(setPreviousNameData({}))
            setIsDeleteModalOpen(false)
          }}
          subHead={BACK_MODAL_TEXT.DELETE_DESC}
          mainHead={BACK_MODAL_TEXT.DELETE_HEAD_LOB}
          okText={BACK_MODAL_TEXT.DELETE}
        />
      )}
    </>
  )
}

export default Breadcrumbs
