import React, { useEffect, useState } from 'react'
import { Dropdown, type MenuProps, message, Switch } from 'antd'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, DropDownIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { DELETE_CATEGORY_MASTAER_ADMIN_SETTING } from 'constants/Constant'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { type IAddUpdateDOcumentCategory, type IDOcumentCategory, type IDocumentMasterAdminSetting } from 'types'
import { formatTitle } from 'utils/Helper'

import CustomModal from 'components/common/CustomModal'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddNewCategory from './modal/AddNewCategory'
import { editCategory, getFindAllCategory, removeCategory } from './api'

const DocumentCategoryForAdmin = () => {
  const dispatch = useAppDispatch()
  const [listData, setListData] = useState<IDocumentMasterAdminSetting[]>([])
  const [showTables, setShowTables] = useState<number[]>([])
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(listData.length).fill(false))
  const [isDeleteCategory, setIsDeleteCategory] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')
  const [addEditDocumentModal, setaddEditDocumentModal] = useState<boolean>(false)
  const [editId, setEditId] = useState('')
  const [editData, setEditData] = useState([])
  const [categoryId, setCategoryId] = useState('')

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: t('pageTitle.documentCategory'),
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetAllCategory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEditDocumentModal])

  const handleGetAllCategory = async () => {
    dispatch(setGlobalLoader(true))
    const response = await getFindAllCategory()
    if (response?.status === 200) {
      const categories = ['agency', 'clients', 'provider']
      const responseData = response?.data?.data
      categories.forEach((category) => {
        if (!responseData?.some((item: { category: string }) => item?.category === category)) {
          responseData.push({ category, document_categories: [] })
        }
      })
      setListData(responseData)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const handleClick = (index: number) => {
    setShowTables((prevVisibleSections) => {
      if (prevVisibleSections.includes(index)) {
        return prevVisibleSections.filter((i) => i !== index)
      } else {
        return [...prevVisibleSections, index]
      }
    })
  }

  const handleDeleteCategory = async (id: string) => {
    if (id) {
      const response = await removeCategory(id)
      if (response?.status === 200) {
        setIsDeleteCategory(false)
        void message.success(response?.data?.message)
        void handleGetAllCategory()
      }
    }
  }

  const handleEditCategory = async (id: string, values: IAddUpdateDOcumentCategory) => {
    dispatch(setGlobalLoader(true))
    const response = await editCategory(id, values)
    if (response?.data.statusCode === 1) {
      void message.success(response?.data?.message)
      void handleGetAllCategory()
    }
    dispatch(setGlobalLoader(false))
  }

  const onSwitchChange = (checked: boolean, data: IDOcumentCategory, id: string) => {
    const newStatus = checked ? 'active' : 'in_active'
    const newData = { ...data, status: newStatus }
    if (id) void handleEditCategory(id, newData)
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: '1',
          label: (
            <div className="option-wrapper">
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
          onClick: () => {
            setaddEditDocumentModal(true)
            setEditId(record.id)
            setEditData(record)
            setCategoryId(record?.categoryId)
          },
        },
        {
          key: '2',
          label: (
            <div className="option-wrapper">
              <div className=" cursor-pointer w-full" key="2">
                <p className="flex items-center justify-between gap-2">
                  <span className="activity-text font-normal text-neutral-800">{t('placeholder.active')}</span>
                  <Switch
                    checked={record.status === 'active'}
                    onChange={(checked) => {
                      onSwitchChange(checked, record, record?.id)
                    }}
                  />
                </p>
              </div>
            </div>
          ),
        },
        {
          key: '3',
          label: (
            <div className="option-wrapper">
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
          onClick: () => {
            setIsDeleteCategory(true)
            setDeleteId(record.id)
            setDeleteName(record.name)
          },
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: t('table.adminDocument.order'),
      dataIndex: 'index',
      key: 'index',
      align: 'start',
      width: 117,
      render: (_value, record, index) => {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={String(index + 1)} />
      },
    },
    {
      title: t('table.adminDocument.documentCategory'),
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 386,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={record.name} />
      },
    },
    {
      title: t('table.adminDocument.note'),
      dataIndex: 'note',
      key: 'note',
      align: 'start',
      width: 380,
      render: (_value, record) => {
        return <HeadingText classString="text-neutral-800 para-p3 font-small" text={record.note ? record.note : '-'} />
      },
    },
    {
      title: t('table.adminDocument.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'start',
      width: 386,
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`${record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} text-neutral-800 para-p3 font-medium `}
            text={formatTitle(record.status)}
          />
        )
      },
    },
    {
      title: t('table.adminDocument.action'),
      dataIndex: '',
      key: 'id',
      align: 'center',
      width: 163,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  return (
    <div className="document-setting-wrapper">
      <div className="flex flex-col items-start gap-4 w-full">
        <div className="flex justify-between items-center w-full">
          <p className="text-neutral-800 desktop-head-h5 font-medium">{t('label.manageDocumentCategories')}</p>
        </div>
        {listData?.map((data, index) => {
          return (
            <div className="table-wrapper" key={data.category}>
              <div className="flex justify-between items-center aline-self cursor-pointer">
                <div
                  className="flex items-center gap-2 w-full"
                  onClick={() => {
                    handleClick(index)
                  }}
                >
                  <div
                    className="flex items-center justify-end"
                    style={{ transform: showTables.includes(index) ? 'rotate(0deg)' : 'rotate(-90deg)', transition: 'transform 0.3s' }}
                  >
                    <DropDownIcon />
                  </div>
                  <p className="text-neutral-800 mobile-heading-h5 font-medium">{formatTitle(data.category)}</p>
                </div>
                <div className="flex items-center gap-6">
                  {showTables.includes(index) && (
                    <PlusIcon
                      className="neutral-icon"
                      onClick={() => {
                        setEditId('')
                        setEditData([])
                        setCategoryId(data.category)
                        setaddEditDocumentModal(true)
                      }}
                    />
                  )}
                </div>
              </div>
              {showTables.includes(index) && (
                <CustomTable column={columns} data={data.document_categories.map((doc) => ({ ...doc, categoryId: data.category }))} pagination={false} />
              )}
            </div>
          )
        })}
      </div>

      {isDeleteCategory && (
        <DeleteCancelModal
          isModalOpen={isDeleteCategory}
          setIsModalOpen={setIsDeleteCategory}
          cancelText={DELETE_CATEGORY_MASTAER_ADMIN_SETTING.CANCEL}
          okClick={() => {
            void handleDeleteCategory(deleteId)
          }}
          deleteName={deleteName}
          subHead={DELETE_CATEGORY_MASTAER_ADMIN_SETTING.DELETE_DESC}
          mainHead={DELETE_CATEGORY_MASTAER_ADMIN_SETTING.DELETE_HEAD_LOB}
          okText={DELETE_CATEGORY_MASTAER_ADMIN_SETTING.DELETE}
        />
      )}
      {addEditDocumentModal && (
        <CustomModal
          open={addEditDocumentModal}
          centered
          footer={null}
          closable={false}
          classStrings="add-certificate-modal"
          onCancel={() => {
            setEditId('')
            setEditData([])
            setaddEditDocumentModal(false)
          }}
          content={<AddNewCategory setEditModalOpen={setaddEditDocumentModal} categoryId={categoryId} editId={editId} data={editData} />}
        />
      )}
    </div>
  )
}

export default DocumentCategoryForAdmin
