import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Dropdown, Form, type MenuProps, message } from 'antd'
import type { SearchProps } from 'antd/es/input'
import type { ColumnsType } from 'antd/es/table'
import { CloseIcon, DatePickerIcon, EditPenIcon, PlusIcon, RefreshIcon, RequestIcon, ThreeDotIcon, TimeCardIcon } from 'assets/svgs'
import { BUTTON_STRING, COMMON, SHIFTS } from 'constants/Constant'
import { SHIFT_STATUS } from 'constants/ConstantStatus'
import { VALIDATION_ERROR } from 'constants/ValidationStrings'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useAppDispatch } from 'hooks/reduxHooks'
import { GET_CERTIFICATE, GET_SPECIALITY } from 'services/common/Dropdown'
import { setGlobalLoader } from 'store/slice/CommonSlice'
import { type IFacility, type IShiftFilter } from 'types'
import { formatTitle } from 'utils/Helper'
import { formatQuerry } from 'utils/utils'

import CustomBtn from 'components/common/CustomBtn'
import CustomModal from 'components/common/CustomModal'
import CustomDatePickerField from 'components/common/DatePicker/CustomDatePicker'
import HeadingText from 'components/common/HeadingText'
import InputField from 'components/common/InputField/InputField'
import CustomMultiSelect from 'components/common/Select/CustomMultiSelect'
import CustomSelect from 'components/common/Select/CustomSelect'
import CustomTable from 'components/common/Table/CustomTable'

import { GET_SHIFTS } from '../../api'

import CancelShift from './CancelShift'
import CreateShift from './CreateShift'
import DetailShift from './DetailShift'

dayjs.extend(customParseFormat)

const Shifts = ({ facilityCity, facilityState }: { facilityCity: string; facilityState: string }) => {
  const [isCancel, setIsCancel] = useState<boolean>(false)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false)
  const [shiftData, setShiftData] = useState([])
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [isCreateShiftModalOpen, setIsCreateShiftModalOpen] = useState<boolean>(false)
  const [rowId, setRowId] = useState<string>('')
  const [tabId, setTabId] = useState<string>('1')
  const [limit, setLimit] = useState(10)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isOpenCancelModal, setIsCancelModal] = useState<boolean>(false)
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  // eslint-disable-next-line
  const [selectedRowData, setSelectedRowData] = useState<any>()
  const { id: facilityId } = useParams()
  const [selectedValue, setSelectedValue] = useState<string | undefined>()
  const [search, setSearch] = useState<string>('')
  const dispatch = useAppDispatch()
  const [statusTag, setStatusTag] = useState<string[]>([])
  const [filterApply, setFilterApply] = useState(false)
  const [specialityData, setSpecialityData] = useState<IFacility[]>([])
  const [specialityOriginData, setSpecialityOriginData] = useState<IFacility[]>([])
  const [certificateData, setCertificateData] = useState<IFacility[]>([])
  const [certificateOriginData, setCertificateOriginData] = useState<IFacility[]>([])
  const [filters, setFilters] = useState<IShiftFilter>({
    status: [],
    certificate: '',
    speciality: '',
    from_date: '',
    to_date: '',
    columns: ['shift_date', 'shift_time', 'license', 'specialties', 'status', 'request'],
    shift_id_from: '',
    shift_id_to: '',
  })
  const [form] = Form.useForm()
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)

  useEffect(() => {
    setTotal(0)
    setCurrent(1)
    setLimit(10)
  }, [facilityId])

  useEffect(() => {
    void handleGetShiftData(current, limit, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line
  }, [current, limit, sortTable, facilityId, search, isCancel, !isCancel, isCreateShiftModalOpen, isDetailModalOpen, !isEdit])

  useEffect(() => {
    if (filterApply) {
      if (current !== 1) {
        setCurrent(1)
      } else {
        void handleGetShiftData(current, limit, sortTable?.order, sortTable?.field)
      }
    }
    // eslint-disable-next-line
  }, [filterApply])

  const handleGetShiftData = async (page: number, pageSize: number, order: string, field: string) => {
    if (facilityId) {
      dispatch(setGlobalLoader(true))
      try {
        const params = {
          ...filters?.status?.reduce((acc: any, status: string, index) => {
            // eslint-disable-next-line
            acc[`status[${index}]`] = status
            return acc
          }, {}),
          [`certificate[0]`]: filters?.certificate,
          [`speciality[0]`]: filters?.speciality,
          from_date: filters?.from_date,
          to_date: filters?.to_date,
          shift_id_from: filters?.shift_id_from,
          shift_id_to: filters?.shift_id_to,
        }
        const query = formatQuerry({ ...params })
        const response = await GET_SHIFTS(facilityId, page, pageSize, search, order, field, query)
        if (response?.data?.statusCode === 1) {
          setShiftData(response?.data?.data)
          setTotal(response?.data?.total)
          setFilterApply(false)
        }
      } catch (error: any) {
        void message.error(error)
      }
      dispatch(setGlobalLoader(false))
    }
  }

  const handleCancelClick = (id: string) => {
    setIsCancelModal(true)
    setRowId(id)
  }

  const handleChangePage = (page: number, pageSize?: number) => {
    setCurrent(page)
    if (pageSize) setLimit(pageSize)
  }

  const handleDetailModal = (id: string) => {
    setRowId(id)
    setIsDetailModalOpen(true)
  }

  const getMenu = (record: any): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: [SHIFT_STATUS.REQUESTED, SHIFT_STATUS.SCHEDULE, SHIFT_STATUS.UNSUBMITTED, SHIFT_STATUS.ON_GOING, SHIFT_STATUS.VOID].includes(record.status ?? '') && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                setIsDetailModalOpen(true)
                setRowId(record?.id)
                if (record?.status === SHIFT_STATUS.REQUESTED || record?.status === SHIFT_STATUS.SCHEDULE) {
                  setTabId('3')
                } else {
                  setTabId('1')
                }
              }}
            >
              <p className="flex items-center gap-2">
                <RequestIcon />{' '}
                <span className="mobile-heading-h6 font-normal text-neutral-800">
                  {[SHIFT_STATUS.UNSUBMITTED, SHIFT_STATUS.ON_GOING, SHIFT_STATUS.VOID].includes(record.status) ? SHIFTS.VIEW_DETAILS : SHIFTS.VIEW_REQUEST}
                </span>
              </p>
            </div>
          ),
        },
        {
          label: ![
            SHIFT_STATUS.OPEN,
            SHIFT_STATUS.REQUESTED,
            SHIFT_STATUS.UNCONFIRMED,
            SHIFT_STATUS.AUTO_SCHEDULING,
            SHIFT_STATUS.SCHEDULE,
            SHIFT_STATUS.UNSUBMITTED,
            SHIFT_STATUS.ON_GOING,
            SHIFT_STATUS.VOID,
          ].includes(record.status) && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                setRowId(record?.id)
                setIsCreateShiftModalOpen(true)
              }}
            >
              <p className="flex items-center gap-2">
                <RefreshIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.REPOST}</span>{' '}
              </p>
            </div>
          ),
          key: 2,
        },
        {
          label: record.status === SHIFT_STATUS.COMPLETED && (
            <div className="p-4 cursor-pointer w-full">
              <p className="flex items-center gap-2">
                <TimeCardIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.VIEW_TIMECARD}</span>
              </p>
            </div>
          ),
          key: 3,
        },
        {
          label: ![
            SHIFT_STATUS.CANCELLED,
            SHIFT_STATUS.COMPLETED,
            SHIFT_STATUS.UNCONFIRMED,
            SHIFT_STATUS.AUTO_SCHEDULING,
            SHIFT_STATUS.UNSUBMITTED,
            SHIFT_STATUS.ON_GOING,
            SHIFT_STATUS.VOID,
          ].includes(record.status) && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                setIsEdit(true)
                setIsDetailModalOpen(true)
                setRowId(record?.id)
              }}
            >
              <p className="flex items-center gap-2">
                <EditPenIcon /> <span className="mobile-heading-h6 font-normal text-neutral-800">{SHIFTS.EDIT_SHIFT}</span>
              </p>
            </div>
          ),
          key: 4,
        },
        {
          label: ![SHIFT_STATUS.CANCELLED, SHIFT_STATUS.COMPLETED, SHIFT_STATUS.UNSUBMITTED, SHIFT_STATUS.ON_GOING, SHIFT_STATUS.VOID].includes(record.status) && (
            <div
              className="p-4 cursor-pointer w-full"
              onClick={() => {
                handleCancelClick(record?.id)
              }}
            >
              <p className="flex items-center gap-2">
                <CloseIcon /> <span className="para-p1 font-normal text-neutral-800">{BUTTON_STRING.CANCEL_SHIFT}</span>
              </p>
            </div>
          ),
          key: 5,
        },
      ],
    }
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    setCurrent(1)
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setCurrent(1)
    }
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const columns: ColumnsType = [
    {
      title: SHIFTS.TABLE.SHIFT_ID,
      dataIndex: 'shift_id',
      key: 's.shift_id',
      align: 'start',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: SHIFTS.TABLE.SHIFT_DATE,
      dataIndex: 'start_date',
      key: 's.start_date',
      align: 'start',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_value, record) => {
        return <p>{dayjs(record?.start_date).format('MM/DD/YYYY')}</p>
      },
      hidden: !filters?.columns?.includes('shift_date'),
    },
    {
      title: SHIFTS.TABLE.SHIFT_TIME,
      dataIndex: 's.start_time',
      key: 's.start_time',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 350,
      render: (_value, record) => {
        // eslint-disable-next-line
        return <p className="status-text">{`${dayjs(record?.start_time, 'HH:mm:ss').format('h:mm A')} - ${dayjs(record?.end_time, 'HH:mm:ss').format('h:mm A')}`}</p>
      },
      hidden: !filters?.columns?.includes('shift_time'),
    },
    {
      title: SHIFTS.TABLE.LICENSE,
      dataIndex: 'c.name',
      key: 'c.name',
      align: 'start',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      hidden: !filters?.columns?.includes('license'),
      render: (_value, record) => {
        return (
          <HeadingText
            classString="status"
            style={{ background: record?.certificate?.background_color, color: record?.certificate?.text_color }}
            text={record?.certificate?.name}
          />
        )
      },
    },
    {
      title: SHIFTS.TABLE.SPECIALTIES,
      dataIndex: 'sp.name',
      key: 'sp.name',
      align: 'start',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      width: 200,
      hidden: !filters?.columns?.includes('specialties'),
      render: (_value, record) => {
        return (
          <HeadingText style={{ background: record?.speciality?.background_color, color: record?.speciality?.text_color }} classString="status" text={record?.speciality?.name} />
        )
      },
    },
    {
      title: SHIFTS.TABLE.STATUS,
      dataIndex: 's.status',
      key: 's.status',
      align: 'start',
      width: 250,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      hidden: !filters?.columns?.includes('status'),
      render: (_value, record) => {
        return (
          <HeadingText
            classString={`common-tags ${record.status === SHIFT_STATUS.OPEN ? 'tertiary' : record.status === SHIFT_STATUS.CANCELLED ? 'error' : record.status === SHIFT_STATUS.COMPLETED ? 'neutral-tag' : record.status === SHIFT_STATUS.SCHEDULE ? 'success' : record?.status === SHIFT_STATUS.VOID ? 'void' : 'warning'}`}
            text={formatTitle(record?.status)}
          />
        )
      },
    },
    {
      title: SHIFTS.TABLE.NO_OF_REQUEST,
      dataIndex: 'total_requests',
      key: 'total_requests',
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      align: 'start',
      width: 200,
      hidden: !filters?.columns?.includes('request'),
    },
    {
      title: COMMON.ACTION,
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 120,
      render: (_value, record) => {
        return (
          <div className="flex justify-center">
            <Dropdown
              overlayClassName="action-menu-dropdown"
              menu={getMenu(record)}
              trigger={['click']}
              onOpenChange={() => {
                setSelectedRowData(record)
              }}
            >
              <div className="three-dot-icon flex  border-action-menu action-cell">
                <ThreeDotIcon className="cursor-pointer svg" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]
  const handleCreateShift = () => {
    setIsCreateShiftModalOpen(true)
  }

  const tableButton = <CustomBtn isIcon svgIcon={<PlusIcon />} text={SHIFTS.CREATE_SHIFT} onClick={handleCreateShift} type="primary" className="rounded-3 border-0" />

  const tabData = [
    { value: 'per_diem', label: 'Per Diem' },
    { value: 'long_term', label: 'Long Term' },
  ]

  // Filters api and dropdown
  const getSpeciality = async () => {
    try {
      const response = await GET_SPECIALITY()
      if (response?.status === 200) {
        setSpecialityData(response?.data?.data)
        setSpecialityOriginData(response?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  const getCertificate = async () => {
    try {
      const response = await GET_CERTIFICATE()
      if (response?.status === 200) {
        setCertificateData(response?.data?.data)
        setCertificateOriginData(response?.data?.data)
      }
    } catch (error: any) {
      void message.error(error)
    }
  }

  useEffect(() => {
    void getSpeciality()
    void getCertificate()
  }, [])

  const handleSearchLicense = (search: string) => {
    if (search !== '') {
      const filteredData = certificateData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setCertificateData(filteredData)
      return
    }
    setCertificateData(certificateOriginData)
  }

  const handleSearchSpeciality = (search: string) => {
    if (search !== '') {
      const filteredData = specialityData?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      setSpecialityData(filteredData)
      return
    }
    setSpecialityData(specialityOriginData)
  }

  const handleStatusChange = (selectedValue: any[]) => {
    setStatusTag(selectedValue)
  }

  const applyFilter = (
    value?: { license?: string; speciality?: string; from_date?: string; to_date?: string; shift_id_from?: string; shift_id_to?: string },
    isReset?: boolean
  ) => {
    isReset && setStatusTag([])
    setFilters({
      status: isReset ? [] : (statusTag ?? []),
      certificate: value?.license ?? '',
      speciality: value?.speciality ?? '',
      from_date: value?.from_date ? (dayjs(value?.from_date).format('YYYY-MM-DD') ?? '') : '',
      to_date: value?.to_date ? (dayjs(value?.to_date).format('YYYY-MM-DD') ?? '') : '',
      columns: ['shift_date', 'shift_time', 'license', 'specialties', 'status', 'request'],
      shift_id_from: value?.shift_id_from ?? '',
      shift_id_to: value?.shift_id_to ?? '',
    })
    if (!isReset) {
      setIsDrawerOpen(false)
    }
  }

  const handleReset = () => {
    form.resetFields()
    form.setFieldsValue({
      license: '',
      speciality: '',
      from_date: '',
      to_date: '',
      shift_id_from: '',
      shift_id_to: '',
    })
    setStatusTag([])
    setFilters({
      status: [],
      certificate: '',
      speciality: '',
      from_date: '',
      to_date: '',
      columns: ['shift_date', 'shift_time', 'license', 'specialties', 'status', 'request'],
      shift_id_from: '',
      shift_id_to: '',
    })
  }

  const handleDrawerOpenChange = (isOpen: boolean) => {
    setIsDrawerOpen(isOpen)
    if (isOpen) {
      form.setFieldsValue({
        ...filters,
        license: filters?.certificate,
        from_date: filters?.from_date ? dayjs(filters?.from_date) : '',
        to_date: filters?.to_date ? dayjs(filters?.to_date) : '',
      })
      setStatusTag(filters?.status || [])
    }
  }

  const getValue = useCallback(
    (fieldName: string) => {
      return form.getFieldValue(fieldName)
    },
    [isDrawerOpen, form]
  )

  const FilterOptionMenu = (
    <div className="h-full">
      <Form
        form={form}
        onFinish={(value) => {
          setFilterApply(true)
          applyFilter(value)
        }}
        className="h-full flex flex-col justify-between filters"
      >
        <div className="flex flex-col gap-2">
          <CustomMultiSelect
            name="status"
            handleChange={handleStatusChange}
            Options={[
              { value: 'open', name: 'Open' },
              { value: 'requested', name: 'Requested' },
              { value: 'scheduled', name: 'Scheduled' },
              { value: 'ongoing', name: 'Ongoing' },
              { value: 'running_late', name: 'Running Late' },
              { value: 'unconfirmed', name: 'Unconfirmed' },
              { value: 'cancelled', name: 'Cancelled' },
              { value: 'completed', name: 'Completed' },
              { value: 'un_submitted', name: 'Unsubmitted' },
              { value: 'void', name: 'Void' },
            ]}
            placeholder={COMMON.STATUS}
            optionWidth={200}
            value={statusTag}
          />
          <CustomSelect
            label={COMMON.LICENSE}
            options={certificateData?.map((item: { name: string; id: string }) => ({
              label: item?.name,
              value: item?.id,
            }))}
            value={getValue('license')}
            isSearch
            searchPlaceholder={COMMON.SEARCH_LICENCE}
            name="license"
            searchData={handleSearchLicense}
            wrapperClass="mt-6"
          />
          <CustomSelect
            label={COMMON.SPECIALITY}
            options={specialityData?.map((item: { name: string; id: string }) => ({
              label: item?.name,
              value: item?.id,
            }))}
            value={getValue('speciality')}
            isSearch
            searchPlaceholder={COMMON.SEARCH_SPECIALITY}
            name="speciality"
            searchData={handleSearchSpeciality}
          />
          <CustomMultiSelect
            name="columns"
            handleChange={(e: any) => {
              setFilters((prev) => ({ ...prev, columns: e as string[] }))
            }}
            Options={[
              { name: 'Shift Date', value: 'shift_date' },
              { name: 'Shift Time', value: 'shift_time' },
              { name: 'License', value: 'license' },
              { name: 'Specialties', value: 'specialties' },
              { name: 'Status', value: 'status' },
              { name: 'Request', value: 'request' },
            ]}
            placeholder={COMMON.COLUMNS}
            updatedTag={filters?.columns}
            value={filters?.columns}
          />
          <InputField
            wrapperClass="mt-6 show-form-error"
            placeholder={COMMON.SHIFT_ID_FROM}
            name="shift_id_from"
            type="number"
            value={getValue('shift_id_from')}
            rules={[
              {
                // eslint-disable-next-line
                validator: () => {
                  const toDate = form?.getFieldValue('shift_id_to')
                  const fromDate = form?.getFieldValue('shift_id_from')
                  if (toDate && !fromDate) {
                    return Promise.reject(new Error(VALIDATION_ERROR.SHIFT_ID))
                  }
                  return Promise.resolve()
                },
              },
            ]}
            onKeyDown={(e) => {
              if (e?.key === 'e' || e.key === 'E') {
                e?.preventDefault()
              }
            }}
          />
          <InputField
            wrapperClass="show-form-error"
            placeholder={COMMON.SHIFT_ID_TO}
            name="shift_id_to"
            type="number"
            value={getValue('shift_id_to')}
            onKeyDown={(e) => {
              if (e?.key === 'e' || e.key === 'E') {
                e?.preventDefault()
              }
            }}
            rules={[
              {
                // eslint-disable-next-line
                validator: () => {
                  const toDate = form?.getFieldValue('shift_id_to')
                  const fromDate = form?.getFieldValue('shift_id_from')
                  if (!toDate && fromDate) {
                    return Promise.reject(new Error(VALIDATION_ERROR.SHIFT_ID))
                  } else if (Number(fromDate) > Number(toDate)) {
                    return Promise.reject(new Error(VALIDATION_ERROR.VALID_SHIFT_ID))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          />
          <CustomDatePickerField
            name="from_date"
            placeholder={SHIFTS.FORM_DATE}
            icon={<DatePickerIcon />}
            form={form}
            value={form.getFieldValue('from_date')}
            wrapperClass="mb-0 show-form-error"
            rules={[
              {
                // eslint-disable-next-line
                validator: () => {
                  const toDate = form?.getFieldValue('to_date')
                  const fromDate = form?.getFieldValue('from_date')
                  if (toDate && !fromDate) {
                    return Promise.reject(new Error(VALIDATION_ERROR.DATE))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          />

          <CustomDatePickerField
            name="to_date"
            placeholder={SHIFTS.TO_DATE}
            icon={<DatePickerIcon />}
            form={form}
            value={form.getFieldValue('to_date')}
            wrapperClass="show-form-error"
            rules={[
              {
                // eslint-disable-next-line
                validator: () => {
                  const fromDate = form?.getFieldValue('from_date')
                  const toDate = form?.getFieldValue('to_date')
                  if (!toDate && fromDate) {
                    return Promise.reject(new Error(VALIDATION_ERROR.DATE))
                  } else if (fromDate && toDate && dayjs(fromDate)?.isAfter(dayjs(toDate))) {
                    return Promise.reject(new Error(VALIDATION_ERROR.DATE_RANGE))
                  }
                  return Promise.resolve()
                },
              },
            ]}
          />
        </div>
        <div className="flex gap-2">
          <CustomBtn
            onClick={() => {
              handleReset()
              applyFilter({}, true)
              setFilterApply(true)
            }}
            text={BUTTON_STRING.RESET}
            className="bg-primary-50 text-primary-600 border-0 h-49 w-160 reset-btn-hover"
            htmlType="reset"
            type="default"
          />
          <CustomBtn type="primary" text={BUTTON_STRING.APPLY} htmlType="submit" className="h-49 w-160" />
        </div>
      </Form>
    </div>
  )

  return (
    <div>
      <CustomTable
        option={tabData}
        isSearch
        isFilter
        searchPlaceholder={COMMON.SEARCH_SHIFT}
        setSelectedValue={setSelectedValue}
        selectedValue={selectedValue}
        tableButton={tableButton}
        column={columns}
        setModalOpen={handleDetailModal}
        data={shiftData}
        total={total}
        current={current}
        limit={limit}
        onChangePage={handleChangePage}
        className="shift-table row-selected"
        wrapperClass="table-top-border-radius-0"
        onChange={handleTableChange}
        name={SHIFTS.SHIFTS}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        onSearch={onSearch}
        filterOption={FilterOptionMenu}
        filterSelected={filters}
        onDrawerOpenChange={handleDrawerOpenChange}
        showFilterDrawer={isDrawerOpen}
      />

      {isDetailModalOpen && (
        <DetailShift
          setTabId={setTabId}
          tabId={tabId}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isCancel={isCancel}
          setIsCreateShiftModalOpen={setIsCreateShiftModalOpen}
          setIsCancel={setIsCancel}
          id={rowId}
          setRowId={setRowId}
          isDetailShiftOpen={isDetailModalOpen}
          setIsDetailShiftOpen={setIsDetailModalOpen}
        />
      )}
      {isOpenCancelModal && (
        <CustomModal
          onCancel={() => {
            setIsCancelModal(false)
          }}
          footer={null}
          closable={false}
          width={600}
          open={isOpenCancelModal}
          title={SHIFTS.CANCEL_SHIFT}
          content={<CancelShift id={rowId} isCancel={isCancel} setIsCancel={setIsCancel} setIsModalOpen={setIsCancelModal} />}
        />
      )}
      {isCreateShiftModalOpen && (
        <CreateShift
          rowId={rowId}
          setRowId={setRowId}
          isCreateShiftModalOpen={isCreateShiftModalOpen}
          setIsCreateShiftModalOpen={setIsCreateShiftModalOpen}
          facilityCity={facilityCity}
          facilityState={facilityState}
        />
      )}
    </div>
  )
}

export default Shifts
