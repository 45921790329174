import { useEffect, useState } from 'react'
import { Dropdown, Form, type MenuProps, message, Popover } from 'antd'
import { type SearchProps } from 'antd/es/input'
import { type ColumnsType } from 'antd/es/table'
import { DeleteIcon, EditPenIcon, PlusIcon, ThreeDotIcon } from 'assets/svgs'
import { BUTTON, COMMON, DELETE_MODAL_TEXT } from 'constants/Constant'
import { CERTIFICATION_STATUS } from 'constants/ConstantStatus'
import { useAppDispatch } from 'hooks/reduxHooks'
import { t } from 'i18next'
import { changeHeaderTitle, setGlobalLoader } from 'store/slice/CommonSlice'
import { formatTitle } from 'utils/Helper'

import CustomBtn from 'components/common/CustomBtn'
import DeleteCancelModal from 'components/common/DeleteCancelModal/DeleteCancelModal'
import HeadingText from 'components/common/HeadingText'
import CustomTable from 'components/common/Table/CustomTable'

import AddFacilityType from './Modal/AddFacilityType'
import { deleteLineOfBusiness, getLineOfBusiness } from './api'

const FacilityType = () => {
  const [search, setSearch] = useState<string>('')
  const [error, setError] = useState({
    name: '',
    work_comp_code: '',
  })
  const [isCreateMenuOpen, setIsCreateMenuOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [lineOfData, setLineOfData] = useState([])
  const [editId, setEditId] = useState<string>('')
  const [deleteName, setDeleteName] = useState<string>('')
  const [editData, setEditData] = useState(null)
  const [actionMenuOpen, setActionMenuOpen] = useState(Array(lineOfData?.length).fill(false))
  const [statusCheck, setStatusCheck] = useState<boolean>(true)
  const [form] = Form.useForm()
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    current: 1,
  })
  const [sortTable, setSortTable] = useState({
    order: '',
    field: '',
  })
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(
      changeHeaderTitle({
        pageTitle: COMMON.FACILITY_TYPE,
        isBack: true,
      })
    )
  }, [dispatch])

  useEffect(() => {
    void handleGetLineOfBusiness(paginationInfo.limit, paginationInfo.offset, sortTable?.order, sortTable?.field)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!isCreateMenuOpen, !isDeleteModalOpen, paginationInfo?.limit, paginationInfo?.offset, paginationInfo?.current, search, sortTable])

  const handleGetLineOfBusiness = async (limit: number, offset: number, order: string, field: string) => {
    dispatch(setGlobalLoader(true))
    const response = await getLineOfBusiness(limit, offset, search, order, field)
    if (response?.status === 200) {
      setLineOfData(response?.data?.data)
      setPaginationInfo((prev) => ({
        ...prev,
        total: response?.data?.total,
        limit: response?.data?.limit,
      }))
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleDeleteLOB = async (id: string) => {
    dispatch(setGlobalLoader(true))
    const response = await deleteLineOfBusiness(id)
    if (response?.status === 200) {
      setIsDeleteModalOpen(false)
      setEditId('')
      void message.success(response?.data?.message)
      const newTotal = paginationInfo.total - 1
      const newOffset = paginationInfo.offset
      const newPage = Math.max(1, Math.ceil(newTotal / paginationInfo.limit))
      if (paginationInfo.current > newPage) {
        handlePageChange(newPage)
      } else {
        void handleGetLineOfBusiness(paginationInfo.limit, newOffset, sortTable?.order, sortTable?.field)
      }
    } else {
      if (response?.data?.message) void message.error(response?.data?.message)
    }
    dispatch(setGlobalLoader(false))
  }

  const handleTableChange = (_pagination: any, _filters: any, sorter: any) => {
    setSortTable({
      order: sorter.order ? (sorter.order === 'ascend' ? 'ASC' : 'DESC') : '',
      field: sorter.field || '',
    })
  }

  const handleChange = (value: string) => {
    if (value.length === 0) {
      setSearch(value)
      setPaginationInfo((prev) => ({
        ...prev,
        offset: 0,
        current: 1,
      }))
    }
  }

  const onSearch: SearchProps['onSearch'] = (value) => {
    setSearch(value.trim().toLowerCase())
    handlePageChange(1)
  }

  const handlePageChange = (page: number, pageSize: number = paginationInfo?.limit) => {
    const newOffset = (page - 1) * paginationInfo?.limit
    setPaginationInfo((prev) => ({
      ...prev,
      current: page,
      offset: newOffset,
      limit: pageSize,
    }))
  }

  const handleOpenChange = (index: number, open: boolean) => {
    setActionMenuOpen((prevOpen) => {
      const newOpen = [...prevOpen]
      newOpen[index] = open
      return newOpen
    })
  }

  const getMenu = (record: any, index: number): MenuProps => {
    return {
      items: [
        {
          key: 1,
          label: (
            <div
              className="option-wrapper"
              onClick={() => {
                form.resetFields()
                setIsCreateMenuOpen(true)
                setEditId(record?.id)
                setEditData(record)
                setError({ name: '', work_comp_code: '' })
              }}
            >
              <EditPenIcon />
              <span className="activity-text font-normal text-neutral-800">{t('button.edit')}</span>
            </div>
          ),
        },
        {
          key: 2,
          label: (
            <div
              className="option-wrapper"
              onClick={() => {
                setDeleteName(record?.name)
                setIsDeleteModalOpen(true)
                setEditId(record?.id)
              }}
            >
              <DeleteIcon className="delete-icon" />
              <span className="activity-text font-normal text-error-500">{t('button.delete')}</span>
            </div>
          ),
        },
      ],
    }
  }

  const columns: ColumnsType = [
    {
      title: COMMON.NAME,
      dataIndex: 'name',
      key: 'name',
      align: 'start',
      width: 690,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render(value, record) {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={record?.name} />
      },
    },
    {
      title: 'Work Comp Code',
      dataIndex: 'work_comp_code',
      key: 'work_comp_code',
      align: 'start',
      width: 300,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render(value, record) {
        return <HeadingText classString="text-neutral-800 activity-text font-medium" text={record?.work_comp_code} />
      },
    },
    {
      title: t('table.certification.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 200,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      className: 'center-align',
      render: (_value, record) => {
        return <HeadingText classString={`${record.status === CERTIFICATION_STATUS.ACTIVE ? 'active' : 'inactive'} `} text={formatTitle(record?.status)} />
      },
    },
    {
      title: t('table.certification.actions'),
      dataIndex: '',
      key: 'name',
      align: 'center',
      width: 140,
      render: (_value, record) => {
        return (
          <div className="flex w-full justify-center p-2">
            <Dropdown
              rootClassName="table-action-dropdown"
              trigger={['click']}
              menu={getMenu(record, _value.id)}
              placement="bottomRight"
              open={actionMenuOpen[_value.id]}
              onOpenChange={(open) => {
                handleOpenChange(_value.id, open)
              }}
            >
              <div className="action-cell flex items-center justify-center three-dot-icon">
                <ThreeDotIcon className="cursor-pointer icon" />
              </div>
            </Dropdown>
          </div>
        )
      },
    },
  ]

  const handleOpenChangePopover = (newOpen: boolean) => {
    setIsCreateMenuOpen(newOpen)
  }

  const createLineOfBssinessBtn = (
    <Popover
      trigger={['click']}
      content={
        <AddFacilityType
          editData={editData}
          isModalOpen={isCreateMenuOpen}
          setIsModalOpen={setIsCreateMenuOpen}
          editId={editId}
          setEditId={setEditId}
          statusCheck={statusCheck}
          setStatusCheck={setStatusCheck}
          error={error}
          form={form}
          setError={setError}
          setSortTable={setSortTable}
        />
      }
      placement="bottomRight"
      open={isCreateMenuOpen}
      rootClassName="general-setting-popover"
      onOpenChange={handleOpenChangePopover}
    >
      <CustomBtn
        isIcon
        svgIcon={<PlusIcon />}
        text={BUTTON.CREATE}
        type="primary"
        onClick={() => {
          setEditData(null)
          form.resetFields()
          form.setFieldsValue({ name: '', work_comp_code: '' })
          setEditId('')
          setStatusCheck(true)
          setIsCreateMenuOpen(true)
          setError({ name: '', work_comp_code: '' })
        }}
      />
    </Popover>
  )

  return (
    <div>
      <CustomTable
        column={columns}
        data={lineOfData}
        total={paginationInfo.total}
        limit={paginationInfo.limit}
        current={paginationInfo.current}
        search={search}
        onChangePage={handlePageChange}
        onChange={handleTableChange}
        name={COMMON.FACILITY_TYPE}
        isSearch
        searchPlaceholder={t('placeholder.search')}
        onSearch={onSearch}
        onSearchChange={(e) => {
          handleChange(e.target.value)
        }}
        tableButton={createLineOfBssinessBtn}
      />

      {isDeleteModalOpen && (
        <DeleteCancelModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          cancelText={DELETE_MODAL_TEXT.CANCEL}
          okClick={() => {
            void handleDeleteLOB(editId)
          }}
          deleteName={deleteName}
          subHead={DELETE_MODAL_TEXT.DELETE_DESC}
          mainHead={DELETE_MODAL_TEXT.DELETE_HEAD_LOB}
          okText={DELETE_MODAL_TEXT.DELETE}
        />
      )}
    </div>
  )
}

export default FacilityType
